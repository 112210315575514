import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../../../../context/AuthContext";
import { TControlContext } from "../../hooks/useTControl";
import { TControlSelect } from "../../../../utils/TControlSelect/TControlSelect";
import { getMonthData, getSmenaData, getTodayData } from "../../../../http/http";
import { TControlInput } from "../../../../utils/TControlInput/TControlInput";
import { ModelTable } from "../../../../../../components/ModelTable/ModelTable";


export let PrintMonthData = () => {

  const { authTokens } = useContext(AuthContext);

  let {
    memoizedBoilersHouses,
    memoizedDate,
    memoizedSmena,
    memoizedErenergyResource
  } = useContext(TControlContext)

  let [electricityByMonth, setElectricityByMonth] = useState([]);
  let [waterByMonth, setWaterByMonth] = useState([]);
  let [gasByMonth, setGasByMonth] = useState([]);

  const formatTime = (timeString) => {
    const [days, other] = timeString.split(' ').map(Number); // Разделяем часы, минуты, секунды
    if (days === 1) {
        return '24:00:00'; // Если это 1 00:00:00, преобразуем в 24:00:00
    }
    return timeString; // В остальных случаях возвращаем оригинальную строку
};

  useEffect(() => {
    if (memoizedSmena?.activeSmena?.[0]?.pk) {
      getSmenaData(authTokens,
        'electricity',
        memoizedBoilersHouses?.selecterBoilersHouse,
        memoizedSmena?.activeSmena?.[0]?.pk,
        memoizedDate.selectedDate,
        setElectricityByMonth)
      getSmenaData(authTokens,
        'water',
        memoizedBoilersHouses?.selecterBoilersHouse,
        memoizedSmena?.activeSmena?.[0]?.pk,
        memoizedDate.selectedDate,
        setWaterByMonth)
      getSmenaData(authTokens,
        'gas',
        memoizedBoilersHouses?.selecterBoilersHouse,
        memoizedSmena?.activeSmena?.[0]?.pk,
        memoizedDate.selectedDate,
        setGasByMonth)
    }
  }, [memoizedBoilersHouses.selecterBoilersHouse, memoizedDate.selectedDate, memoizedErenergyResource.selectedErenergyResource, memoizedSmena?.activeSmena])


  return (
    <div style={{ marginTop: '50px' }}>
      <form className='hydrologist_worker-form'>
        <TControlSelect
          name={"Выберите котельную"}
          list={memoizedBoilersHouses?.boilersHouses}
          value={'pk'}
          visible_value={'address'}
          getter={memoizedBoilersHouses?.selecterBoilersHouse}
          setter={memoizedBoilersHouses?.setSelecterBoilersHouse}
          parse={'int'}
          defaultValue={memoizedBoilersHouses?.selecterBoilersHouse}
        />
        <TControlSelect
          name={"Выберите энергоресурс"}
          list={[{ name: 'Электричество', value: 'electricity' }, { name: 'Вода', value: 'water' }, { name: 'Газ', value: 'gas' }]}
          value={'value'}
          visible_value={'name'}
          getter={memoizedErenergyResource?.selectedErenergyResource}
          setter={memoizedErenergyResource?.setSelectedErenergyResource}
          parse={'String'}
          defaultValue={memoizedErenergyResource?.selectedErenergyResource}
        />
        <TControlInput
          type='date'
          title='Выберите дату'
          name='selectDate'
          setter={memoizedDate?.setSelectedDate}
          getter={memoizedDate?.selectedDate}
          value={memoizedDate?.selectedDate}
          required={true}
        />
      </form>


      <div>
        {memoizedErenergyResource?.selectedErenergyResource === 'electricity' &&
          <ModelTable>
            <thead>
              <tr>
                <th></th>
                <th colSpan='3'>Котельная {
                  memoizedBoilersHouses?.boilersHouses?.find(
                    house => house.pk === memoizedBoilersHouses?.selecterBoilersHouse
                  )?.address
                }</th>
              </tr>
              <tr>
                <th>Дата измерения</th>
                <th>Показания ПУ</th>
                <th>Время работы ПУ</th>
                <th>Потребление эл.энергии КВт/час</th>
              </tr>
            </thead>
            <tbody>
              {electricityByMonth?.map((item, index) => (
                <tr key={index}>
                  <td>{item?.created_at ? new Date(item.created_at).toLocaleDateString('ru-RU') : ''}</td>
                  <td>{item?.electricity_consumption}</td>
                  <td>{formatTime(item?.electricity_consumption_time)}</td>
                  <td>{item?.electricity_consumption_difference}</td>
                </tr>
              ))}
            </tbody>
          </ModelTable>}

        {memoizedErenergyResource?.selectedErenergyResource === 'water' &&
          <ModelTable>
            <thead>
              <tr>
                <th></th>
                <th colSpan='3'>Котельная {
                  memoizedBoilersHouses?.boilersHouses?.find(
                    house => house.pk === memoizedBoilersHouses?.selecterBoilersHouse
                  )?.address
                }</th>
              </tr>
              <tr>
                <th>Дата измерения</th>
                <th>Показания ПУ</th>
                <th>Время работы ПУ</th>
                <th>Расход воды м3/сут</th>
              </tr>
            </thead>
            <tbody>
              {waterByMonth?.map((item, index) => (
                <tr key={index}>
                  <td>{item?.created_at ? new Date(item.created_at).toLocaleDateString('ru-RU') : ''}</td>
                  <td>{item?.water_consumption}</td>
                  <td>{formatTime(item?.water_consumption_time)}</td>
                  <td>{item?.water_consumption_difference}</td>
                </tr>
              ))}
            </tbody>
          </ModelTable>}

        {memoizedErenergyResource?.selectedErenergyResource === 'gas' &&
          <ModelTable>
            <thead>
              <tr>
                <th></th>
                <th colSpan='3'>Котельная {
                  memoizedBoilersHouses?.boilersHouses?.find(
                    house => house.pk === memoizedBoilersHouses?.selecterBoilersHouse
                  )?.address
                }</th>
              </tr>
              <tr>
                <th>Дата измерения</th>
                <th>Показания ПУ</th>
                <th>Время работы ПУ</th>
                <th>Расход газа м3/сут</th>
              </tr>
            </thead>
            <tbody>
              {gasByMonth?.map((item, index) => (
                <tr key={index}>
                  <td>{item?.created_at ? new Date(item.created_at).toLocaleDateString('ru-RU') : ''}</td>
                  <td>{item?.gas_consumption}</td>
                  <td>{formatTime(item?.gas_consumption_time)}</td>
                  <td>{item?.gas_consumption_difference}</td>
                </tr>
              ))}
            </tbody>
          </ModelTable>}
      </div>

    </div>
  )
}