import Footer from "../components/Footer";
import Header from "../components/Header";
import { Link } from 'react-router-dom';
import v2 from '../images/ads.mp4'
import React, { useContext, useEffect, useState, useRef } from 'react';



const AdsPage = () => {

    const pdfPath = 'media/file/ADS_Kr_instruktsia_30_10.pdf';
    const [IsV, setIsV] = useState(false);


    function handleButtonClick() {
        setIsV(!IsV);
    }


    return (
        <>
            <Header />
            <div className="ForAds">
                <div className="container">
                    <div className="ForAds__title">
                        Программа «ДиспетчерКонтроль»
                    </div>
                    <div className="ForAds__inf">
                        Разработана Инжиниринговым центром «Технологии цифровой среды» Федерального государственного бюджетного образовательного учреждения высшего образования «Орловский государственный университет имени И.С. Тургенева».<br /><br />
                    </div>
                </div>
                <div className="ForAds__block1">
                    <div className="container">
                        <div className="ForAds__block1-describe1">
                            Веб-сервис «ДиспетчерКонтроль» позволит усовершенствовать работу
                            Вашего предприятия.
                            Для пользования Веб-сервисом «ДиспетчерКонтроль» его не нужно
                            устанавливать на ваших компьютерах.
                            Это сайт в интернете.
                            Работать можно через браузер с любого устройства и рабочего места.
                        </div>
                    </div>
                </div>
                <div className="ForAds__block2">
                    <div className="container">
                        <div className="ForAds__block2-describe2">
                            <b>С помощью программы «ДиспетчерКонтроль» вы сможете:</b>
                            <br /><br />- Принимать, обрабатывать по категориям заявки о возникших
                            нештатных ситуациях на сетях;
                            <br />- Получать отражение на электронной карте места аварий;
                            <br />- Формировать электронный журнал заявок, аварий и обращений;
                            <br />- Осуществлять поиск по адресу, периоду времени, дате, объектов
                            аварий с отражением на электронной карте;
                            <br />- Оперативно передать заявки исполнителям;
                            <br />- Контролировать состояние исполнения заявок;
                            <br />- Получать аналитические отчеты в разрезе необходимых параметров.
                        </div>
                    </div>
                </div>
                <div className="ForAds__block2">
                    <div className="container">
                        <div className="ForAds__block2-describe2">
                            Разграничение по функциональным полномочиям через код
                            доступа: диспетчер, технический администратор, ответственный
                            исполнитель, руководитель.
                            Доступ к информации согласно статусам пользователей в сервисе.
                            Каждый видит только то, что необходимо для его работы.
                        </div>
                    </div>
                </div>
                <div className="ForAds__ForMargin">
                    <button className="ForVzy__btn ForAds__ForVBtn" onClick={handleButtonClick}>
                        Демо видео
                    </button>
                    <div className="ForVzy__button">
                        <Link className="ForVzy__btn" to={pdfPath} target="_blank">Руководство пользователя</Link>
                    </div>
                </div>
                {IsV === true ? (
                    <div className="ForAds__BlocV">
                        <video className="AdsPage__V2" width="1200" height="660" controls>
                            <source src={v2} type="video/mp4" />
                        </video>
                    </div>
                ) : (
                    ''
                )}
            </div >
            <Footer />

        </>
    )
}

export default AdsPage;