//Отключено
import React from "react";
import "../../css/Button.css";

const ButtonTypeHouse = ({ updateState }) => {
  const handleNextClick = () => {
    updateState(3);
  };
  return (
    <button className="btn-osn-wear" type="button" onClick={handleNextClick}>
      Далее
    </button>
  );
};

export default ButtonTypeHouse;
