// ButtonFundament.js
import React from "react";
import "../../css/Wear.css";

const ButtonBalcon = ({ onButtonClick, balconyType }) => {
  const handleClick = () => {
    // Определите данные для таблицы фундамента в зависимости от foundationType
    let data;

    switch (balconyType) {
      case "1":
        data = [
          ["Признаки износа", "Количественная оценка", "Физический износ, %"],
          ["Мелкие повреждения металлических обделок и ограждений, усадочные трещины на стенках лоджий", "Повреждения на площади до 10%. Суммарная длина усадочных трещин на 1 м2 до 1 м", "0-20"],
          ["Повреждения пола и гидроизоляции, следы протечек на стене, трещины на нижней поверхности плиты и на стенках", "Повреждения на площади до 20%, уклон пола менее 1%. Ширина раскрытия трещин до 1 мм", "21-40"],
          ["Скалывание бетона стенок в местах опирания плит, трещины в стенах и плитах, прогиб плит", "Ширина раскрытия трещин до 2 мм. Прогиб плит до 1/100 пролета", "41-60"],
          ["Прогрессирующие прогибы плит, разрушение опорных участков стенок, деформации стенок, разрушение ограждений", "Прогиб плит более 1/100 пролета. Трещины более 2 мм. Выпучивание стенок более 1/150", "61-80"]
      ];

        break;
      case "2":
        data = [
          ["Признаки износа", "Количественная оценка", "Физический износ, %"],
          ["Мелкие повреждения металлических обделок и ограждений, усадочные трещины на стенках лоджий", "Повреждения на площади до 10%. Суммарная длина усадочных трещин на 1 м2 до 1 м", "0-20"],
          ["Повреждения пола и гидроизоляции, следы протечек на стене, трещины на нижней поверхности плиты и на стенках", "Повреждения на площади до 20%, уклон пола менее 1%. Ширина раскрытия трещин до 1 мм", "21-40"],
          ["Скалывание бетона стенок в местах опирания плит, трещины в стенах и плитах, прогиб плит", "Ширина раскрытия трещин до 2 мм. Прогиб плит до 1/100 пролета", "41-60"],
          ["Прогрессирующие прогибы плит, разрушение опорных участков стенок, деформации стенок, разрушение ограждений", "Прогиб плит более 1/100 пролета. Трещины более 2 мм. Выпучивание стенок более 1/150", "61-80"]
      ];
        break;
      
      default:
        console.log("Ошибка !")
    }

    onButtonClick("Балкон", data);
  };

  return  <button className="btn_addwear_wear"  onClick={handleClick}>Балкон</button>;

};

export default ButtonBalcon;
