export let getNowDate = () => {//Получаем текущую дату в формате ГГГГ-ММ-ЧЧ
  const today = new Date();
  const year = today.getFullYear();
  const month = (today.getMonth() + 1).toString().padStart(2, '0');
  const day = today.getDate().toString().padStart(2, '0');
  const currentDate = `${year}-${month}-${day}`;
  return currentDate
}

export let dateConverter = (dateString) => {
  const parts = dateString.split('-');
  if (parts.length !== 3) {
    // Если строка даты не соответствует ожидаемому формату, возвращаем исходную строку
    return dateString;
  }

  const year = parts[0];
  const month = parts[1];
  const day = parts[2];

  return `${day}.${month}.${year}`;
}


export let formatDate = (inputDate) => {
  // Разбиваем входную дату по точкам
  var parts = inputDate.split('.');

  // Проверяем, что количество элементов равно 3 (день, месяц, год)
  if (parts.length !== 3) {
    throw new Error('Некорректный формат даты');
  }

  // Переупорядочиваем элементы массива и формируем строку в формате ГГГГ-ММ-ДД
  var formattedDate = parts[2] + '-' + parts[1] + '-' + parts[0];

  return formattedDate;
}

export let getDaysInMonth = (selectedDate, setter) => {
  const today = new Date();
  const month = selectedDate ? parseInt(selectedDate.split('-')[1], 10) : today.getMonth() + 1;
  const year = selectedDate ? parseInt(selectedDate.split('-')[0], 10) : today.getFullYear();

  const daysInMonth = new Date(year, month, 0).getDate();

  let dates = [];
  for (let i = 1; i <= daysInMonth; i++) {
    dates.push(`${i.toString().padStart(2, '0')}.${month.toString().padStart(2, '0')}.${year}`);
  }

  setter(dates);
}

export let sumTime = (data) => {
  let totalMinutes = 0;

  data?.forEach((item) => {
    if (item?.device_operating_time && item.device_operating_time.split(' ').length === 1) {
      const [hours, minutes] = item.device_operating_time.split(':').map(Number);
      totalMinutes += hours * 60 + minutes; // Складываем все временные интервалы в минутах
    }
    else if (item?.device_operating_time && item.device_operating_time.split(' ').length === 2) {
      const [days, otherTime] = item.device_operating_time.split(' ');
      totalMinutes += days * 24 * 60; // Складываем все временные интервалы в минутах
    }
  });

  const hours = Math.floor(totalMinutes / 60); // Получаем количество целых часов
  const minutes = totalMinutes % 60; // Получаем количество оставшихся минут

  const sum = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`; // Форматируем результат

  return sum;
}

export let sumWaterConsumption = (data) => {
  let sum = 0
  data?.map((item) => {
    if (item?.water_consumption) {
      sum += item.water_consumption
    }
  })

  return sum
}

export const getMonthName = (dateString) => {
  const months = [
    'январь', 'февраль', 'март',
    'апрель', 'май', 'июнь',
    'июль', 'август', 'сентябрь',
    'октябрь', 'ноябрь', 'декабрь'
  ];

  const date = new Date(dateString);
  const monthIndex = date.getMonth(); // Получаем индекс месяца (от 0 до 11)
  const monthName = months[monthIndex]; // Получаем название месяца из массива

  return monthName;
}