import { NavLink } from "react-router-dom";
import Layout from "../components/Layout";
import React, { useState } from "react";
import Slider from 'react-slick';
import Footer from "../components/Footer";
import Header from "../components/Header";
import screenLogin from '../images/vxod1.png';
import screenPodtverka from '../images/podtverka.png';
import screenVvodka from '../images/vvodka.png';
import screenTablica2 from '../images/tablica2.png';
import screenOtkaz from '../images/otkaz2.jpg';
import screenVivod from '../images/vivod.png';
import 'loupe-js/dist/style.css';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { saveAs } from 'file-saver';
import { Link } from 'react-router-dom';

const VzyPage = () => {

    const pdfPath = '/media/file/Rukovodstvo_polzovatelya.pdf';


    return (
        <>
            <Header />
            <div className="ForVzy">
                <div className="container">
                    <div className="ForVzy__title">
                        Веб-сервис «ВЗУконтроль»
                    </div>
                    <div className="ForVzy__inf">
                        Разработан Инжиниринговым центром «Технологии цифровой среды» Федерального государственного бюджетного образовательного учреждения высшего образования «Орловский государственный университет имени И.С. Тургенева».<br /><br />
                    </div>
                </div>
                <div className="ForVzy__block1">
                    <div className="container">
                        <div className="ForVzy__block1-describe1">
                            Веб-сервис «ВЗУконтроль» - основа  информационного обмена оперативными данными работы оборудования ВЗУ, станций подъема, с диспетчерской службой, подразделениями управления предприятий Водоканала на основе цифровых производственных технологий.
                        </div>
                    </div>
                </div>
                <div className="ForVzy__block2">
                    <div className="container">
                        <div className="ForVzy__block2-describe2">
                            <b>Описание возможностей:</b><br /><br />
                            Программа предоставляет пользователям следующие возможности:
                            <br />- ведение реестра почасовых технических показателей работающего
                            оборудования конкретных ВЗУ/ насосных станций за сутки.
                            <br />- формирование сводного реестра почасовых технических показателей
                            работающего оборудования ВЗУ/ насосных станций предприятия за сутки с
                            возможностью выгрузки в формате Excel.
                            <br /> - ведение реестра почасовых показателей хлорирования воды за сутки.
                            <br /> - ведение сводного реестра суточного объема подачи воды в систему, в
                            том числе в разрезе ВЗУ, в течение календарного месяца с возможностью
                            выгрузки в формате Excel.
                            <br /> - ведение реестра отказов оборудования, времени ремонта (замены)
                            конкретной ВЗУ, насосной станции.
                            <br /> - формирование сводного реестра отказов оборудования, времени
                            ремонта (замены) ВЗУ/ насосных станций предприятия за сутки.
                            <br />- посменный отчет о состоянии оборудования ВЗУ, скважин, насосных
                            станций (в работе, в резерве, ремонт).
                            <br />- доступ к информации о ВЗУ/насосной станции;
                            <div className="ForVzy__text">
                                cхема оборудования и водопроводных сетей,
                                <br />технический регламент,
                                <br />технический паспорт.
                            </div>
                            Для доступа к использованию этих возможностей администратором
                            программы предоставляются пользователю соответствующие права доступа
                            (логин, пароль)                        </div>
                    </div>
                </div>
                <div className="ForVzy__block2">
                    <div className="container">
                        <div className="ForVzy__block2-describe2">
                            <b>Программа Веб-сервис «ВЗУконтроль» позволяет осуществлять:</b><br /><br /> Объективный контроль почасовых динамических параметров подачи воды в систему, показателей ее качества, а также текущего рабочего состояния оборудования ВЗУ, станций подъема, аварийной устойчивости инженерных систем на основании накопленных данных по авариям и инцидентам, а также контроль отклонения от установленных параметров динамических показателей.
                            <div className="ForVzy__button">
                                <Link className="ForVzy__btn" to={pdfPath} target="_blank">Руководство пользователя</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )

}
export default VzyPage;