import { useContext, useEffect, useState } from "react"
import { ModelTable } from "../../../../../../../components/ModelTable/ModelTable"
import AuthContext from "../../../../../../../context/AuthContext";
import { fetchAllDaylyReportForDateExcel, getDailyReportAll } from '../../../http/dispatcherHttp'
import { dispatcher_context } from "../../../hooks/useStats_dispatcher";
import { dateConverter } from '../../../../../../../utils/utils'
import Jelly from "../../../../../../../components/Jelly";
import { RSODynamicButton } from '../../../../../utils/RSODynamicButton/RSODynamicButton'
import { useForm } from "react-hook-form";

export let RSODynamicDispDateReportHourly = () => {

  let { authTokens } = useContext(AuthContext);
  let { selectedDate } = useContext(dispatcher_context)

  let [dateReportAll, setDateReportAll] = useState([])
  let [reportLength, setReportLength] = useState([])
  let [hoursTable] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23])

  const { handleSubmit } = useForm({ mode: 'onBlur' });

  let saveExcel = (e) => {
    e.preventDefault();
    fetchAllDaylyReportForDateExcel(authTokens, selectedDate)
  }

  function sumFirstElementsInArrays(list) {
    let total = 0;
    for (const key in list) {
      if (list.hasOwnProperty(key) && Array.isArray(list[key]) && list[key].length > 0) {
        total += list[key][0];
      }
    }
    return total;
  }

  useEffect(() => {
    getDailyReportAll(authTokens, selectedDate, setDateReportAll, setReportLength)
  }, [selectedDate])

  return (
    <>
      {(dateReportAll?.length !== 0 || reportLength.length !== 0) ?
        <>
          <ModelTable style="min">
            <thead>
              <tr>
                <th colSpan={sumFirstElementsInArrays(reportLength) + 1}>Отчет за {dateConverter(selectedDate)}</th>
              </tr>
              <tr>
                <th></th>
                {dateReportAll?.map((item, index) => {
                  return (
                    <>
                      {reportLength?.[item.vzu_name]?.[0] ? (
                        <th colSpan={reportLength?.[item.vzu_name]?.[0]}>
                          {item.vzu_name}
                        </th>
                      ) : ('')}
                    </>
                  )
                })}
              </tr>
              <tr>
                <th>Час</th>
                {dateReportAll.map((item, index) => {
                  return (
                    <>
                      {reportLength?.[item.vzu_name][2].map((item1, index1) => {
                        return (
                          <th>
                            {item1}
                          </th>
                        )
                      })}
                    </>
                  )
                })}
              </tr>
            </thead>
            <tbody>
              {hoursTable.map((item, index) => {
                let i = -1;
                return (
                  <>
                    <tr>
                      <td>{item}</td>
                      {dateReportAll.map((item1, index1) => {
                        let flag1 = true
                        let flag2 = true
                        if (reportLength[item1.vzu_name][1].length !== 0) {
                          i++
                          return (
                            <>
                              {item1.dynamics.map((item2, index2) => {
                                return (
                                  <>
                                    {item2.hour === item ? (
                                      <>
                                        {flag1 = false}
                                        {reportLength[item1.vzu_name][1].map((item3, index3) => {
                                          return (
                                            <>
                                              {item3 !== 'max_chlorine' ? (
                                                <>
                                                  <td className={i % 2 === 0 ? 'td-even' : 'td-odd'}>
                                                    {item3 === 'water_level_in_reseruar' ? (
                                                      <>
                                                        {item2[item3][0]}
                                                      </>
                                                    ) : (
                                                      <>
                                                        {item2[item3]}
                                                      </>
                                                    )}
                                                  </td>
                                                </>
                                              ) : ('')}
                                            </>
                                          )
                                        })}
                                      </>
                                    ) : ('')}
                                  </>
                                )
                              })}
                              {flag1 ? (
                                <>
                                  {reportLength[item1.vzu_name][1].map((item3, index3) => {
                                    return (
                                      <>
                                        {item3 !== 'max_chlorine' ? (
                                          <td className={i % 2 === 0 ? 'td-even' : 'td-odd'}></td>
                                        ) : ('')}
                                      </>
                                    )
                                  })}
                                  {flag1 = false}
                                </>
                              ) : ('')}
                              {reportLength[item1.vzu_name][1].indexOf('max_chlorine') !== -1 ? (
                                item1.chlorine.map((item2, index2) => {
                                  return (
                                    <>
                                      {item2.hour === item ? (
                                        <>
                                          {flag2 = false}
                                          <td className={i % 2 === 0 ? 'td-even' : 'td-odd'}>
                                            {item2.max_chlorine}
                                          </td>
                                        </>
                                      ) : ('')}
                                    </>
                                  )
                                })
                              ) : (
                                <>
                                  {flag2 = false}
                                </>
                              )}
                              {flag2 ? (
                                <>
                                  <td className={i % 2 === 0 ? 'td-even' : 'td-odd'}></td>
                                  {flag2 = false}
                                </>
                              ) : ('')}
                            </>
                          )
                        }

                      })}
                    </tr>
                  </>
                )
              })}

            </tbody>
          </ModelTable>

          <form onSubmit={e => handleSubmit(saveExcel(e))} style={{ marginTop: "30px" }}>
            <div style={{ display: "flex", alignItems: "center"}}>
              <RSODynamicButton
                title='Скачать отчет'
                type='sumbit'
              />
              <h5 style={{ marginLeft: "20px" }}>Перед отпрвкой на печать заполните недостающие данные в Excel</h5>
            </div>
          </form>
        </>

        : <Jelly />
      }
    </>
  )
}