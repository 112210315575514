import Footer from "../components/Footer";
import Header from "../components/Header";


const AdsPage = () => {
    return (
        <>
            <Header />
            <div className="ForTContr">
                <div className="container">
                    <div className="ForTContr__title">
                        Программа «ТЕПЛОконтроль»
                    </div>
                    <div className="ForTContr__inf">
                        Разработана Инжиниринговым центром «Технологии цифровой среды» Федерального государственного бюджетного образовательного учреждения высшего образования «Орловский государственный университет имени И.С. Тургенева».<br /><br />
                    </div>
                </div>
                <div className="ForTContr__block1">
                    <div className="container">
                        <div className="ForTContr__block1-describe1">
                            <b>Контролируемые показатели:</b>
                            <br /><br />Состояние оборудования ( NN котлов, насосов): в работе, в резерве, в ремонте.
                            <br />Параметры теплоносителя:
                            Температура Т1  подающего трубопровода,   Т2 – обратного трубопровода
                            Давление Р1   подающего трубопровода, Р2 - обратного трубопровода.
                            <br /> Параметры горячего водоснабжения:
                            Температура Т3 подающего трубопровода,  Т4 обратного трубопровода
                            Давление  Р3 подающего трубопровода, Р4 обратного трубопровода.
                            <br /> Pвх - давление холодной воды на вводе в котельную.
                            <br />Расход воды за 24 часа по узлу учета.
                            <br />Pгаз - давление газа на вводе в котельную.
                            <br />Расход газа за 24 часа по узлу учета.
                            <br />Потребление электроэнергии.
                            <br />Расход электроэнергии за 7 дней по узлу учета.
                        </div>
                    </div>
                </div>
                <div className="ForTContr__block2">
                    <div className="container">
                        <div className="ForTContr__block2-describe2">
                            <b>Функционал программы обеспечивает:</b>
                            <br /><br />Ведение реестра технических показателей  работающего оборудования котельных
                            (такт 2 часа);
                            <br />Формирование сводного реестра технических показателей  работающего
                            оборудования котельных предприятия за сутки с возможностью выгрузки в
                            формате Excel;
                            <br /> Ведение реестра отказов оборудования, времени ремонта (замены) котельной;
                            <br />Формирование сводного реестра отказов оборудования, времени ремонта
                            (замены) котельных предприятия за сутки;
                            <br />Посменный отчет о состоянии оборудования котельных (в работе, в резерве,
                            ремонт).
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default AdsPage;