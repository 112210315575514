import React, { useState, useContext, useEffect } from "react";

import { API_URL } from "../../../../../../../config";
import AuthContext from "../../../../../../../context/AuthContext";

import "./ButtonAddWear.css";

export const Krisa = () => {
  const { authTokens } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    plotName: "",
    wear: "",
    area: "",
    photo: null,
  });

  // Определяем переменные
  const currentDate = new Date(); // Получаем текущую дату
  const year = currentDate.getFullYear(); // Получаем год
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Получаем месяц (месяцы начинаются с 0, поэтому добавляем 1) и добавляем 0 спереди
  const day = String(currentDate.getDate()).padStart(2, "0"); // Получаем день и добавляем 0 спереди
  const data_in_wear = `${year}-${month}-${day}`; // Форматируем в строку "YYYY-MM-DD"

  const idHouse = localStorage.getItem("ID_House"); // Получаем ID из localStorage

  const element = 4; // Элемент

  const roofType = 1;

  // Функция обнуления значений
  const resetFormData = () => {
    setFormData({
      plotName: "",
      wear: "",
      area: "",
      photo: null,
    });
  };

  //Считывание текста
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  //Считывание фото
  const handlePhotoUpload = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      photo: file,
    }));
  };

  // Отправка данных
  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestData = {
      position: formData.plotName,
      data: data_in_wear,
      Element: element,
      volume: formData.area,
      degreeOfDamage: formData.wear,
      idHouse: idHouse,
    };

    try {
      const response = await fetch(`${API_URL}/CivilWear/Damage/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Ошибка при отправке данных: ${errorText}`);
      }

      const result = await response.json();
      console.log("Успешно отправлено:", result);
      alert("Износ добавлен !");
      resetFormData();
    } catch (error) {
      console.error("Ошибка при отправке:", error);
    }
  };

  let data = []; //Данные для таблицы

  switch (roofType) {
    case 1:
      data = [
        ["Признаки износа", "Количественная оценка", "Физический износ, %"],
        [
          "Ослабление креплений: болтов, хомутов, скоб; повреждение деталей слуховых окон",
          "-",
          "0-20",
        ],
        [
          "Поражение гнилью мауэрлата и концов стропильных ног, ослабление врубок и соединений",
          "Повреждения на площади до 20%",
          "21-40",
        ],
        [
          "Поражение гнилью древесины мауэрлата, стропил, обрешетки; наличие дополнительных временных креплений стропильных ног; увлажнение древесины",
          "То же, до 50%",
          "41-60",
        ],
        [
          "Прогибы стропильных ног, поражение гнилью и жучком древесины деталей крыши",
          "-",
          "61-80",
        ],
      ];

      break;
    case 2:
      data = [
        ["Признаки износа", "Количественная оценка", "Физический износ, %"],
        [
          "Мелкие повреждения деревянных деталей, кирпичных столбиков",
          "-",
          "0-20",
        ],
        [
          "Трещины в кирпичных столбиках или опорных участках железобетонных панелей, мелкие пробоины в плитах покрытия, гниль в деревянных деталях",
          "Повреждения на площади до 20%",
          "21-40",
        ],
        [
          "Неглубокие трещины в железобетонных стропильных балках и плитах, протечки крыш",
          "Ширина раскрытия трещин до 2 мм",
          "41-60",
        ],
        [
          "Сквозные трещины в стропильных балках, плитах; прогибы плит покрытия; разрушение кирпичных столбиков и опорных участков железобетонных панелей стен; обнажение арматуры",
          "Ширина раскрытия трещин более 2 мм. Прогибы плит более 1/100 пролета. Повреждения на площади более 20%",
          "61-80",
        ],
      ];

      break;
    case 3:
      data = [
        ["Признаки износа", "Количественная оценка", "Физический износ, %"],
        [
          "Мелкие выбоины на поверхности плит",
          "Повреждения на площади до 15%",
          "0-20",
        ],
        [
          "Трещины в панелях, пробоины, следы протечек. Оседание утеплителя и его высокая влажность",
          "Ширина трещин до 1 мм. Протечки на площади до 10%. Относительная влажность утеплителя более 20%",
          "21-40",
        ],
        [
          "Множественные трещины в панелях, протечки и промерзания, прогибы панелей",
          "Ширина трещин до 2 мм. Протечки и промерзания на площади до 25%. Прогиб панели до 1/80 пролета",
          "41-60",
        ],
        [
          "Местные разрушения панелей, деструкция утеплителя, протечки и промерзания",
          "-",
          "61-80",
        ],
      ];

      break;

    default:
      console.log("Ошибка !");
  }

  return (
    <div className="container_Wear_ButtonAddWear">
      <form className="form_Wear_ButtonAddWear" onSubmit={handleSubmit}>
        <h3>Крыша:</h3>
        <div className="form-group_Wear_ButtonAddWear">
          <label htmlFor="plotName" className="label_Wear_ButtonAddWear">
            Обозначение участка
          </label>
          <input
            className="input_Wear_ButtonAddWear"
            type="text"
            id="plotName"
            name="plotName"
            value={formData.plotName}
            onChange={handleChange}
          />
        </div>

        <div className="form-group_Wear_ButtonAddWear">
          <label htmlFor="wear" className="label_Wear_ButtonAddWear">
            Фактический физический износ участка
          </label>
          <input
            className="input_Wear_ButtonAddWear"
            type="text"
            id="wear"
            name="wear"
            value={formData.wear}
            onChange={handleChange}
          />
        </div>

        <div className="form-group_Wear_ButtonAddWear">
          <label htmlFor="area" className="label_Wear_ButtonAddWear">
            Площадь участка
          </label>
          <input
            className="input_Wear_ButtonAddWear"
            type="text"
            id="area"
            name="area"
            value={formData.area}
            onChange={handleChange}
          />
        </div>

        <div className="form-group_Wear_ButtonAddWear">
          <label htmlFor="photo" className="label_Wear_ButtonAddWear">
            Прикрепить фото
          </label>
          <input
            className="input_Wear_ButtonAddWear"
            type="file"
            id="photo"
            onChange={handlePhotoUpload}
          />
        </div>

        <button type="submit" className="submit-button_Wear_ButtonAddWear">
          Сохранить данные
        </button>
      </form>

      <div className="table-container_Wear_ButtonAddWear">
        <table className="table_Wear_ButtonAddWear">
          <thead>
            <tr>
              {data[0] &&
                data[0].map((header, index) => <th key={index}>{header}</th>)}
            </tr>
          </thead>
          <tbody>
            {data.slice(1).map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
