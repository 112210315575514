//Компонента ввода инжинерных систем мкд
import React from "react";
import "../css/Inz.css";

const Inz = ({ formData, onDataChange }) => {
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    onDataChange({ ...formData, [id]: value });
  };

  return (
    <div className="main-content_inz">
      <div className="data_mane_inz">
        <div className="data_input_inz">
          <input
            className="input-field-inz-wear"
            type="number"
            id="text16"
            placeholder="Система горячего водоснабжения, всего, м"
            required
            value={formData.text16}
            onChange={handleInputChange}
          />
          <input
            className="input-field-inz-wear"
            type="number"
            id="text17"
            placeholder="Система центрального отопления, всего, м"
            required
            value={formData.text17}
            onChange={handleInputChange}
          />
          <input
            className="input-field-inz-wear"
            type="number"
            id="text18"
            placeholder="Система холодного водоснабжения, всего, м"
            required
            value={formData.text18}
            onChange={handleInputChange}
          />
          <input
            className="input-field-inz-wear"
            type="number"
            id="text19"
            placeholder="Система канализации, всего, м"
            required
            value={formData.text19}
            onChange={handleInputChange}
          />
          <input
            className="input-field-inz-wear"
            type="number"
            id="text20"
            placeholder="Система электрооборудования, всего, м"
            required
            value={formData.text20}
            onChange={handleInputChange}
          />
          <input
            className="input-field-inz-wear"
            type="number"
            id="text21"
            placeholder="Мусоропроводы, всего, м"
            required
            value={formData.text21}
            onChange={handleInputChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Inz;
