import React from "react";
import "../../ResidentialHouse.css";

export const AddDatatoHome = ({ formDataResidential, onAdd }) => {
  return (
    <div className="AddDatatoHome_main">
      <button 
        className="AddDatatoHome_button" 
        onClick={() => onAdd(formDataResidential)}
      >
        Добавить здание
      </button>
    </div>
  );
};
