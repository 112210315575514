import { useContext, useEffect, useState } from "react"
import { dispatcher_context } from "../../../hooks/useStats_dispatcher";
import AuthContext from "../../../../../../../context/AuthContext";
import { getDispatcherPeriodReportGraphics } from "../../../http/dispatcherHttp";
import { Line } from 'react-chartjs-2';
import Jelly from "../../../../../../../components/Jelly";
import { dateConverter } from "../../../../../../../utils/utils";
import { RSODynamicNotFound } from '../../../../../utils/RSODynamicNotFound/RSODynamicNotFound'

export let RSODynamicDispDateReportGraphicsPeriod = () => {

  let { authTokens } = useContext(AuthContext);
  let { selectedStartOfPeriodDate, selectedEndOfPeriodDate, setIsSelectDateActive, setIsSelectPeriodDateActive, selectedPeriodWPS } = useContext(dispatcher_context)

  let [graphicsData, setGraphicsData] = useState([]);

  function isEmptyObject(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  const Chart = ({ data }) => {
    if (!data || Object.keys(data).length === 0 || isEmptyObject(data[Object.keys(data)[0]])) {
      return <RSODynamicNotFound
        title={`Нет данных за этот период`}
        icon='🔍'
        descr={`Возможно, вы выбрали некорректные даты.`}
      />
    }

    let dates = null; // Устанавливаем значение по умолчанию

    if (data[selectedPeriodWPS]) { // Проверяем, существует ли значение
      dates = data[selectedPeriodWPS];

      if (dates !== null) { // Добавляем проверку на null
        const chartData = {
          labels: Object.keys(dates[Object.keys(dates)[0]]),
          datasets: Object.keys(dates).map((date, index) => ({
            label: dateConverter(date),
            data: Object.values(dates[date]),
            fill: false,
            borderColor: `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, 1)`,
            tension: 0.1,
          })),
        };

        const options = {
          scales: {
            x: {
              type: 'linear',
              position: 'bottom',
              title: {
                display: true,
                text: 'Время',
              },
            },
            y: {
              type: 'linear',
              position: 'left',
              title: {
                display: true,
                text: 'Значения',
              },
            },
          },
        };

        return <Line data={chartData} options={options} />;
      }
    }
  };


  useEffect(() => {
    getDispatcherPeriodReportGraphics(authTokens, selectedStartOfPeriodDate, selectedEndOfPeriodDate, setGraphicsData);
    setIsSelectPeriodDateActive(true);
    setIsSelectDateActive(false);
  }, [selectedStartOfPeriodDate, selectedEndOfPeriodDate]);

  return (
    <>
      {(graphicsData?.length !== 0 || graphicsData !== null || graphicsData !== undefined) ? (
        <Chart data={graphicsData} />
      ) : (
        <Jelly />
      )}
    </>
  );

}