import { useContext, useEffect, useState } from "react"
import AuthContext from "../../../../../../../context/AuthContext";
import { dispatcher_context } from "../../../hooks/useStats_dispatcher";
import Jelly from "../../../../../../../components/Jelly";
import { getDispatcherDailyReportGraphics } from '../../../http/dispatcherHttp'
import React from 'react';
import { Line } from 'react-chartjs-2';
import { CategoryScale } from 'chart.js'; 
import Chart from 'chart.js/auto';

export let RSODynamicDispDateReportGraphics = () => {

  let { authTokens } = useContext(AuthContext);
  let { selectedDate, setIsSelectDateActive, setIsSelectPeriodDateActive } = useContext(dispatcher_context)

  let [graphicsData, setGraphicsData] = useState([]);

  const Chart = ({ data }) => {
    if (!data || Object.keys(data).length === 0) {
      return null; // или возвращайте заглушку, если данных нет
    }

    const chartData = {
      labels: Object.keys(data[Object.keys(data)[0]]),
      datasets: Object.keys(data).map((vzu, index) => ({
        label: vzu,
        data: Object.values(data[vzu]),
        fill: false,
        borderColor: `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, 1)`,
        tension: 0.1,
      })),
    };

    const options = {
      scales: {
        x: {
          type: 'linear',
          position: 'bottom',
          title: {
            display: true,
            text: 'Время',
          },
        },
        y: {
          type: 'linear',
          position: 'left',
          title: {
            display: true,
            text: 'Значения',
          },
        },
      },
    };

    return <Line data={chartData} options={options} />;
  };


  // Загрузка данных при изменении выбранной даты
  useEffect(() => {
    getDispatcherDailyReportGraphics(authTokens, selectedDate, setGraphicsData);
    setIsSelectPeriodDateActive(false);
    setIsSelectDateActive(true);
  }, [selectedDate])

  // Отображение компонента графика или заглушки, если данных нет
  return (
    <>
      {graphicsData?.length !== 0 ? (
        <Chart data={graphicsData}/>
      ) : (
        <Jelly />
      )}
    </>
  );

}