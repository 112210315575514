import React, { useEffect } from 'react';
import './ModalWindow.scss'; // Подключите ваши стили модального окна

export let ModalWindow = ({ isOpen, onClose, title, children, hook, isHook1Required, hook1 }) => {

  useEffect(() => {
    if ((!hook || (isHook1Required && !hook1)) || (Object.keys(hook).length === 0 || (hook1 && Object.keys(hook1).length === 0))) {
      onClose(); // Вызываем функцию для закрытия модального окна
    }
  }, [hook, hook1, onClose, isHook1Required]);


  if (!isOpen) return null;

  return (
    <div className="ModalWindow">
      <div className="ModalWindow__inner">
        <div className="ModalWindow__top">
          <h2 className='ModalWindow-title'>{title}</h2>
          <button className="ModalWindow__close-button" onClick={onClose}></button>
        </div>
        <div className="ModalWindow__center">
          <div className="modal-content">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
