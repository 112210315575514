import React from "react";
import "./Jelly.scss";

const Jelly = () => {
    return (
        <div className="jelly-container">
            <div className="jelly-wrapper">
                <div className="jelly-hair"></div>
                <div className="jelly-body">
                    <div className="jelly-inner">
                        <div className="jelly-eyes"></div>
                        <div className="jelly-mouth"></div>
                        <div className="jelly-hands"></div>
                    </div>
                </div>
                <div className="jelly-tentacle-1"></div>
                <div className="jelly-tentacle-2"></div>
                <div className="jelly-tentacle-3"></div>
                <div className="jelly-tentacle-4"></div>
                <div className="jelly-tentacle-5"></div>
            </div>
            <div className="jelly-shadow"></div>
        </div>
    )
}
export default Jelly;