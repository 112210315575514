import { useContext, useEffect, useState } from 'react';
import { ModelTable } from '../../../../../../../../components/ModelTable/ModelTable'
import { dispatcher_dynamic_context } from '../../../../hooks/useStats_dispatcher_dynamic';
import Jelly from '../../../../../../../../components/Jelly';

export let RSODynamicDispDynamicOper = () => {

  let { daylyReport, reportLength } = useContext(dispatcher_dynamic_context)
  let [hoursTable, setHoursTable] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23])


  return (
    <>
      {(reportLength.length !== 0 && daylyReport.length !== 0) ? 
      <ModelTable style="min">
        <thead>
          <tr>
            <th></th>
            {daylyReport?.map((item, index) => {
              return (
                <>
                  {reportLength?.[item.vzu_name]?.[0] ? (
                    <th colSpan={reportLength?.[item.vzu_name]?.[0]}>
                      {item.vzu_name}
                    </th>
                  ) : ('')}
                </>
              )
            })}
          </tr>
          <tr>
            <th>Час</th>
            {daylyReport.map((item, index) => {
              return (
                <>
                  {reportLength?.[item?.vzu_name][2]?.map((item1, index1) => {
                    return (
                      <th>
                        {item1}
                      </th>
                    )
                  })}
                </>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {hoursTable.map((item, index) => {
            let i = -1;
            return (
              <>
                <tr>
                  <td>{item}</td>
                  {daylyReport.map((item1, index1) => {
                    let flag1 = true
                    let flag2 = true
                    if (reportLength[item1.vzu_name][1].length !== 0) {
                      i++
                      return (
                        <>
                          {item1.dynamics.map((item2, index2) => {
                            return (
                              <>
                                {item2.hour === item ? (
                                  <>
                                    {flag1 = false}
                                    {reportLength[item1.vzu_name][1].map((item3, index3) => {
                                      return (
                                        <>
                                          {item3 !== 'max_chlorine' ? (
                                            <>
                                              <td className={i % 2 === 0 ? 'td-even' : 'td-odd'}>
                                                {item3 === 'water_level_in_reseruar' ? (
                                                  <>
                                                    {item2[item3][0]}
                                                  </>
                                                ) : (
                                                  <>
                                                    {item2[item3]}
                                                  </>
                                                )}
                                              </td>
                                            </>
                                          ) : ('')}
                                        </>
                                      )
                                    })}
                                  </>
                                ) : ('')}
                              </>
                            )
                          })}
                          {flag1 ? (
                            <>
                              {reportLength[item1.vzu_name][1].map((item3, index3) => {
                                return (
                                  <>
                                    {item3 !== 'max_chlorine' ? (
                                      <td className={i % 2 === 0 ? 'td-even' : 'td-odd'}></td>
                                    ) : ('')}
                                  </>
                                )
                              })}
                              {flag1 = false}
                            </>
                          ) : ('')}
                          {reportLength[item1.vzu_name][1].indexOf('max_chlorine') !== -1 ? (
                            item1.chlorine.map((item2, index2) => {
                              return (
                                <>
                                  {item2.hour === item ? (
                                    <>
                                      {flag2 = false}
                                      <td className={i % 2 === 0 ? 'td-even' : 'td-odd'}>
                                        {item2.max_chlorine}
                                      </td>
                                    </>
                                  ) : ('')}
                                </>
                              )
                            })
                          ) : (
                            <>
                              {flag2 = false}
                            </>
                          )}
                          {flag2 ? (
                            <>
                              <td className={i % 2 === 0 ? 'td-even' : 'td-odd'}></td>
                              {flag2 = false}
                            </>
                          ) : ('')}
                        </>
                      )
                    }

                  })}
                </tr>
              </>
            )
          })}

        </tbody>
      </ModelTable> : <Jelly />}
    </> 
  )
}