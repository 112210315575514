import React, {useContext} from "react";
import "../../css/Button.css";
import { API_URL } from "../../../../config";
import AuthContext from "../../../../context/AuthContext";

const ButtonAdressHouse = ({ formData, updateState }) => {
  let { authTokens } = useContext(AuthContext)
  const handleNextClick = () => {

    const address = formData.text22;
    
    const api = `${API_URL}/Wear/House/houseByAddress/${address}/`;

    fetch(api, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          console.log("Данные успешно получены!");
          return response.json();
        } else {
          alert("Введен не верный адрес !");
        }
      })
      .then((data) => {

        if (data && data.length > 0) {

        var balconyType = data[0].balconyType;
        console.log(balconyType);

        var balconyVolume = data[0].balconyVolume;
        console.log(balconyVolume);
        var centralHeatingLength = data[0].centralHeatingLength;
        console.log(centralHeatingLength);

        var chuteLength = data[0].chuteLength;
        console.log(chuteLength);

        var coldWaterSupplyLength = data[0].coldWaterSupplyLength;
        console.log(coldWaterSupplyLength);
        var floorType = data[0].floorType;
        console.log(floorType);

        var floorVolume = data[0].floorVolume;
        console.log(floorVolume);

        var foundationType = data[0].foundationType;
        console.log(foundationType);

        var foundationVolume = data[0].foundationVolume;
        console.log(foundationVolume);

        var hotWaterSupplyLength = data[0].hotWaterSupplyLength;
        console.log(hotWaterSupplyLength);

        var id = data[0].id;
        console.log(id);

        var roofType = data[0].roofType;
        console.log(roofType);

        var roofVolume = data[0].roofVolume;
        console.log(roofVolume);

        var roofingType = data[0].roofingType;
        console.log(roofingType);

        var roofingVolume = data[0].roofingVolume;
        console.log(roofingVolume);

        var sewerage = data[0].sewerage;
        console.log(sewerage);

        var sewerageLength = data[0].sewerageLength;
        console.log(sewerageLength);

        var wallType = data[0].wallType;
        console.log(wallType);

        var wallVolume = data[0].wallVolume;
        console.log(wallVolume);

        var wiringLength = data[0].wiringLength;
        console.log(wiringLength);

        localStorage.setItem("balconyType", balconyType);
        localStorage.setItem("balconyVolume", balconyVolume);
        localStorage.setItem("floorType", floorType);
        localStorage.setItem("floorVolume", floorVolume);
        localStorage.setItem("foundationType", foundationType);
        localStorage.setItem("foundationVolume", foundationVolume);
        localStorage.setItem("id", id);
        localStorage.setItem("wallType", wallType);
        localStorage.setItem("wallVolume", wallVolume);
        localStorage.setItem("roofType", roofType);
        localStorage.setItem("roofVolume", roofVolume);
        localStorage.setItem("roofingType", roofingType);
        localStorage.setItem("roofingVolume", roofingVolume);
        localStorage.setItem("sewerageLength", sewerageLength);
        localStorage.setItem("chuteLength", chuteLength);
        localStorage.setItem("centralHeatingLength",centralHeatingLength);
        localStorage.setItem("coldWaterSupplyLength",coldWaterSupplyLength);
        localStorage.setItem("hotWaterSupplyLength",centralHeatingLength);
        localStorage.setItem("wiringLength", wiringLength);

        updateState(6);
        
      } else {
        alert("Неправильный ввод адрес");
      }
    })
      .catch((error) => {
        console.error("Ошибка при получении данных:", error.message);
      });
  };

  return (

      <button className= "btn-osn-wear" type="button" onClick={handleNextClick}>
                Далее
      </button>
  );
};

export default ButtonAdressHouse;
