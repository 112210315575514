import { createContext, useContext, useEffect, useMemo, useState } from "react";
import AuthContext from "../../../../../context/AuthContext";
import { getNowDate } from "../../../utils/utils";
import { getWPS, getWells } from "../../Hydrologist_worker/http/HyderologistWorkerHttp";

export const HydrologistBossContext = createContext();

export const HydrologistBossProvider = ({ children }) => {

  const { authTokens } = useContext(AuthContext);

  const [wps, setWps] = useState([]);
  const [wells, setWells] = useState([]);
  const [selectedWps, setSelectedWps] = useState(null);
  const [selectedWell, setSelectedWell] = useState(null);
  const [selectedDate, setSelectedDate] = useState(getNowDate());

  //Основные данные
  useEffect(() => {
    getWPS(authTokens, setWps, setWells);
  }, []);

  useEffect(() => {
    if (wps.length !== 0 && selectedWps) {
      getWells(authTokens, selectedWps, setWells);
    }
  }, [wps, selectedWps]);

  //Первые значения
  useEffect(() => {
    if (wps.length !== 0) {
      setSelectedWps(wps[0].id);
    }
  }, [wps]);

  useEffect(() => {
    if (wells.length !== 0) {
      setSelectedWell(wells[0].id);
    } else {
      setSelectedWell(null)
    }
  }, [wells]);

  //Выгружаем
  const memoizedWps = useMemo(() => ({
    wps,
    selectedWps,
    setSelectedWps,
  }), [wps, selectedWps]);

  const memoizedWells = useMemo(() => ({
    wells,
    selectedWell,
    setSelectedWell
  }), [wells, selectedWell]);

  const memoizedDate = useMemo(() => ({
    selectedDate,
    setSelectedDate
  }), [selectedDate]);

  return <HydrologistBossContext.Provider value={{ memoizedWps, memoizedWells, memoizedDate }}>
    {children}
  </HydrologistBossContext.Provider>;

}