import React from "react";
import "../../css/Wear.css";

const ButtonKrovla = ({ onButtonClick, roofingType }) => {
  const handleClick = () => {
    let data;



    onButtonClick("Кровля", data);
  };

  return (
    <button className="btn_addwear_wear" onClick={handleClick}>
      Кровля
    </button>
  );
};

export default ButtonKrovla;
