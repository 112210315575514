//Стартовая компонента
import React from "react";
import "../css/StartState.css";

const StartState = ({ handleAddWear, handleViewData }) => {
  return (

      <div className="wearmenu">
        <button className = "wearmenu-btn-wear" onClick={handleAddWear}>Добавить здание</button>
        <button className = "wearmenu-btn-wear" onClick={handleViewData}>Добавить износ</button>
      </div>

  );
};

export default StartState;
