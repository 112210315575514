import React, { useState, useEffect } from "react";
import { ResidentialData } from "./component/ResidentialData";
import { ResidentialConstr } from "./component/ResidentialConstr";
import { ResidentialInz } from "./component/ResidentialInz";
import { ResidentialOption } from "./component/ResidentialOption";
import { ResidentialWindow } from "./component/ResidentialWindow";
import { ResidentialDoor } from "./component/ResidentialDoor";

import { NextAndPrefButton } from "./component/Button/NextAndPrefButton";
import { AddDatatoHome } from "./component/Button/AddDatatoHome";
import { PrefButton } from "./component/Button/PrefButton";
import { NextButton } from "./component/Button/NextButton";

import "./ResidentialHouse.css";

export const ResidentialHouse = () => {

  const type_in_programm = 1; //Тип здания Жилое 

  const min_page = 1;
  const max_page = 6;

  const [step, setStep] = useState(1);
  const [formDataResidential, setformDataResidential] = useState({
    type_in_programm: type_in_programm, //Тип здания (Жилое/Общественное) int 
    address: '', // Адрес string 
    year: '', // Год ввода в эксплуатацию int 
    floors: '', // Этажность int 
    underground_floors: '', // Кол-во подземных этажей int 
    building_area: '', // Площадь здания float 
    organization: '', // Организация, управляющая МКД string
    construction_year: '', // Год постройки int
    lifecycle_stage: '', // Стадия жизненного цикла int 
    reconstruction_year: '', // Год проведения реконструкции int 
    apartment_count: '', // Кол-во квартир int 
    apartments_per_entrance: '', // Кол-во квартир в подъезде int
    building_volume: '', // Объем здания float
    building_width: '', // Ширина здания float 
    building_length: '', // Длина здания float 
    wall_height: '', // Высота стен float 
    number_window_openings: '', // Количество оконных проемов int 
    varied_window_count: '' , //Количество разноразмерных оконных проемов int 
    basement: false, // Подвал bool
    annex: false, // Пристройка bool
    attic: false, // Мансарда bool
    sewage: false, // Канализация bool
    trash_chute: false, // Мусоропровод bool
    heating: false, // Центральное отопление bool
    cold_water: false, // Холодное водоснабжение bool
    hot_water: false, // Горячее водоснабжение bool
    electrical: false, // Электропроводка bool
    elevator: false, // Лифт bool
    foundation_walls_area: '', // Площадь стен фундамента float
    balcony_area: '', //Общая площадь балконов float 
    building_walls_area: '', //Площадь стен здания float 
    load_bearing_area: '', // Общая площадь несущих конструкций float
    floor_area: '', // Общая площадь перекрытия float 
    roof_area: '', // Общая плозщадь крыши float
    balcony_count: '', // Кол-во балконов int 
    loggia_count: '', // Кол-во лоджий int 
    canopy_count: '', //Кол-во козырьков int
    bay_window_count: '', //Кол-во эркеров int
    hot_water_system: '', // Система горячего водоснабжения float 
    central_heating_system: '', // Система центрального водоснабжения float
    cold_water_system: '', // Система холодного водоснабжения float
    sewage_system: '', // Система канализаций float
    electrical_system: '', // Система электрооборудования float
    trash_chutes: '', //Мусоропроводы float
    foundationType: '', // Тип фундамента int
    wallType: '', // Тип стен int
    floorType: '', // Тип перекрытий int
    roofType: '', // Тип крыши int 
    roofingType: '', // Тип кровли int
    balconyType: '', // Тип балконов int
    totalareawindow: '', // Общая площаль оконных проемов float 
    totalaredoor: '', // Общая площадь дверных проемов float 
    buildingType: ''// Тип здания int 
  });

  const renderComponent = () => {
    switch (step) {
      case 1:
        return (
          <>
            <ResidentialData formDataResidential={formDataResidential} onDataChange={handleDataChange} />
            <NextButton handleStepChange={handleStepChange} />
          </>
        );
      case 2:
        return (
          <>
            <ResidentialOption formDataResidential={formDataResidential} onDataChange={handleDataChange} />
            <NextAndPrefButton handleStepChange={handleStepChange} />
          </>
        );
      case 3:
        return (
          <>
            <ResidentialConstr formDataResidential={formDataResidential} onDataChange={handleDataChange} />
            <NextAndPrefButton handleStepChange={handleStepChange} />
          </>
        );
      case 4:
        return (
          <>
            <ResidentialInz formDataResidential={formDataResidential} onDataChange={handleDataChange} />
            <div className="Button_to_Inz_AddHouse">
              <PrefButton handleStepChange={handleStepChange} />
              <AddDatatoHome formDataResidential={formDataResidential} onAdd={handleAddDataResidentialHouse} />
            </div>
          </>
        );
        case 5:
          return (
            <>
              <ResidentialWindow formDataResidential={formDataResidential} onDataChange={handleDataChange}></ResidentialWindow>
              <NextAndPrefButton handleStepChange={handleStepChange} />
            </>
          );
        case 6:
          return (
            <>
              <ResidentialDoor formDataResidential={formDataResidential} onDataChange={handleDataChange}></ResidentialDoor>
              <NextAndPrefButton handleStepChange={handleStepChange} />
            </>
          );
      default:
        return (
          <>
            <ResidentialData formDataResidential={formDataResidential} onDataChange={handleDataChange} />
            <NextAndPrefButton handleStepChange={handleStepChange} />
          </>
        );
    }
  };

  //Логика перемещения по страницам
  const handleStepChange = (direction) => {
    if (direction === "next" && step < max_page) {
      setStep(step + 1);
    } else if (direction === "prev" && step > min_page) {
      setStep(step - 1);
    }
  };

  //Сохрание данных о здании в состоянии
  const handleDataChange = (updatedData) => {
    setformDataResidential(prevData => ({ ...prevData, ...updatedData }));
  };

  //Автоматический подсчет объема здания  
  useEffect(() => {
    const update_building_volume = () => {
      const currentvalue = parseInt(formDataResidential.floors) + parseInt(formDataResidential.underground_floors);
      const newValue = parseInt(formDataResidential.building_area) * currentvalue * 2.7;
      const roundedValue = newValue.toFixed(2);
      setformDataResidential((prevState) => ({
        ...prevState,
        building_volume: roundedValue.toString(),
      }));
    };

    update_building_volume();
  }, [formDataResidential.floors, formDataResidential.underground_floors, formDataResidential.building_area]);

    //Автоматический подсчет площади здания  
    useEffect(() => {
      const update_building_area = () => {
        const newValue = parseInt(formDataResidential.building_width) * parseInt(formDataResidential.building_length)
        const roundedValue = newValue.toFixed(2);
        setformDataResidential((prevState) => ({
          ...prevState,
          building_area: roundedValue.toString(),
        }));
      };
  
      update_building_area();
    }, [formDataResidential.building_width, formDataResidential.building_length]);

  //Добавление здания
  const handleAddDataResidentialHouse = (data) => {
    console.log("Добавленные данные о здании:", data);
  };

  return (
    <div>
      {renderComponent()}
    </div>
  );
};
