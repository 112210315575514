import { useNavigate } from 'react-router-dom';
import './Hydrologist.scss'
import { useContext, useEffect, useState } from 'react';
import AuthContext from '../../context/AuthContext';
import { getJobTitle } from './http/http';
import { HydrologistProvider } from './components/Hydrologist_worker/hooks/useHydrologist';
import { Hydrologist_worker } from './components/Hydrologist_worker/Hydrologist_worker';
import Layout from '../../components/Layout';
import { HydrologistNotFound } from './utils/HydrologistNotFound/HydrologistNotFound';
import Jelly from '../../components/Jelly'
import { HydrologistBossProvider } from './components/HydrologistBoss/hooks/useHydrologistBoss';
import { HydrologistBoss } from './components/HydrologistBoss/HydrologistBoss';

export const Hydrologist = () => {

  let navigate = useNavigate();

  let { authTokens, logoutUser } = useContext(AuthContext);
  let [jobTitle, setJobTitle] = useState();//Должность

  useEffect(() => {
    getJobTitle(authTokens)
      .then(data => {
        if (!data?.[0]?.RSO_access) {
          navigate('/', { replace: true });
        }
        if (data?.[0]?.job_title) {
          setJobTitle(data[0].job_title)
        } else if (data?.detail === "Учетные данные не были предоставлены.") {
          logoutUser();
        } else {
          localStorage.setItem('last_page', '/services/rso/hydrologist');
          navigate('/', { replace: true });
        }
      })
  }, [])

  return <Layout>

    {jobTitle ?
      <>
        {(jobTitle === 'Гидрогиолог' || jobTitle === 'Начальник гидрогиологов') ? (
          <>
            {jobTitle === 'Гидрогиолог' ? (
              <HydrologistProvider>
                <Hydrologist_worker />
              </HydrologistProvider>
            ) :
              <></>
            }

            {jobTitle === 'Начальник гидрогиологов' ? (
              <HydrologistBossProvider>
                <HydrologistBoss />
              </HydrologistBossProvider>
            ) :
              <></>
            }
          </>
        ) :
          <div style={{ marginTop: '50vh', transform: 'translateY(-50%)', }}>
            <HydrologistNotFound
              title='У вас нет доступа к этой странице'
              descr='Вы не работаете гидрогеологом, этот сервис доступен только для них' />
          </div>
        }

      </> :
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Jelly />
      </div>
    }


  </Layout>
}