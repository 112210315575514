import Footer from "../components/Footer";
import Header from "../components/Header";
import React, { useState } from "react";
import v1 from '../images/v1.MP4';



const NewsPage = () => {


    return (
        <>
            <Header />
            <h1 className="NewsPage__Title">К дням Науки</h1>
            <div class="NewsPage__video-container">
                <video className="NewsPage__V1" width="640" height="360" controls>
                    <source src={v1} type="video/mp4" />
                </video>
            </div>
            <Footer />
        </>
    )
}

export default NewsPage;