import React, { useState, useContext } from "react";
import "../../css/Wear.css";
import { API_URL } from "../../../../config";
import AuthContext from "../../../../context/AuthContext";

const ButtonAddClearNextPrefWear = ({inputData,selectedComponent,onButtonClick,setInputData}) => {
  let { authTokens } = useContext(AuthContext);
  let [page, setPage] = useState(0);


  const isInputDataValid = () => {
    // Проверка на заполнение всех полей и отрицательные значения
    if (!inputData.field1 || !inputData.field2 || !inputData.field3) {
      alert("Пожалуйста, заполните все поля");
      return false;
    }

    // Проверка на отрицательные значения
    if (inputData.field2 < 0 || inputData.field3 < 0) {
      alert("Значения не могут быть отрицательными");
      return false;
    }

    return true;
  };

  const handleAddClick = () => {

    
    if (!isInputDataValid()) {
      return;
    }

    console.log("Selected Component:", selectedComponent);
    console.log("Input Data:", inputData);
    const id = localStorage.getItem("id");

    const api = `${API_URL}/Wear/Damage/`;

    function postDataToServer(elementValue) {
      const today = new Date().toISOString().slice(0, 10);

      const data = {
        position: inputData.field1,
        data: today,
        Element: elementValue,
        volume: inputData.field3,
        degreeOfDamage: inputData.field2,
        idHouse: id,
      };

      console.log(data);

      fetch(api, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.ok) {
            alert("Износ добавлен !");
            setInputData({ field1: "", field2: "", field3: "" }); // Сброс значений полей ввода
            const inputFields = document.querySelectorAll('input');
            inputFields.forEach(field => {
              field.value = "";
            });
          } else {
            throw new Error("Ошибка при отправке данных");
          }
        })
        .catch((error) => {
          console.error("Ошибка при отправке данных:", error.message);
        });
    }

    switch (selectedComponent) {
      case "Фундамент":
        postDataToServer(0);
        break;
      case "Стены":
        postDataToServer(1);
        break;
      case "Перекрытия":
        postDataToServer(2);
        break;
      case "Балкон":
        postDataToServer(3);
        break;
      case "Крыша":
        postDataToServer(4);
        break;
      case "Кровля":
        postDataToServer(5);
        break;
      case "Канализация":
        postDataToServer(6);
        break;
      case "Мусоропровод":
        postDataToServer(7);
        break;
      case "Центральное отопление":
        postDataToServer(8);
        break;
      case "Холодное водоснабжение":
        postDataToServer(9);
        break;
      case "Горячее водоснабжение":
        postDataToServer(10);
        break;
      case "Электрооборудование":
        postDataToServer(11);
        break;
      default:
        alert("Ошибка в выборе типа элемента !");
    }
  };

  const handleCloseClick = () => {
    //Cкрыть генерацию таблицы и полей
    onButtonClick(selectedComponent, []);
  };

  function identifyNonZeroVariables() {
    const variables = {
      sewerageLength: localStorage.getItem("sewerageLength"),
      chuteLength: localStorage.getItem("chuteLength"),
      centralHeatingLength: localStorage.getItem("centralHeatingLength"),
      coldWaterSupplyLength: localStorage.getItem("coldWaterSupplyLength"),
      hotWaterSupplyLength: localStorage.getItem("hotWaterSupplyLength"),
      wiringLength: localStorage.getItem("wiringLength"),
    };

    console.log(variables);

    const result = [];

    if (
      variables.sewerageLength !== null &&
      parseInt(variables.sewerageLength, 10) !== 0
    ) {
      result.push("Канализация");
    }

    if (
      variables.chuteLength !== null &&
      parseInt(variables.chuteLength, 10) !== 0
    ) {
      result.push("Мусоропровод");
    }

    if (
      variables.centralHeatingLength !== null &&
      parseInt(variables.centralHeatingLength, 10) !== 0
    ) {
      result.push("Центральное отопление");
    }

    if (
      variables.coldWaterSupplyLength !== null &&
      parseInt(variables.coldWaterSupplyLength, 10) !== 0
    ) {
      result.push("Холодное водоснабжение");
    }

    if (
      variables.hotWaterSupplyLength !== null &&
      parseInt(variables.hotWaterSupplyLength, 10) !== 0
    ) {
      result.push("Горячее водоснабжение");
    }

    if (
      variables.wiringLength !== null &&
      parseInt(variables.wiringLength, 10) !== 0
    ) {
      result.push("Электрооборудование");
    }

    return result;
  }

  const sewerage = () => {
    let tableData;

    tableData = [
      [
        "Признаки износа канализации",
        "Физический износ, %",
      ],
      [
        "Ослабление мест присоединения приборов; повреждение эмалированного покрытия моек, раковин, умывальников, ванн на площади до 10% их поверхности; трещины в трубопроводах из полимерных материалов",
        "0-20",
      ],
      [
        "Наличие течи в местах присоединения приборов до 10% всего количества; повреждение эмалированного покрытия моек, раковин, умывальников, ванн до 20% их поверхности; повреждение керамических умывальников и унитазов (сколы, трещины, выбоины) до 10% их количества; повреждения отдельных чугунных трубопроводов ; значительное повреждение трубопроводов из полимерных материалов",
        "21-40",
      ],
      [
        "Массовые течи в местах присоединения приборов; повреждение эмалированного покрытия моек, раковин, ванн, умывальников до 30% их поверхности; повреждение керамических умывальников и унитазов до 20% их количества; повреждение чугунных трубопроводов, масовые повреждение трубопроводов из полимерных материалов",
        "41-60",
      ],
      [
        "Неисправность системы; повсеместные повреждения приборов; следы ремонтов (хомуты, заделка и замена отдельных участков)",
        "61-80",
      ],
    ];

    onButtonClick("Канализация", tableData);
  };

  const chute = () => {
    let tableData;

    tableData = [
      ["Признаки износа мусоропровода", "Физический износ, %"],
      ["Мелкие повреждения в стволе, застревание загрузочных клапанов", "0-20"],
      [
        "Неисправность загрузочных клапанов, неплотность в раструбных соединениях, отдельные пробоины в стволе мусоропровода, коррозия металлических частей",
        "21-40",
      ],
      [
        "Отсутствие или поломка металлических деталей загрузочных люков, большие пробоины и расшатывание соединений участков ствола, поломка бункера с шиберами, неисправности в стенках вентиляционной камеры мусоропровода",
        "41-60",
      ],
      [
        "Полное расшатывание ствола мусоропровода, отсутствие или поломка загрузочных устройств, разрушение вентиляционной камеры и неисправности в камере мусоросборника",
        "61-80",
      ],
    ];

    onButtonClick("Мусоропровод", tableData);
  };

  const сentralHeating = () => {
    let tableData;

    tableData = [
      ["Признаки износа центрального отопления", "Физический износ, %"],
      [
        "Ослабление прокладок и набивки запорной арматуры, нарушения окраски отопительных приборов и стояков, нарушение теплоизоляции магистралей в отдельных местах",
        "0-20",
      ],
      [
        "Капельные течи в местах врезки запорной арматуры, приборов и в секциях отопительных приборов; отдельные хомуты на стояках и магистралях; значительные нарушения теплоизоляции магистралей; следы ремонта калориферов",
        "21-40",
      ],
      [
        "Капельные течи в отопительных приборах и местах их врезки; следы протечек в отопительных приборах, следы их восстановления, большое количество хомутов на стояках и магистралях, следы их ремонта отдельными местами и выборочной заменой; коррозия трубопроводов магистралей; неудовлетворительная работа калориферов",
        "41-60",
      ],
      [
        "Массовое повреждение трубопроводов (стояков и магистралей), сильное поражение ржавчиной, следы ремонта отдельными местами (хомуты, заварка), неудовлетворительная работа отопительных приборов и запорной арматуры, их закипание; значительное нарушение теплоизоляции трубопроводов",
        "61-80",
      ],
    ];

    onButtonClick("Центральное отопление", tableData);
  };

  const coldWaterSupply = () => {
    let tableData;

    tableData = [
      ["Признаки износа холодного водоснабжения", "Физический износ, %"],
      [
        "Ослабление сальниковых набивок и прокладок кранов и запорной арматуры, в некоторых смывных бачках имеются утечки воды, повреждение окраски трубопроводов в отдельных местах",
        "0-20",
      ],
      [
        "Капельные течи в местах врезки кранов и запорной арматуры; отдельные повреждения трубопроводов (свищи, течи); поражение коррозией отдельных участков трубопроводов; утечки воды в 20% приборов и смывных бачков",
        "21-40",
      ],
      [
        "Расстройство арматуры и смывных бачков (до 40%); следы ремонта трубопроводов (хомуты, заварка, замена отдельных участков); значительная коррозия трубопроводов; повреждение до 10% смывных бачков (трещины, потеря крышек, рукояток)",
        "41-60",
      ],
      [
        "Полное расстройство системы, выход из строя запорной арматуры, большое количество хомутов, следы замены отдельными местами трубопроводов, большая коррозия элементов системы, повреждение до 30% смывных бачков",
        "61-80",
      ],
    ];

    onButtonClick("Холодное водоснабжение", tableData);
  };

  const hotWaterSupply = () => {
    let tableData;

    tableData = [
      ["Признаки износа горячего водоснабжения", "Физический износ, %"],
      [
        "Ослабление сальниковых набивок и прокладок кранов и запорной арматуры, в некоторых смывных бачках имеются утечки воды, повреждение окраски трубопроводов в отдельных местах",
        "0-20",
      ],
      [
        "Капельные течи в местах врезки кранов и запорной арматуры; отдельные повреждения трубопроводов (свищи, течи); поражение коррозией отдельных участков трубопроводов; утечки воды в 20% приборов и смывных бачков",
        "21-40",
      ],
      [
        "Расстройство арматуры и смывных бачков (до 40%); следы ремонта трубопроводов (хомуты, заварка, замена отдельных участков); значительная коррозия трубопроводов; повреждение до 10% смывных бачков (трещины, потеря крышек, рукояток)",
        "41-60",
      ],
      [
        "Полное расстройство системы, выход из строя запорной арматуры, большое количество хомутов, следы замены отдельными местами трубопроводов, большая коррозия элементов системы, повреждение до 30% смывных бачков",
        "61-80",
      ],
    ];

    console.log(tableData);

    onButtonClick("Горячее водоснабжение", tableData);
  };

  const wiring = () => {
    let tableData;

    tableData = [
      ["Признаки износа Электрооборудованиеи", "Физический износ, %"],
      [
        "Неисправность, ослабление закреплений и отсутствие отдельных приборов (розеток, штепселей, патронов и т.д.); следы коррозии на поверхности металлических шкафов и частичное повреждение деревянных крышек",
        "0-20",
      ],
      [
        "Повреждение изоляции магистральных и внутриквартирных сетей в отдельных местах, потеря эластичности изоляции проводов, открытые проводки покрыты значительным слоем краски, отсутствие части приборов и крышек к ним, следы ремонта вводно-распределительных устройств (ВРУ)",
        "21-40",
      ],
      [
        "Полная потеря эластичности изоляции проводов, значительные повреждения магистральных и внутриквартирных сетей и приборов, следы ремонта системы с частичной заменой сетей и приборов отдельными местами, наличие временных прокладок, неисправность ВРУ",
        "41-60",
      ],
      [
        "Неисправность системы: проводки, щитков, приборов, ВРУ; отсутствие части приборов; оголение проводов; следы больших ремонтов (провесы проводов, повреждения шкафов, щитков, ВРУ)",
        "61-80",
      ],
    ];

    onButtonClick("Электрооборудование", tableData);
  };

  const arraypage = identifyNonZeroVariables(); // Массив для переключения по страницам

  const handleNextClick = () => {
    console.log(arraypage);
    setPage((prevPage) => {
      const newPage = prevPage + 1;

      if (newPage < arraypage.length) {
        switch (arraypage[newPage]) {
          case "Канализация":
            sewerage();
            break;
          case "Мусоропровод":
            chute();
            break;
          case "Центральное отопление":
            сentralHeating();
            break;
          case "Холодное водоснабжение":
            coldWaterSupply();
            break;
          case "Горячее водоснабжение":
            hotWaterSupply();
            break;
          case "Электрооборудование":
            wiring();
            break;
          default:
            alert("Ошибка выбора элемента !");
        }
        return newPage;
      } else {
        alert("Достигнут конец массива.");
        return prevPage;
      }
    });
  };

  const handlePrefClick = () => {
    setPage((prevPage) => {
      const newPage = prevPage - 1;

      if (newPage >= 0 && newPage < arraypage.length) {
        switch (arraypage[newPage]) {
          case "Канализация":
            sewerage();
            break;
          case "Мусоропровод":
            chute();
            break;
          case "Центральное отопление":
            сentralHeating();
            break;
          case "Холодное водоснабжение":
            coldWaterSupply();
            break;
          case "Горячее водоснабжение":
            hotWaterSupply();
            break;
          case "Электрооборудование":
            wiring();
            break;
          default:
            alert("Ошибка выбора элемента !");
        }
        return newPage;
      } else {
        alert("Достигнут начало массива.");
        return prevPage;
      }
    });
  };

  return (
    <div className="btn_addwear_inz_wear">
      <button className="btn_addwear_wear" onClick={handleAddClick}>
        {" "}
        Добавить{" "}
      </button>
      <button className="btn_addwear_wear" onClick={handleCloseClick}>
        {" "}
        Закрыть{" "}
      </button>
      <button className="btn_addwear_wear" onClick={handleNextClick}>
        {" "}
        Следующее{" "}
      </button>
      <button className="btn_addwear_wear" onClick={handlePrefClick}>
        {" "}
        Предыдущее{" "}
      </button>
    </div>
  );
};

export default ButtonAddClearNextPrefWear;
