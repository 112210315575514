import './RSODynamic.scss'
import { useNavigate } from "react-router-dom";
import Layout from '../../components/Layout'
import { RSODynamic_worker } from './components/RSODynamic_worker/RSODynamic_worker'
import { WorkerProvider } from './components/RSODynamic_worker/hooks/useStats_worker'
import { RSODynamicDispatcher } from './components/RSODynamicDispatcher/RSODynamicDispatcher'
import { DispatcherProvider } from './components/RSODynamicDispatcher/hooks/useStats_dispatcher'
import { useContext, useEffect, useState } from 'react'
import { getJobTitle } from './http/http'
import AuthContext from '../../context/AuthContext'

export let RSODynamic = () => {

  let navigate = useNavigate();

  let { authTokens, logoutUser } = useContext(AuthContext);
  let [jobTitle, setJobTitle] = useState();//Должность

  useEffect(() => {
    getJobTitle(authTokens)
      .then(data => {
        if (!data?.[0]?.RSO_access) {
          navigate('/', { replace: true });
        }
        if (data?.[0]?.job_title) {
          setJobTitle(data[0].job_title)
        } else if (data?.detail === "Учетные данные не были предоставлены.") {
          logoutUser();
        } else {
          localStorage.setItem('last_page', '/services/rso/dynamic');
          navigate('/', { replace: true });
        }
      })
  }, [])

  return <Layout>

    {jobTitle === 'Работник' ? (
      <WorkerProvider>
        <RSODynamic_worker />
      </WorkerProvider>
    ) : ("")}
    {(jobTitle === "Диспетчер" || jobTitle === "Технический администратор") &&
      <DispatcherProvider>
        <RSODynamicDispatcher />
      </DispatcherProvider>
    }

  </Layout>
}