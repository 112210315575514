import { useContext } from "react"
import { dispatcher_context } from "./hooks/useStats_dispatcher"
import { RSODynamicButtonGroup } from "../../utils/RSODynamicButtonGroup/RSODynamicButtonGroup";
import { RSODynamicDispDynamic } from "./components/RSODynamicDispDynamic/RSODynamicDispDynamic";
import { RSODynamicDispDateReport } from "./components/RSODynamicDispDateReport/RSODynamicDispDateReport";
import { RSODynamicDispEquipmentFailures } from "./components/RSODynamicDispEquipmentFailures/RSODynamicDispEquipmentFailures";
import { RSODynamicDispDynamicDetailWPS } from "./components/RSODynamicDispDynamicDetailWPS/RSODynamicDispDynamicDetailWPS";

export let RSODynamicDispatcher = () => {

  let { activeTab, setActiveTab } = useContext(dispatcher_context);

  return (
    <div className="large__container" style={{ marginTop: '120px' }}>
      <RSODynamicButtonGroup
        buttons={[
          { title: 'ВЗУ детально' },
          { title: 'Динамическая информация' },
          { title: 'Отчет за дату' },
          { title: 'Отказы оборудования' },
        ]}
        setter={setActiveTab}
        getter={activeTab} />

      {activeTab === "ВЗУ детально" &&
        <RSODynamicDispDynamicDetailWPS />}

      {activeTab === "Отчет за дату" &&
        <RSODynamicDispDateReport />}

      {activeTab === "Динамическая информация" &&
        <RSODynamicDispDynamic />}

      {activeTab === "Отказы оборудования" &&
        <RSODynamicDispEquipmentFailures />}
    </div>
  )
}