import React from "react";
import "../../css/Wear.css";

const ButtonRoof = ({ onButtonClick, roofType }) => {
  const handleClick = () => {
    let data;

    switch (roofType) {
      case "1":
        data = [
          ["Признаки износа", "Количественная оценка", "Физический износ, %"],
          [
            "Ослабление креплений: болтов, хомутов, скоб; повреждение деталей слуховых окон",
            "-",
            "0-20",
          ],
          [
            "Поражение гнилью мауэрлата и концов стропильных ног, ослабление врубок и соединений",
            "Повреждения на площади до 20%",
            "21-40",
          ],
          [
            "Поражение гнилью древесины мауэрлата, стропил, обрешетки; наличие дополнительных временных креплений стропильных ног; увлажнение древесины",
            "То же, до 50%",
            "41-60",
          ],
          [
            "Прогибы стропильных ног, поражение гнилью и жучком древесины деталей крыши",
            "-",
            "61-80",
          ],
        ];

        break;
      case "2":
        data = [
          ["Признаки износа", "Количественная оценка", "Физический износ, %"],
          [
            "Мелкие повреждения деревянных деталей, кирпичных столбиков",
            "-",
            "0-20",
          ],
          [
            "Трещины в кирпичных столбиках или опорных участках железобетонных панелей, мелкие пробоины в плитах покрытия, гниль в деревянных деталях",
            "Повреждения на площади до 20%",
            "21-40",
          ],
          [
            "Неглубокие трещины в железобетонных стропильных балках и плитах, протечки крыш",
            "Ширина раскрытия трещин до 2 мм",
            "41-60",
          ],
          [
            "Сквозные трещины в стропильных балках, плитах; прогибы плит покрытия; разрушение кирпичных столбиков и опорных участков железобетонных панелей стен; обнажение арматуры",
            "Ширина раскрытия трещин более 2 мм. Прогибы плит более 1/100 пролета. Повреждения на площади более 20%",
            "61-80",
          ],
        ];

        break;
      case "3":
        data = [
          ["Признаки износа", "Количественная оценка", "Физический износ, %"],
          [
            "Мелкие выбоины на поверхности плит",
            "Повреждения на площади до 15%",
            "0-20",
          ],
          [
            "Трещины в панелях, пробоины, следы протечек. Оседание утеплителя и его высокая влажность",
            "Ширина трещин до 1 мм. Протечки на площади до 10%. Относительная влажность утеплителя более 20%",
            "21-40",
          ],
          [
            "Множественные трещины в панелях, протечки и промерзания, прогибы панелей",
            "Ширина трещин до 2 мм. Протечки и промерзания на площади до 25%. Прогиб панели до 1/80 пролета",
            "41-60",
          ],
          [
            "Местные разрушения панелей, деструкция утеплителя, протечки и промерзания",
            "-",
            "61-80",
          ],
        ];

        break;

      default:
        console.log("Ошибка !");
    }

    onButtonClick("Крыша", data);
  };

  return (
    <button className="btn_addwear_wear" onClick={handleClick}>
      Крыша
    </button>
  );
};

export default ButtonRoof;
