import ReconnectingWebSocket from 'reconnecting-websocket';
import { useContext, useEffect, useRef, useState } from "react";
import { createContext } from "react";
import AuthContext from "../../../../../context/AuthContext";
import { WS_URL } from "../../../../../config";

export const dispatcher_dynamic_context = createContext();

export const DynamicDispatchProvider = ({ children }) => {
  const { authTokens } = useContext(AuthContext);
  const webSocket = useRef(null);
  const [allAboutVZU, setAllAboutVZU] = useState([]);
  const [daylyReport, setDaylyReport] = useState([]);
  const [reportLength, setReportLength] = useState({});

  useEffect(() => {
    webSocket.current = new ReconnectingWebSocket(`${WS_URL}/ws/dispather_check/?token=${authTokens.access}`);

    const sendWebSocketData = (action) => {
      const requestData = {
        action: action,
        request_id: new Date().getTime(),
        data: authTokens.access,
      };
      webSocket.current.send(JSON.stringify(requestData));
    };

    const handleWebSocketMessage = (e) => {
      const data = JSON.parse(e.data);
      if (data.act === 'connect' || data.act === 'well_activity' || data.act === 'pump_activity') {
        setAllAboutVZU(data.message);
      } else if (data.act === 'dynamic_connect' || data.act === 'dynamic_activity' || data.act === 'chlorine_activity') {
        processDynamicData(data.message);
      }
    };

    const processDynamicData = (message) => {
      // Обработка данных дневного отчета и обновление состояний.
      let finalArr = {};
      message.forEach((item) => {
        const pressureFlag = item.dynamics.some((item1) => item1?.pressure);
        const waterLevelInReseruarFlag = item.dynamics.some((item1) => item1?.water_level_in_reseruar[0]);
        const maxChlorineFlag = item.chlorine.some((item1) => item1?.max_chlorine);

        const count = [pressureFlag, waterLevelInReseruarFlag, maxChlorineFlag].filter(Boolean).length;
        const arr = [pressureFlag && 'pressure', waterLevelInReseruarFlag && 'water_level_in_reseruar', maxChlorineFlag && 'max_chlorine'].filter(Boolean);
        const normArr = arr.map((item) => {
          if (item === 'pressure') return 'Д';
          if (item === 'water_level_in_reseruar') return 'Ур';
          if (item === 'max_chlorine') return 'Хл';
          return '';
        });

        finalArr[item.vzu_name] = [count, arr, normArr];
      });

      setReportLength(finalArr);
      setDaylyReport(message);
    };

    webSocket.current.onopen = () => {
      sendWebSocketData('on_connect');
      sendWebSocketData('on_dynamic_connect');
      sendWebSocketData('on_smena_connect');
      console.log('Подключение установлено');
    };

    webSocket.current.onmessage = handleWebSocketMessage;

    webSocket.current.onclose = (e) => {
      console.error('WebSocket closed unexpectedly:', e.reason);
      // Попытка переподключения здесь, если это имеет смысл в вашем контексте.
    };

    webSocket.current.onerror = (err) => {
      console.error('WebSocket error:', err.message);
      // Обработка ошибок веб-сокета, если это необходимо.
    };

    return () => {
      webSocket.current.close();
    };
  }, [authTokens]);

  // Передаем состояния через контекстный провайдер
  return (
    <dispatcher_dynamic_context.Provider value={{ allAboutVZU, daylyReport, reportLength }}>
      {children}
    </dispatcher_dynamic_context.Provider>
  );
};