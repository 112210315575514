//Dev
// export const API_URL = "http://localhost:8000/api";
// export const WS_URL = "ws://localhost:8000";
// export const MEDIA_URL = "http://localhost:8000/media";
//Test
// export const API_URL = "http://localhost:5860/api";
// export const WS_URL = "ws://localhost:5860";
// export const MEDIA_URL = "http://localhost:5860/media";
//Prod
export const API_URL = "https://erazkh.orel-ecenter.ru/api";
export const WS_URL = "wss://erazkh.orel-ecenter.ru";
export const MEDIA_URL = "https://erazkh.orel-ecenter.ru/media";
//Dadata Token API
export const DADATA_TOKEN = "42e0ac4f9aafed59b6bcf13251e93840d7333a8a"