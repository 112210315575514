import React from "react";
import "../../PublicHouse.css";

export const AddDatatoHome = ({handleAddDataPublicHouse}) => {
  return (
    <div className="AddDatatoHome_main">
      <button 
        className="AddDatatoHome_button" 
        onClick={() => {
          handleAddDataPublicHouse();
        }}
      >
        Добавить здание
      </button>
    </div>
  );
};
