import React, { useState, useEffect, useContext } from "react";
import { API_URL } from "../../../config";
import AuthContext from "../../../context/AuthContext";
import "../css/Search.css";

const Search = ({ formData, onDataChange }) => {
  const [addresses, setAddresses] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(true);
  const [allAddresses, setAllAddresses] = useState([]); // Состояние для хранения исходного списка адресов
  const { authTokens } = useContext(AuthContext);

  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        const response = await fetch(`${API_URL}/Wear/House/getAllAddresses/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
        });
        if (!response.ok) {
          throw new Error("Ошибка при загрузке адресов");
        }
        const data = await response.json();
        const filteredAddresses = Object.values(data).filter(
          (value) => typeof value === "string"
        );
        setAddresses(filteredAddresses);
        setAllAddresses(filteredAddresses); // Сохраняем полный список адресов
      } catch (error) {
        console.error("Ошибка:", error);
      }
    };

    fetchAddresses();
  }, []);

  const handleAddressChange = (value) => {
    const text = value.trim().toLowerCase();
    const filteredAddresses = allAddresses.filter((address) =>
      address.toLowerCase().includes(text)
    );
    onDataChange({ ...formData, text22: value });
    setAddresses(filteredAddresses);
    setShowSuggestions(true);
  };

  const handleSuggestionClick = (suggestion) => {
    onDataChange({ ...formData, text22: suggestion });
    setShowSuggestions(false);
  };

  return (
    <div className="Searchmenu">
      <input
        className="Searchmenu-input-wear-AddressSuggestions"
        placeholder="Введите адрес"
        value={formData.text22}
        onChange={(e) => handleAddressChange(e.target.value)}
      />
      
      {showSuggestions && (
        <>
        <label>Выберите подходящий адрес:</label>
        <div className="suggestions-container"> 
          {addresses.length > 0 ? (
            <ul className="suggestions-list">
              {addresses.map((suggestion, index) => (
                <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                  {suggestion}
                </li>
              ))}
            </ul>
          ) : (
            <p className="no-addresses">Нет подходящих адресов</p>
          )}
        </div>
        </>
      )}
    </div>
  );
};

export default Search;