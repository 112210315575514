import { API_URL } from "../../../../../config";
import { saveAs } from 'file-saver';

export let getDailyReportAll = async (authTokens, date, setter, setReportLength) => {
  let response = await fetch(`${API_URL}/RsoDynamic/dispatcher/dispather_check/${date}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${authTokens.access}` },
  });

  let data = await response.json();
  if (response.status === 200) {
    setter(data);
    let finalArr = {};
    data.map((item, index) => {
      let count = 0;
      let arr = [];
      let normArr = [];
      if (item.dynamics?.[0]?.pressure) {
        count++
        arr.push('pressure')
        normArr.push('Д')
      }
      if (item.dynamics?.[0]?.water_level_in_reseruar[0]) {
        count++
        arr.push('water_level_in_reseruar')
        normArr.push('Ур')
      }
      if (item.chlorine?.[0]?.max_chlorine) {
        count++
        arr.push('max_chlorine')
        normArr.push('Хл')
      }
      finalArr[item.vzu_name] = [count, arr, normArr]
    })
    setReportLength(finalArr)
  } else {
  }
}

export async function fetchWaterData(date, authToken) {
  const response = await fetch(API_URL + '/RsoDynamic/dispatcher/dispather_check/water/' + date, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + String(authToken),
    },
  });
  const data = await response.json();
  return data;
}

export let fetchAllDaylyReportForDateExcel = async (authTokens, daylyReportDate) => {
  let day = daylyReportDate.split('-')[2] + '.' +
    daylyReportDate.split('-')[1] + '.' +
    daylyReportDate.split('-')[0]
  let response = await fetch(API_URL + '/RsoDynamic/dispatcher/' + daylyReportDate + '/excel', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + String(authTokens.access),
    }
  })

    .then((res) => res.blob())
    .then((result) => saveAs(result, 'Дневной отчет - ' + day + '.xlsx'))
};

export let fetchAllDailyWaterForDateExcel = async (authTokens, daylyReportDate) => {
  let day = daylyReportDate.split('-')[2] + '.' +
    daylyReportDate.split('-')[1] + '.' +
    daylyReportDate.split('-')[0]
  let response = await fetch(API_URL + '/RsoDynamic/dispatcher/water/' + daylyReportDate + '/excel', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + String(authTokens.access),
    }
  })

    .then((res) => res.blob())
    .then((result) => saveAs(result, 'Подача воды за ' + day + '.xlsx'))
};

export let getWells = async (authTokens, setter) => {
  const response = await fetch(API_URL + '/RSO/well/', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + String(authTokens.access),
    },
  });
  setter(await response.json());
}

export let getPumps = async (authTokens, setter) => {
  const response = await fetch(API_URL + '/RSO/waterpumpingstationpump/', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + String(authTokens.access),
    },
  });
  setter(await response.json());
}

export let getWPS = async (authTokens, setter, setEquipmentFailuresWPS) => {
  const response = await fetch(API_URL + '/RSO/waterpumpingstation/', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + String(authTokens.access),
    },
  });
  let data = await response.json();
  setter(data);
  let WPSIdS = [{id: -1, name: 'Все'}]
  if (setEquipmentFailuresWPS){
    data.map(item => {
      WPSIdS.push({id: item?.id, name: item?.name})
      setEquipmentFailuresWPS([...WPSIdS])
    })
  }
}

//Получаем конкретный ВЗУ по id
export let getWPSId = async (authTokens, setter, WPSId) => {
  let response = await fetch(`${API_URL}/RSO/waterpumpingstation/${WPSId}/`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${authTokens.access}` },
  });
  return setter(await response.json());
};

export let getDispatcherAllRepair = (authTokens, isReportActive, setFailureWells, setFailurePumps) => {
  let wells = []
  let pumps = []
  let headers = new Headers()
  headers.set('Authorization', 'Bearer ' + String(authTokens.access));
  setFailureWells([]);
  setFailurePumps([]);
  fetch(`${API_URL}/RSO/waterpumpingstation/all_repair/?fixing=${isReportActive}`, { method: 'GET', headers: headers })
    .then((response) => response.json())
    .then((data) => {
      data.map(item => {
        if (item.type === "well") {
          wells.push(item)//Если скважины
          setFailureWells([...wells])
        } else {
          pumps.push(item)//Если насосы
          setFailurePumps([...pumps])
        }
      })
    })
}

export let getDispatcherAllRepairPeriod = (authTokens, isReportActive, period, setFailureWells, setFailurePumps) => {
  let wells = []
  let pumps = []
  let headers = new Headers()
  headers.set('Authorization', 'Bearer ' + String(authTokens.access));
  setFailureWells([]);
  setFailurePumps([]);
  fetch(`${API_URL}/RSO/waterpumpingstation/period_repair/${period}/?fixing=${isReportActive}`, { method: 'GET', headers: headers })
    .then((response) => response.json())
    .then((data) => {
      data.map(item => {
        if (item.type === "well") {
          wells.push(item)//Если скважины
          setFailureWells([...wells])
        } else {
          pumps.push(item)//Если насосы
          setFailurePumps([...pumps])
        }
      })
    })
}


export let getDispatcherDailyReportGraphics = async (authTokens, date, setter,) => {
  const response = await fetch(API_URL + `/RsoDynamic/dispatcher/graphics/${date}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + String(authTokens.access),
    },
  });
  setter(await response.json());
};


export let getDispatcherPeriodReportGraphics = async (authTokens, startDate, endDate, setter,) => {
  console.log(startDate, endDate);
  const response = await fetch(API_URL + `/RsoDynamic/dispatcher/graph/consumption/${startDate}/${endDate}/`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + String(authTokens.access),
    },
  });
  let data = await response.json()
  setter && setter(data)
  return data
};