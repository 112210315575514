import React from "react"
import '../../css/Button.css'


const ButtonClearPref = ({ handleBack, currentState, formData, setFormData }) => {

    const handleClearData = () => {
        if (currentState === 1) {
          // Сбросить поля, соответствующие текущему состоянию
          setFormData(prevFormData => ({
            ...prevFormData,
            text1: "",
            text2: "",
            text3: "",
            text4: "",
            text5: "",
            text23: "",
            text24: "",
            text26: "",
            text27: "",
            text28: "",
            text29: "",
            dropdown9: "",
            checkbox1: false,
            checkbox2: false,
            checkbox3: false,
            checkbox4: false,
            checkbox5: false,
            checkbox6: false,
            checkbox7: false,
            checkbox8: false,
            checkbox9: false,
            checkbox10: false,

            
           
          }));
        }
        if (currentState === 7) {
            setFormData(prevFormData => ({
                ...prevFormData,
                dropdown1: 0,
                dropdown2: 0,
                dropdown3: 0,
                dropdown4: 0,
                dropdown5: 0,
                dropdown6: 0,
                dropdown7: 0,
                dropdown8: 0
              }));
        }
        if (currentState === 3) {
            setFormData(prevFormData => ({
                ...prevFormData,
                text6:"",
                text7:"",
                text8:"",
                text9:"",
                text10:"",
                text11:"",
                text12:"",
                text13:"",
                text14:"",
                text15:""

              }));
        }
        if (currentState === 4) {
            setFormData(prevFormData => ({
                ...prevFormData,
                text16:"",
                text17:"",
                text18:"",
                text19:"",
                text20:"",
                text21:""
              }));
        }
        if (currentState === 5) {
            setFormData(prevFormData => ({
                ...prevFormData,
                text22:"",
              }));
        }
      };




    return(
        
        <>
        <button className= "btn-osn-wear" onClick = {handleClearData} >Очистить данные</button>
        <button className= "btn-osn-wear" onClick={handleBack}> Назад </button>
        </>

     
    );
};

export default ButtonClearPref