import React, { useContext, useEffect, useState } from 'react';
import './HydrologistWorkTable.scss';
import { HydrologistContext } from '../../hooks/useHydrologist';
import { addHydrologistDataCreateMany, getHydrologistDataMonthDate } from '../../http/HyderologistWorkerHttp';
import AuthContext from '../../../../../../context/AuthContext';
import { formatDate, sumTime, sumWaterConsumption } from '../../../../utils/utils';
import { HydrologistButton } from '../../../../utils/HydrologistButton/HydrologistButton';
import { ModalWindow } from '../../../../../../components/ModalWindow/ModalWindow';
import { LoadingSpinner } from '../../../../../../components/LoadingSpinner/LoadingSpinner';
import { useForm } from 'react-hook-form';

export const EditableTable = ({ data, action }) => {

  const { authTokens } = useContext(AuthContext);
  let { handleSubmit, reset } = useForm({ mode: 'onBlur' });

  let {
    memoizedHydrologists,
    memoizedWells,
  } = useContext(HydrologistContext)

  const [tableData, setTableData] = useState([]);
  const [editedData, setEditedData] = useState([]);

  useEffect(() => {
    let buffer = data;
    buffer = buffer?.map((item, index) => {
      let time_split = item.device_operating_time.split(' ');
      return {
        ...item, device_operating_time_days: time_split.length > 1 ? time_split[0] : '',
        device_operating_time_other: time_split.length > 1 ? time_split[1] : time_split[0],
      }
    })

    setTableData(buffer);
    setEditedData(buffer);
  }, [data]);

  const handleEdit = (index, field, value) => {
    setEditedData(editedData.map((item, id) => {
      if (id === index) {
        return { ...item, [field]: value }
      } else {
        return item
      }
    }))
  };


  const compareData = (e) => {
    e.preventDefault()
    let comparedData = editedData.map((item, index) => {
      if (
        !tableData[index].meter_readings
        && !!item.meter_readings
        // && (!!item.meter_readings && (!!item.device_operating_time_days || !!item.device_operating_time_other))
      ) {
        return {
          ...item,
          // device_operating_time: `${item.device_operating_time_days} ${item.device_operating_time_other || '00:00'}`.replace(/^\s+|\s+$/g, ''),
          well_id_set: memoizedWells?.selectedWell,
          hydrologist_id_set: memoizedHydrologists?.selectedHydrologist,
          measurement_date: formatDate(item?.date)
        }
      }
    }).filter(item => item !== undefined)


    comparedData.map((item,) => {
      // delete item.date
      // delete item.device_operating_time_days
      // delete item.device_operating_time_other
      delete item.hydrologist
    })

    if (comparedData.length) {
      addHydrologistDataCreateMany({ authTokens: authTokens, data: comparedData, action: action })
      // setSendingDataLoader(true)
      setIsModalOpen(false)
    }
  };

  let [isModalOpen, setIsModalOpen] = useState(false);
  // let [sendingDataLoader, setSendingDataLoader] = useState(false)

  const handleOpenModal = (e) => {
    e.preventDefault()
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <form onSubmit={e => handleOpenModal(e)}>
        <table className='ModelTable hydrologistWorkTable'>
          <thead>
            <tr>
              <th>Дата</th>
              <th>Показания <br />измерительного <br /> прибора</th>
              <th>Время работы <br /> измерительного <br /> прибора</th>
              <th>Расход воды <br /> м³/сут.</th>
              <th>Производительность <br /> м³/час</th>
              <th>Оператор ввода</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, index) => (
              <tr key={index}>
                <td>{row.date}</td>
                <td>
                  <input
                    type="number"
                    disabled={tableData[index].meter_readings !== ''}
                    readOnly={tableData[index].meter_readings !== ''}
                    value={tableData[index].meter_readings || editedData[index].meter_readings}
                    onChange={(e) => {
                      if (tableData[index].meter_readings === '') {
                        handleEdit(index, 'meter_readings', e.target.value);
                      }
                    }}
                  />
                </td>
                <td>
                  {/* <input
                    type="time"
                    disabled={tableData[index].device_operating_time_other !== ''}
                    readOnly={tableData[index].device_operating_time_other !== ''}
                    value={tableData[index].device_operating_time_other || editedData[index].device_operating_time_other}
                    onChange={(e) => {
                      const timeString = e.target.value; // Получаем значение времени
                      const timeArray = timeString.split(':'); // Разбиваем строку времени по двоеточию
                      const timeWithSeconds = `${timeArray[0]}:${timeArray[1]}:00`; // Добавляем секунды
                      if (tableData[index].device_operating_time_other === '') {
                        handleEdit(index, 'device_operating_time_other', timeWithSeconds); // Отправляем значение с секундами для обработки
                      }
                    }}
                  /> */}
                  <td>
                    {tableData[index].device_operating_time_days ? '24:00:00' :  tableData[index].device_operating_time_other}
                  </td>
                </td>
                <td>
                  {tableData[index].water_consumption}
                </td>
                <td>
                  {tableData[index].reporting_capacity && tableData[index].reporting_capacity.toFixed(2)}
                </td>
                <td>
                  {tableData[index].hydrologist}
                </td>
              </tr>
            ))}
            <tr>
              <td></td>
              <td><b>Итого за месяц:</b></td>
              <td><b>{sumTime(tableData)}</b></td>
              <td><b>{sumWaterConsumption(tableData)}</b></td>
            </tr>
          </tbody>
        </table>
        <HydrologistButton
          title='Сохранить'
          type='submit' />
      </form>

      <ModalWindow
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title='Подтверждение передаваемых данных'
        hook={editedData}
        isHook1Required={false}>
        <p><b>Внимательно проверьте передаваемые данные</b></p>
        <p>Если вы обнаружили ошибку, то закройте это окно, нажав на крестик. Если данные верны - нажмите кнопку "Подтвердить".</p>
        <p>Данные: </p>
        <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
          {editedData.map((item, index) => {
            if (!tableData[index].meter_readings && !!item.meter_readings) {
              return <p key={index}><b>{item.date}:</b> {item.meter_readings}</p>;
            }
            return null; // обязательно возвращайте null, если условие не выполнено
          })}
        </div>

        <form className='create__smena-btn' onSubmit={e => handleSubmit(compareData(e))}>
          <HydrologistButton title='Подтвердить' type='submit' />
        </form>
      </ModalWindow>
    </div>
  );
};
