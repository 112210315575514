//Корневая компонента
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";
import { getJobTitle } from "./components/HTTP/HTTP";
import AuthContext from "../../context/AuthContext";
import Header from "./components/Header";
import Data from "./components/Data";
import OptionHouse from "./components/OptionHouse";
import Wear from "./components/Wear";
import ButtonClearPref from "./components/Button/ButtonClearPref";
import ButtonNextTypeHouse from "./components/Button/ButtonNextTypeHouse";
import StartState from "./components/StartState";
import Search from "./components/Search";
import Constr from "./components/Constr";
import useHistory from "./components/useHistory";
import Inz from "./components/Inz";
import TypeHouse from "./components/TypeHouse";
import ButtonTypeHouse from "./components/Button/ButtonTypeHouse";
import ButtonConstr from "./components/Button/ButtonConstr";
import ButtonAddHouse from "./components/Button/ButtonAddHouse";
import ButtonAdressHouse from "./components/Button/ButtonAdressHouse";
import ButtonData from "./components/Button/ButtonData";

import "./css/App.css";

export const AppWear = () => {
  let navigate = useNavigate();

  let { authTokens, logoutUser } = useContext(AuthContext);
  let [jobTitle, setJobTitle] = useState(); //Должность

  useEffect(() => {
    getJobTitle(authTokens).then((data) => {
      if (!data?.[0]?.wear_access) {
        navigate("/", { replace: true });
      }
      if (data?.[0]?.job_title) {
        setJobTitle(data[0].job_title);
      } else if (data?.detail === "Учетные данные не были предоставлены.") {
        logoutUser();
      } else {
        localStorage.setItem("last_page", "/services/wear");
        navigate("/", { replace: true });
      }
    });
  }, []);

  //Текущее состояние и поля в которые заносятся основные данные по мкд
  const { currentState, updateState, goBack } = useHistory(0);
  const [formData, setFormData] = useState({
    text1: "",
    text3: "",
    text4: "",
    text2: "",
    text5: "",
    dropdown1: 0,
    dropdown2: 0,
    dropdown3: 0,
    dropdown4: 0,
    dropdown5: 0,
    dropdown6: 0,
    dropdown7: 0,
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
    checkbox4: false,
    checkbox5: false,
    checkbox6: false,
    checkbox7: false,
    checkbox8: false,
    checkbox9: false,
    checkbox10: false,

    text23: "",
    text24: "",
    dropdown9: "",
    text26: "",
    text27: "",
    text28: "",
    text29: "",

    // Конструктив

    text6: "",
    text7: "",
    text8: "",
    text9: "",
    text10: "",
    text11: "",
    text12: "",
    text13: "",
    text14: "",
    text15: "",

    // Инжинерные системы

    text16: "",
    text17: "",
    text18: "",
    text19: "",
    text20: "",
    text21: "",

    //Выбор типа дома

    dropdown8: 0,

    //Поиск по адресу

    text22: "",
  });

  // Обработчик автоматического подсчета объема здания
  useEffect(() => {
    const updateText29 = () => {
      const currentvalue = parseInt(formData.text3) + parseInt(formData.text4);
      const newValue = parseInt(formData.text5) * currentvalue * 2.7;
      const roundedValue = newValue.toFixed(2);
      setFormData((prevState) => ({
        ...prevState,
        text29: roundedValue.toString(),
      }));
    };

    updateText29();
  }, [formData.text3, formData.text5, formData.text4]);
  

  const resetFormData = () => {
    setFormData({
      text1: "",
      text3: "",
      text4: "",
      text2: "",
      text5: "",
      dropdown1: 0,
      dropdown2: 0,
      dropdown3: 0,
      dropdown4: 0,
      dropdown5: 0,
      dropdown6: 0,
      dropdown7: 0,
      checkbox1: false,
      checkbox2: false,
      checkbox3: false,
      checkbox4: false,
      checkbox5: false,
      checkbox6: false,
      checkbox7: false,
      checkbox8: false,
      checkbox9: false,
      checkbox10: false,
  
      text23: "",
      text24: "",
      dropdown9: "",
      text26: "",
      text27: "",
      text28: "",
      text29: "",
  
      text6: "",
      text7: "",
      text8: "",
      text9: "",
      text10: "",
      text11: "",
      text12: "",
      text13: "",
      text14: "",
      text15: "",
  
      text16: "",
      text17: "",
      text18: "",
      text19: "",
      text20: "",
      text21: "",
  
      dropdown8: 0,
    });
  };

  const handleAddWear = () => {
    updateState(1);
  };

  //Хук для перехода к предыдущему состоянию
  const handleBack = () => {
    goBack();
  };

  const handleViewData = () => {
    updateState(5);
  };

  const handleFormDataChange = (newFormData) => {
    setFormData(newFormData); // Обновление данных из Data
  };

  return (
    <Layout>
      <div className="App">
        <Header />

        {currentState === 1 ? ( // Стартовая страница добавления мкд
          <>
            <Data formData={formData} onDataChange={handleFormDataChange} />{" "}
            <div className="btn-main-wear">
              <ButtonData formData={formData} updateState={updateState} />
              <ButtonClearPref
                handleBack={handleBack}
                formData={formData}
                currentState={currentState}
                setFormData={setFormData}
              />
            </div>
          </>
        ) : currentState === 0 ? ( //Стартовая страница
          <StartState
            handleAddWear={handleAddWear}
            handleViewData={handleViewData}
          />
        ) : currentState === 2 ? ( // Страница выбора типа мкд(отключена)
          <>
            <TypeHouse
              formData={formData}
              onDataChange={handleFormDataChange}
            />
            <div className="btn-main-wear">
              <ButtonTypeHouse updateState={updateState} />
              <ButtonClearPref
                handleBack={handleBack}
                formData={formData}
                currentState={currentState}
                setFormData={setFormData}
              />
            </div>
          </>
        ) : currentState === 3 ? ( //Страница добавления конструктива мкд
          <>
            <Constr formData={formData} onDataChange={handleFormDataChange} />
            <div className="btn-main-wear">
              <ButtonConstr formData={formData} updateState={updateState} />
              <ButtonClearPref
                handleBack={handleBack}
                formData={formData}
                currentState={currentState}
                setFormData={setFormData}
              />
            </div>
          </>
        ) : currentState === 4 ? ( //Страница добавления данных инж.систем мкд
          <>
            <Inz formData={formData} onDataChange={handleFormDataChange} />
            <div className="btn-main-wear">
              <ButtonAddHouse formData={formData} updateState={updateState} onDataChange = {resetFormData}/>
              <ButtonClearPref
                handleBack={handleBack}
                formData={formData}
                currentState={currentState}
                setFormData={setFormData}
              />
            </div>
          </>
        ) : currentState === 5 ? ( //Страница поиска мкд по адресу
          <>
            <Search formData={formData} onDataChange={handleFormDataChange} />
            <div className="btn-main-wear">
              <ButtonAdressHouse
                formData={formData}
                updateState={updateState}
              />
              <ButtonClearPref
                handleBack={handleBack}
                formData={formData}
                currentState={currentState}
                setFormData={setFormData}
              />
            </div>
          </>
        ) : currentState === 6 ? ( //Страница добавления износа мкд
          <>
            <Wear updateState={updateState}/>
          </>
        ) : currentState === 7 ? ( //Страница выбора типа элементов мкд
          <>
            <OptionHouse 
              formData={formData}
              onDataChange={handleFormDataChange}
            />
            <div className="btn-main-wear">
              <ButtonNextTypeHouse
                formData={formData}
                updateState={updateState}
              />
              <ButtonClearPref
                handleBack={handleBack}
                formData={formData}
                currentState={currentState}
                setFormData={setFormData}
              />
            </div>
          </>
        ) : null}
      </div>
    </Layout>
  );
};
