import React from "react";
import "../../ResidentialHouse.css";

export const NextButton = ({ handleStepChange }) => {
  return (
    <div className="NextButton_main">
      <button className="NextButton_button" onClick={() => handleStepChange("next")}>
        Далее
      </button>
    </div>
  );
};
