import React from "react";
import "../ResidentialHouse.css";

export const ResidentialConstr = ({ formDataResidential, onDataChange }) => {
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    onDataChange({ ...formDataResidential, [id]: value });
  };

  return (
    <div className="Residential_Constr_Main">
      <div className="Residential_Constr_Data">
        <div className="Residential_Constr_Input_Group">
          <input
            className="Residential_Constr_Input_Field"
            type="number"
            id="foundation_walls_area"
            placeholder="Площадь стен фундамента"
            required
            value={formDataResidential.foundation_walls_area || ''}
            onChange={handleInputChange}
          />
          <input
            className="Residential_Constr_Input_Field"
            type="number"
            id="balcony_area"
            placeholder="Общая площадь балконов и т.д"
            required
            value={formDataResidential.balcony_area || ''}
            onChange={handleInputChange}
          />
          <input
            className="Residential_Constr_Input_Field"
            type="number"
            id="building_walls_area"
            placeholder="Площадь стен здания"
            required
            value={formDataResidential.building_walls_area || ''}
            onChange={handleInputChange}
          />
          <input
            className="Residential_Constr_Input_Field"
            type="number"
            id="load_bearing_area"
            placeholder="Общая площадь несущих конструкций"
            required
            value={formDataResidential.load_bearing_area || ''}
            onChange={handleInputChange}
          />
          <input
            className="Residential_Constr_Input_Field"
            type="number"
            id="floor_area"
            placeholder="Общая площадь перекрытия"
            required
            value={formDataResidential.floor_area || ''}
            onChange={handleInputChange}
          />
          <input
            className="Residential_Constr_Input_Field"
            type="number"
            id="roof_area"
            placeholder="Общая площадь крыши"
            required
            value={formDataResidential.roof_area || ''}
            onChange={handleInputChange}
          />
          <input
            className="Residential_Constr_Input_Field"
            type="number"
            id="balcony_count"
            placeholder="Количество балконов"
            required
            value={formDataResidential.balcony_count || ''}
            onChange={handleInputChange}
          />
          <input
            className="Residential_Constr_Input_Field"
            type="number"
            id="loggia_count"
            placeholder="Количество лоджий"
            required
            value={formDataResidential.loggia_count || ''}
            onChange={handleInputChange}
          />
          <input
            className="Residential_Constr_Input_Field"
            type="number"
            id="canopy_count"
            placeholder="Количество козырьков"
            required
            value={formDataResidential.canopy_count || ''}
            onChange={handleInputChange}
          />
          <input
            className="Residential_Constr_Input_Field"
            type="number"
            id="bay_window_count"
            placeholder="Количество эркеров"
            required
            value={formDataResidential.bay_window_count || ''}
            onChange={handleInputChange}
          />
        </div>
      </div>
    </div>
  );
};
