import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { getActiveSmena, getBoilersHouseBoiler, getBoilersHousePump, getBoilersHouses } from "../../../http/http";
import AuthContext from "../../../../../context/AuthContext";
import { getNowDate } from "../../../../Hydrologist/utils/utils";

export const TControlContext = createContext();

export let TControlProvider = ({ children }) => {
  const { authTokens } = useContext(AuthContext);

  let [boilersHouses, setBoilersHouses] = useState([]);
  let [boilersHouseBoiler, setBoilersHouseBoiler] = useState([]);
  let [boilersHousePump, setBoilersHousePump] = useState([]);
  let [activeSmena, setActiveSmena] = useState({});
  let [selecterBoilersHouse, setSelecterBoilersHouse] = useState(null);
  let [selectedErenergyResource, setSelectedErenergyResource] = useState('electricity');
  let [selectedDate, setSelectedDate] = useState(getNowDate());

  useEffect(() => {
    getBoilersHouses(authTokens, setBoilersHouses);
    getBoilersHouseBoiler(authTokens, setBoilersHouseBoiler);
    getBoilersHousePump(authTokens, setBoilersHousePump);
  }, []);

  useEffect(() => {
    if (boilersHouses.length !== 0) {
      setSelecterBoilersHouse(boilersHouses[0].pk);
    }
  }, [boilersHouses]);

  useEffect(() => {
    getActiveSmena(authTokens, setActiveSmena)
  }, [])

  const memoizedBoilersHouses = useMemo(() => ({
    boilersHouses,
    selecterBoilersHouse,
    setSelecterBoilersHouse,
  }), [boilersHouses, selecterBoilersHouse]);

  const memoizedDate = useMemo(() => ({
    selectedDate,
    setSelectedDate
  }), [selectedDate]);

  const memoizedErenergyResource = useMemo(() => ({
    selectedErenergyResource,
    setSelectedErenergyResource
  }), [selectedErenergyResource]);


  const memoizedSmena = useMemo(() => ({
    activeSmena
  }), [activeSmena])

  return (
    <TControlContext.Provider value={{ memoizedBoilersHouses, memoizedDate, memoizedSmena, memoizedErenergyResource }}>
      {children}
    </TControlContext.Provider>
  );
}