import { useContext, useState } from "react";
import AuthContext from "../../../../../../../../../../context/AuthContext";
import { useForm } from "react-hook-form";
import { worker_context } from "../../../../../../hooks/useStats_worker";
import { RSODynamicInput } from "../../../../../../../../utils/RSODynamicInput/RSODynamicInput";
import { RSODynamicButton } from "../../../../../../../../utils/RSODynamicButton/RSODynamicButton";
import { ModalWindow } from "../../../../../../../../../../components/ModalWindow/ModalWindow";
import { addDayWater } from "../../../../../../http/worker_http";

export let RSODynamicDayWater = () => {
  let { authTokens, logoutUser } = useContext(AuthContext);
  let { handleSubmit, reset } = useForm({ mode: 'onBlur' });

  let [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  let {
    smena,
    updateSmena
  } = useContext(worker_context)

  let createPostDayWater = (e) => {
    e.preventDefault();
    handleOpenModal()
  }

  let [RSODynamicDayWater, setRSODynamicDayWater] = useState({});

  return (
    <>
      <form className='RSODynamicValuesValuesInput__form container' onSubmit={e => handleSubmit(createPostDayWater(e))}>
        <RSODynamicInput
          type='number'
          title='Подано воды за сутки'
          name='all_water'
          setter={setRSODynamicDayWater}
          getter={RSODynamicDayWater}
          option='all_water'
          required={true}
          min={0}
          step={0.01} />
        <RSODynamicButton
          title='Сохранить'
          type='submit' />
      </form>

      <ModalWindow
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title='Подтверждение воды за сутки'
        hook={RSODynamicDayWater}
        isHook1Required={false}>
        <p><b>Вы передаете следующие воду за сутки:</b></p>
        <ul>
          <li>Вода за сутки - {`${RSODynamicDayWater?.all_water}`}</li>          
        </ul>

        <form className='create__smena-btn' onSubmit={e => handleSubmit(addDayWater(e, authTokens, RSODynamicDayWater.all_water, smena[0].id, setRSODynamicDayWater, updateSmena))}>
          <RSODynamicButton title='Подтвердить' type='submit' />
        </form>
      </ModalWindow>
    </>
  )
}