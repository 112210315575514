import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../../../../context/AuthContext";
import { TControlContext } from "../../hooks/useTControl";
import { getDynamicValuesSmena } from "../../../../http/http";
import { ModelTable } from "../../../../../../components/ModelTable/ModelTable";
import { TControlSelect } from "../../../../utils/TControlSelect/TControlSelect";

export let OperParam = () => {
  const { authTokens } = useContext(AuthContext);
  let { memoizedBoilersHouses, memoizedSmena } = useContext(TControlContext);

  const formatDate = (dateStr) => {
    const dateObj = new Date(dateStr);
    return dateObj.toLocaleString('ru-RU', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  };

  const [dynamicValuesGet, setDynamicValuesGet] = useState([]);

  // Загружаем данные для смены один раз при изменении активной смены
  useEffect(() => {
    getDynamicValuesSmena(authTokens, memoizedSmena?.activeSmena?.[0]?.pk, setDynamicValuesGet);
  }, [authTokens, memoizedSmena?.activeSmena]);

  // Обновляем значения с предупреждениями при изменении dynamicValuesGet
  useEffect(() => {
    if (dynamicValuesGet.length <= 1) {
      // Если только одна запись, удаляем предупреждения
      setDynamicValuesGet((prev) => prev.map(item => ({ ...item, warning: null })));
      return;
    }
    const updatedValues = dynamicValuesGet.map((item, index) => {
      if (index === 0) {
        return { ...item, warning: null };
      }

      const previousItem = dynamicValuesGet[index - 1];
      const hasPressureDrop = checkPressureDrop(item.pressure, previousItem.pressure);
      const hasGasDrop = checkPressureDrop(item.gas, previousItem.gas);

      return {
        ...item,
        warning: hasPressureDrop || hasGasDrop ? "Внимание утечка!" : null,
      };
    });

    // Проверяем, изменилось ли значение перед обновлением состояния
    if (JSON.stringify(updatedValues) !== JSON.stringify(dynamicValuesGet)) {
      setDynamicValuesGet(updatedValues);
    }
  }, [dynamicValuesGet]);

  const checkPressureDrop = (current, previous) => {
    return previous && (current < previous * 0.9 || current > previous * 1.1);
  };

  return (
    <div className="container">
      <TControlSelect
        name={"Выберите котельную"}
        list={memoizedBoilersHouses?.boilersHouses}
        value={'pk'}
        visible_value={'address'}
        getter={memoizedBoilersHouses?.selecterBoilersHouse}
        setter={memoizedBoilersHouses?.setSelecterBoilersHouse}
        parse={'int'}
        defaultValue={memoizedBoilersHouses?.selecterBoilersHouse}
      />
      {dynamicValuesGet.length !== 0 ? (
        <>
          {((memoizedBoilersHouses?.boilersHouses.find((wps) => wps.pk === memoizedBoilersHouses?.selecterBoilersHouse)?.coolant_activity) &&
            (memoizedBoilersHouses?.boilersHouses.find((wps) => wps.pk === memoizedBoilersHouses?.selecterBoilersHouse)?.gvs_activity)) &&
            <>
              <ModelTable>
                <thead>
                  <tr>
                    <th colSpan='13'>Параметры контроля</th>
                  </tr>
                  <tr>
                    <th rowSpan='2'>Время/Дата</th>
                    <th rowSpan='2'>Котельная</th>
                    <th>Давление воды на вводе</th>
                    <th colSpan='4'>Теплоноситель</th>
                    <th colSpan='4'>ГВС</th>
                    <th colSpan='1'>Давление газа на вводе</th>
                    <th rowSpan='2'>Наличие утечек</th>
                  </tr>
                  <tr>
                    <th>Pвх</th>
                    <th>T1</th>
                    <th>P1</th>
                    <th>T2</th>
                    <th>P2</th>
                    <th>T3</th>
                    <th>P3</th>
                    <th>T4</th>
                    <th>P4</th>
                    <th>Pгаз</th>
                  </tr>
                </thead>
                <tbody>
                  {dynamicValuesGet.map(item => {
                    const matchingBoilerhouse = memoizedBoilersHouses?.boilersHouses.find(
                      item1 => item.boilerhouse_id === item1.pk && item1.pk === memoizedBoilersHouses?.selecterBoilersHouse
                    );

                    if (matchingBoilerhouse) {
                      return (
                        <tr key={item.id}>
                          <td>{formatDate(item.created_at)}</td>
                          <td>{matchingBoilerhouse.address}</td>
                          <td>
                            {item.pressure}
                          </td>
                          <td>{item.t1}</td>
                          <td>{item.p1}</td>
                          <td>{item.t2}</td>
                          <td>{item.p2}</td>
                          <td>{item.t3}</td>
                          <td>{item.p3}</td>
                          <td>{item.t4}</td>
                          <td>{item.p4}</td>
                          <td>
                            {item.gas}
                          </td>
                          <td>{item.warning && <div style={{ color: "red" }}>Внимание утечка!</div>}</td>
                        </tr>
                      );
                    }
                    return null;
                  })}
                </tbody>
              </ModelTable>
            </>}


          {((memoizedBoilersHouses?.boilersHouses.find((wps) => wps.pk === memoizedBoilersHouses?.selecterBoilersHouse)?.coolant_activity) &&
            (!memoizedBoilersHouses?.boilersHouses.find((wps) => wps.pk === memoizedBoilersHouses?.selecterBoilersHouse)?.gvs_activity)) &&
            <>
              <ModelTable>
                <thead>
                  <tr>
                    <th colSpan='9'>Параметры контроля</th>
                  </tr>
                  <tr>
                    <th rowSpan='2'>Время/Дата</th>
                    <th rowSpan='2'>Котельная</th>
                    <th>Давление воды на вводе</th>
                    <th colSpan='4'>Теплоноситель</th>
                    <th colSpan='1'>Давление газа на вводе</th>
                    <th rowSpan='2'>Наличие утечек</th>
                  </tr>
                  <tr>
                    <th>Pвх</th>
                    <th>T1</th>
                    <th>P1</th>
                    <th>T2</th>
                    <th>P2</th>
                    <th>Pгаз</th>
                  </tr>
                </thead>
                <tbody>
                  {dynamicValuesGet.map(item => {
                    const matchingBoilerhouse = memoizedBoilersHouses?.boilersHouses.find(item1 => item.boilerhouse_id === item1.pk && item1.pk === memoizedBoilersHouses?.selecterBoilersHouse);

                    if (matchingBoilerhouse) {
                      return (
                        <tr key={item.id}>
                          <td>{formatDate(item.created_at)}</td>
                          <td>{matchingBoilerhouse.address}</td>
                          <td>{item.pressure}</td>
                          <td>{item.t1}</td>
                          <td>{item.p1}</td>
                          <td>{item.t2}</td>
                          <td>{item.p2}</td>
                          <td>{item.gas}</td>
                          <td>{item.warning && <div style={{ color: "red" }}>Внимание утечка!</div>}</td>
                        </tr>
                      );
                    }
                    return null; // Если нет соответствия, возвращаем null или пустой фрагмент
                  })}
                </tbody>
              </ModelTable>
            </>}
        </>
      ) : ("")}
    </div>
  );
};
