import { useContext } from "react"
import { worker_workzone_context } from "../../../../hooks/useStats_worker_workzone"
import { RSODynamicButtonGroup } from "../../../../../../utils/RSODynamicButtonGroup/RSODynamicButtonGroup"
import { RSODynamicEquipmentFailuresPaint } from "./components/RSODynamicEquipmentFailuresPaint/RSODynamicEquipmentFailuresPaint"
import { RSODynamicEquipmentFailuresInput } from "./components/RSODynamicEquipmentFailuresInput/RSODynamicEquipmentFailuresInput"

export let RSODynamicEquipmentFailures = () => {

  let { equipmentFailuresTab, setEquipmentFailuresTab } = useContext(worker_workzone_context)

  return (
    <>
      <RSODynamicButtonGroup
        buttons={[
          { title: 'Вывод отказов оборудования' },
          { title: 'Ввод отказов оборудования' },]}
        setter={setEquipmentFailuresTab}
        getter={equipmentFailuresTab}
        color='green' />

      {equipmentFailuresTab === "Вывод отказов оборудования" && (
        <RSODynamicEquipmentFailuresPaint />)}
      {equipmentFailuresTab === "Ввод отказов оборудования" && (
        <RSODynamicEquipmentFailuresInput />)}
    </>
  )
}