import React from "react";
import "../../ResidentialHouse.css";

export const PrefButton = ({ handleStepChange }) => {
  return (
    <div className="PrefButton_main">
      <button className="PrefButton_button" onClick={() => handleStepChange("prev")}>
        Назад
      </button>
    </div>
  );
};
