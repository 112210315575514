import React from 'react';
import '../../css/Button.css';

const ButtonConstr = ({formData, updateState}) => {

  const isFormDataValid = () => {
    // Проверка на заполнение всех полей
    if (!formData.text6 || !formData.text7 || !formData.text8 || !formData.text9 || !formData.text10 ||
        !formData.text11 || !formData.text12 || !formData.text13 || !formData.text14 || !formData.text15) {
      alert("Пожалуйста, заполните все необходимые поля, в случае отсутствия каких-либо элементов введите '0'");
      return false;
    }

    // Проверка на отрицательные значения
    if (formData.text6 < 0 || formData.text7 < 0 || formData.text8 < 0 || formData.text9 < 0 ||
        formData.text10 < 0 || formData.text11 < 0 || formData.text12 < 0 || formData.text13 < 0 ||
        formData.text14 < 0 || formData.text15 < 0) {
      alert("Значения не могут быть отрицательными");
      return false;
    }

    // Все проверки прошли успешно
    return true;
  };

  const handleNextClick = () => {
    if (!isFormDataValid()) {
      return;
    }
    
    updateState(4);
  };

  return(
    <button className="btn-osn-wear" type="button" onClick={handleNextClick}> Далее </button>
  );
};

export default ButtonConstr;
