import { useContext, useEffect, useState } from "react"
import { RSODynamicInput } from "../../../../../../../utils/RSODynamicInput/RSODynamicInput"
import { RSODynamicSelect } from "../../../../../../../utils/RSODynamicSelect/RSODynamicSelect"
import { worker_workzone_context } from "../../../../../hooks/useStats_worker_workzone"
import AuthContext from "../../../../../../../../../context/AuthContext"
import { getHydrologistDataMonthDate, getWells } from "../../../../../http/worker_http"
import { getNowDate } from "../../../../../../../../../utils/utils"
import { getDaysInMonth } from "../../../../../../../../Hydrologist/utils/utils"
import { RSODynamicArchiveEditTable } from "./RSODynamicArchiveEditTable"

export let RSODynamicArchiveHydrologistHands = () => {

  let { authTokens, logoutUser } = useContext(AuthContext);
  let { currentWps } = useContext(worker_workzone_context)

  let [WPSWells, setWPSWells] = useState([]);
  let [selectedWell, setSelectedWell] = useState(null);
  let [tableData, setTableData] = useState([]);
  let [isLoading, setIsLoading] = useState(false);
  let [isModalOpen, setIsModalOpen] = useState(false)
  let [selectedDate, setSelectedDate] = useState(getNowDate());

  const [datesInMonth, setDatesInMonth] = useState([])

  useEffect(() => {
    getWells(authTokens, currentWps.prefabricate_water_pipeline, setWPSWells);
  }, [])

  useEffect(() => {
    setSelectedWell(WPSWells?.[0]?.id)
  }, [WPSWells])

  useEffect(() => {
    if (selectedDate && selectedWell) {
      getHydrologistDataMonthDate(authTokens, selectedDate, selectedWell, setTableData, datesInMonth)
    }
  }, [selectedDate, selectedWell, datesInMonth])

  useEffect(() => {
    getDaysInMonth(selectedDate, setDatesInMonth)
  }, [selectedDate])

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return <div>
    <div className="container">
      <form className='hydrologist_worker-form'>
        <div>
          <RSODynamicSelect
            name='Выберите номер скважины'
            list={WPSWells}
            value='id'
            parse='int'
            visible_value='number_well'
            setter={setSelectedWell}
            getter={selectedWell}
            secondVisibleName='status'
          />
        </div>
        <div style={{ marginLeft: '20px' }}>
          <RSODynamicInput
            type='date'
            title='Выберите дату'
            name='selectDate'
            setter={setSelectedDate}
            getter={selectedDate}
            value={selectedDate}
            required={true}
          />
        </div>
      </form>
      {selectedWell &&
        <RSODynamicArchiveEditTable data={tableData}
          action={() => getHydrologistDataMonthDate(authTokens, selectedDate, selectedWell, setTableData, datesInMonth)}
          wells={WPSWells}
          selectedWell={selectedWell}

          actions />
      }
    </div>
  </div>
}