import { useContext, useEffect, useState } from "react";
import { dispatcher_context } from "../../../../hooks/useStats_dispatcher";
import AuthContext from "../../../../../../../../context/AuthContext";
import { ModelTable } from "../../../../../../../../components/ModelTable/ModelTable"
import { dateConverter } from "../../../../../../../../utils/utils";
import { RSODynamicNotFound } from '../../../../../../utils/RSODynamicNotFound/RSODynamicNotFound'
import { getPumps, getWPS, getWells } from "../../../../http/dispatcherHttp";
import { RSODynamicSelect } from "../../../../../../utils/RSODynamicSelect/RSODynamicSelect";
import Jelly from '../../../../../../../../components/Jelly'

export let RSODynamicDispEquipmentFailuresPaint = ({ getFunc, title }) => {

  let { authTokens } = useContext(AuthContext);

  let {
    equipmentFailuresTimeTab,
    equipmentFailuresFinishRepairTab,
    equipmentFailuresStartPeriod,
    equipmentFailuresEndPeriod,
    equipmentFailuresType,
    equipmentFailuresSelectedWPS
  } = useContext(dispatcher_context);

  let [failureWells, setFailureWells] = useState([]);
  let [failurePumps, setFailurePumps] = useState([]);
  let [wells, setWells] = useState([])
  let [pumps, setPumps] = useState([])
  let [WPS, setWPS] = useState([])


  useEffect(() => {//useEffect для подгрузки данных
    if (title && title === 'period') {
      getFunc(authTokens,
        (equipmentFailuresFinishRepairTab && (equipmentFailuresFinishRepairTab === "В ремонте" ? true : false)),
        `${equipmentFailuresStartPeriod}/${equipmentFailuresEndPeriod}`,
        setFailureWells,
        setFailurePumps
      );
    } else {
      getFunc(authTokens,
        (equipmentFailuresFinishRepairTab && (equipmentFailuresFinishRepairTab === "В ремонте" ? true : false)),
        setFailureWells,
        setFailurePumps
      );
    }
    getWells(authTokens, setWells);
    getPumps(authTokens, setPumps);
    if (equipmentFailuresSelectedWPS !== -1) {
      getWPS(authTokens, setWPS)
    }
  }, [equipmentFailuresTimeTab,
    equipmentFailuresSelectedWPS,
    equipmentFailuresFinishRepairTab,
    equipmentFailuresStartPeriod,
    equipmentFailuresEndPeriod])

  let findWPSById = (id) => {
    return WPS.find(wps => wps.id === id)?.name
  }

  return (
    <div className="large__container">
      {(wells.length !== 0 && pumps.length !== 0) ?
        <>
          {(equipmentFailuresType === "Скважины" &&
            failureWells.length !== 0 &&
            wells.length !== 0 &&
            equipmentFailuresFinishRepairTab) ?
            <>
              {(equipmentFailuresSelectedWPS === -1) &&//Отказы по всем ВЗУ
                <>
                  {(failureWells.length !== 0) ?
                    <ModelTable>
                      <thead>
                        <tr>
                          <th colSpan={(equipmentFailuresFinishRepairTab === "Завершено" ? 10 : 9)}>{`${equipmentFailuresFinishRepairTab === "Завершено" ? 'Завершенные ремонты скважин' : 'Скважины в ремонте'}`}{title && ` за период с ${dateConverter(equipmentFailuresStartPeriod)} по ${dateConverter(equipmentFailuresEndPeriod)}`}</th>
                        </tr>
                        <tr>
                          <th>Дата</th>
                          <th>Время</th>
                          <th>ВЗУ</th>
                          <th>ФИО</th>
                          <th>Номер смены</th>
                          <th>Номер скважины</th>
                          <th>Неисправное оборудование</th>
                          <th>Вид отказа</th>
                          <th>Проведенные работы</th>
                          {equipmentFailuresFinishRepairTab === "Завершено" &&
                            <th>Дата окончания ремонта</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {failureWells?.map(item => (
                          <tr>
                            <td>{dateConverter(item?.date_of_repair)}</td>
                            <td>{item?.time.substring(0, 5)}</td>
                            <td>{title ? item?.wps_name : item?.vzu_name}</td>
                            <td>{item?.fullname}</td>
                            <td>{item?.shift_number}</td>
                            <td>{wells?.find(well => well.id === item?.well_id)?.number_well}</td>
                            <td>{item?.broken}</td>
                            <td>{item?.type_of_repair}</td>
                            <td>{item?.note}</td>
                            {equipmentFailuresFinishRepairTab === "Завершено" &&
                              <td>{item?.date_of_technical_inspection && dateConverter(item?.date_of_technical_inspection)}</td>}
                          </tr>
                        ))}
                      </tbody>
                      {console.log(failureWells.length)}
                    </ModelTable> :
                    <RSODynamicNotFound
                      title={`Нет отказов скважин`}
                      icon='😊'
                      descr={`Среди всех ВЗУ нет отказов скважин за период`}
                    />
                  }

                </>
              }
              {(equipmentFailuresSelectedWPS !== -1) &&//Отказы конкретного ВЗУ
                <>
                  {(failureWells.length !== 0 &&
                    wells.length !== 0 &&
                    (failureWells.filter(currentWPS => (title ? currentWPS.wps_name : currentWPS.vzu_name) === (WPS.find(wps => wps.id === equipmentFailuresSelectedWPS))?.name)).length !== 0) ?
                    //Ремонты конкретного ВЗУ
                    <ModelTable>
                      <thead>
                        <tr>
                          <th colSpan={(equipmentFailuresFinishRepairTab === "Завершено" ? 10 : 9)}>{`${equipmentFailuresFinishRepairTab === "Завершено" ? 'Завершенные ремонты скважин' : 'Скважины в ремонте ' + findWPSById(equipmentFailuresSelectedWPS)}`}{title && ` за период с ${dateConverter(equipmentFailuresStartPeriod)} по ${dateConverter(equipmentFailuresEndPeriod)}`}</th>
                        </tr>
                        <tr>
                          <th>Дата</th>
                          <th>Время</th>
                          <th>ВЗУ</th>
                          <th>ФИО</th>
                          <th>Номер смены</th>
                          <th>Номер скважины</th>
                          <th>Неисправное оборудование</th>
                          <th>Вид отказа</th>
                          <th>Проведенные работы</th>
                          {equipmentFailuresFinishRepairTab === "Завершено" &&
                            <th>Дата окончания ремонта</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {(failureWells.filter(currentWPS => (title ? currentWPS.wps_name : currentWPS.vzu_name) === (WPS.find(wps => wps.id === equipmentFailuresSelectedWPS))?.name))?.map(item => (
                          <tr>
                            <td>{dateConverter(item?.date_of_repair)}</td>
                            <td>{item?.time.substring(0, 5)}</td>
                            <td>{title ? item?.wps_name : item?.vzu_name}</td>
                            <td>{item?.fullname}</td>
                            <td>{item?.shift_number}</td>
                            <td>{wells?.find(well => well.id === item?.well_id)?.number_well}</td>
                            <td>{item?.broken}</td>
                            <td>{item?.type_of_repair}</td>
                            <td>{item?.note}</td>
                            {equipmentFailuresFinishRepairTab === "Завершено" &&
                              <td>{item?.date_of_technical_inspection && dateConverter(item?.date_of_technical_inspection)}</td>}
                          </tr>
                        ))}
                      </tbody>
                    </ModelTable> :
                    <RSODynamicNotFound
                      title={`Нет отказов скважин`}
                      icon='😊'
                      descr={`У "${findWPSById(equipmentFailuresSelectedWPS)}" нет скважин со статусом [${equipmentFailuresFinishRepairTab}]`} />
                  }
                </>
              }
            </> : <></>
          }

          {(equipmentFailuresType === "Насосы" &&
            failurePumps.length !== 0 &&
            pumps.length !== 0 &&
            equipmentFailuresFinishRepairTab) ?
            <>
              {(equipmentFailuresSelectedWPS === -1) &&//Отказы по всем ВЗУ
                <>
                  {(failurePumps.length !== 0 &&
                    pumps.length !== 0) ?
                    <ModelTable>
                      <thead>
                        <tr>
                          <th colSpan={(equipmentFailuresFinishRepairTab === "Завершено" ? 10 : 9)}>{`${equipmentFailuresFinishRepairTab === "Завершено" ? 'Завершенные ремонты насосов' : 'Насосы в ремонте'}`}{title && ` за период с ${dateConverter(equipmentFailuresStartPeriod)} по ${dateConverter(equipmentFailuresEndPeriod)}`}</th>
                        </tr>
                        <tr>
                          <th>Дата</th>
                          <th>Время</th>
                          <th>ВЗУ</th>
                          <th>ФИО</th>
                          <th>Номер смены</th>
                          <th>Номер насоса</th>
                          <th>Неисправное оборудование</th>
                          <th>Вид отказа</th>
                          <th>Проведенные работы</th>
                          {equipmentFailuresFinishRepairTab === "Завершено" &&
                            <th>Дата окончания ремонта</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {failurePumps?.map(item => (
                          <tr>
                            <td>{dateConverter(item?.date_of_repair)}</td>
                            <td>{item?.time.substring(0, 5)}</td>
                            <td>{title ? item?.wps_name : item?.vzu_name}</td>
                            <td>{item?.fullname}</td>
                            <td>{item?.shift_number}</td>
                            <td>{pumps?.find(pump => pump.id === item?.water_pumping_station_pump_id)?.number_of_line}</td>
                            <td>{item?.broken}</td>
                            <td>{item?.type_of_repair}</td>
                            <td>{item?.note}</td>
                            {equipmentFailuresFinishRepairTab === "Завершено" &&
                              <td>{item?.date_of_technical_inspection && dateConverter(item?.date_of_technical_inspection)}</td>}
                          </tr>
                        ))}
                      </tbody>
                    </ModelTable> :
                    <RSODynamicNotFound
                      title={`Нет отказов насосов`}
                      icon='😊'
                      descr={`Среди всех ВЗУ нет отказов насосов`}
                    />
                  }

                </>
              }
              {(equipmentFailuresSelectedWPS !== -1) &&
                <>
                  {(failurePumps.length !== 0 &&
                    pumps.length !== 0 &&
                    (failurePumps.filter(currentWPS => (title ? currentWPS.wps_name : currentWPS.vzu_name) === (WPS.find(wps => wps.id === equipmentFailuresSelectedWPS))?.name)).length !== 0) ?
                    //Ремонты конкретного ВЗУ
                    <ModelTable>
                      <thead>
                        <tr>
                          <th colSpan={(equipmentFailuresFinishRepairTab === "Завершено" ? 10 : 9)}>{`${equipmentFailuresFinishRepairTab === "Завершено" ? 'Завершенные ремонты насосов' : 'Насосы в ремонте ' + findWPSById(equipmentFailuresSelectedWPS)}`}{title && ` за период с ${dateConverter(equipmentFailuresStartPeriod)} по ${dateConverter(equipmentFailuresEndPeriod)}`}</th>
                        </tr>
                        <tr>
                          <th>Дата</th>
                          <th>Время</th>
                          <th>ВЗУ</th>
                          <th>ФИО</th>
                          <th>Номер смены</th>
                          <th>Номер насоса</th>
                          <th>Неисправное оборудование</th>
                          <th>Вид отказа</th>
                          <th>Проведенные работы</th>
                          {equipmentFailuresFinishRepairTab === "Завершено" &&
                            <th>Дата окончания ремонта</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {(failurePumps.filter(currentWPS => (title ? currentWPS.wps_name : currentWPS.vzu_name) === (WPS.find(wps => wps.id === equipmentFailuresSelectedWPS))?.name))?.map(item => (
                          <tr>
                            <td>{dateConverter(item?.date_of_repair)}</td>
                            <td>{item?.time.substring(0, 5)}</td>
                            <td>{item?.vzu_name}</td>
                            <td>{item?.fullname}</td>
                            <td>{item?.shift_number}</td>
                            <td>{pumps?.find(pump => pump.id === item?.water_pumping_station_pump_id)?.number_of_line}</td>
                            <td>{item?.broken}</td>
                            <td>{item?.type_of_repair}</td>
                            <td>{item?.note}</td>
                            {equipmentFailuresFinishRepairTab === "Завершено" &&
                              <td>{item?.date_of_technical_inspection && dateConverter(item?.date_of_technical_inspection)}</td>}
                          </tr>
                        ))}
                      </tbody>
                    </ModelTable> :
                    <RSODynamicNotFound
                      title={`Нет отказов насосов`}
                      icon='😊'
                      descr={`У "${findWPSById(equipmentFailuresSelectedWPS)}" нет насосов со статусом [${equipmentFailuresFinishRepairTab}]`} />
                  }
                </>
              }
            </> : <></>
          }
        </> : <Jelly />}
    </div>
  )

}