import { API_URL } from "../../../config";

export let getJobTitle = async (authTokens) => {
  let response = await fetch(`${API_URL}/user/profile/`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${authTokens.access}` },
  });
  let data = await response.json()
  return data
};
