import React, { useState, useEffect} from "react"; 

import "../ResidentialHouse.css";

export const ResidentialOption = ({ formDataResidential, onDataChange }) => {

    // Прокрутка вверх при монтировании компонента
    useEffect(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' 
      });
    }, []); // Пустой массив зависимостей, чтобы эффект срабатывал только при монтировании компонента
  
  //Отслеживанеи ввода данных, а также состояние выпадающего списка
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    onDataChange({ ...formDataResidential, [id]: value });
  };

  return (
    <div className="Residential_Option_Main">
      <div className="Residential_Option_Inputs">
        <div className="Residential_Option_Input_Field_Group">
        <label className="Residential_Option_Label" htmlFor="foundationType">
            Тип Здания:
          </label>
          <select
            className="Residential_Option_Select_Field"
            id="buildingType"
            value={formDataResidential.buildingType || ''}
            onChange={handleInputChange}
          >
            <option value="0">Выбрать</option>
          </select>

          <label className="Residential_Option_Label" htmlFor="foundationType">
            Тип фундамента:
          </label>
          <select
            className="Residential_Option_Select_Field"
            id="foundationType"
            value={formDataResidential.foundationType || ''}
            onChange={handleInputChange}
          >
            <option value="0">Выбрать</option>
            <option value="1">столбчатые деревянные с забиркой</option>
            <option value="2">столбчатые, каменные с кирпичным цоколем</option>
            <option value="3">ленточные каменные</option>
            <option value="4">ленточные крупноблочные</option>
            <option value="5">свайные столбчатые каменные, бетонные и железобетонные</option>
          </select>

          <label className="Residential_Option_Label" htmlFor="wallType">
            Тип стен:
          </label>
          <select
            className="Residential_Option_Select_Field"
            id="wallType"
            value={formDataResidential.wallType || ''}
            onChange={handleInputChange}
          >
            <option value="0">Выбрать</option>
            <option value="1">деревянные, сборно-щитовые</option>
            <option value="2">деревянные каркасные</option>
            <option value="3">рубленные из бревен и брусчатые</option>
            <option value="4">деревянные рубленные с наружной облицовкой кирпичом</option>
            <option value="5">кирпичные</option>
            <option value="6">кирпичные c облицовкой керамическими блоками и плитками</option>
            <option value="7">из мелких блоков, исскуственных и естественных камней</option>
            <option value="8">из крупноразмерных блоков и однослойных несущих панелей</option>
            <option value="9">из слоистых железобетонных панелей</option>
            <option value="10">из несущих панелей</option>
          </select>

          <label className="Residential_Option_Label" htmlFor="floorType">
            Тип перекрытий:
          </label>
          <select
            className="Residential_Option_Select_Field"
            id="floorType"
            value={formDataResidential.floorType || ''}
            onChange={handleInputChange}
          >
            <option value="0">Выбрать</option>
            <option value="1">деревянные неоштукатуренные</option>
            <option value="2">из кирпичных сводов по стальным балкам</option>
            <option value="3">из двухскорлупных железобетонных прокатных панелей</option>
            <option value="4">из сборного железобетонного настила</option>
            <option value="5">из сборных монолитных сплошных плит</option>
            <option value="6">монолитные и сборные железобетонные балки покрытий и перекрытий</option>
          </select>

          <label className="Residential_Option_Label" htmlFor="roofType">
            Тип крыши:
          </label>
          <select
            className="Residential_Option_Select_Field"
            id="roofType"
            value={formDataResidential.roofType || ''}
            onChange={handleInputChange}
          >
            <option value="0">Выбрать</option>
            <option value="1">деревянные</option>
            <option value="2">железобетонные сборные</option>
            <option value="3">совмещенные, из сборных железобетонных слоистых панелей</option>
          </select>
          
          <label className="Residential_Option_Label" htmlFor="roofingType">
            Тип кровли:
          </label>
          <select
            className="Residential_Option_Select_Field"
            id="roofingType"
            value={formDataResidential.roofingType || ''}
            onChange={handleInputChange}
          >
            <option value="0">Выбрать</option>
            <option value="1">рулонные</option>
            <option value="2">мастичные</option>
            <option value="3">стальные</option>
            <option value="4">из асбестоцементных листов</option>
            <option value="5">черепичные</option>
            <option value="5">драночные</option>
            <option value="5">тесовые</option>
          </select>

          <label className="Residential_Option_Label" htmlFor="balconyType">
            Тип балконов:
          </label>
          <select
            className="Residential_Option_Select_Field"
            id="balconyType"
            value={formDataResidential.balconyType || ''}
            onChange={handleInputChange}
          >
            <option value="0">Выбрать</option>
            <option value="1">сборные железобетонные лоджии</option>
            <option value="2">балконы, козырьки, эркеры</option>
          </select> 
        </div>
      </div>
    </div>
  );
};
