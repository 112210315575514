import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../../../../context/AuthContext";
import { TControlContext } from "../../hooks/useTControl";
import { TControlSelect } from "../../../../utils/TControlSelect/TControlSelect";
import { getMonthData, getSmenaData, getTodayData, patchDailyRates, postDailyRates } from "../../../../http/http";
import { TControlInput } from "../../../../utils/TControlInput/TControlInput";
import { useForm } from "react-hook-form";
import { TControlButton } from "../../../../utils/TControlButton/TControlButton";
import { ModalWindow } from "../../../../../../components/ModalWindow/ModalWindow";


export let DayData = () => {
  const { authTokens } = useContext(AuthContext);

  const zxc123 = (e) => {
    if (e && typeof e.preventDefault === 'function') {
      e.preventDefault();
    }

    // Получаем текущую дату в формате yyyy-MM-dd
    const currentDate = new Date().toISOString().split('T')[0]; // Например, "2024-09-22"

    // Функция для проверки массива на наличие записи с текущей датой
    const hasSameDayRecord = (array) => {
      return array.some(item => {
        const itemDate = new Date(item.created_at).toISOString().split('T')[0];
        return itemDate === currentDate;
      });
    };

    // Проверяем массивы electricityByMonth, waterByMonth, gasByMonth
    const shouldPatch = hasSameDayRecord(electricityByMonth) ||
      hasSameDayRecord(waterByMonth) ||
      hasSameDayRecord(gasByMonth);

    if (shouldPatch) {
      // Если запись с таким же днем найдена, вызываем PatchDailyRates
      patchDailyRates(authTokens, dailyDinamycData?.[0]?.pk, data, memoizedBoilersHouses?.selecterBoilersHouse, memoizedSmena?.activeSmena?.[0]?.pk, setData);
    } else {
      // Иначе создаем новую запись с помощью postDailyRates
      postDailyRates(authTokens, data, memoizedBoilersHouses?.selecterBoilersHouse, memoizedSmena?.activeSmena?.[0]?.pk, setData);
    }
  };

  let {
    memoizedBoilersHouses,
    memoizedDate,
    memoizedSmena,
    memoizedErenergyResource
  } = useContext(TControlContext)

  let [dailyDinamycData, setDailyDinamycData] = useState();
  let [electricityByMonth, setElectricityByMonth] = useState([]);
  let [waterByMonth, setWaterByMonth] = useState([]);
  let [gasByMonth, setGasByMonth] = useState([]);

  useEffect(() => {
    if (memoizedSmena?.activeSmena?.[0]?.pk) {
      getTodayData(authTokens, memoizedSmena?.activeSmena?.[0]?.pk, setDailyDinamycData)
      getSmenaData(authTokens,
        'electricity',
        memoizedBoilersHouses?.selecterBoilersHouse,
        memoizedSmena?.activeSmena?.[0]?.pk,
        memoizedDate.selectedDate,
        setElectricityByMonth)
      getSmenaData(authTokens,
        'water',
        memoizedBoilersHouses?.selecterBoilersHouse,
        memoizedSmena?.activeSmena?.[0]?.pk,
        memoizedDate.selectedDate,
        setWaterByMonth)
      getSmenaData(authTokens,
        'gas',
        memoizedBoilersHouses?.selecterBoilersHouse,
        memoizedSmena?.activeSmena?.[0]?.pk,
        memoizedDate.selectedDate,
        setGasByMonth)
    }
  }, [memoizedBoilersHouses.selecterBoilersHouse, memoizedDate.selectedDate, memoizedErenergyResource.selectedErenergyResource, memoizedSmena?.activeSmena])

  let [isModalOpen, setIsModalOpen] = useState(false);
  let [data, setData] = useState({})

  let [note, setNote] = useState('')

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  let createPostHourlyRates = (e) => {
    console.log('open modal', e);
    e.preventDefault();
    handleOpenModal()
  }

  useEffect(() => {
  }, [memoizedBoilersHouses.selecterBoilersHouse, memoizedDate.selectedDate, memoizedErenergyResource.selectedErenergyResource])


  return (
    <div style={{ marginTop: '50px' }}>
      <form className='hydrologist_worker-form'>
        <TControlSelect
          name={"Выберите котельную"}
          list={memoizedBoilersHouses?.boilersHouses}
          value={'pk'}
          visible_value={'address'}
          getter={memoizedBoilersHouses?.selecterBoilersHouse}
          setter={memoizedBoilersHouses?.setSelecterBoilersHouse}
          parse={'int'}
          defaultValue={memoizedBoilersHouses?.selecterBoilersHouse}
        />
        <TControlInput
          type='date'
          title='Выберите дату'
          name='selectDate'
          setter={memoizedDate?.setSelectedDate}
          getter={memoizedDate?.selectedDate}
          value={memoizedDate?.selectedDate}
          required={true}
        />
      </form>

      <form className='RSODynamicValuesValuesInput__form container' style={{ marginTop: '50px' }} onSubmit={createPostHourlyRates}>
        <h3 className="tcontrol__title" style={{ marginTop: '0px', marginBottom: '20px' }}>Вода</h3>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <TControlInput
            type='number'
            title='Показание ПУ'
            name='water_consumption'
            setter={setData}
            getter={data}
            option='water_consumption'
            required={true}
            min='0'
            step='0.01'
          />
          <TControlInput
            type='number'
            title='Время работы (часы)'
            name='water_consumption_time_hours'
            setter={setData}
            getter={data}
            option='water_consumption_time_hours'
            required={true}
            min='0'
            max='24'
            step='1'
          />
          <TControlInput
            type='number'
            title='Время работы (минуты)'
            name='water_consumption_time_minutes'
            setter={setData}
            getter={data}
            option='water_consumption_time_minutes'
            required={true}
            min='0'
            max='60'
            step='1'
          />
        </div>
        <h3 className="tcontrol__title" style={{ marginTop: '0px', marginBottom: '20px' }}>Газ</h3>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <TControlInput
            type='number'
            title='Показание ПУ'
            name='gas_consumption'
            setter={setData}
            getter={data}
            option='gas_consumption'
            required={true}
            min='0'
            step='0.01'
          />
          <TControlInput
            type='number'
            title='Время работы (часы)'
            name='gas_consumption_time_hours'
            setter={setData}
            getter={data}
            option='gas_consumption_time_hours'
            required={true}
            min='0'
            max='24'
            step='1'
          />
          <TControlInput
            type='number'
            title='Время работы (минуты)'
            name='gas_consumption_time_minutes'
            setter={setData}
            getter={data}
            option='gas_consumption_time_minutes'
            required={true}
            min='0'
            max='60'
            step='1'
          />
        </div>
        <h3 className="tcontrol__title" style={{ marginTop: '0px', marginBottom: '10px' }}>Электричество</h3>
        <p style={{ textAlign: 'center', marginBottom: '20px' }}>Вводится только по понедельникам и в первое число месяца</p>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <TControlInput
            type='number'
            title='Показание ПУ'
            name='electricity_consumption'
            setter={setData}
            getter={data}
            option='electricity_consumption'
            required={false}
            min='0'
            step='0.01'
          />
          <TControlInput
            type='number'
            title='Время работы (часы)'
            name='electricity_consumption_time_hours'
            setter={setData}
            getter={data}
            option='electricity_consumption_time_hours'
            required={false}
            min='0'
            max='24'
            step='1'
          />
          <TControlInput
            type='number'
            title='Время работы (минуты)'
            name='electricity_consumption_time_minutes'
            setter={setData}
            getter={data}
            option='electricity_consumption_time_minutes'
            required={false}
            min='0'
            max='60'
            step='1'
          />
        </div>
        <TControlButton
          title='Сохранить'
          type='submit' />
      </form>
      <ModalWindow
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title='Подтверждение показателей'
        hook={data}>
        <p><b>Вы передаете следующие динамические параметры:</b></p>
        <ul>
          <li>Показание ПУ (вода) - {`${data.water_consumption}`}</li>
          <li>Время работы водного оборудования - {`${data.water_consumption_time_hours}:${data.water_consumption_time_minutes}`}</li>
          <li>Показание ПУ (газ) - {`${data.gas_consumption}`}</li>
          <li>Время работы газового оборудования - {`${data.gas_consumption_time_hours}:${data.gas_consumption_time_minutes}`}</li>
          {data.electricity_consumption &&
            <li>Показание ПУ (электроэнергия) - {`${data.electricity_consumption}`}</li>}
          {(data.electricity_consumption_time_hours && data.electricity_consumption_time_minutes) &&
            <li>Время работы электрооборудования - {`${data.electricity_consumption_time_hours}:${data.electricity_consumption_time_minutes}`}</li>}

          {data.note && (
            ''
            // <li>Включение резервного насоса - {`${RSODynamicValuesValuesInput.note}`}</li>
          )}
        </ul>

        <form className='create__smena-btn 123' onSubmit={zxc123}>
          <TControlButton title='Подтвердить' type='submit' />
        </form>
      </ModalWindow>
    </div>
  )
}