export let resetInputFields = (name) => {//Очистка полей ввода по name
  const inputField = document.querySelector(`input[name="${name}"]`);
  inputField.value = '';
};

export let extractValues = (obj) => {//Переделывает список в массив подряд идущих значений списка
  return Object.values(obj);
}

export let dateConverter = (dateString) => {
  const parts = dateString.split('-');
  const year = parts[0];
  const month = parts[1];
  const day = parts[2];
  const formattedDate = `${day}.${month}.${year}`;
  return formattedDate;
};

export let getNowDate = () => {//Получаем текущую дату в формате ГГГГ-ММ-ЧЧ
  const today = new Date();
  const year = today.getFullYear();
  const month = (today.getMonth() + 1).toString().padStart(2, '0');
  const day = today.getDate().toString().padStart(2, '0');
  const currentDate = `${year}-${month}-${day}`;
  return currentDate
}