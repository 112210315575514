import { createContext, useState } from "react"
import { getNowDate } from "../../../../../utils/utils"

export let dispatcher_context = createContext()

export let DispatcherProvider = ({ children }) => {

  let [activeTab, setActiveTab] = useState("Динамическая информация")
  let [dynamicActiveTab, setDynamicActiveTab] = useState("Оперативные данные")
  let [dateReportTab, setDateReportTab] = useState('Почасовые показатели')
  let [selectedDate, setSelectedDate] = useState(getNowDate())
  let [isSelectDateActive, setIsSelectDateActive] = useState(true)
  let [selectedStartOfPeriodDate, setSelectedStartOfPeriodDate] = useState(getNowDate())
  let [selectedEndOfPeriodDate, setSelectedEndOfPeriodDate] = useState(getNowDate())
  let [isSelectPeriodDateActive, setIsSelectPeriodDateActive] = useState(false)
  let [selectedPeriodWPS, setSelectedPeriodWPS] = useState('')
  let [equipmentFailuresTimeTab, setEquipmentFailuresTimeTab] = useState('Отказы всего')
  let [equipmentFailuresWPS, setEquipmentFailuresWPS] = useState([{id: -1, name: 'Все'}])
  let [equipmentFailuresSelectedWPS, setEquipmentFailuresSelectedWPS] = useState(-1)
  let [equipmentFailuresFinishRepairTab, setEquipmentFailuresFinishRepairTab] = useState('В ремонте')
  let [equipmentFailuresStartPeriod, setEquipmentFailuresStartPeriod] = useState(getNowDate())
  let [equipmentFailuresEndPeriod, setEquipmentFailuresEndPeriod] = useState(getNowDate())
  let [equipmentFailuresType, setEquipmentFailuresType] = useState('Скважины')

  return (
    <dispatcher_context.Provider
      value={{
        activeTab,
        dynamicActiveTab,
        dateReportTab,
        selectedDate,
        isSelectDateActive,
        selectedStartOfPeriodDate,
        selectedEndOfPeriodDate,
        isSelectPeriodDateActive,
        selectedPeriodWPS,
        equipmentFailuresSelectedWPS,
        equipmentFailuresTimeTab,
        equipmentFailuresWPS,
        equipmentFailuresFinishRepairTab,
        equipmentFailuresStartPeriod,
        equipmentFailuresEndPeriod,
        equipmentFailuresType,        
        setActiveTab,
        setDynamicActiveTab,
        setDateReportTab,
        setSelectedDate,
        setIsSelectDateActive,
        setSelectedStartOfPeriodDate,
        setSelectedEndOfPeriodDate,
        setIsSelectPeriodDateActive,
        setSelectedPeriodWPS,
        setEquipmentFailuresSelectedWPS,
        setEquipmentFailuresTimeTab,
        setEquipmentFailuresWPS,
        setEquipmentFailuresFinishRepairTab,
        setEquipmentFailuresStartPeriod,
        setEquipmentFailuresEndPeriod,
        setEquipmentFailuresType,
      }}
    >
      {children}
    </dispatcher_context.Provider>
  )
}