import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { getJobTitle } from "../Hydrologist/http/http";
import Jelly from "../../components/Jelly";
import Layout from "../../components/Layout";
import { TControlNotFound } from "./utils/TControlNotFound/TControlNotFound";
import { TControlProvider } from "./components/TControlWorker/hooks/useTControl";
import { TControlWorker } from "./components/TControlWorker/TControlWorker";

export const TControl = () => {
  let navigate = useNavigate();

  let { authTokens, logoutUser } = useContext(AuthContext);
  let [jobTitle, setJobTitle] = useState();//Должность

  useEffect(() => {
    getJobTitle(authTokens)
      .then(data => {
        console.log(data);

        if (!data?.[0]?.TControl_access) {
          navigate('/', { replace: true });
        }
        if (data?.[0]?.job_title) {
          setJobTitle(data[0].job_title)
        } else if (data?.detail === "Учетные данные не были предоставлены.") {
          logoutUser();
        } else {
          localStorage.setItem('last_page', '/services/tcontrol');
          navigate('/', { replace: true });
        }
      })
  }, [])


  return <Layout title='ЭРА ЖКХ | ТЕПЛОконтроль' content='TControl'>

    {jobTitle ?
      <>
        {(jobTitle === 'Работник') ? (
          <TControlProvider>
            <TControlWorker />
          </TControlProvider>

        ) :
          <div style={{ marginTop: '50vh', transform: 'translateY(-50%)', }}>
            <TControlNotFound
              title='У вас нет доступа к этой странице'
              descr='Вы не работаете гидрогеологом, этот сервис доступен только для них' />
          </div>
        }

      </> :
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Jelly />
      </div>
    }


  </Layout>


}
export default TControl