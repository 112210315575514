import React, { useContext, useEffect, useState } from 'react';
import { API_URL } from '../config';
import Header from '../components/Header';
import Footer from '../components/Footer';
import AuthContext from '../context/AuthContext';

function DetailedInfoPageTeplo() {

    let { authTokens, logoutUser } = useContext(AuthContext);

    const [ZayvaByid, setZayvaByid] = useState([]);

    useEffect(() => {

        const searchParams = new URLSearchParams(window.location.search);
        const id = searchParams.get('id');
        fetchZayvkaForNewWindow(id);

    }, []);

    const fetchZayvkaForNewWindow = async (id) => {
        let response = await fetch(API_URL + '/ADS/application/' + id + '/', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + String(authTokens.access),
            },
        });

        let data = await response.json();

        if (response.status === 200) {
            setZayvaByid(Array.isArray(data) ? data : [data]);
        }
        else {
            alert('Ошибка');
        }
    }


    return (
        <>
            {ZayvaByid.length !== 0 ? (
                <div className='Ads2__container'>
                    <table className="Ads__tableById2 Ads__tableOleg Ads__ForFontSze">
                        <thead>
                            <tr>
                                <th>
                                    №Заявки
                                </th>
                                <th>
                                    Дата
                                </th>
                                <th>
                                    Время
                                </th>
                                <th>
                                    Адрес
                                </th>
                                <th>
                                    Фио заявителя
                                </th>
                                <th>
                                    Объект аварии
                                </th>
                                {ZayvaByid[0].object === "Течь теплотрассы" ? (
                                    <>
                                        <th>
                                            Участок
                                        </th>
                                    </>
                                ) : (
                                    ''
                                )}
                                {ZayvaByid[0].object === "Колодец" ? (
                                    <>
                                        <th>
                                            Номер колодца
                                        </th>
                                    </>
                                ) : (
                                    ''
                                )}
                                {ZayvaByid[0].object === "Тепловая камера" ? (
                                    <>
                                        <th>
                                            Номер камеры
                                        </th>
                                    </>
                                ) : (
                                    ''
                                )}
                                {ZayvaByid[0].object === "ЦТП" ? (
                                    <>
                                        <th>
                                            Номер ЦТП
                                        </th>
                                    </>
                                ) : (
                                    ''
                                )}
                                {ZayvaByid[0].object !== "ЦТП" ? (
                                    <>
                                        <th>
                                            Характер повреждения
                                        </th>
                                        <th>
                                            Способ устранения
                                        </th>
                                        <th>
                                            Комментарий
                                        </th>
                                    </>
                                ) : (
                                    ''
                                )}
                                {ZayvaByid[0].object === "Колодец" || ZayvaByid[0].object === "Тепловая камера" ? (
                                    <>
                                        <th>
                                            Диаметр
                                        </th>
                                        <th>
                                            Материал
                                        </th>
                                    </>
                                ) : (
                                    ''
                                )}
                                {ZayvaByid[0].object === "Течь теплотрассы" ? (
                                    <>
                                        <th>
                                            Способ прокладки
                                        </th>
                                        <th>
                                            Трубопровод
                                        </th>
                                        <th>
                                            Диаметр наружный, мм
                                        </th>
                                        <th>
                                            Расстояние до верха трубы, см
                                        </th>
                                    </>
                                ) : (
                                    ''
                                )}
                                <th>
                                    Исполнитель
                                </th>
                                <th>
                                    Дата конца работ
                                </th>
                                <th>
                                    Время конца работ
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {ZayvaByid.map((item, index) => {
                                return (
                                    <>
                                        <tr>
                                            <td>
                                                {item.id}
                                            </td>
                                            <td>
                                                {(item.created_date.substring(0, 10)).split('-')[2]}.{(item.created_date.substring(0, 10)).split('-')[1]}.{(item.created_date.substring(0, 10)).split('-')[0]}
                                            </td>
                                            <td>
                                                {(item.created_date.substring(11, 16))}
                                            </td>
                                            <td>
                                                {item.adress}
                                            </td>
                                            <td>
                                                {item.fullname}
                                            </td>
                                            <td>
                                                {item.object}
                                            </td>
                                            {item.object === "Течь теплотрассы" ? (
                                                <>
                                                    <td>
                                                        {item.plot}
                                                    </td>
                                                </>
                                            ) : (
                                                ''
                                            )}
                                            {item.object === "Колодец" ? (
                                                <>
                                                    <td>
                                                        {item.well_number}
                                                    </td>
                                                </>
                                            ) : (
                                                ''
                                            )}
                                            {item.object === "Тепловая камера" ? (
                                                <>
                                                    <td>
                                                        {item.camera_number}
                                                    </td>
                                                </>
                                            ) : (
                                                ''
                                            )}
                                            {item.object === "ЦТП" ? (
                                                <>
                                                    <td>
                                                        {item.ctp_number}
                                                    </td>
                                                </>
                                            ) : (
                                                ''
                                            )}
                                            {item.object !== "ЦТП" ? (
                                                <>
                                                    <td>
                                                        {item.damage_character}
                                                    </td>
                                                    <td>
                                                        {item.solution}
                                                    </td>
                                                    <td>
                                                        {item.comment}
                                                    </td>
                                                </>
                                            ) : (
                                                ''
                                            )}
                                            {item.object === "Колодец" || item.object === "Тепловая камера" ? (
                                                <>
                                                    <td>
                                                        {item.diameter}
                                                    </td>
                                                    <td>
                                                        {item.pipe_material}
                                                    </td>
                                                </>
                                            ) : (
                                                ''
                                            )}
                                            {item.object === "Течь теплотрассы" ? (
                                                <>
                                                    <td>
                                                        {item.laying_method}
                                                    </td>
                                                    <td>
                                                        {item.pipeline}
                                                    </td>
                                                    <td>
                                                        {item.outer_diameter}
                                                    </td>
                                                    <td>
                                                        {item.depth_top_of_pipe}
                                                    </td>
                                                </>
                                            ) : (
                                                ''
                                            )}
                                            <td>
                                                {item.performer}
                                            </td>
                                            <td>
                                                {(item.updated_date.substring(0, 10)).split('-')[2]}.{(item.created_date.substring(0, 10)).split('-')[1]}.{(item.created_date.substring(0, 10)).split('-')[0]}
                                            </td>
                                            <td>
                                                {(item.updated_date.substring(11, 16))}
                                            </td>
                                        </tr>
                                    </>
                                )
                            })}
                        </tbody>
                    </table>
                    <div className='Ads2__ContForPhoto'>
                        <h1 className='Ads2__TtleStartPhoto'>Фото начала работ:</h1>
                        <h1 className='Ads2__TtleEndPhoto'>Фото результата работ:</h1>
                    </div>
                    <div className="Ads2__ContForPhoto">

                        <img className='Ads2__PhotoStart' src={ZayvaByid[0].photo_start} alt="Нет фото начала работ" />
                        <img className='Ads2__PhotoEnd' src={ZayvaByid[0].photo_end} alt="Нет фото результата работ" />
                    </div>
                </div>
            ) : (
                ''
            )}

        </>

    );
}

export default DetailedInfoPageTeplo;