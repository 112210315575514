import React, { useState, useContext, useEffect } from "react";

import { API_URL } from "../../../../../../../config";
import AuthContext from "../../../../../../../context/AuthContext";

import "./ButtonAddWear.css";

export const Steni = () => {
  const { authTokens } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    plotName: "",
    wear: "",
    area: "",
    photo: null,
  });

  // Определяем переменные
  const currentDate = new Date(); // Получаем текущую дату
  const year = currentDate.getFullYear(); // Получаем год
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Получаем месяц (месяцы начинаются с 0, поэтому добавляем 1) и добавляем 0 спереди
  const day = String(currentDate.getDate()).padStart(2, "0"); // Получаем день и добавляем 0 спереди
  const data_in_wear = `${year}-${month}-${day}`; // Форматируем в строку "YYYY-MM-DD"

  const idHouse = localStorage.getItem("ID_House"); // Получаем ID из localStorage

  const element = 1; // Элемент

  const wallType = 1;

  // Функция обнуления значений
  const resetFormData = () => {
    setFormData({
      plotName: "",
      wear: "",
      area: "",
      photo: null,
    });
  };

  //Считывание текста
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  //Считывание фото
  const handlePhotoUpload = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      photo: file,
    }));
  };

  // Отправка данных
  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestData = {
      position: formData.plotName,
      data: data_in_wear,
      Element: element,
      volume: formData.area,
      degreeOfDamage: formData.wear,
      idHouse: idHouse,
    };

    try {
      const response = await fetch(`${API_URL}/CivilWear/Damage/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Ошибка при отправке данных: ${errorText}`);
      }

      const result = await response.json();
      console.log("Успешно отправлено:", result);
      alert("Износ добавлен !");
      resetFormData();
    } catch (error) {
      console.error("Ошибка при отправке:", error);
    }
  };

  let data = []; //Данные для таблицы

  switch (wallType) {
    case 1:
      data = [
        ["Признаки износа", "Количественная оценка", "Физический износ, %"],
        [
          "Мелкие повреждение наружной обшивки щитов",
          "Повреждение на площади до 10%",
          "0-10",
        ],
        [
          "Поражение гнилью отливной доски, обшивки углов и стыков внутренних стен",
          "То же, до 30%",
          "11-20",
        ],
        [
          "Незначительный перекос стен, поражение гнилью нижней части щитов и обвязки, образование щелей в стыках щитов",
          "То же, до 25%",
          "21-30",
        ],
        [
          "Заметный перекос стен, образование щелей в вертикальных стыках между щитами, неравномерная осадка щитов, поражение древесины гнилью",
          "То же, до 30%",
          "31-40",
        ],
        [
          "Заметный перекос стен, выпучивание, отклонение от вертикали, поражение древесины гнилью, повышенная влажность в помещениях",
          "Повреждения на площади более 30%",
          "41-50",
        ],
        [
          "Перекос оконных и дверных проемов, деформация стен, поражение древесины гнилью, увлажнение древесины",
          "-",
          "51-60",
        ],
        [
          "Деформация стен, поражение древесины гнилью, сырость в помещениях, наличие временных креплений и подпорок",
          "-",
          "61-70",
        ],
      ];

      break;
    case 2:
      data = [
        ["Признаки износа", "Количественная оценка", "Физический износ, %"],
        ["Мелкие повреждение обшивки или штукатурки", "-", "0-10"],
        [
          "Продуваемость и следы промерзания стен, повреждение обшивки или отпадение штукатурки в угловых участках",
          "Повреждения на площади до 10%",
          "44136",
        ],
        [
          "Штукатурка выкрошилась местами, отдельные доски покороблены и повреждены, нижние - повреждены гнилью",
          "То же, до 20%",
          "21-30",
        ],
        [
          "Обшивка покоробилась, растрескалась и местами отстала, штукатурка отпала",
          "То же, до 40%",
          "31-40",
        ],
        [
          "Массовое отпадение штукатурки или гниль в древесине и отставание обшивки",
          "То же, до 50%",
          "41-50",
        ],
        [
          "Перекос стен, оконных и дверных коробок",
          "Повреждения на площади более 50%",
          "51-60",
        ],
        [
          "Выпучивание наружной обшивки и штукатурки, отставание досок",
          "-",
          "61-70",
        ],
        [
          "Значительное повреждение каркаса, поражение гнилью, полное разрушение обшивки",
          "-",
          "",
        ],
      ];

      break;
    case 3:
      data = [
        ["Признаки износа", "Количественная оценка", "Физический износ, %"],
        [
          "Незначительные повреждения наружной обшивки и конопатки",
          "-",
          "0-10",
        ],
        [
          "Трещины в наружной обшивке стен или штукатурке, нарушение конопатки, растрескивание древесины венцов",
          "Повреждения на площади до 10%",
          "ноя.20",
        ],
        [
          "Искривление горизонтальных линий фасада, следы увлажнения и гнили на уровне нижнего оклада венца у карниза и под оконными проемами. Нарушение наружной обшивки или трещины в штукатурке",
          "Повреждения на площади до 20%",
          "21-30",
        ],
        [
          "Продуваемость и промерзание стен, глубокие трещины в венцах и частичное поражение гнилью",
          "-",
          "31-40",
        ],
        [
          "Выпучивание стен и прогибы, неравномерная осадка, перекос дверных и оконных косяков, поражение гнилью, осадка углов",
          "Выход из плоскости до ? толщины стены",
          "41-50",
        ],
        [
          "Деформация стен, повреждение венцов гнилью и трещинами",
          "Повреждения на площади до 40%",
          "51-60",
        ],
        [
          "Полное нарушение жесткости сруба, образование трещин, поражение гнилью",
          "-",
          "61-70",
        ],
      ];

      break;
    case 4:
      data = [
        ["Признаки износа", "Количественная оценка", "Физический износ, %"],
        [
          "Отдельные трещины и выбоины",
          "Повреждения на площади до 10%",
          "0-10",
        ],
        [
          "Трещины в швах кладки",
          "Ширина трещин до 2 мм на площади до 15%",
          "ноя.20",
        ],
        [
          "Выпучивание облицовки местами, выветривание раствора из швов, трещины в кирпичной кладке",
          "То же, до 20%",
          "21-30",
        ],
        [
          "Массовые выпучивания с отпадением штукатурки или выветриванием раствора из швов, выпадение отдельных кирпичей, частичное поражение гнилью венцов, увлажнение древесины",
          "Повреждения на площади до 50%",
          "31-40",
        ],
        [
          "Неравномерные осадки, перекос стояков проемов, частичное разрушение кирпичной кладки облицовки, поражение гнилью древесины окладных и местами вышерасположенных венцов",
          "-",
          "41-50",
        ],
        [
          "Выпадение кирпичей из кладки, неравномерные осадки, поражение древесины гнилью",
          "-",
          "51-60",
        ],
        ["Разрушение облицовки, поражение древесины гнилью", "-", "61-70"],
      ];

      break;
    case 5:
      data = [
        ["Признаки износа", "Количественная оценка", "Физический износ, %"],
        ["Отдельные трещины и выбоины", "Ширина трещины до 1 мм", "0-10"],
        [
          "Глубокие трещины и отпадение штукатурки местами, выветривание швов",
          "Ширина трещин до 2 мм, глубина до 1/3 толщины стены, разрушение швов на глубину до 1 см на площади до 10%",
          "ноя.20",
        ],
        [
          "Отслоение и отпадение штукатурки стен, карнизов и перемычек, выветривание швов, ослабление кирпичной кладки, выпадение отдельных кирпичей, трещины в карнизах и перемычках, увлажнение поверхности стен",
          "Глубина разрушения швов до 2 см на площади до 30%. Ширина трещины более 2 мм",
          "21-30",
        ],
        [
          "Массовое отпадение штукатурки, выветривание швов, ослабление кирпичной кладки стен, карниза, перемычек с выпадением отдельных кирпичей, высолы и следы увлажнения",
          "Глубина разрушения швов до 4 см на площади до 50%",
          "31-40",
        ],
        [
          "Сквозные трещины в перемычках и под оконными проемами, выпадение кирпичей, незначительное отклонение от вертикали и выпучивание стен",
          "Отклонение стены от вертикали в пределах помещения более 1/200 длины деформируемого участка",
          "41-50",
        ],
        [
          "Массовое прогрессирующие сквозные трещины, ослабление и частичное разрушение кладки, заметное искривление стен",
          "Выпучивание с прогибом более 1/200 длины деформируемого участка",
          "51-60",
        ],
        ["Разрушение кладки местами", "-", "61-70"],
      ];

      break;
    case 6:
      data = [
        ["Признаки износа", "Количественная оценка", "Физический износ, %"],
        [
          "Мелкие единичные трещины и местные выбоины в керамике",
          "Ширина трещин до 1 мм. Повреждения на площади до 10%",
          "0-10",
        ],
        [
          "Трещины на откосах проемов, отслоение облицовки и выпадение отдельных блоков или плиток на фасаде",
          "Трещины шириной более 1 мм",
          "ноя.20",
        ],
        [
          "Отслоение облицовки от кладки, трещины в швах, следы влаги в местах отсутствия облицовки",
          "Трещины в швах шириной до 2 мм. Выпадение плитки до 20% площади",
          "21-30",
        ],
        [
          "Выпадение облицовки, трещины в кладке, выветривание раствора из швов, высолы и следы увлажнения на поверхности кладки в местах отсутствия облицовки, трещины в отдельных перемычках",
          "Выпадение облицовки на площади более 20%. Трещины в кладке шириной более 2 мм",
          "31-40",
        ],
        [
          "Трещины в кирпичной кладке и в перемычках, выпадение отдельных кирпичей из карнизов, массовое отпадение облицовки, следы увлажнения стен",
          "Глубина трещин в кладке 0,5 толщины стены, трещины в перемычках шириной более 2 мм",
          "41-50",
        ],
        [
          "Полное отпадение облицовки, развивающиеся трещины в кладке и перемычках, выпадение кирпичей из кладки, заметное искривление стен, ослабление связей между отдельными участками стен",
          "Отклонение стены от вертикали в пределах помещения более 1/200 его высоты",
          "51-60",
        ],
        ["Массовое разрушение кладки", "-", "61-70"],
      ];

      break;
    case 7:
      data = [
        ["Признаки износа", "Количественная оценка", "Физический износ, %"],
        ["Отдельные трещины и выбоины", "Повреждения на площади до 5%", "0-10"],
        [
          "Выветривание швов или трещины в штукатурке местами, коррозия металлических обделок выступающих частей",
          "То же, до 10%",
          "ноя.20",
        ],
        [
          "Выветривание швов отдельных камней, трещины в швах или отпадение штукатурки местами, сколы краев камней, глубокие трещины в карнизе",
          "Ширина трещин до 5 мм",
          "21-30",
        ],
        [
          "Глубокие трещины и выпадение камней карниза, массовое выветривание швов и камней кладки, отпадение штукатурки",
          "Глубина выветривания швов до 2 см. Площадь повреждений до 20%",
          "31-40",
        ],
        [
          "Сквозные трещины и выпадение камней в перемычках, карнизах и углах здания, незначительные отклонения от вертикали и выпучивание отдельных участков стен",
          "Отклонение от вертикали до 1/200 высоты помещений, выпучивание до 1/200 длины деформируемого участка",
          "41-50",
        ],
        [
          "Вертикальные трещины в простенках, разрушение и расслоение кадки стен местами, нарушение связи отдельных участков кладки",
          "-",
          "51-60",
        ],
        [
          "Массовое разрушение кладки, наличие временных креплений",
          "-",
          "61-70",
        ],
      ];

      break;
    case 8:
      data = [
        ["Признаки износа", "Количественная оценка", "Физический износ, %"],
        [
          "Нарушение покрытия выступающих частей фасада, отдельные мелкие выбоины, трещины",
          "На площади до 5%",
          "0-10",
        ],
        [
          "Выбоины местами в фактурном слое, ржавые потеки, загрязнение и выцветание наружной отделки",
          "На площадь до 30%",
          "ноя.20",
        ],
        [
          "Отслоение и выветривание раствора в стыках, следы протечек через стыки внутри здания, трещины",
          "Протечки в 5% помещений. Ширина трещин до 2 мм",
          "21-30",
        ],
        [
          "Глубоко раскрытые усадочные трещины, выветривание раствора в стыках, следы постоянных протечек, промерзание и продувание через стыки",
          "Ширина трещин до 3 мм. Повреждения на площади до 20%. Протечки и промерзания в 20% помещений",
          "31-40",
        ],
        [
          "Диагональные трещины по углам простенков, вертикальные трещины по перемычкам, в местах установки балконных плит и козырьков",
          "Ширина раскрытых трещин до 3 мм",
          "41-50",
        ],
        [
          "Вертикальные широко раскрытые трещины в стыках и перемычках, нарушение связи между отдельными участками стен",
          "Ширина трещин более 3 мм, длина трещин более 3 м",
          "51-60",
        ],
        [
          "Заметное искривление горизонтальных и вертикальных стен, массовое разрушение блоков и панелей",
          "Выпучивание стен более 1/200 длины деформированного участка, отклонение от вертикали более 1/100 высоты стены в пределах помещения",
          "61-70",
        ],
      ];

      break;
    case 9:
      data = [
        ["Признаки износа", "Количественная оценка", "Физический износ, %"],
        [
          "Незначительные повреждения отделки панелей, усадочные трещины, выбоины",
          "Повреждения на площади до 10 %. Ширина трещин до 0,3 мм",
          "0-10",
        ],
        [
          "Выбоины в фактурном слое, ржавые потеки",
          "Повреждения на площади до 15%",
          "ноя.20",
        ],
        [
          "Отслоение раствора в стыках, трещины на наружной поверхности, следы протечек в помещениях",
          "Ширина трещин до 1 мм. Протечки на площади до 10%",
          "21-30",
        ],
        [
          "Трещины, выбоины, отслоение защитного слоя бетона, местами протечки и промерзание в стыках",
          "Ширина трещин до 2 мм. Повреждения на площади до 20%",
          "31-40",
        ],
        [
          "Горизонтальные трещины в простенках и вертикальные в перемычках, выпучивание бетонных слоев, протечки и промерзание панелей",
          "Ширина трещин до 3 мм. Выпучивание до 1/200 расстояния между опорными участками панелей",
          "41-50",
        ],
        [
          "Трещины в простенках и перемычках, разрушение (деструкция) утеплителя, протечки и промерзание",
          "Ширина трещин более 3 мм",
          "51-60",
        ],
        [
          "Массовые трещины и деформации, разрушение и оседание утеплителя, протечки и промерзание панелей",
          "-",
          "61-70",
        ],
      ];

      break;
    case 10:
      data = [
        ["Признаки износа", "Количественная оценка", "Физический износ, %"],
        [
          "Повреждение обрамлений выступающих частей фасада, местами мелкие выбоины",
          "Повреждения на площади до 5%",
          "0-10",
        ],
        [
          "Трещины, выветривание раствора из стыков, мелкие повреждения облицовки или фактурного слоя, следы протечек через стыки внутри здания",
          "То же, до 10%",
          "ноя.20",
        ],
        [
          "Массовое отслоение, выветривание раствора из стыков, повреждение облицовки или фактурного слоя панелей, следы протечек внутри здания",
          "Повреждения на площади до 20%",
          "21-30",
        ],
        [
          "Промерзание стен, разрушение заделки стыков",
          "Промерзание в 5% помещений",
          "31-40",
        ],
        [
          "Следы протечек внутри помещения, высолы",
          "Повреждения в 10% помещений, наружные повреждения на площади до 30%",
          "41-50",
        ],
        [
          "Выпучивание или смещение панелей, разрушение узлов крепления панелей",
          "Прогиб панели до 1/200 ее длины",
          "51-60",
        ],
        [
          "Деформация стен, смещение панелей, трещины в панелях, разрушение узлов",
          "Прогиб панели более 1/200 ее длины",
          "61-70",
        ],
      ];

      break;

    default:
      console.log("Ошибка");
  }

  return (
    <div className="container_Wear_ButtonAddWear">
      <form className="form_Wear_ButtonAddWear" onSubmit={handleSubmit}>
        <h3>Стены:</h3>
        <div className="form-group_Wear_ButtonAddWear">
          <label htmlFor="plotName" className="label_Wear_ButtonAddWear">
            Обозначение участка
          </label>
          <input
            className="input_Wear_ButtonAddWear"
            type="text"
            id="plotName"
            name="plotName"
            value={formData.plotName}
            onChange={handleChange}
          />
        </div>

        <div className="form-group_Wear_ButtonAddWear">
          <label htmlFor="wear" className="label_Wear_ButtonAddWear">
            Фактический физический износ участка
          </label>
          <input
            className="input_Wear_ButtonAddWear"
            type="text"
            id="wear"
            name="wear"
            value={formData.wear}
            onChange={handleChange}
          />
        </div>

        <div className="form-group_Wear_ButtonAddWear">
          <label htmlFor="area" className="label_Wear_ButtonAddWear">
            Площадь участка
          </label>
          <input
            className="input_Wear_ButtonAddWear"
            type="text"
            id="area"
            name="area"
            value={formData.area}
            onChange={handleChange}
          />
        </div>

        <div className="form-group_Wear_ButtonAddWear">
          <label htmlFor="photo" className="label_Wear_ButtonAddWear">
            Прикрепить фото
          </label>
          <input
            className="input_Wear_ButtonAddWear"
            type="file"
            id="photo"
            onChange={handlePhotoUpload}
          />
        </div>

        <button type="submit" className="submit-button_Wear_ButtonAddWear">
          Сохранить данные
        </button>
      </form>

      <div className="table-container_Wear_ButtonAddWear">
        <table className="table_Wear_ButtonAddWear">
          <thead>
            <tr>
              {data[0] &&
                data[0].map((header, index) => <th key={index}>{header}</th>)}
            </tr>
          </thead>
          <tbody>
            {data.slice(1).map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
