import { useContext, useEffect, useState } from 'react';
import './RSODynamcOperInfo.scss'
import AuthContext from '../../../../../../../../context/AuthContext';
import { worker_workzone_context } from '../../../../hooks/useStats_worker_workzone';
import { worker_context } from '../../../../hooks/useStats_worker';
import { ModelTable } from '../../../../../../../../components/ModelTable/ModelTable';
import { dateConverter } from '../../../../../../../../utils/utils';
import { getWPSPumps, getWells } from '../../../../http/worker_http';
import Jelly from '../../../../../../../../components/Jelly';

export let RSODynamcOperInfo = () => {

  let { authTokens, logoutUser } = useContext(AuthContext);
  let { currentWps } = useContext(worker_workzone_context);
  let { smena, updateSmena } = useContext(worker_context);
  let [WPSPumps, setWpsPumps] = useState([]);
  let [WPSWells, setWPSWells] = useState([]);

  useEffect(() => {
    getWPSPumps(authTokens, currentWps.water_pumping_station_pump, setWpsPumps)
    getWells(authTokens, currentWps.prefabricate_water_pipeline, setWPSWells);
    updateSmena()
  }, [])

  let renderPumps = (pumps, status) => (
    pumps
      ?.filter(pump => pump.status === status)
      .map((pump, index) => (
        <span key={index}>
          {pump.number_of_line}
          {(index + 1) % 3 === 0 && <br />}
          {(index + 1) % 3 !== 0 && "; "}
        </span>
      ))
  );

  let renderWells = (wells, status) => (
    wells
      ?.filter(well => well.status === status)
      .map((well, index) => (
        <span key={index}>
          {well.number_well}
          {(index + 1) % 3 === 0 && <br />}
          {(index + 1) % 3 !== 0 && "; "}
        </span>
      ))
  );

  return (
    <div className="large__container">
      {(smena && currentWps && WPSPumps && WPSWells) ? (
        <ModelTable>
          <thead>
            <tr>
              <th rowSpan='2'>Дата</th>
              <th rowSpan='2'>Время</th>
              <th rowSpan='2'>ВЗУ</th>
              <th rowSpan='2'>Смена</th>
              <th rowSpan='2'>ФИО</th>
              <th colSpan='3'>Насосы, машинный зал, N</th>
              <th colSpan='3'>Скважины, N</th>
            </tr>
            <tr>
              <th>В работе</th>
              <th>В резерве</th>
              <th>В ремонте</th>
              <th>В работе</th>
              <th>В резерве</th>
              <th>В ремонте</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{dateConverter(smena[0]?.date)}</td>
              <td>{smena[0]?.time.substring(0, 5)}</td>
              <td>{currentWps?.name}</td>
              <td>{smena[0]?.shift_number}</td>
              <td>{smena[0]?.fullname}</td>
              <td>{renderPumps(WPSPumps, "В работе")}</td>
              <td>{renderPumps(WPSPumps, "В резерве")}</td>
              <td>{renderPumps(WPSPumps, "В ремонте")}</td>
              <td>{renderWells(WPSWells, "В работе")}</td>
              <td>{renderWells(WPSWells, "В резерве")}</td>
              <td>{renderWells(WPSWells, "В ремонте")}</td>
            </tr>
          </tbody>
        </ModelTable>) : <Jelly />}
    </div>
  )

}