import './RSODynamicValuesValuesInput.scss'
import { useContext, useState } from 'react'
import { RSODynamicButton } from '../../../../../../../../utils/RSODynamicButton/RSODynamicButton'
import { RSODynamicInput } from '../../../../../../../../utils/RSODynamicInput/RSODynamicInput'
import { useForm } from 'react-hook-form'
import { ModalWindow } from '../../../../../../../../../../components/ModalWindow/ModalWindow'
import { postHourlyRates } from '../../../../../../http/worker_http'
import AuthContext from '../../../../../../../../../../context/AuthContext'
import { worker_context } from '../../../../../../hooks/useStats_worker'

export let RSODynamicValuesValuesInput = () => {

  let { authTokens, logoutUser } = useContext(AuthContext);
  let { handleSubmit, reset } = useForm({ mode: 'onBlur' });

  let [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  let {
    smena,
    updateSmena
  } = useContext(worker_context)

  let createPostHourlyRates = (e) => {
    e.preventDefault();
    handleOpenModal()
  }

  let [RSODynamicValuesValuesInput, setRSODynamicValuesValuesInput] = useState({});
  let [waterLevelInReseruar, setWaterLevelInReseruar] = useState([])

  return (
    <>
      <form className='RSODynamicValuesValuesInput__form container' onSubmit={e => handleSubmit(createPostHourlyRates(e))}>
        <div className="RSODynamicValuesValuesInput__form-container">
          <RSODynamicInput
            type='number'
            title='Давление, кг/см²'
            name='pressure'
            setter={setRSODynamicValuesValuesInput}
            getter={RSODynamicValuesValuesInput}
            option='pressure'
            required={true}
            min={0}
            step={0.01}
          />
          <RSODynamicInput
            type='number'
            title='ДТ, кг/см²'
            name='dt'
            setter={setRSODynamicValuesValuesInput}
            getter={RSODynamicValuesValuesInput}
            option='dt'
            required={true}
            min={0}
            step={0.01}
          />
          <RSODynamicInput
            type='number'
            title='ДБ, кг/см²'
            name='db'
            setter={setRSODynamicValuesValuesInput}
            getter={RSODynamicValuesValuesInput}
            option='db'
            required={true}
            min={0}
            step={0.01}
          />
          <RSODynamicInput
            type='number'
            title='Уровень воды в первом резервуаре'
            name='water_level_in_reseruar1'
            setter={setWaterLevelInReseruar}
            getter={waterLevelInReseruar}
            option='water_level_in_reseruar1'
            required={true}
            min={0}
            step={0.01}
          />
          <RSODynamicInput
            type='number'
            title='Уровень воды во втором резервуаре'
            name='water_level_in_reseruar2'
            setter={setWaterLevelInReseruar}
            getter={waterLevelInReseruar}
            option='water_level_in_reseruar2'
            required={false}
            min={0}
            step={0.01}
          />
          <RSODynamicInput
            type='number'
            title='Нагрузка, А'
            name='current'
            setter={setRSODynamicValuesValuesInput}
            getter={RSODynamicValuesValuesInput}
            option='current'
            required={true}
            min={0}
            step={0.01}
          />
          <RSODynamicInput
            type='number'
            title='Напряжение, В'
            name='voltage'
            setter={setRSODynamicValuesValuesInput}
            getter={RSODynamicValuesValuesInput}
            option='voltage'
            required={true}
            min={0}
            step={1}
          />
          <RSODynamicInput
            type='number'
            title='Средний часовой расход'
            name='max_consumption'
            setter={setRSODynamicValuesValuesInput}
            getter={RSODynamicValuesValuesInput}
            option='max_consumption'
            required={true}
            min={0}
            step={0.01}
          />
          <RSODynamicInput
            type='number'
            title='Наличие пломб на РЧВ, шт'
            name='presence_plomb'
            setter={setRSODynamicValuesValuesInput}
            getter={RSODynamicValuesValuesInput}
            option='presence_plomb'
            required={true}
            min={0}
            step={1}
          />
          <RSODynamicInput
            type='string'
            title='Включение резервного насоса'
            name='note'
            setter={setRSODynamicValuesValuesInput}
            getter={RSODynamicValuesValuesInput}
            option='note'
            required={false}
          />
        </div>
        <RSODynamicButton
          title='Сохранить'
          type='submit' />
      </form>

      <ModalWindow
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title='Подтверждение почасовых показателей'
        hook={RSODynamicValuesValuesInput}
        isHook1Required={true}
        hook1={waterLevelInReseruar}>
        <p><b>Вы передаете следующие динамические параметры:</b></p>
        <ul>
          <li>Давление - {`${RSODynamicValuesValuesInput.pressure} кг/см²`}</li>
          <li>ДТ - {`${RSODynamicValuesValuesInput.dt} кг/см²`}</li>
          <li>ДБ - {`${RSODynamicValuesValuesInput.db} кг/см²`}</li>
          <li>Уровень воды в первом резервуаре - {`${waterLevelInReseruar.water_level_in_reseruar1}`}</li>
          {(waterLevelInReseruar?.water_level_in_reseruar2 !== null && waterLevelInReseruar?.water_level_in_reseruar2 !== undefined) && (
            <li>Уровень воды во втором резервуаре - {`${waterLevelInReseruar.water_level_in_reseruar2}`}</li>
          )}
          <li>Нагрузка - {`${RSODynamicValuesValuesInput.current} А`}</li>
          <li>Напряжение - {`${RSODynamicValuesValuesInput.voltage} B`}</li>
          <li>Средний часовой расход - {`${RSODynamicValuesValuesInput.max_consumption}`}</li>
          <li>Наличие пломб на РЧВ - {`${RSODynamicValuesValuesInput.presence_plomb} шт`}</li>
          {RSODynamicValuesValuesInput.note && (
            <li>Включение резервного насоса - {`${RSODynamicValuesValuesInput.note}`}</li>
          )}
        </ul>

        <form className='create__smena-btn' onSubmit={e => handleSubmit(postHourlyRates(e, authTokens, RSODynamicValuesValuesInput, waterLevelInReseruar, smena[0].id, setRSODynamicValuesValuesInput, setWaterLevelInReseruar, updateSmena))}>
          <RSODynamicButton title='Подтвердить' type='submit' />
        </form>
      </ModalWindow>
    </>
  )
}