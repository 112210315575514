import React from 'react';
import "../../css/Wear.css";

const ButtonCloseElementWear = ({ selectedComponent, onButtonClick }) => {

  const handleCloseClick = () => {
        //Cкрыть генерацию таблицы и полей
        onButtonClick(selectedComponent, []);
  }

  return (
    
    <button className="btn_addwear_hold_wear" onClick={handleCloseClick}> Закрыть  </button>
    
  );
};

export default ButtonCloseElementWear;
