import React from "react";
import "../../PublicHouse.css";

export const NextAndPrefButton = ({ handleStepChange }) => {
  return (
    <div className="NextAndPrefButton_main">
      <button className="NextAndPrefButton_button" onClick={() => handleStepChange("next")}>
        Далее
      </button>
      <button className="NextAndPrefButton_button" onClick={() => handleStepChange("prev")}>
        Назад
      </button>
    </div>
  );
};
