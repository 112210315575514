import { useContext, useEffect, useState } from "react";
import { dispatcher_context } from "../../hooks/useStats_dispatcher";
import { RSODynamicButtonGroup } from "../../../../utils/RSODynamicButtonGroup/RSODynamicButtonGroup"
import { RSODynamicInput } from "../../../../utils/RSODynamicInput/RSODynamicInput"
import { RSODynamicSelect } from "../../../../utils/RSODynamicSelect/RSODynamicSelect"
import { RSODynamicDispEquipmentFailuresPaint } from "./components/RSODynamicDispEquipmentFailuresPaint/RSODynamicDispEquipmentFailuresPaint";
import { getDispatcherAllRepair, getDispatcherAllRepairPeriod, getWPS } from "../../http/dispatcherHttp";
import AuthContext from "../../../../../../context/AuthContext";

export let RSODynamicDispEquipmentFailures = () => {

  let { authTokens } = useContext(AuthContext);

  let { equipmentFailuresTimeTab,
    equipmentFailuresWPS,
    equipmentFailuresFinishRepairTab,
    equipmentFailuresStartPeriod,
    equipmentFailuresEndPeriod,
    equipmentFailuresType,
    equipmentFailuresSelectedWPS,
    setEquipmentFailuresTimeTab,
    setEquipmentFailuresWPS,
    setEquipmentFailuresFinishRepairTab,
    setEquipmentFailuresStartPeriod,
    setEquipmentFailuresEndPeriod,
    setEquipmentFailuresType,
    setEquipmentFailuresSelectedWPS } = useContext(dispatcher_context);

  let [WPS, setWPS] = useState([])


  useEffect(() => {
    getWPS(authTokens, setWPS, setEquipmentFailuresWPS)
  }, [])

  return (
    <div className="large__container">
      <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
        <div style={{ margin: "0 20px 0 20px " }}>
          <RSODynamicButtonGroup
            buttons={[
              { title: 'Отказы всего' },
              { title: 'Отказы за период' },
            ]}
            setter={setEquipmentFailuresTimeTab}
            getter={equipmentFailuresTimeTab}
            color='green' />
        </div>
        <div style={{ margin: "0 20px 0 20px " }}>
          <RSODynamicButtonGroup
            buttons={[
              { title: 'Скважины' },
              { title: 'Насосы' },
            ]}
            setter={setEquipmentFailuresType}
            getter={equipmentFailuresType}
            color='green' />
        </div>
        <div style={{ margin: "0 20px 0 20px " }}>
          <RSODynamicButtonGroup
            buttons={[
              { title: 'Завершено' },
              { title: 'В ремонте' },
            ]}
            setter={setEquipmentFailuresFinishRepairTab}
            getter={equipmentFailuresFinishRepairTab}
            color='green' />
        </div>
      </div>

      <div style={{ display: "flex" }}>
        <RSODynamicSelect
          name='Выберите ВЗУ'
          list={equipmentFailuresWPS}
          value='id'
          parse='int'
          visible_value='name'
          selectedDate={equipmentFailuresSelectedWPS}
          setter={setEquipmentFailuresSelectedWPS}
          getter={equipmentFailuresSelectedWPS} />
        {equipmentFailuresTimeTab === "Отказы за период" &&
          <>
            <div style={{ margin: "0 20px 0 20px" }}>
              <RSODynamicInput
                type='date'
                title='Выберите начало периода'
                name='selectDate'
                setter={setEquipmentFailuresStartPeriod}
                required={true}
              />
            </div>
            <div>
              <RSODynamicInput
                type='date'
                title='Выберите конец периода'
                name='selectDate'
                setter={setEquipmentFailuresEndPeriod}
                required={true}
              />
            </div>
          </>
        }
      </div>

      {equipmentFailuresTimeTab === 'Отказы всего' &&
        <RSODynamicDispEquipmentFailuresPaint
          getFunc={getDispatcherAllRepair} />
      }

      {equipmentFailuresTimeTab === 'Отказы за период' &&
        <RSODynamicDispEquipmentFailuresPaint
          title="period"
          getFunc={getDispatcherAllRepairPeriod} />
      }

    </div>
  )
}