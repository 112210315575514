import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../../../../context/AuthContext";
import { TControlContext } from "../../hooks/useTControl";
import { TControlSelect } from "../../../../utils/TControlSelect/TControlSelect";
import { getBoilerHouseById } from "../../../../http/http";
import { RSODynamicNotFound } from "../../../../../RSODynamic/utils/RSODynamicNotFound/RSODynamicNotFound";

export let BoilersInfo = () => {

  const { authTokens } = useContext(AuthContext);

  let {
    memoizedBoilersHouses,
  } = useContext(TControlContext)

  let [boilerInfo, setBoilerInfo] = useState([]);

  useEffect(() => {
    getBoilerHouseById(authTokens, memoizedBoilersHouses?.selecterBoilersHouse, setBoilerInfo)
  }, [memoizedBoilersHouses?.selecterBoilersHouse])


  return (
    <div style={{ marginTop: '50px' }}>
      <form className='hydrologist_worker-form'>
        <TControlSelect
          name={"Выберите котельную"}
          list={memoizedBoilersHouses?.boilersHouses}
          value={'pk'}
          visible_value={'address'}
          getter={memoizedBoilersHouses?.selecterBoilersHouse}
          setter={memoizedBoilersHouses?.setSelecterBoilersHouse}
          parse={'int'}
          defaultValue={memoizedBoilersHouses?.selecterBoilersHouse}
        />
      </form>
      <div className="container">
        {boilerInfo?.newtwork_schema ? (
          <a className="WPSInfo__link" href={boilerInfo?.newtwork_schema} target="_blank">
            <div className="card">
              <span>Схема</span>
            </div>
          </a>
        ) :
          <RSODynamicNotFound
            title={`Временно в разработке`}
            icon='👨‍💻'
            descr={`Ждем появления схем на сервере... После появления надпись исчезнет`}
          />
        }
        {/* {currentWps?.technical_reglament && (
          <a className="WPSInfo__link" href={currentWps.technical_reglament} target="_blank">
            <div className="card">
              <span>Технический регламент</span>
            </div>
          </a>
        )} */}
        {/* {currentWps?.excel_info && (
          <a className="WPSInfo__link" href={currentWps.excel_info} target="_blank">
            <div className="card">
              <span>Перечень насосного оборудования</span>
            </div>
          </a>
        )} */}

      </div>
    </div>
  )
}