import React from "react";
import "../../css/Wear.css";

const ButtonPerecritia = ({ onButtonClick, floorType }) => {
  const handleClick = () => {
    let data;

    switch (floorType) {
      case "1":
        data = [
          ["Признаки износа", "Количественная оценка", "Физический износ, %"],
          [
            "Зазоры и щели между досками наката, прогибы балок и настилов",
            "Прогибы балок и настилов до 1/150 пролета",
            "0-40",
          ],
          [
            "Поражение верхних слоев древесины грибком, небольшие трещины, частичное скалывание в узлах соединений балок с настилом, прогиб балок и прогонов",
            "Поражение гилью на площади до 10%. Прогиб балок и прогонов до 1/100 пролета",
            "41-60",
          ],
          [
            "Сильное поражение древесины гнилью, появление продольных и поперечных трещин, расслоение древесины, полное или частичное скалывание в узлах сопряжений балок, прогиб балок и прогонов",
            "Прогиб балок и прогонов до 1/50 пролета",
            "61-80",
          ],
        ];

        break;
      case "2":
        data = [
          ["Признаки износа", "Количественная оценка", "Физический износ, %"],
          ["Незначительные трещины перпендикулярно балкам", "-", "0-20"],
          [
            "Трещины в средней части сводов вдоль балок",
            "Ширина трещин до 1 мм",
            "21-40",
          ],
          [
            "Глубокие трещины в средней части сводов вдоль балок, расшатывание отдельных кирпичей, выщелачивание раствора в швах, выпадение отдельных кирпичей, коррозия балок",
            "То же, до 2 мм. Уменьшение сечения балок на 10%",
            "41-60",
          ],
          [
            "Ослабление кирпичной кладки, массовое выпадение кирпичей, наличие временных подпорок, коррозия и заметные прогибы балок",
            "Прогиб металлических балок до 1/150 пролета. Уменьшение сечения балок более 10%",
            "61-80",
          ],
        ];

        break;
      case "3":
        data = [
          ["Признаки износа", "Количественная оценка", "Физический износ, %"],
          [
            "Мелкие отслоения и трещины в фактурном слое",
            "Повреждения на площади до 10%",
            "0-10",
          ],
          [
            "Отпадение фактурного слоя местами",
            "Повреждения на площади до 20%",
            "11-20",
          ],
          [
            "Усадочные трещины в нижних плитах",
            "Ширина трещин до 1 мм. Суммарная длина трещин на 1 м2 до 0,5 м",
            "21-30",
          ],
          [
            "Отдельные глубокие трещины в нижних плитах и в местах опирания плит, прогибы",
            "Ширина трещин до 2 мм. Прогибы до 1/120 пролета",
            "31-40",
          ],
          [
            "Продольные и поперечные глубокие трещины на нижних плитах, прогиб нижних плит, продавливание верхних плит под мебелью",
            "Ширина трещин до 3 мм. Суммарная длина трещин на 1 м2 до 1 м. Прогиб до 1/100 пролета",
            "41-50",
          ],
          [
            "Массовые сквозные продольные трещины на нижних плитах, отпадение защитного слоя нижних плит с обнажением арматуры, изломы и прогибы плит",
            "Прогиб до 1/50 пролета",
            "51-60",
          ],
          [
            "Прогибы, местами отпадение бетона нижних плит, отслоение бетона и обнажение ребер верхних плит",
            "Прогиб до 1/50 пролета",
            "61-80",
          ],
        ];

        break;
      case "4":
        data = [
          ["Признаки износа", "Количественная оценка", "Физический износ, %"],
          ["Трещины в швах между плитами", "Ширина трещин до 2 мм", "0-10"],
          [
            "Незначительное смещение плит относительно друг друга по высоте",
            "Смещение плит до 1,5 см. Повреждения на площади до 10%",
            "11-20",
          ],
          [
            "Значительное смещение плит перекрытий по высоте",
            "Смещение плит до 3 см. Повреждения на площади до 20%",
            "21-30",
          ],
          [
            "Трещины в плитах, следы протечек или промерзаний",
            "Ширина трещин до 1 мм",
            "31-40",
          ],
          [
            "Поперечные трещины в плитах без оголения арматуры, прогиб",
            "Ширина трещин до 2 мм. Прогиб до 1/100 пролета",
            "41-50",
          ],
          [
            "Глубокие поперечные трещины с оголением арматуры, прогиб",
            "Ширина трещин более 2 мм. Прогиб до 1/80 пролета",
            "51-60",
          ],
          [
            "Множественные глубокие трещины в плитах, смещение",
            "Прогиб более 1/80 пролета",
            "61-80",
          ],
        ];

        break;
      case "5":
        data = [
          ["Признаки износа", "Количественная оценка", "Физический износ, %"],
          [
            "Трещины в местах примыканий к стенам",
            "Ширина трещин до 0,5 мм",
            "0-10",
          ],
          [
            "Трещины в плитах (усадочные или вдоль рабочего пролета)",
            "Ширина трещин до 2 мм. Суммарная длина усадочных трещин на 1 м2 до 0,8 м",
            "11-20",
          ],
          [
            "Трещины в плитах поперек рабочего пролета или множественные усадочные",
            "Ширина раскрытия трещин до 2 мм. Суммарная длина усадочных трещин на 1 м2 до 1,5 м",
            "21-30",
          ],
          [
            "Трещины, прогибы, следы протечек или промерзаний в местах примыканий к наружным стенам",
            "Трещины более 2 мм. Прогибы до 1/150 пролета",
            "31-40",
          ],
          [
            "Развивающиеся трещины у опорных участков плит, прогибы",
            "Прогибы до 1/100 пролета",
            "41-50",
          ],
          [
            "Увеличение трещин и прогибов во времени",
            "Прогибы до 1/100 пролета. Трещины 3 мм",
            "51-80",
          ],
        ];

        break;
      case "6":
        data = [
          ["Признаки износа", "Количественная оценка", "Физический износ, %"],
          [
            "Отдельные трещины в растянутой зоне, незначительное увлажнение местами, поверхностные отколы в растянутой зоне, прогибы",
            "Ширина трещин до 1 мм. Глубина отколов до 3 мм, не более трех на 1 м2",
            "0-40",
          ],
          [
            "Трещины различных направлений, следы увлажнения бетона атмосферными и агрессивными водами, отслоение защитного слоя бетона в растянутой зоне, оголение и коррозия арматуры, механические повреждения и глубокие сколы бетона на большой площади балки, прогиб",
            "Ширина трещин до 2 мм. Коррозия арматуры до 10% сечения. Прогиб до1/50 пролета",
            "41-60",
          ],
          [
            "Трещины по всей длине и высоте балки в середине пролета и в растянутой зоне, следы постоянного увлажнения бетона атмосферными и агрессивными водами, оголение и сильная коррозия арматуры, местами разрывы арматуры, крупные выбоины и сколы бетона в сжатой зоне",
            "Ширина трещин более 2 мм. Коррозия арматуры более 10% сечения. Прогиб более 1/150 пролета",
            "61-80",
          ],
        ];

        break;

      default:
        console.log("Ошибка !");
    }

    onButtonClick("Перекрытия", data);
  };

  return (
    <button className="btn_addwear_wear" onClick={handleClick}>
      Перекрытия
    </button>
  );
};

export default ButtonPerecritia;
