//Компонента выбора типа мкд (отключена)
import React, { useState, useEffect, useContext } from 'react';
import '../css/TypeHouse.css';
import { API_URL } from '../../../config';
import AuthContext from '../../../context/AuthContext';

const TypeHouse = ({ formData, onDataChange }) => {
  const { authTokens } = useContext(AuthContext);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const api = `${API_URL}/Wear/house/HouseTypeSelect/`;
        const requestData = {
          volume: parseInt(formData.text29),
          floorCount: parseInt(formData.text3),
          foundationType: parseInt(formData.dropdown1),
          wallType: parseInt(formData.dropdown2),
          floorType: parseInt(formData.dropdown3),
          balconyType: parseInt(formData.dropdown4),
          roofType: parseInt(formData.dropdown5),
          roofingType: parseInt(formData.dropdown6),
        };
        console.log("Отправляемые данные:", requestData); // Вывод отправленных данных в консоль
        const response = await fetch(api, {
          method: "POST",
          body: JSON.stringify(requestData),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens.access}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          console.log("Полученные данные:", data); // Вывод полученных данных в консоль
          const formattedOptions = data.map((item) => ({
            value: item[0],
            text: item[1],
          }));
          setOptions(formattedOptions);
        } else {
          throw new Error("Ошибка при получении данных с сервера");
        }
      } catch (error) {
        console.error("Ошибка при получении данных с сервера:", error.message);
      }
    };

    fetchData();
  }, [authTokens.access, formData.dropdown1, formData.dropdown2, formData.dropdown3, formData.dropdown4, formData.dropdown5, formData.dropdown6, formData.text29, formData.text3]);

  const handleSelectChange = (e) => {
    const { value } = e.target;
    const newFormData = { ...formData, dropdown8: value };
    onDataChange(newFormData);
  };

  return (
    <div className='TypeHouse'>
      <h1>Выберите тип здания:</h1>
      <select className="TypeHouse-select-wear" id="dropdown8" value={formData.dropdown8} onChange={handleSelectChange}>
        {options.map((option, index) => (
          <option className='TypeHouse-option-wear' key={index} value={option.value}>{option.text}</option>
        ))}
      </select>
    </div>
  );
};

export default TypeHouse;
