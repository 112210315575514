import React from 'react';
import "../../css/Wear.css";
import { API_URL } from "../../../../config";
import { useContext} from "react";
import AuthContext from "../../../../context/AuthContext";

const ButtonAddWear = ({ inputData, selectedComponent, onButtonClick, setInputData }) => {
  let { authTokens } = useContext(AuthContext)

  const isInputDataValid = () => {
    // Проверка на заполнение всех полей и отрицательные значения
    if (!inputData.field1 || !inputData.field2 || !inputData.field3) {
      alert("Пожалуйста, заполните все поля");
      return false;
    }

    // Проверка на отрицательные значения
    if (inputData.field2 < 0 || inputData.field3 < 0) {
      alert("Значения не могут быть отрицательными");
      return false;
    }

    return true;
  };
  
  const handleAddClick = () => {

    if (!isInputDataValid()) {
      return;
    }

    // Логика считывания данных и вывода в консоль
    console.log('Selected Component:', selectedComponent);
    console.log('Input Data:', inputData);

    const id = localStorage.getItem("id");
    
    const api = `${API_URL}/Wear/Damage/`;

    function postDataToServer(elementValue) {



      const today = new Date().toISOString().slice(0, 10); // дата

      const data = {
        position: inputData.field1,
        data: today,
        Element: elementValue,
        volume: inputData.field3,
        degreeOfDamage: inputData.field2,
        idHouse: id,
      };

      console.log(data);

      fetch(api, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.ok) {
            alert("Износ добавлен !");
            setInputData({ field1: "", field2: "", field3: "" }); // Сброс значений полей ввода
            const inputFields = document.querySelectorAll('input');
            inputFields.forEach(field => {
              field.value = "";
            });
          } else {
            throw new Error("Ошибка при отправке данных");
          }
        })
        .catch((error) => {
          console.error("Ошибка при отправке данных:", error.message);
        });
    }

    switch (selectedComponent) {
      case "Фундамент":
        postDataToServer(0);
        break;
      case "Стены":
        postDataToServer(1);
        break;
      case "Перекрытия":
        postDataToServer(2);
        break;
      case "Балкон":
        postDataToServer(3);
        break;
      case "Крыша":
        postDataToServer(4);
        break;
      case "Кровля":
        postDataToServer(5);
        break;
      case "Канализация":
        postDataToServer(6);
        break;
      case "Мусоропровод":
        postDataToServer(7);
        break;
      case "Центральное отопление":
        postDataToServer(8);
        break;
      case "Холодное водоснабжение":
        postDataToServer(9);
        break;
      case "Горячее водоснабжение":
        postDataToServer(10);
        break;
      case "Электрооборудование":
        postDataToServer(11);
        break;
      default:
        alert("Ошибка в выборе типа элемента !");
    }
  };

  const handleCloseClick = () => {
        //Cкрыть генерацию таблицы и полей
        onButtonClick(selectedComponent, []);
  }

  return (
    <div className='conteiner_btn_addwear_hold_wear'>
    <button className="btn_addwear_hold_wear" onClick={handleAddClick}> Добавить повреждение </button>
    <button className="btn_addwear_hold_wear" onClick={handleCloseClick}> Закрыть повреждение </button>
    </div>
  );
};

export default ButtonAddWear;
