import { API_URL } from "../../../../../config";
import { dateConverter } from "../../../utils/utils";
import axios from 'axios'

let fetchOnLink = async (item, authTokens) => {
  let response = await fetch(item, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${authTokens.access}` },
  });
  return await response.json();
}

export let getHydrologists = async (authTokens, setter) => {
  let response = await fetch(`${API_URL}/hydrologist/`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${authTokens.access}` },
  });
  return setter(await response.json());
}


export let getWPS = async (authTokens, setter, setWells) => {
  let response = await fetch(`${API_URL}/RSO/waterpumpingstation/`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${authTokens.access}` },
  });

  let data = await response.json()
  setter(data)
  getWells(authTokens, data[0]?.id, setWells)
};


export let getWPSId = async (authTokens, WPSId, setter) => {
  let response = await fetch(`${API_URL}/RSO/waterpumpingstation/${WPSId}/`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${authTokens.access}` },
  });
  let data = await response.json()

  if (setter && data) {
    setter(data)
  }
  if (data) {
    return data
  }
};

export let getWells = async (authTokens, WPSId, setter) => {
  let wps = await getWPSId(authTokens, WPSId);
  let wells = [];

  if (wps.prefabricate_water_pipeline && wps.prefabricate_water_pipeline.length > 0) {
    try {
      let prefabricateWaterPipelineArr = await Promise.all(wps?.prefabricate_water_pipeline.map(item => fetchOnLink(item, authTokens)));
      prefabricateWaterPipelineArr.map(item => {
        item?.section_of_the_water_pipeline?.map(item1 =>
          fetchOnLink(item1, authTokens)
            .then(data => {
              if (data?.wells && data.wells.length > 0) {
                Promise.all(data.wells.map(item2 =>
                  fetchOnLink(item2, authTokens)
                    .then(data1 => {
                      wells.push(data1);
                    })
                )).then(() => {
                  setter([...wells]);
                }).catch(error => {
                  console.error('Ошибка запроса:', error);
                });
              } else {
                setter([]);
              }
            })
            .catch(error => {
              console.error('Ошибка запроса:', error);
            })
        );
      });
    } catch (error) {
      console.error(error);
    }
  } else {
    setter([]);
  }
}

export const getHydrologistDataMonthDate = async (authTokens, date, selectedWell, setter, datesInMonth) => {
  const params = new URLSearchParams({
    well_ids: selectedWell,
  });

  const response = await fetch(`${API_URL}/hydrologist/data/month/${date}?${params}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authTokens.access}`,
    },
  });

  const fetchData = await response.json();

  const tableData = datesInMonth.reduce((acc, dateItem) => {
    acc[dateItem] = {
      meter_readings: '',
      device_operating_time: '',
      measurement_date: '',
      reporting_capacity: '',
      hydrologist: ''
    };
    return acc;
  }, {});

  fetchData.forEach(item => {
    const convertedDate = dateConverter(item.measurement_date);
    if (tableData.hasOwnProperty(convertedDate)) {
      tableData[convertedDate] = {
        meter_readings: item.meter_readings,
        device_operating_time: item.device_operating_time,
        measurement_date: item.measurement_date,
        water_consumption: item.water_consumption,
        reporting_capacity: item.reporting_capacity,
        hydrologist: `${item.hydrologist.surname} ${item.hydrologist.name}`
      };
    }
  });

  const dataArray = Object.entries(tableData).map(([date, data]) => ({ date, ...data }));


  setter(dataArray);
}


export let addHydrologistDataCreateMany = async ({authTokens, data, action, closeModal}) => {
  console.log(data);


  let response = await axios.post(`${API_URL}/hydrologist/data/create/many/`,
    data,
    {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${authTokens.access}` },
    });
  console.log(response);
  if (response.status === 201) {
    action();
  }
}
