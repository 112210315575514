import './TControlSelect.scss';

export let TControlSelect = ({ name, list, parse, value, visible_value, setter, getter, option, secondVisibleName, defaultValue }) => {
  let parseIt = (e) => {
    if (parse === 'int') {
      option ? (setter({ ...getter, [option]: parseInt(e.target.value) })) : (setter(parseInt(e.target.value)));
    }
    if (parse === 'String') {
      option ? (setter({ ...getter, [option]: String(e.target.value) })) : (setter(String(e.target.value)));
    }
  };

  return (
    <div className="TControlSelect">
      <label className="form-label">{name}</label>
      <select
        className='form-input'
        value={getter?.[option] || defaultValue || ''}  // Set default value or selected state
        onChange={e => parseIt(e)}
      >
        {list?.map((item, index) => (
          <option key={index} value={item[value]}>
            {`${item[visible_value]} ${secondVisibleName ? `[${item[secondVisibleName]}]` : ''}`}
          </option>
        ))}
      </select>
    </div>
  );
};