import React from "react";
import "../../css/Wear.css";
import { API_URL } from "../../../../config";
import { useContext } from "react";
import AuthContext from "../../../../context/AuthContext";

const ButtonFactFullWear = ({ onButtonClick }) => {
  let { authTokens } = useContext(AuthContext);

  const id = localStorage.getItem("id");
  console.log(id)

  // const accessToken = localStorage.getItem("accessToken");

  const fetchData = async () => {
    const api = `${API_URL}/Wear/House/GetTheoreticalWear/${id}/`; 
    //const api = `http://127.0.0.1:8000/api/Wear/House/GetTheoreticalWear/${id}/`

    try {
      const response = await fetch(api, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
      });

      if (!response.ok) {
        throw new Error("Ошибка при получении данных");
      }

      const data = await response.text();
      return parseFloat(data);
    } catch (error) {
      console.error("Ошибка !", error.message);
      return null;
    }
  };

const handleAddClick = async () => {
    const fullfactwear = await fetchData();

    // Проверяем, есть ли значение в fullwear
    const fullwearValue = fullfactwear !== null ?`${fullfactwear}%` : "0%";
    
    // Добавляем в таблицу
    const tableData = [["Фактический износ здания"], [fullwearValue]];

    // Пример вывода данных
    console.log(tableData);
    console.log({fullfactwear });

    onButtonClick("Фактический износ здания", tableData);
  };

  return (
    <button className="btn_addwear_wear" onClick={handleAddClick}>
      Теоретический износ здания
    </button>
  );
};

export default ButtonFactFullWear;
