import './RSODynamic_worker.scss'
import Layout from '../../../../components/Layout'
import { RSODynamicInput } from '../../utils/RSODynamicInput/RSODynamicInput'
import { RSODynamicButton } from '../../utils/RSODynamicButton/RSODynamicButton'
import { RSODynamic_worker_create_smena } from './components/RSODynamic_worker_create_smena/RSODynamic_worker_create_smena'
import { RSODynamic_worker_workzone } from './components/RSODynamic_worker_workzone/RSODynamic_worker_workzone'
// import { useStats_worker } from './hooks/useStats_worker'
import { worker_context } from './hooks/useStats_worker'
import { useContext } from 'react'
import { WorkerWorkzoneProvider } from './hooks/useStats_worker_workzone'


export let RSODynamic_worker = () => {
  let {
    smena,
  } = useContext(worker_context)

  return (

    <div style={{ marginTop: '120px' }}>
      {smena.length === 0 ? (
        //Меню создания смены
        <RSODynamic_worker_create_smena />
      ) :
        //Рабочая зона оператора ВЗУ
        <WorkerWorkzoneProvider>
          <RSODynamic_worker_workzone />
        </WorkerWorkzoneProvider>}
    </div>
  )
}