//Компоннета выбора типа элементов мкд
import React, { useState, useEffect, useContext } from "react";
import "../css/OptionHouse.css";
import { API_URL } from "../../../config";
import AuthContext from "../../../context/AuthContext";

const OptionHouse = ({ formData, onDataChange }) => {
  const { authTokens } = useContext(AuthContext);

  const [options, setOptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const api = `${API_URL}/Wear/house/HouseTypeSelect/`;
        
        const requestData = {
          volume: parseInt(formData.text29),
          floorCount: parseInt(formData.text3),
          foundationType: parseInt(formData.dropdown1),
          wallType: parseInt(formData.dropdown2),
          floorType: parseInt(formData.dropdown3),
          balconyType: parseInt(formData.dropdown4),
          roofType: parseInt(formData.dropdown5),
          roofingType: parseInt(formData.dropdown6),
        };
        console.log("Отправляемые данные:", requestData); // Вывод отправленных данных в консоль
        const response = await fetch(api, {
          method: "POST",
          body: JSON.stringify(requestData),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens.access}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          console.log("Полученные данные:", data); // Вывод полученных данных в консоль
          const formattedOptions = data.map((item) => ({
            value: item[0],
            text: item[1],
          }));
          setOptions(formattedOptions);
        } else {
          throw new Error("Ошибка при получении данных с сервера");
        }
      } catch (error) {
        console.error("Ошибка при получении данных с сервера:", error.message);
      }
    };

    fetchData();
  }, [
    authTokens.access,
    formData.dropdown1,
    formData.dropdown2,
    formData.dropdown3,
    formData.dropdown4,
    formData.dropdown5,
    formData.dropdown6,
    formData.text29,
    formData.text3,
  ]);

  const handleSelectChange = (e) => {
    const { value } = e.target;
    const newFormData = { ...formData, dropdown8: value };
    onDataChange(newFormData);
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    onDataChange({ ...formData, [id]: value });
  };

  return (
    <div className="main-content_option">
      <div className="form-group">
        <label className="label-field-data-wear" htmlFor="dropdown1">
          Тип фундамента
        </label>
        <select
          className="select-field-data-wear"
          id="dropdown1"
          value={formData.dropdown1}
          onChange={handleInputChange}
        >
          <option className="option-field-data-wear" value="0">
            Выбрать
          </option>
          <option className="option-field-data-wear" value="1">
            - столбчатые деревянные с забиркой
          </option>
          <option className="option-field-data-wear" value="2">
            - столбчатые, каменные с кирпичным цоколем
          </option>
          <option className="option-field-data-wear" value="3">
            - ленточные каменные
          </option>
          <option className="option-field-data-wear" value="4">
            - ленточные крупноблочные
          </option>
          <option className="option-field-data-wear" value="5">
            - свайные столбчатые каменные, бетонные и железобетонные
          </option>
        </select>
      </div>

      <div className="form-group">
        <label className="label-field-data-wear" htmlFor="dropdown2">
          Тип стен
        </label>
        <select
          className="select-field-data-wear"
          id="dropdown2"
          value={formData.dropdown2}
          onChange={handleInputChange}
        >
          <option className="option-field-data-wear" value="0">
          Выбрать
          </option>
          <option className="option-field-data-wear" value="1">
            - деревянные, сборно-щитовые
          </option>
          <option className="option-field-data-wear" value="2">
            - деревянные каркасные
          </option>
          <option className="option-field-data-wear" value="3">
            - рубленные из бревен и брусчатые
          </option>
          <option className="option-field-data-wear" value="4">
            - деревянные рубленные, каркасные и брусчатые с наружной облицовкой
            кирпичом
          </option>
          <option className="option-field-data-wear" value="5">
            - кирпичные
          </option>
          <option className="option-field-data-wear" value="6">
            - кирпичные с облицовкой керамическими блоками и плитками
          </option>
          <option className="option-field-data-wear" value="7">
            - из мелких блоков, искусственных и естественных камней
          </option>
          <option className="option-field-data-wear" value="8">
            - из крупноразмерных блоков и однослойных несущих панелей
          </option>
          <option className="option-field-data-wear" value="9">
            - из слоистых железобетонных панелей
          </option>
          <option className="option-field-data-wear" value="10">
            - из несущих панелей
          </option>
        </select>
      </div>

      <div className="form-group">
        <label className="label-field-data-wear" htmlFor="dropdown3">
          Тип перекрытий
        </label>
        <select
          className="select-field-data-wear"
          id="dropdown3"
          value={formData.dropdown3}
          onChange={handleInputChange}
        >
          <option className="option-field-data-wear" value="0">
          Выбрать
          </option>
          <option className="option-field-data-wear" value="1">
            - деревянные неоштукатуренные
          </option>
          <option className="option-field-data-wear" value="2">
            - из кирпичных сводов по стальным балкам
          </option>
          <option className="option-field-data-wear" value="3">
            - из двухскорлупных железобетонных прокатных панелей
          </option>{" "}
          Перекрытия из сборного железобетонного настила
          <option className="option-field-data-wear" value="4">
            - перекрытия из сборного железобетонного настила{" "}
          </option>
          <option className="option-field-data-wear" value="5">
            - из сборных и монолитных сплошных плит
          </option>
          <option className="option-field-data-wear" value="6">
            - монолитные и сборные железобетонные балки покрытий и перекрытий
          </option>
        </select>
      </div>

      <div className="form-group">
        <label className="label-field-data-wear" htmlFor="dropdown4">
          Тип балконов
        </label>
        <select
          className="select-field-data-wear"
          id="dropdown4"
          value={formData.dropdown4}
          onChange={handleInputChange}
        >
          <option className="option-field-data-wear" value="0">
          Выбрать
          </option>
          <option className="option-field-data-wear" value="1">
            - сборные железобетонные детали лоджий
          </option>
          <option className="option-field-data-wear" value="2">
            - балконы, козырьки, эркеры
          </option>
        </select>
      </div>

      <div className="form-group">
        <label className="label-field-data-wear" htmlFor="dropdown5">
          Тип крыши
        </label>
        <select
          className="select-field-data-wear"
          id="dropdown5"
          value={formData.dropdown5}
          onChange={handleInputChange}
        >
          <option className="option-field-data-wear" value="0">
          Выбрать
          </option>
          <option className="option-field-data-wear" value="1">
            - деревянные
          </option>
          <option className="option-field-data-wear" value="2">
            - железобетонные сборные (чердачные)
          </option>
          <option className="option-field-data-wear" value="3">
            - совмещенные из сборных железобетонных слоистых панелей
          </option>
        </select>
      </div>

      <div className="form-group">
        <label className="label-field-data-wear" htmlFor="dropdown6">
          Тип кровли
        </label>
        <select
          className="select-field-data-wear"
          id="dropdown6"
          value={formData.dropdown6}
          onChange={handleInputChange}
        >
          <option className="option-field-data-wear" value="0">
          Выбрать
          </option>
          <option className="option-field-data-wear" value="1">
            - рулонные
          </option>
          <option className="option-field-data-wear" value="2">
            - мастичные
          </option>
          <option className="option-field-data-wear" value="3">
            - стальные
          </option>
          <option className="option-field-data-wear" value="4">
            - из асбестоцементных листов
          </option>
          <option className="option-field-data-wear" value="5">
            - черепичные
          </option>
          <option className="option-field-data-wear" value="6">
            - драночные
          </option>
          <option className="option-field-data-wear" value="7">
            - тесовые
          </option>
        </select>
      </div>

      <div className="form-group">
        <label className="label-field-data-wear" htmlFor="dropdown7">
          Тип мансарды
        </label>
        <select
          className="select-field-data-wear"
          id="dropdown7"
          value={formData.dropdown7}
          onChange={handleInputChange}
        >
          <option className="option-field-data-wear" value="0">
          Выбрать
          </option>
          <option className="option-field-data-wear" value="1">
            - отсутствует
          </option>
          <option className="option-field-data-wear" value="2">
            - отапливаемая
          </option>
          <option className="option-field-data-wear" value="3">
            - не отапливаемая
          </option>
        </select>
      </div>

      <div className='form-group'>
      <label className="label-field-data-wear" htmlFor="dropdown7">
          Тип многоквартирного дома
      </label>
      <select className="select-field-data-wear" id="dropdown8" value={formData.dropdown8} onChange={handleSelectChange}>
      <option className="option-field-data-wear" value ="0">Выбрать</option>
        {options.map((option, index) => (
          <option className='option-field-data-wear' key={index} value={option.value}>{option.text}</option>
        ))}
      </select>
    </div>

    </div>
  );
};

export default OptionHouse;
