import { useContext, useEffect, useState } from 'react';
import { ModelTable } from '../../../../../../../../components/ModelTable/ModelTable'
import { dispatcher_dynamic_context } from '../../../../hooks/useStats_dispatcher_dynamic';
import Jelly from '../../../../../../../../components/Jelly';

export let RSODynamicDispDynamicDaily = () => {

  let { allAboutVZU } = useContext(dispatcher_dynamic_context)

  function addLineBreaks(array) {
    const elements = [];
    array.forEach((item, index) => {
      elements.push(<span key={index}>{item}; </span>);
      if ((index + 1) % 3 === 0) {
        elements.push(<br key={`br-${index}`} />);
      }
    });
    return elements;
  }

  const [dateTime, setDateTime] = useState('');

  useEffect(() => {
    function getCurrentDateTime() {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');

      const formattedDateTime = `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
      setDateTime(formattedDateTime);
    }

    getCurrentDateTime();

    const intervalId = setInterval(() => {
      getCurrentDateTime();
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="container">
      {allAboutVZU.length !== 0 ? 
      <ModelTable>
        <thead>
          <tr>
            <th colSpan='7'>{dateTime}</th>
          </tr>
          <tr>
            <th>ВЗУ</th>
            <th colSpan='3'>Скважины, N</th>
            <th colSpan='3'>Насосы, машинный зал, N</th>
          </tr>
          <tr>
            <th></th>
            <th>В работе</th>
            <th>В резерве</th>
            <th>Ремонт</th>
            <th>В работе</th>
            <th>В резерве</th>
            <th>Ремонт</th>
          </tr>
        </thead>
        <tbody>
          {allAboutVZU?.map((item, index) => (
            <tr key={index}>
              <td>{item.vzu_name}</td>
              <td>{addLineBreaks(item.well_worked)}</td>
              <td>{addLineBreaks(item.well_reserved)}</td>
              <td>{addLineBreaks(item.well_repaired)}</td>
              <td>{addLineBreaks(item.pump_worked)}</td>
              <td>{addLineBreaks(item.pump_reserved)}</td>
              <td>{addLineBreaks(item.pump_repaired)}</td>
            </tr>
          ))}
        </tbody>

      </ModelTable> : <Jelly />}
    </div>
  )
};
