import React, { useState, useContext, useEffect } from "react";
import { ResidentialHouse } from "./House/ResidentialHouse/ResidentialHouse";
import { PublicHouse } from "./House/PublicHouse/PublicHouse";

import "./AddHouse.css";

export const AddHouse = () => {
  const [selectedHouseType, setSelectedHouseType] = useState(null);

  //Логика перемещения по страницам
  const renderHouseComponent = () => {
    if (selectedHouseType === 1) {
      return <ResidentialHouse />;
    } else if (selectedHouseType === 2) {
      return <PublicHouse />;
    }
    return null;
  };

  return (
    <div className="Add_House_Main">
      {!selectedHouseType && (
        <>
          <h1 className="Add_House_h1">Выберите тип здания</h1>
          <div className="Add_House_Button_Group">
            <button
              className="Add_House_Button"
              onClick={() => setSelectedHouseType(1)}
            >
              Жилое здание
            </button>
            <button
              className="Add_House_Button"
              onClick={() => setSelectedHouseType(2)}
            >
              Общественное здание
            </button>
          </div>
        </>
      )}

      {selectedHouseType && (
        <div>
          {renderHouseComponent()}
        </div>
      )}
    </div>
  );
};
