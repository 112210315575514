import React, { useEffect, useState, useMemo } from "react";
import { useContext, createContext } from "react";
import AuthContext from "../../../../../context/AuthContext";
import { getHydrologists, getWPS, getWells } from "../http/HyderologistWorkerHttp";
import { getNowDate } from "../../../utils/utils";

export const HydrologistContext = createContext();

export const HydrologistProvider = ({ children }) => {
  const { authTokens } = useContext(AuthContext);

  const [hydrologists, setHydrologists] = useState([]);
  const [wps, setWps] = useState([]);
  const [wells, setWells] = useState([]);
  const [selectedHydrologist, setSelectedHydrologist] = useState(null);
  const [selectedWps, setSelectedWps] = useState(null);
  const [selectedWell, setSelectedWell] = useState(null);
  const [selectedDate, setSelectedDate] = useState(getNowDate());


  //Основные данные
  useEffect(() => {
    getHydrologists(authTokens, setHydrologists);
    getWPS(authTokens, setWps, setWells);
  }, []);

  useEffect(() => {
    if (wps.length !== 0 && selectedWps) {
      getWells(authTokens, selectedWps, setWells);
    }
  }, [wps, selectedWps]);


  //Первые значения
  useEffect(() => {
    if (hydrologists.length !== 0) {
      setSelectedHydrologist(hydrologists[0].id);
    }
  }, [hydrologists]);

  useEffect(() => {
    if (wps.length !== 0) {
      setSelectedWps(wps[0].id);
    }
  }, [wps]);


  useEffect(() => {
    if (wells.length !== 0) {
      setSelectedWell(wells[0].id);
    }else{
      setSelectedWell(null)
    }
  }, [wells]);


  //Выгружаем
  const memoizedHydrologists = useMemo(() => ({
    hydrologists,
    selectedHydrologist,
    setSelectedHydrologist,
  }), [hydrologists, selectedHydrologist]);

  const memoizedWps = useMemo(() => ({
    wps,
    selectedWps,
    setSelectedWps,
  }), [wps, selectedWps]);

  const memoizedWells = useMemo(() => ({
    wells,
    selectedWell,
    setSelectedWell
  }), [wells, selectedWell]);

  const memoizedDate = useMemo(() => ({
    selectedDate,
    setSelectedDate
  }), [selectedDate]);

  return (
    <HydrologistContext.Provider value={{ memoizedHydrologists, memoizedWps, memoizedWells, memoizedDate }}>
      {children}
    </HydrologistContext.Provider>
  );
};
