import React from "react";
import "../PublicHouse.css";

export const PublicConstr = ({ formDataPublic, onDataChange }) => {
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    onDataChange({ ...formDataPublic, [id]: value });
  };

  return (
    <div className="Public_Constr_Main">
      <div className="Public_Constr_Data">
        <div className="Public_Constr_Input_Group">
          <input
            className="Public_Constr_Input_Field"
            type="number"
            id="foundation_walls_area"
            placeholder="Площадь стен фундамента"
            required
            value={formDataPublic.foundation_walls_area || ''}
            onChange={handleInputChange}
          />
          <input
            className="Public_Constr_Input_Field"
            type="number"
            id="balcony_area"
            placeholder="Общая площадь балконов и т.д"
            required
            value={formDataPublic.balcony_area || ''}
            onChange={handleInputChange}
          />
          <input
            className="Public_Constr_Input_Field"
            type="number"
            id="building_walls_area"
            placeholder="Площадь стен здания"
            required
            value={formDataPublic.building_walls_area || ''}
            onChange={handleInputChange}
          />
          <input
            className="Public_Constr_Input_Field"
            type="number"
            id="load_bearing_area"
            placeholder="Общая площадь несущих конструкций"
            required
            value={formDataPublic.load_bearing_area || ''}
            onChange={handleInputChange}
          />
          <input
            className="Public_Constr_Input_Field"
            type="number"
            id="floor_area"
            placeholder="Общая площадь перекрытия"
            required
            value={formDataPublic.floor_area || ''}
            onChange={handleInputChange}
          />
          <input
            className="Public_Constr_Input_Field"
            type="number"
            id="roof_area"
            placeholder="Общая площадь крыши"
            required
            value={formDataPublic.roof_area || ''}
            onChange={handleInputChange}
          />
          <input
            className="Public_Constr_Input_Field"
            type="number"
            id="balcony_count"
            placeholder="Количество балконов"
            required
            value={formDataPublic.balcony_count || ''}
            onChange={handleInputChange}
          />
          <input
            className="Public_Constr_Input_Field"
            type="number"
            id="loggia_count"
            placeholder="Количество лоджий"
            required
            value={formDataPublic.loggia_count || ''}
            onChange={handleInputChange}
          />
          <input
            className="Public_Constr_Input_Field"
            type="number"
            id="canopy_count"
            placeholder="Количество козырьков"
            required
            value={formDataPublic.canopy_count || ''}
            onChange={handleInputChange}
          />
          <input
            className="Public_Constr_Input_Field"
            type="number"
            id="bay_window_count"
            placeholder="Количество эркеров"
            required
            value={formDataPublic.bay_window_count || ''}
            onChange={handleInputChange}
          />
        </div>
      </div>
    </div>
  );
};
