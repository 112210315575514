import React from "react";
import "../../css/Wear.css";

const ButtonFundament = ({ onButtonClick, foundationType }) => {
  const handleClick = () => {
    let data;

    switch (foundationType) {
      case "1":
        data = [
          ["Признаки износа", "Количественная оценка", "Физический износ, %"],
          ["Разрушение отделочного слоя цоколя, ослабление врубок", "-", "0-20"],
          [
            "Искривление горизонтальных линий цоколя, забирки > и повреждение отдельных досок забирки",
            "Повреждения на площади до 25%*",
            "21-40",
          ],
          [
            "Поражение забирки гнилью, обрастание мхом нижней части цоколя, коробление и отставание досок Мелкие повреждения верх-",
            "Повреждения на площади до 50%*",
            "41-60",
          ],
          [
            "Искривление горизонтальной линии стен, осадка отдельных участков здания. Поражение гнилью, жучком, частичные разрушения забирки и столбов",
            "-",
            "61-80",
          ],
        ];

        break;
      case "2":
        data = [
          ["Признаки износа", "Количественная оценка", "Физический износ, %"],
          [
            "Мелкие повреждения цокольной части - трещины, местные выбоины",
            "Повреждения на площади до 5%",
            "0-20",
          ],
          [
            "Трещины, сколы, выпадение отдельных камней в надземной части цоколя и фундаментных столбов",
            "То же, до 25%",
            "21-40",
          ],
          [
            "Перекосы, выпучивание цоколя, трещины в цоколе, трещины, сколы и выпадение камней в надземной части столбов",
            "Ширина трещин до 5 мм. Выпучивание цоколя до 1/3 его толщины",
            "41-60",
          ],
          [
            "Искривление горизонтальных линий стен, осадка отдельных участков, перекосы оконных и дверных проемов, полное разрушение цоколя, нарушение монолитности кладки столбов",
            "-",
            "61-80",
          ],
        ];

        break;
      case "3":
        data = [
          ["Признаки износа", "Количественная оценка", "Физический износ, %"],
          [
            "Мелкие трещины в цоколе, местные нарушения штукатурного слоя цоколя и стен",
            "Ширина трещин до 1,5 мм",
            "0-20",
          ],
          [
            "Трещины в швах между блоками, высолы и следы увлажнения стен подвала",
            "То же, до 2 мм",
            "21-40",
          ],
          [
            "Трещины, частичное разрушение блоков (до арматуры), выщелачивание раствора из швов между блоками, следы увлажнения цоколя и стен подвала",
            "То же, более 2 мм, глубина более 10 мм",
            "41-60",
          ],
          [
            "Массовые повреждения и разрушение блоков, прогрессирующие сквозные трещины на всю высоту здания, выпирание грунта в подвале",
            "61-80",
          ],
        ];

        break;
      case "4":
      
        data =[
        ["Признаки износа", "Количественная оценка", "Физический износ, %"],
        [
          "Мелкие повреждения цокольной части - трещины, местные выбоины",
          "Повреждения на площади до 5%",
          "0-20",
        ],
        [
          "Трещины, сколы, выпадение отдельных камней в надземной части цоколя и фундаментных столбов",
          "То же, до 25%",
          "21-40",
        ],
        [
          "Перекосы, выпучивание цоколя, трещины в цоколе, трещины, сколы и выпадение камней в надземной части столбов",
          "Ширина трещин до 5 мм. Выпучивание цоколя до 1/3 его толщины",
          "41-60",
        ],
        [
          "Искривление горизонтальных линий стен, осадка отдельных участков, перекосы оконных и дверных проемов, полное разрушение цоколя, нарушение монолитности кладки столбов",
          "-",
          "61-80",
        ],
        ];

        break;
      case "5":

        data = [
        ["Признаки износа", "Количественная оценка", "Физический износ, %"],
        [
          "Трещины в цокольной части здания",
          "Ширина раскрытия трещин до 1,5 мм",
          "0-20",
        ],
        [
          "Искривление горизонтальных линий цоколя без признаков увеличения осадочных деформаций",
          "Неравномерная осадка с прогибом стен до 0,01 от длины стены",
          "21-40",
        ],
        [
          "Сквозные трещины в цоколе, распространение трещин на всю высоту здания. Искривление и значительная осадка отдельных участков стен. Развитие осадок не наблюдается",
          "Ширина раскрытия трещин до 10 мм. Неравномерная осадка с прогибом стен и более 0,01 от длины стены",
          "41-60",
        ],
        [
          "Развитие сквозных трещин в стенах здания, разрушение цоколя, развитие деформаций фундаментов",
          "-",
          "61-80",
        ],
        ];

        break;
      // case "6":

      //    data =[
      //   ["Признаки износа", "Количественная оценка", "Физический износ, %"],
      //   [
      //     "Трещины в цокольной части здания",
      //     "Ширина раскрытия трещин до 1,5 мм",
      //     "0-20",
      //   ],
      //   [
      //     "Искривление горизонтальных линий цоколя без признаков увеличения осадочных деформаций",
      //     "Неравномерная осадка с прогибом стен до 0,01 от длины стены",
      //     "21-40",
      //   ],
      //   [
      //     "Сквозные трещины в цоколе, распространение трещин на всю высоту здания. Искривление и значительная осадка отдельных участков стен. Развитие осадок не наблюдается",
      //     "Ширина раскрытия трещин до 10 мм. Неравномерная осадка с прогибом стен и более 0,01 от длины стены",
      //     "41-60",
      //   ],
      //   [
      //     "Развитие сквозных трещин в стенах здания, разрушение цоколя, развитие деформаций фундаментов",
      //     "-",
      //     "61-80",
      //   ],
      //    ];

      //    break;
      
      default:
        console.log("Ошибка !")

    }

    onButtonClick("Фундамент", data);
  };

  return (
    <button className="btn_addwear_wear" onClick={handleClick}>
      Фундамент
    </button>
  );
};

export default ButtonFundament;
