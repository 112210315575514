import React from "react";
import '../../css/Button.css';

const ButtonNextTypeHouse = ({ updateState, formData }) => {
  const handleNextClick = () => {
    // Проверяем каждое значение formData отдельно
    if (
      formData.dropdown1 !== 0 &&
      formData.dropdown2 !== 0 &&
      formData.dropdown3 !== 0 &&
      formData.dropdown4 !== 0 &&
      formData.dropdown5 !== 0 &&
      formData.dropdown6 !== 0 &&
      formData.dropdown7 !== 0 &&
      formData.dropdown8 !== 0
    ) {
      updateState(3); 
    } else {
      alert("Пожалуйста, выберите значения для всех полей"); 
    }
  };

  return (
    <button className="btn-osn-wear" type="button" onClick={handleNextClick}>
      Далее
    </button>
  );
};

export default ButtonNextTypeHouse;
