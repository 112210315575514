import './WPSInfo.scss'
import { useContext } from "react";
import { worker_workzone_context } from "../../../../hooks/useStats_worker_workzone";
import { RSODynamicNotFound } from '../../../../../../utils/RSODynamicNotFound/RSODynamicNotFound';

export let WPSInfo = () => {
  let { currentWps } = useContext(worker_workzone_context);

  return (
    <div className="container">
      {currentWps?.scheme && (
        <a className="WPSInfo__link" href={currentWps.scheme} target="_blank">
          <div className="card">
            <span>Схема</span>
          </div>
        </a>
      )}
      {currentWps?.technical_reglament && (
        <a className="WPSInfo__link" href={currentWps.technical_reglament} target="_blank">
          <div className="card">
            <span>Технический регламент</span>
          </div>
        </a>
      )}
      {currentWps?.excel_info && (
        <a className="WPSInfo__link" href={currentWps.excel_info} target="_blank">
          <div className="card">
            <span>Перечень насосного оборудования</span>
          </div>
        </a>
      )}
      {/* <RSODynamicNotFound
        title={`Временно в разработке`}
        icon='👨‍💻'
        descr={`Ждем появления схем на сервере... После появления надпись исчезнет`}
      /> */}
    </div>
  )
}