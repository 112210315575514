import React, { useState } from "react";

import { Balcon } from "./ButtonAddWear/Balcon";
import { Fundament } from "./ButtonAddWear/Fundament";
import { Krisa } from "./ButtonAddWear/Krisa";
import { Krovla } from "./ButtonAddWear/Krovla";
import { Perecritia } from "./ButtonAddWear/Perecritia";
import { Steni } from "./ButtonAddWear/Steni";
import { Inz } from "./ButtonAddWear/Inz";

import "./ButtonAdd.css"

export const ButtonAddWear = () => {
  const [activeComponent, setActiveComponent] = useState(null);

  const renderComponent = () => {
    switch (activeComponent) {
      case "balcon":
        return <Balcon />;
      case "fundament":
        return <Fundament />;
      case "krisa":
        return <Krisa />;
      case "krovla":
        return <Krovla />;
      case "perecritia":
        return <Perecritia />;
      case "steni":
        return <Steni />;
      case "inz":
        return <Inz />;
      default:
        return null;
    }
  };

  return (
    <div className="ButtonAddWear_container">
      <div className="ButtonAddWear_buttons">
        <button className="ButtonAddWear_button" onClick={() => setActiveComponent("balcon")}>Балкон</button>
        <button className="ButtonAddWear_button" onClick={() => setActiveComponent("fundament")}>Фундамент</button>
        <button className="ButtonAddWear_button" onClick={() => setActiveComponent("krisa")}>Крыша</button>
        <button className="ButtonAddWear_button" onClick={() => setActiveComponent("krovla")}>Кровля</button>
        <button className="ButtonAddWear_button" onClick={() => setActiveComponent("perecritia")}>Перекрытия</button>
        <button className="ButtonAddWear_button" onClick={() => setActiveComponent("steni")}>Стены</button>
        <button className="ButtonAddWear_button" onClick={() => setActiveComponent("inz")}>Инженерия</button>
      </div>

      <div className="ButtonAddWear_content">
        {renderComponent()}
      </div>
    </div>
  );
};
