import axios from "axios";
import { API_URL } from "../../../../../config";
import { dateConverter, getNowDate, resetInputFields } from "../../../../../utils/utils";
import { extractValues } from "../../../../../utils/utils";

let fetchOnLink = async (item, authTokens) => {
  let response = await fetch(item, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${authTokens.access}` },
  });
  // console.log(response.json());
  return await response.json();
}

//Получаем смену
export let getSmena = async (authTokens, setter) => {
  let response = await fetch(`${API_URL}/RsoDynamic/smena/`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${authTokens.access}` },
  });
  return setter(await response.json());
};

//Получаем все ВЗУ
export let getWPS = async (authTokens, setter) => {
  let response = await fetch(`${API_URL}/RSO/waterpumpingstation/`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${authTokens.access}` },
  });
  return setter(await response.json());
};


export let postSmena = async (e, authTokens, data, setter, setLoading) => {
  e.preventDefault();
  setLoading(true)
  let response = await fetch(`${API_URL}/RsoDynamic/smena/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${authTokens.access}` },
    body: JSON.stringify({
      shift_number: data.shift_number,
      fullname: data.fullname,
      water_pumping_station: data.water_pumping_station
    })
  });
  if (response.status === 500) {
    window.location.reload();
    setLoading(false)
  }
  return setter(await response.json());
}

//Получаем конкретный ВЗУ по id
export let getWPSId = async (authTokens, setter, WPSId) => {
  let response = await fetch(`${API_URL}/RSO/waterpumpingstation/${WPSId}/`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${authTokens.access}` },
  });
  return setter(await response.json());
};


//Отправка почасовых показателей
export let postHourlyRates = async (e, authTokens, data, waterLevelInReseruar, smenaId, setter, setWaterLevelInReseruar, updateSmena) => {//Отправить почасовые показатели
  e.preventDefault();

  let response = await fetch(`${API_URL}/RsoDynamic/dynamicsettings/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${authTokens.access}` },
    body: JSON.stringify({
      ...data,
      smena_id: smenaId,
      water_level_in_reseruar: extractValues(waterLevelInReseruar)
    })
  });
  if (response.status === 201) {
    //Очистка хука с данными
    setter({})
    setWaterLevelInReseruar({})
    //Очистка полей ввода
    let arrNames = ["pressure", "dt", "db", "water_level_in_reseruar1", "water_level_in_reseruar2", "current", "voltage", "max_consumption", "presence_plomb", "note"]
    arrNames.forEach(name => {
      resetInputFields(name);
    });
    updateSmena()
  }
}

//Получаем почасовые показатели
export let getHourlyRates = async (authTokens, ordering_dynamic_settings, setter) => {
  try {
    const responses = await Promise.all(ordering_dynamic_settings.map(item => fetchOnLink(item, authTokens)));
    setter(responses)
  } catch (error) { console.log(error) }
};

//Отправка хлора
export let postChlorine = async (e, authTokens, data, smenaId, setter, updateSmena) => {//Отправить почасовые показатели
  e.preventDefault();

  let response = await fetch(`${API_URL}/RsoDynamic/chlorine/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${authTokens.access}` },
    body: JSON.stringify({
      ...data,
      smena_id: smenaId,
    })
  });
  if (response.status === 201) {
    //Очистка хука с данными
    setter({})
    //Очистка полей ввода
    let arrNames = ["max_chlorine", "reagent_consumption", "note"]
    arrNames.forEach(name => {
      resetInputFields(name);
    });
    updateSmena()
  }
}

//Получаем хлор
export let getChlorine = async (authTokens, ordering_chlorine, setter) => {
  try {
    const responses = await Promise.all(ordering_chlorine.map(item => fetchOnLink(item, authTokens)));
    setter(responses)
  } catch (error) { console.log(error) }
};


//Закрытие смены
export let closeSmena = async (e, authTokens, smenaId, WPSId, updateSmena, setLoading) => {
  e.preventDefault();
  setLoading(true)

  let response = await fetch(`${API_URL}/RsoDynamic/smena/${smenaId}/`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${authTokens.access}` },
    body: JSON.stringify({ flag_complete: true, water_pumping_station: WPSId }),
  });

  if (response.status === 200) {
    updateSmena()
    setLoading(false)
  }
};


//Отправка воды за сутки
export let addDayWater = async (e, authTokens, data, smenaId, setter, updateSmena) => {
  e.preventDefault();

  let response = await fetch(`${API_URL}/RsoDynamic/smena/${smenaId}/`, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${authTokens.access}` },
    body: JSON.stringify({ all_water: data }),
  });

  if (response.status === 200) {
    setter({})
    resetInputFields('all_water');
    updateSmena()
  }
}

export let getWPSPumps = async (authTokens, water_pumping_station_pump, setter, setFirstPump, setStatusFirstPump) => {
  try {
    const responses = await Promise.all(water_pumping_station_pump.map(item => fetchOnLink(item, authTokens)));
    setter(responses)
    setFirstPump && setFirstPump(responses?.[0]?.id)
    setStatusFirstPump && setStatusFirstPump(responses?.[0]?.status)
  } catch (error) { console.log(error) }
};

let getWPSPump = async (e, authTokens, pumpId) => {
  let response = await fetch(`${API_URL}/RSO/waterpumpingstationpump/${pumpId}/`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${authTokens.access}` },
  });
  return await response.json();
}

export let getWells = async (authTokens, prefabricate_water_pipeline, setter, setFirstWell, setStatusFirstWell) => {
  let prefabricateWaterPipelineArr
  let wells = []
  try {
    prefabricateWaterPipelineArr = await Promise.all(prefabricate_water_pipeline.map(item => fetchOnLink(item, authTokens)));
    prefabricateWaterPipelineArr.map(item => {
      item?.section_of_the_water_pipeline?.map(item1 =>
        fetchOnLink(item1, authTokens)
          .then(data => {
            data?.wells.map(item2 =>
              fetchOnLink(item2, authTokens)
                .then(data1 => {
                  wells.push(data1);
                  setter([...wells]);
                  setFirstWell && setFirstWell(wells[0].id)
                  setStatusFirstWell && setStatusFirstWell(wells[0].status)
                })
            )
          })
          .catch(error => {
            console.error('Ошибка запроса:', error);
          })
      );
    })
  } catch (error) { console.log(error) }
};

let getWell = async (e, authTokens, wellId) => {
  let response = await fetch(`${API_URL}/RSO/well/${wellId}/`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${authTokens.access}` },
  });
  return await response.json();
}

export let changeWellStatus = async (e, authTokens, wellId, status, setter, updateSmena) => {
  e.preventDefault();

  let currentWellBeforeChangeStatus = await getWell(e, authTokens, wellId);
  let response = await fetch(`${API_URL}/RSO/well/${wellId}/`, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${authTokens.access}` },
    body: JSON.stringify({ status: status }),
  });

  if (response.status === 200) {
    if (currentWellBeforeChangeStatus.status === "В ремонте") {
      finishWellRepair(e, authTokens, currentWellBeforeChangeStatus.last_well_repair.split('/')[6])
    }
    setter && setter({})
    updateSmena && updateSmena()
  }
}

export let finishWellRepair = async (e, authTokens, repairId) => {
  e.preventDefault();

  let currentDate = getNowDate();
  let response = await fetch(`${API_URL}/RSO/wellrepair/${repairId}/`, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${authTokens.access}` },
    body: JSON.stringify({ date_of_technical_inspection: currentDate }),
  });

  if (response.status === 200) {
  }
}

export let changePumpStatus = async (e, authTokens, pumpId, status, setter, updateSmena) => {
  e.preventDefault();

  let currentPumpBeforeChangeStatus = await getWPSPump(e, authTokens, pumpId);
  let response = await fetch(`${API_URL}/RSO/waterpumpingstationpump/${pumpId}/`, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${authTokens.access}` },
    body: JSON.stringify({ status: status }),
  });

  if (response.status === 200) {
    if (currentPumpBeforeChangeStatus.status === "В ремонте" && currentPumpBeforeChangeStatus.last_water_pumping_station_pump_repair.length !== 0) {
      finishPumpRepair(e, authTokens, currentPumpBeforeChangeStatus.last_water_pumping_station_pump_repair.split('/')[6])
    }
    setter && setter({})
    updateSmena && updateSmena()
  }
}

export let finishPumpRepair = async (e, authTokens, repairId) => {
  e.preventDefault();

  let currentDate = getNowDate();
  let response = await fetch(`${API_URL}/RSO/waterpumpingstationpumprepair/${repairId}/`, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${authTokens.access}` },
    body: JSON.stringify({ date_of_technical_inspection: currentDate }),
  });

  if (response.status === 200) {
  }
}

//Получение отказов скважин
export let getFailrueWells = async (authTokens, prefabricate_water_pipeline, setter) => {
  let prefabricateWaterPipelineArr
  let failrueWells = []
  try {
    prefabricateWaterPipelineArr = await Promise.all(prefabricate_water_pipeline.map(item => fetchOnLink(item, authTokens)));
    prefabricateWaterPipelineArr.map(item => {
      item?.section_of_the_water_pipeline?.map(item1 =>
        fetchOnLink(item1, authTokens)
          .then(data => {
            data?.wells.map(item2 =>
              fetchOnLink(item2, authTokens)
                .then(data1 => {
                  if (data1.status === "В ремонте") {
                    fetchOnLink(data1?.last_well_repair, authTokens)
                      .then(data2 => {
                        failrueWells.push(data2);
                        setter([...failrueWells]);
                      })
                  }
                })
            )
          })
          .catch(error => {
            console.error('Ошибка запроса:', error);
          })
      );
    })
  } catch (error) { console.log(error) }
};

//Получаем все полоанные насосы 
export let getFailruePumps = async (authTokens, water_pumping_station_pump, setter) => {
  let pumpsArr
  let failruePupms = []
  try {
    pumpsArr = await Promise.all(water_pumping_station_pump.map(item => fetchOnLink(item, authTokens)));
    pumpsArr.map(item => {
      if (item.status === "В ремонте") {
        fetchOnLink(item?.last_water_pumping_station_pump_repair, authTokens)
          .then(data => {
            failruePupms.push(data)
            setter([...failruePupms])
          })
      }
    });
  } catch (error) { console.log(error) }
};

//Отправка скважины в ремонт
export let addWellRepair = async (e, authTokens, data, setter, updateSmena, closeModal) => {
  e.preventDefault();

  let response = await fetch(`${API_URL}/RSO/wellrepair/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${authTokens.access}` },
    body: JSON.stringify({
      ...data,
    })
  });
  if (response.status === 201) {
    changeWellStatus(e, authTokens, data.wellId, data.status)
    setter({})
    let arrNames = ["wellRepairNote"]
    arrNames.forEach(name => {
      resetInputFields(name);
    });
    updateSmena()
    closeModal()
  }
}

//Отправка насоса в ремонт
export let addPumpRepair = async (e, authTokens, data, setter, updateSmena, closeModal) => {
  e.preventDefault();

  let response = await fetch(`${API_URL}/RSO/waterpumpingstationpumprepair/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${authTokens.access}` },
    body: JSON.stringify({
      ...data,
    })
  });
  if (response.status === 201) {
    changePumpStatus(e, authTokens, data.pumpId, data.status)
    setter({})
    let arrNames = ["pumpRepairNote"]
    arrNames.forEach(name => {
      resetInputFields(name);
    });
    updateSmena()
    closeModal()
  }
}

//Получаем архив
export let getArchive = async (authTokens, WPSId, date, setterHourly, setterChlorine, setterRepair) => {
  let hourly = await fetch(`${API_URL}/RsoDynamic/waterpumpingstation/${WPSId}/dispatcher/dynamicsettings/${date}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${authTokens.access}` },
  });
  if (hourly.status === 200) setterHourly(await hourly.json())

  let chlorine = await fetch(`${API_URL}/RsoDynamic/waterpumpingstation/${WPSId}/dispatcher/chlorine/${date}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${authTokens.access}` },
  });
  if (chlorine.status === 200) setterChlorine(await chlorine.json())

} 

export const getHydrologistDataMonthDate = async (authTokens, date, selectedWell, setter, datesInMonth) => {
  const params = new URLSearchParams({
    well_ids: selectedWell,
  });

  const response = await fetch(`${API_URL}/hydrologist/data/month/${date}?${params}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authTokens.access}`,
    },
  });

  const fetchData = await response.json();

  const tableData = datesInMonth.reduce((acc, dateItem) => {
    acc[dateItem] = {
      meter_readings: '',
      device_operating_time: '',
      measurement_date: '',
      reporting_capacity: '',
      hydrologist: ''
    };
    return acc;
  }, {});

  fetchData.forEach(item => {
    const convertedDate = dateConverter(item.measurement_date);
    if (tableData.hasOwnProperty(convertedDate)) {
      tableData[convertedDate] = {
        meter_readings: item.meter_readings,
        device_operating_time: item.device_operating_time,
        measurement_date: item.measurement_date,
        water_consumption: item.water_consumption,
        reporting_capacity: item.reporting_capacity,
        hydrologist: `${item.hydrologist.surname} ${item.hydrologist.name}`
      };
    }
  });

  const dataArray = Object.entries(tableData).map(([date, data]) => ({ date, ...data }));


  setter(dataArray);
}

export let changeTimeHydrologistic = async ({ authTokens, data, well_id, action, closeModal }) => {
  console.log(data);


  let response = await axios.patch(`${API_URL}/hydrologist/data/set/time/json/${well_id}`,
    data,
    {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${authTokens.access}` },
    });
  console.log(response);
  if (response.status === 200) {
    action();
  }
}