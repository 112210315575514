import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../../../../../../../../context/AuthContext";
import { useForm } from "react-hook-form";
import { worker_context } from "../../../../../../hooks/useStats_worker";
import { worker_workzone_context } from "../../../../../../hooks/useStats_worker_workzone";
import { addPumpRepair, addWellRepair, getWPSPumps, getWells } from "../../../../../../http/worker_http";
import { RSODynamicSelect } from "../../../../../../../../utils/RSODynamicSelect/RSODynamicSelect";
import { RSODynamicInput } from "../../../../../../../../utils/RSODynamicInput/RSODynamicInput";
import { RSODynamicButton } from "../../../../../../../../utils/RSODynamicButton/RSODynamicButton";
import { ModalWindow } from "../../../../../../../../../../components/ModalWindow/ModalWindow";

export let RSODynamicEquipmentFailuresInput = () => {
  let { authTokens, logoutUser } = useContext(AuthContext);
  let { handleSubmit } = useForm({ mode: 'onBlur' });

  let [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  let {
    smena,
    updateSmena
  } = useContext(worker_context)
  let { currentWps } = useContext(worker_workzone_context)

  let [selectedChangeType, setSelectedChangeType] = useState('Скважина');

  let [WPSWells, setWPSWells] = useState([]);
  let [normalWPSWells, setNormalWPSWells] = useState([]);
  let [selectedWell, setSelectedWell] = useState();
  let [wellBroken, setWellBroken] = useState('КИПиА');
  let [wellTypeOfRepair, setWellTypeOfRepair] = useState('По току');
  let [wellRepairNote, setWellRepairNote] = useState('')

  let [WPSPumps, setWpsPumps] = useState([]);
  let [normalWPSPumps, setNormalWPSPumps] = useState([]);
  let [selectedPump, setSelectedPump] = useState()
  let [pumpBroken, setPumpBroken] = useState('КИПиА');
  let [pumpTypeOfRepair, setPumpTypeOfRepair] = useState('Шум, стук');
  let [pumpRepairNote, setPumpRepairNote] = useState('')


  useEffect(() => {//useEffect для подгрузки данных
    getWells(authTokens, currentWps.prefabricate_water_pipeline, setWPSWells);
    getWPSPumps(authTokens, currentWps.water_pumping_station_pump, setWpsPumps);
    setWellBroken('КИПиА')
    setWellTypeOfRepair('По току')
    setWellRepairNote('')
    setPumpBroken('КИПиА');
    setPumpTypeOfRepair('Шум, стук')
    setPumpRepairNote('')
  }, [smena, selectedChangeType])

  useEffect(() => {//useEffect для создания удобных массивов со статусами В работе и В резерве
    let wellsArr = []
    let pumpsArr = []
    WPSWells.map(item => {
      if (item.status !== "В ремонте") {
        wellsArr.push(item)
        setNormalWPSWells([...wellsArr])
        setSelectedWell(wellsArr?.[0]?.id)

      }
    })
    WPSPumps.map(item => {
      if (item.status !== "В ремонте") {
        pumpsArr.push(item)
        setNormalWPSPumps([...pumpsArr])
        setSelectedPump(pumpsArr?.[0]?.id)
      }
    })

  }, [WPSWells, WPSPumps])

  let WellRepar = (e) => {
    e.preventDefault();
    handleOpenModal()
  }

  let PumpRepar = (e) => {
    e.preventDefault();
    handleOpenModal()
  }

  return (
    <div className="container">
      <div style={{ marginTop: '40px', display: "grid", gridTemplateColumns: "33% 33% 33%", }}>
        <RSODynamicSelect
          name='Выберите объект'
          list={[{ name: "Скважина" }, { name: "Насосная станция" }]}
          value='name'
          parse='String'
          visible_value='name'
          setter={setSelectedChangeType}
          getter={selectedChangeType}
        />

        {selectedChangeType === "Скважина" &&
          <>
            <RSODynamicSelect
              name='Выберите номер скважины'
              list={normalWPSWells}
              value='id'
              parse='int'
              visible_value='number_well'
              setter={setSelectedWell}
              getter={selectedWell}
              secondVisibleName='status'
            />
            <RSODynamicSelect
              name='Выберите неисправное устройство'
              list={[
                { title: "КИПиА" },
                { title: "Запорная арматура" },
                { title: "Агрегат насосный" },
              ]}
              value='title'
              parse='String'
              visible_value='title'
              setter={setWellBroken}
              getter={wellBroken}
            />
            <RSODynamicSelect
              name='Выберите вид отказа'
              list={[
                { title: "По току" },
                { title: "По напряжению" },
                { title: "Нет сети" },
                { title: "Сбой оборудования" },
              ]}
              value='title'
              parse='String'
              visible_value='title'
              setter={setWellTypeOfRepair}
              getter={wellTypeOfRepair}
            />
            <RSODynamicInput
              type='string'
              title='Проведенные работы'
              name='wellRepairNote'
              setter={setWellRepairNote}
              getter={wellRepairNote}
              required={false}
            />
            <form onSubmit={e => handleSubmit(WellRepar(e))} style={{ gridRow: 3 }}>
              <RSODynamicButton
                title='Сохранить'
                type='submit' />
            </form>

            <ModalWindow
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              title='Подтверждение отказа скважины'
              hook={normalWPSWells}>
              <p><b>{`Вы добавляете скважину №${normalWPSWells?.find(well => well.id === selectedWell)?.number_well} в ремонт. Будут переданы следующие данные:`}</b></p>
              <ul>
                <li>{`1) Неисправное устройство - ${wellBroken}`}</li>
                <li>{`2) Вид отказа - ${wellTypeOfRepair}`}</li>
                {wellRepairNote &&
                  <li>{`3) Проведенные работы - ${wellRepairNote}`}</li>}
              </ul>

              <form className='create__smena-btn' onSubmit={e => handleSubmit(addWellRepair(e, authTokens, { well: selectedWell, broken: wellBroken, type_of_repair: wellTypeOfRepair, note: wellRepairNote, shift_number: smena[0].shift_number, fullname: smena[0].fullname, wellId: selectedWell, status: "В ремонте" }, setWellRepairNote, updateSmena, handleCloseModal))}>
                <RSODynamicButton title='Подтвердить' type='submit' />
              </form>
            </ModalWindow>
          </>
        }
        {selectedChangeType === "Насосная станция" &&
          <>
            <RSODynamicSelect
              name='Выберите номер насоса'
              list={normalWPSPumps}
              value='id'
              parse='int'
              visible_value='number_of_line'
              setter={setSelectedPump}
              getter={selectedPump}
              secondVisibleName='status'
            />
            <RSODynamicSelect
              name='Выберите неисправное устройство'
              list={[
                { title: "КИПиА" },
                { title: "Запорная арматура" },
                { title: "Агрегат насосный" },
              ]}
              value='title'
              parse='String'
              visible_value='title'
              setter={setPumpBroken}
              getter={pumpBroken}
            />
            <RSODynamicSelect
              name='Выберите вид отказа'
              list={[
                { title: "Шум, стук" },
                { title: "Вибрация повыш." },
                { title: "Температура > 70гр." },
                { title: "Дым, изоляция" },
                { title: "Мех. повреждения" },
                { title: "Несчастный случай" },
              ]}
              value='title'
              parse='String'
              visible_value='title'
              setter={setPumpTypeOfRepair}
              getter={pumpTypeOfRepair}
            />
            <RSODynamicInput
              type='string'
              title='Проведенные работы'
              name='pumpRepairNote'
              setter={setPumpRepairNote}
              getter={pumpRepairNote}
              required={false}
            />
            <form onSubmit={e => handleSubmit(WellRepar(e))} style={{ gridRow: 3 }}>
              <RSODynamicButton
                title='Сохранить'
                type='submit' />
            </form>

            <ModalWindow
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              title='Подтверждение отказа насоса'
              hook={normalWPSWells}>
              <p><b>{`Вы добавляете насос №${normalWPSPumps?.find(pump => pump.id === selectedPump)?.number_of_line} в ремонт. Будут переданы следующие данные:`}</b></p>
              <ul>
                <li>{`1) Неисправное устройство - ${pumpBroken}`}</li>
                <li>{`2) Вид отказа - ${pumpTypeOfRepair}`}</li>
                {pumpRepairNote &&
                  <li>{`3) Проведенные работы - ${pumpRepairNote}`}</li>}
              </ul>

              <form className='create__smena-btn' onSubmit={e => handleSubmit(addPumpRepair(e, authTokens, { water_pumping_station_pump: selectedPump, broken: pumpBroken, type_of_repair: pumpTypeOfRepair, note: pumpRepairNote, shift_number: smena[0].shift_number, fullname: smena[0].fullname, pumpId: selectedPump, status: "В ремонте" }, setPumpRepairNote, updateSmena, handleCloseModal))}>
                <RSODynamicButton title='Подтвердить' type='submit' />
              </form>
            </ModalWindow>
          </>
        }
      </div>
    </div>
  )
}