import React, { useState, useEffect, useContext } from 'react';
import { API_URL } from '../../../../../config';
import AuthContext from '../../../../../context/AuthContext';
import './AddressSearch.css';

const AddressSearch = ({onAddressSelected }) => {
  const [addresses, setAddresses] = useState([]); // Список адресов
  const [filteredAddresses, setFilteredAddresses] = useState([]); // Отфильтрованный список адресов
  const [selectedAddress, setSelectedAddress] = useState(''); // Выбранный адрес
  const [houseId, setHouseId] = useState(null); // ID здания
  const [loading, setLoading] = useState(true); // Флаг загрузки данных
  const { authTokens } = useContext(AuthContext); // Получение токена
  const [searchTerm, setSearchTerm] = useState(''); // Текст для поиска адресов

  // Запрос для получения всех адресов
  useEffect(() => {
    fetch(`${API_URL}/CivilWear/House/getAllAddresses/`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authTokens.access}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {
        const addressesArray = Object.values(data); // Преобразование объекта в массив
        setAddresses(addressesArray); // Установка полученных адресов
        setFilteredAddresses(addressesArray); // Изначально отображаем все адреса
        setLoading(false); // Остановка индикатора загрузки
      })
      .catch(error => {
        console.error('Ошибка при получении адресов:', error);
        setLoading(false); // Остановка индикатора загрузки при ошибке
      });
  }, [authTokens]);

  // Функция для обработки нажатия на адрес
  const handleAddressClick = (address) => {
    setSelectedAddress(address);

    // Запрос для получения ID по выбранному адресу
    fetch(`${API_URL}/CivilWear/House/findHouseIdByAddress/${address}/`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authTokens.access}`, // Добавление токена в заголовок
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {
        setHouseId(data.id); // Установка полученного ID
        localStorage.setItem("ID_House", data.id); // Сохраняем ID здания в localStorage
        console.log(`Выбранный адрес: ${address}`); // Выводим выбранный адрес в консоль
        console.log(`ID здания: ${data.id}`); // Выводим ID здания в консоль
        onAddressSelected(); // Переход в добавление износа
      })
      .catch(error => {
        console.error('Ошибка при получении ID здания:', error);
        alert('Произошла ошибка при получении ID здания.');
      });
  };

  // Функция для фильтрации адресов по вводу
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    const filtered = addresses.filter(address =>
      address.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredAddresses(filtered);
  };

  return (
    <div className="address-container">
      <h2>Выберите адрес здания:</h2>

      <input
        type="text"
        placeholder="Введите адрес"
        value={searchTerm}
        onChange={handleSearchChange}
        className="address-search-input"
      />

      {loading ? (
        <p className="loading-message">Загрузка адресов...</p>
      ) : filteredAddresses.length === 0 ? (
        <p className="no-addresses-message">Адреса не найдены.</p>
      ) : (
        <ul className="address-list">
          {filteredAddresses.map((address, index) => (
            <li key={index} className="address-item" onClick={() => handleAddressClick(address)}>
              {address}
            </li>
          ))}
        </ul>
      )}

      {houseId && (
        <div className="result">
          <p>Выбранный адрес: <strong>{selectedAddress}</strong></p>
          <p>ID здания: <strong>{houseId}</strong></p>
        </div>
      )}
    </div>
  );
};

export default AddressSearch;
