import React, { useState, useEffect } from "react";

import "../ResidentialHouse.css";

export const ResidentialWindow = ({ formDataResidential, onDataChange }) => {
  const [windowRecords, setWindowRecords] = useState([]); // Записи окон
  const [selectedSize, setSelectedSize] = useState(''); // Выбранный размер
  const [customWidth, setCustomWidth] = useState(''); // Ширина проема
  const [customHeight, setCustomHeight] = useState(''); // Высота проема
  const [quantity, setQuantity] = useState(''); // Количество оконных проемов
  const [totalArea, setTotalArea] = useState(0); // Общая площадь

  // Обработчик изменения выбора из списка
  const handleSelectChange = (e) => {
    setSelectedSize(e.target.value);
    setCustomWidth('');
    setCustomHeight('');
  };

  // Обработчик изменения ширины проема
  const handleWidthChange = (e) => {
    setCustomWidth(e.target.value);
    setSelectedSize('custom');
  };

  // Обработчик изменения высоты проема
  const handleHeightChange = (e) => {
    setCustomHeight(e.target.value);
    setSelectedSize('custom');
  };

  // Обработчик изменения количества проемов
  const handleQuantityChange = (e) => {
    setQuantity(e.target.value);
  };

  // Добавление новой записи в таблицу
  const handleAddWindowRecord = () => {
    let width, height;

    // Если выбран стандартный размер
    if (selectedSize !== 'custom') {
      [width, height] = selectedSize.split('x').map(Number);
    } else {
      // Если пользователь вводит произвольный размер
      width = parseFloat(customWidth);
      height = parseFloat(customHeight);
    }

    const areaPerWindow = (width * height) / 1000000; // Площадь одного окна в м²
    const windowArea = areaPerWindow * quantity; // Общая площадь для этой записи

    const newRecord = {
      size: selectedSize !== 'custom' ? `${width} × ${height} мм` : `Произвольный: ${width} × ${height} мм`,
      quantity: parseInt(quantity, 10),
      totalArea: windowArea.toFixed(2) + " м²", // Общая площадь с округлением
    };

    // Добавляем новую запись
    const updatedRecords = [...windowRecords, newRecord];
    setWindowRecords(updatedRecords);

    // Обновляем общую площадь
    const newTotalArea = updatedRecords.reduce((sum, record) => sum + parseFloat(record.totalArea), 0);
    setTotalArea(newTotalArea.toFixed(2) + " м²");

    // Обновляем formDataResidential
    onDataChange({
      ...formDataResidential,
      totalareawindow: newTotalArea.toFixed(2) // Записываем значение в formDataResidential
    });

    // Сброс полей ввода
    setSelectedSize('');
    setCustomWidth('');
    setCustomHeight('');
    setQuantity('');
  };

  useEffect(() => {
    // Устанавливаем начальное значение общей площади при изменении formDataResidential
    if (formDataResidential.totalareawindow) {
      setTotalArea(formDataResidential.totalareawindow);
    }
  }, [formDataResidential]);

  return (
    <div className="Residential_Window_Main">
      <div className="Residential_Window_Input_Field_Group">
        <label className="Residential_Window_Label" htmlFor="windowSize">
          Размер оконного проема:
        </label>
        <select
          className="Residential_Window_Select_Field"
          id="windowSize"
          value={selectedSize}
          onChange={handleSelectChange}
        >
          <option value="">Выбрать размер</option>
          <option value="850x1150">850 × 1150 мм</option>
          <option value="1150x1900">1150 × 1900 мм</option>
          <option value="1300x2200">1300 × 2200 мм</option>
          <option value="1450x1500">1450 × 1500 мм</option>
          <option value="2400x2100">2400 × 2100 мм</option>
          <option value="custom">Добавить свой размер</option>
        </select>
      </div>

      {selectedSize === 'custom' && (
        <div className="Residential_Window_Custom_Size">
          <label className="Residential_Window_Label" htmlFor="customWidth">
            Ширина проема (мм):
          </label>
          <input
            className="Residential_Window_Input_Field"
            type="number"
            id="customWidth"
            placeholder="Введите ширину"
            value={customWidth}
            onChange={handleWidthChange}
            required
          />

          <label className="Residential_Window_Label" htmlFor="customHeight">
            Высота проема (мм):
          </label>
          <input
            className="Residential_Window_Input_Field"
            type="number"
            id="customHeight"
            placeholder="Введите высоту"
            value={customHeight}
            onChange={handleHeightChange}
            required
          />
        </div>
      )}

      <div className="Residential_Window_Input_Field_Group">
        <label className="Residential_Window_Label" htmlFor="quantity">
          Количество оконных проемов:
        </label>
        <input
          className="Residential_Window_Input_Field"
          type="number"
          id="quantity"
          placeholder="Введите количество"
          value={quantity}
          onChange={handleQuantityChange}
          required
        />
      </div>

      <button
        className="Residential_Window_Add_Button"
        onClick={handleAddWindowRecord}
      >
        Добавить проем
      </button>

      {/* Таблица с добавленными записями */}
      <div className="Residential_Window_Table">
        <h3>Таблица оконных проемов</h3>
        <table>
          <thead>
            <tr>
              <th>Размер оконного проема</th>
              <th>Количество</th>
              <th>Общая площадь (м²)</th>
            </tr>
          </thead>
          <tbody>
            {windowRecords.map((record, index) => (
              <tr key={index}>
                <td>{record.size}</td>
                <td>{record.quantity}</td>
                <td>{record.totalArea}</td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Отображение общей площади */}
        <div className="Residential_Window_Total_Area">
          <h4>Всего:</h4>
          <p>{totalArea}</p>
        </div>
      </div>
    </div>
  );
};
