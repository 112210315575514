import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import AuthContext from "../../../../../../../../../../context/AuthContext";
import { worker_context } from "../../../../../../hooks/useStats_worker";
import { worker_workzone_context } from "../../../../../../hooks/useStats_worker_workzone";
import { ModelTable } from "../../../../../../../../../../components/ModelTable/ModelTable";
import { changePumpStatus, changeWellStatus, finishWellRepair, getWPSPumps, getWells } from "../../../../../../http/worker_http";
import { RSODynamicSelect } from "../../../../../../../../utils/RSODynamicSelect/RSODynamicSelect";
import { RSODynamicButtonGroup } from "../../../../../../../../utils/RSODynamicButtonGroup/RSODynamicButtonGroup";
import { RSODynamicButton } from "../../../../../../../../utils/RSODynamicButton/RSODynamicButton";
import { ModalWindow } from "../../../../../../../../../../components/ModalWindow/ModalWindow";

export let RSODynamicChangeStatus = () => {
  let { authTokens, logoutUser } = useContext(AuthContext);
  let { handleSubmit, reset } = useForm({ mode: 'onBlur' });

  let [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  let {
    smena,
    updateSmena
  } = useContext(worker_context)
  let { currentWps } = useContext(worker_workzone_context)

  let [WPSPumps, setWpsPumps] = useState([]);
  let [WPSWells, setWPSWells] = useState([]);
  let [selectedChangeType, setSelectedChangeType] = useState('Скважина');

  let [selectedWell, setSelectedWell] = useState(WPSWells?.[0]?.id)
  let [selectedStatusWell, setSelectedStatusWell] = useState('')
  let [newStatusWell, setNewStatusWell] = useState('');//Новый статус для скажин, устанавливаемый пользователем
  let [selectedPump, setSelectedPump] = useState(WPSPumps?.[0]?.id)
  let [selectedStatusPump, setSelectedStatusPump] = useState('')
  let [newStatusPump, setNewStatusPump] = useState('');//Новый статус для насосов, устанавливаемый пользователем

  useEffect(() => {//useEffect для подгрузки данных
    getWells(authTokens, currentWps.prefabricate_water_pipeline, setWPSWells, setSelectedWell, setSelectedStatusWell);
    getWPSPumps(authTokens, currentWps.water_pumping_station_pump, setWpsPumps, setSelectedPump, setSelectedStatusPump)
  }, [smena, selectedChangeType])

  useEffect(() => {//useEffect для обновления статуса скважин и насосов при выборе других
    setSelectedStatusWell(WPSWells?.find(well => well.id === selectedWell)?.status)
    setSelectedStatusPump(WPSPumps?.find(pump => pump.id === selectedPump)?.status)
    setNewStatusWell('')
    setNewStatusPump('')
  }, [selectedWell, selectedPump])

  let renderPumps = (pumps, status) => (
    pumps
      ?.filter(pump => pump.status === status)
      .map((pump, index) => (
        <span key={index}>
          {pump.number_of_line}
          {(index + 1) % 3 === 0 && <br />}
          {(index + 1) % 3 !== 0 && "; "}
        </span>
      ))
  );

  let renderWells = (wells, status) => (
    wells
      ?.filter(well => well.status === status)
      .map((well, index) => (
        <span key={index}>
          {well.number_well}
          {(index + 1) % 3 === 0 && <br />}
          {(index + 1) % 3 !== 0 && "; "}
        </span>
      ))
  );

  let changeStatusWell = (e) => {
    e.preventDefault();
    handleOpenModal()
  }

  let changeStatusPump = (e) => {
    e.preventDefault();
    handleOpenModal()
  }

  return (
    <div className="container">
      <ModelTable>
        <thead>
          <tr>
            <th colSpan='6'>{`Информация о работе оборудования ВЗУ ${currentWps?.name}`}</th>
          </tr>
          <tr>
            <th colSpan='3'>Насосы, машинный зал, N</th>
            <th colSpan='3'>Скважины, N</th>
          </tr>
          <tr>
            <th>В работе</th>
            <th>В резерве</th>
            <th>В ремонте</th>
            <th>В работе</th>
            <th>В резерве</th>
            <th>В ремонте</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{renderPumps(WPSPumps, "В работе")}</td>
            <td>{renderPumps(WPSPumps, "В резерве")}</td>
            <td>{renderPumps(WPSPumps, "В ремонте")}</td>
            <td>{renderWells(WPSWells, "В работе")}</td>
            <td>{renderWells(WPSWells, "В резерве")}</td>
            <td>{renderWells(WPSWells, "В ремонте")}</td>
          </tr>
        </tbody>
      </ModelTable>

      <div style={{ marginTop: '40px', display: "grid", gridTemplateColumns: "33% 33% 33%", }}>
        <RSODynamicSelect
          name='Выберите объект'
          list={[{ name: "Скважина" }, { name: "Насосная станция" }]}
          value='name'
          parse='String'
          visible_value='name'
          setter={setSelectedChangeType}
          getter={selectedChangeType}
        />

        {selectedChangeType === "Скважина" &&
          <>
            <RSODynamicSelect
              name='Выберите номер скважины'
              list={WPSWells}
              value='id'
              parse='int'
              visible_value='number_well'
              setter={setSelectedWell}
              getter={selectedWell}
              secondVisibleName='status'
            />
            <div>
              <p style={{ fontSize: "18px", fontWeight: "bold", color: "#1f487e", marginBottom: "0.5em" }}>Выберите статус:</p>
              <RSODynamicButtonGroup
                buttons={[
                  { title: "В работе" },
                  { title: "В резерве" }]}
                setter={setNewStatusWell}
                getter={newStatusWell}
                myStyle={{ marginBottom: '0', display: "block" }}
              />
            </div>
            {newStatusWell &&
              <>
                <form onSubmit={e => handleSubmit(changeStatusWell(e))}>
                  <RSODynamicButton
                    title='Сменить статус'
                    type='submit' />
                </form>
                <ModalWindow
                  isOpen={isModalOpen}
                  onClose={handleCloseModal}
                  title='Подтверждение смены статуса'
                  hook={newStatusWell}>
                  <p><b>{`Вы меняете статус скважины №${WPSWells?.find(well => well.id === selectedWell)?.number_well}`}</b></p>
                  <ul>
                    <li>{`Вы собираетесь сменить статус скважины с [${selectedStatusWell}] на [${newStatusWell}]`}</li>
                    <br />
                    {selectedStatusWell === newStatusWell &&
                      <li><i>*Вы установили тот же самый статус, при подтверждении ничего не изменится</i></li>
                    }
                  </ul>

                  <form className='create__smena-btn' onSubmit={e => handleSubmit( changeWellStatus(e, authTokens, selectedWell, newStatusWell, setNewStatusWell, updateSmena))}>
                    <RSODynamicButton title='Подтвердить' type='submit' />
                  </form>
                </ModalWindow>
              </>
            }
          </>
        }

        {selectedChangeType === "Насосная станция" &&
          <>
            <RSODynamicSelect
              name='Выберите номер насоса'
              list={WPSPumps}
              value='id'
              parse='int'
              visible_value='number_of_line'
              setter={setSelectedPump}
              getter={selectedPump}
              secondVisibleName='status'
            />
            <div>
              <p style={{ fontSize: "18px", fontWeight: "bold", color: "#1f487e", marginBottom: "0.5em" }}>Выберите статус:</p>
              <RSODynamicButtonGroup
                buttons={[
                  { title: "В работе" },
                  { title: "В резерве" }]}
                setter={setNewStatusPump}
                getter={newStatusPump}
                myStyle={{ marginBottom: '0', display: "block" }}
              />
            </div>
            {newStatusPump &&
              <>
                <form onSubmit={e => handleSubmit(changeStatusPump(e))}>
                  <RSODynamicButton
                    title='Сменить статус'
                    type='submit' />
                </form>
                <ModalWindow
                  isOpen={isModalOpen}
                  onClose={handleCloseModal}
                  title='Подтверждение смены статуса'
                  hook={newStatusPump}>
                  <p><b>{`Вы меняете статус насоса №${WPSPumps?.find(pump => pump.id === selectedPump)?.number_of_line}`}</b></p>
                  <ul>
                    <li>{`Вы собираетесь сменить статус насоса с [${selectedStatusPump}] на [${newStatusPump}]`}</li>
                    <br />
                    {selectedStatusPump === newStatusPump &&
                      <li><i>*Вы установили тот же самый статус, при подтверждении ничего не изменится</i></li>
                    }
                  </ul>

                  <form className='create__smena-btn' onSubmit={e => handleSubmit(changePumpStatus(e, authTokens, selectedPump, newStatusPump, setNewStatusPump, updateSmena))}>
                    <RSODynamicButton title='Подтвердить' type='submit' />
                  </form>
                </ModalWindow>
              </>
            }
          </>
        }
      </div>
    </div >
  )
}