import './RSODynamic_dynamic_values.scss'
import { useContext } from 'react'
import { RSODynamicButtonGroup } from '../../../../../../utils/RSODynamicButtonGroup/RSODynamicButtonGroup'
import { worker_workzone_context } from '../../../../hooks/useStats_worker_workzone'
import { worker_context } from '../../../../hooks/useStats_worker'
import { RSODynamicValuesValuesInput } from './components/RSODynamicValuesValuesInput/RSODynamicValuesValuesInput'
import { RSODynamicValuesValuesPaint } from './components/RSODynamicValuesValuesPaint/RSODynamicValuesValuesPaint'
import { RSODynamicDayWater } from './components/RSODynamicDayWater/RSODynamicDayWater'
import { RSODynamicChangeStatus } from './components/RSODynamicChangeStatus/RSODynamicChangeStatus'

export let RSODynamic_dynamic_values = () => {

  let {
    hourlyRatesTab,
    setHourlyRatesTab
  } = useContext(worker_workzone_context)

  let { smena } = useContext(worker_context)

  return (
    <>
      <RSODynamicButtonGroup
        buttons={[
          { title: 'Вывод почасовых показателей' },
          { title: 'Ввод почасовых показателей' },
          { title: 'Смена статуса' },
          smena[0].shift_number === 2 ? { title: 'Ввод воды за сутки' } : null
        ]}
        setter={setHourlyRatesTab}
        getter={hourlyRatesTab}
        color='green' />

      {hourlyRatesTab === "Вывод почасовых показателей" && (
        <RSODynamicValuesValuesPaint />)}
      {hourlyRatesTab === "Ввод почасовых показателей" && (
        <RSODynamicValuesValuesInput />)}
      {hourlyRatesTab === "Смена статуса" && (
        <RSODynamicChangeStatus />)}
      {hourlyRatesTab === "Ввод воды за сутки" && (
        <RSODynamicDayWater />)}
    </>
  )
}