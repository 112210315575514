import { useEffect, useState, React } from "react"
import { useContext, createContext } from "react";
import AuthContext from "../../../../../context/AuthContext";
import { worker_context } from "./useStats_worker";
import {
  getWPSId
} from "../http/worker_http"

export let worker_workzone_context = createContext();

export let WorkerWorkzoneProvider = ({ children }) => {

  let { authTokens, logoutUser } = useContext(AuthContext);
  let [currentWps, setCurrentWps] = useState([]);
  let [activeTab, setActiveTab] = useState('Почасовые показатели');//Меню выюора у работника ВЗУ
  let [equipmentFailuresTab, setEquipmentFailuresTab] = useState('Вывод отказов оборудования')//Меню выбора при выборе отказов оборудования
  let [hourlyRatesTab, setHourlyRatesTab] = useState('Ввод почасовых показателей');//Меню выбора при выборе почасовых показателей
  let [chlorineTab, setChlorineTab] = useState('Вывод хлора');//Меню выбора при выборе хлора
  let [archiveTab, setArchiveTab] = useState('Архив почасовых показаний')

  let {
    smena
  } = useContext(worker_context)

  useEffect(() => {
    getWPSId(authTokens, setCurrentWps, smena?.[0]?.water_pumping_station)
  }, [])

  return (
    <worker_workzone_context.Provider
      value={{
        currentWps,
        activeTab,
        hourlyRatesTab,
        chlorineTab,
        equipmentFailuresTab,
        archiveTab,
        setActiveTab,
        setHourlyRatesTab,
        setChlorineTab,
        setEquipmentFailuresTab, 
        setArchiveTab,
      }} >
      {children}
    </worker_workzone_context.Provider >
  )
}