import React, { useState, useEffect } from "react";

import "../ResidentialHouse.css";

export const ResidentialDoor = ({ formDataResidential, onDataChange }) => {
  const [DoorRecords, setDoorRecords] = useState([]); // Записи окон
  const [selectedSize, setSelectedSize] = useState(''); // Выбранный размер
  const [customWidth, setCustomWidth] = useState(''); // Ширина дверь
  const [customHeight, setCustomHeight] = useState(''); // Высота двери
  const [quantity, setQuantity] = useState(''); // Количество подъездов
  const [totalArea, setTotalArea] = useState(0); // Общая площадь

  // Обработчик изменения выбора из списка
  const handleSelectChange = (e) => {
    setSelectedSize(e.target.value);
    setCustomWidth('');
    setCustomHeight('');
  };

  // Обработчик изменения ширины проема
  const handleWidthChange = (e) => {
    setCustomWidth(e.target.value);
    setSelectedSize('custom');
  };

  // Обработчик изменения высоты проема
  const handleHeightChange = (e) => {
    setCustomHeight(e.target.value);
    setSelectedSize('custom');
  };

  // Обработчик изменения количества проемов
  const handleQuantityChange = (e) => {
    setQuantity(e.target.value);
  };

  // Добавление новой записи в таблицу
  const handleAddDoorRecord = () => {
    let width, height;

    // Если выбран стандартный размер
    if (selectedSize !== 'custom') {
      [width, height] = selectedSize.split('x').map(Number);
    } else {
      // Если пользователь вводит произвольный размер
      width = parseFloat(customWidth);
      height = parseFloat(customHeight);
    }

    const areaPerDoor = (width * height) / 1000000; // Площадь одного окна в м²
    const DoorArea = areaPerDoor * quantity; // Общая площадь для этой записи

    const newRecord = {
      size: selectedSize !== 'custom' ? `${width} × ${height} мм` : `Произвольный: ${width} × ${height} мм`,
      quantity: parseInt(quantity, 10),
      totalArea: DoorArea.toFixed(2) + " м²", // Общая площадь с округлением
    };

    // Добавляем новую запись
    const updatedRecords = [...DoorRecords, newRecord];
    setDoorRecords(updatedRecords);

    // Обновляем общую площадь
    const newTotalArea = updatedRecords.reduce((sum, record) => sum + parseFloat(record.totalArea), 0);
    setTotalArea(newTotalArea.toFixed(2) + " м²");

    // Обновляем formDataResidential
    onDataChange({
      ...formDataResidential,
      totalaredoor: newTotalArea.toFixed(2) // Записываем значение в formDataResidential
    });

    // Сброс полей ввода
    setSelectedSize('');
    setCustomWidth('');
    setCustomHeight('');
    setQuantity('');
  };

  useEffect(() => {
    // Устанавливаем начальное значение общей площади при изменении formDataResidential
    if (formDataResidential.totalaredoor) {
      setTotalArea(formDataResidential.totalaredoor);
    }
  }, [formDataResidential]);

  return (
    <div className="Residential_Door_Main">
      <div className="Residential_Door_Input_Field_Group">
        <label className="Residential_Door_Label" htmlFor="DoorSize">
          Размер дверного проема:
        </label>
        <select
          className="Residential_Door_Select_Field"
          id="DoorSize"
          value={selectedSize}
          onChange={handleSelectChange}
        >
          <option value="">Выбрать размер</option>
          <option value="1200x2200">1200 * 2200 мм</option>
          <option value="1800x2200">1800 × 2200 мм</option>
          <option value="custom">Добавить свой размер</option>
        </select>
      </div>

      {selectedSize === 'custom' && (
        <div className="Residential_Door_Custom_Size">
          <label className="Residential_Door_Label" htmlFor="customWidth">
            Ширина проема (мм):
          </label>
          <input
            className="Residential_Door_Input_Field"
            type="number"
            id="customWidth"
            placeholder="Введите ширину"
            value={customWidth}
            onChange={handleWidthChange}
            required
          />

          <label className="Residential_Door_Label" htmlFor="customHeight">
            Высота проема (мм):
          </label>
          <input
            className="Residential_Door_Input_Field"
            type="number"
            id="customHeight"
            placeholder="Введите высоту"
            value={customHeight}
            onChange={handleHeightChange}
            required
          />
        </div>
      )}

      <div className="Residential_Door_Input_Field_Group">
        <label className="Residential_Door_Label" htmlFor="quantity">
          Количество подъездов:
        </label>
        <input
          className="Residential_Door_Input_Field"
          type="number"
          id="quantity"
          placeholder="Введите количество"
          value={quantity}
          onChange={handleQuantityChange}
          required
        />
      </div>

      <button
        className="Residential_Door_Add_Button"
        onClick={handleAddDoorRecord}
      >
        Добавить проем
      </button>

      {/* Таблица с добавленными записями */}
      <div className="Residential_Door_Table">
        <h3>Таблица дверных проемов</h3>
        <table>
          <thead>
            <tr>
              <th>Размер дверного проема</th>
              <th>Количество подъездов</th>
              <th>Общая площадь (м²)</th>
            </tr>
          </thead>
          <tbody>
            {DoorRecords.map((record, index) => (
              <tr key={index}>
                <td>{record.size}</td>
                <td>{record.quantity}</td>
                <td>{record.totalArea}</td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Отображение общей площади */}
        <div className="Residential_Door_Total_Area">
          <h4>Всего:</h4>
          <p>{totalArea}</p>
        </div>
      </div>
    </div>
  );
};
