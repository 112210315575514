import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../../../../../../../context/AuthContext";
import { useForm } from "react-hook-form";
import { sumTime, sumWaterConsumption } from "../../../../../../../../Hydrologist/utils/utils";
import { HydrologistButton } from "../../../../../../../../Hydrologist/utils/HydrologistButton/HydrologistButton";
import { ModalWindow } from "../../../../../../../../../components/ModalWindow/ModalWindow";
import { changeTimeHydrologistic } from "../../../../../http/worker_http";


export const RSODynamicArchiveEditTable = ({ data, action, wells, selectedWell }) => {
  const { authTokens } = useContext(AuthContext);

  const [tableData, setTableData] = useState([]);
  const [editedData, setEditedData] = useState([]);

  useEffect(() => {
    let buffer = data.map((item, index) => {
      let day_split = item.device_operating_time.split(' ');
      let time_split = item.device_operating_time.split(':');
      return {
        ...item,
        device_operating_time_hours: day_split.length > 1 ? '24' : time_split[0],
        device_operating_time_minutes: day_split.length > 1 ? '00' : time_split[1],
      };
    });

    setTableData(buffer);
    setEditedData(buffer);
  }, [data]);

  const handleEdit = (index, field, value) => {
    setEditedData(editedData.map((item, id) => {
      if (id === index) {
        return { ...item, [field]: value }
      } else {
        return item
      }
    }))
  };


  const compareData = (e) => {
    e.preventDefault()
    let comparedData = editedData.map((item, index) => {
      if (
        ((item.device_operating_time_hours !== tableData[index].device_operating_time_hours || item.device_operating_time_minutes !== tableData[index].device_operating_time_minutes))
      ) {
        return {
          date: item.date.replace(/^(\d{2})\.(\d{2})\.(\d{4})$/, '$3-$2-$1'),
          device_operating_time: `${(item.device_operating_time_hours + ':' + item.device_operating_time_minutes + ':00')}`,
        }
      }
    }).filter(item => item !== undefined)


    if (comparedData.length) {
      changeTimeHydrologistic({ authTokens: authTokens, data: comparedData, well_id: selectedWell, action: action })
    }
  };

  return (
    <div>
      {/* <form onSubmit={e => handleOpenModal(e)}> */}
      <form onSubmit={e => console.log(compareData(e))}>
        <table className='ModelTable hydrologistWorkTable'>
          <thead>
            <tr>
              <th colSpan='1' rowSpan='2'>Дата</th>
              <th colSpan='1' rowSpan='2'>Показания <br />измерительного <br /> прибора</th>
              <th colSpan='1' rowSpan='2'>Время работы <br /> измерительного <br /> прибора <br /> (сохраненное)</th>
              <th colSpan='2' rowSpan='1'>Время работы <br /> измерительного <br /> прибора <br /> (редактируемое)</th>
              <th colSpan='1' rowSpan='2'>Расход воды <br /> м³/сут.</th>
              <th colSpan='1' rowSpan='2'>Производительность <br /> м³/час</th>
              <th colSpan='1' rowSpan='2'>Оператор ввода</th>
            </tr>
            <tr>
              <th>Часы</th>
              <th>Минуты</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, index) => (
              <tr key={index}>
                <td>{row.date}</td>
                <td>
                  {tableData[index].meter_readings}
                </td>
                <td>
                  {tableData[index].device_operating_time.split(' ').length > 1 ? '24:00:00' : tableData[index].device_operating_time}
                </td>
                <td>
                  {tableData[index].meter_readings !== '' &&
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <input
                        type="number"
                        style={{ maxWidth: '50px', textAlign: 'center' }}
                        disabled={tableData[index].meter_readings === ''}
                        readOnly={tableData[index].meter_readings === ''}
                        value={editedData[index].device_operating_time_hours}
                        onChange={(e) => {
                          let timeString = e.target.value; // Получаем значение времени
                          if (parseInt(timeString) > 24) {
                            timeString = '24';
                          }
                          handleEdit(index, 'device_operating_time_hours', timeString); // Отправляем значение с секундами для обработки                
                        }}
                        max={24}
                      />
                    </div>
                  }
                </td>
                <td>
                  {tableData[index].meter_readings !== '' &&
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <input
                        type="number"
                        style={{ maxWidth: '50px', textAlign: 'center' }}
                        disabled={tableData[index].meter_readings === ''}
                        readOnly={tableData[index].meter_readings === ''}
                        value={editedData[index].device_operating_time_minutes}
                        onChange={(e) => {
                          let timeString = e.target.value; // Получаем значение времени
                          if (parseInt(timeString) > 60) {
                            timeString = '60';
                          }
                          handleEdit(index, 'device_operating_time_minutes', timeString); // Отправляем значение с секундами для обработки                
                        }}
                        max={60}
                      />
                    </div>
                  }
                </td>

                <td>
                  {tableData[index].water_consumption}
                </td>
                <td>
                  {tableData[index].reporting_capacity}
                </td>
                <td>
                  {tableData[index].hydrologist}
                </td>
              </tr>
            ))}
            <tr>
              <td></td>
              <td><b>Итого за месяц:</b></td>
              <td><b>{sumTime(tableData)}</b></td>
              <td></td><td></td>
              <td><b>{sumWaterConsumption(tableData)}</b></td>
            </tr>
          </tbody>
        </table>
        <HydrologistButton
          title='Сохранить'
          type='submit' />
      </form>
    </div>
  );
};
