import React from "react";

import "./ButtonShowWear.css"

export const TeorElementWear = () => {

  const tableData = [
    ["Теоретический износ элементов здания"],
    ["В работе",],
  ];

  return (
    <div className="container_ButtonShowWear">
      <table className="table_ButtonShowWear">
        <thead className="thead_ButtonShowWear">
          <tr className="tr_ButtonShowWear">
            {tableData[0].map((header, index) => (
              <th
                key={index}
                className="th_ButtonShowWear"
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="tbody_ButtonShowWear">
          {tableData.slice(1).map((row, rowIndex) => (
            <tr key={rowIndex} className="tr_ButtonShowWear">
              {row.map((cell, cellIndex) => (
                <td
                  key={cellIndex}
                  className="td_ButtonShowWear"
                >
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
