import { useContext, useEffect, useState } from "react"
import { getWPS, getWPSId } from '../../http/dispatcherHttp'
import AuthContext from "../../../../../../context/AuthContext";
import { RSODynamicSelect } from "../../../../utils/RSODynamicSelect/RSODynamicSelect";
import Jelly from "../../../../../../components/Jelly";
import { RSODynamicNotFound } from "../../../../utils/RSODynamicNotFound/RSODynamicNotFound";

export let RSODynamicDispDynamicDetailWPS = () => {

  let { authTokens } = useContext(AuthContext)

  let [selectedWPS, setSelectedWPS] = useState();
  let [WPS, setWPS] = useState([])
  let [selectedWPSData, setSelectedWPSData] = useState()

  useEffect(() => {//Получаем все ВЗУ
    getWPS(authTokens, setWPS)
  }, [])

  useEffect(() => {
    setSelectedWPS(WPS?.[0]?.id)
  }, [WPS])

  useEffect(() => {
    getWPSId(authTokens, setSelectedWPSData, selectedWPS)
  }, [selectedWPS])

  return (
    <div className="container">
      {(WPS.length !== 0 && selectedWPSData && selectedWPS) ?
        <>
          <RSODynamicSelect
            name='Выберите ВЗУ'
            list={WPS}
            value='id'
            parse='int'
            visible_value='name'
            setter={setSelectedWPS}
            getter={selectedWPS}
          />
          <>
            {selectedWPSData?.scheme && (
              <a className="WPSInfo__link" href={selectedWPSData.scheme} target="_blank">
                <div className="card">
                  <span>Схема</span>
                </div>
              </a>
            )}
            {selectedWPSData?.technical_reglament && (
              <a className="WPSInfo__link" href={selectedWPSData.technical_reglament} target="_blank">
                <div className="card">
                  <span>Технический регламент</span>
                </div>
              </a>
            )}
            {selectedWPSData?.excel_info && (
              <a className="WPSInfo__link" href={selectedWPSData.excel_info} target="_blank">
                <div className="card">
                  <span>Перечень насосного оборудования</span>
                </div>
              </a>
            )}
          </>
        </> : <Jelly />}
      {/* <RSODynamicNotFound
        title={`Временно в разработке`}
        icon='👨‍💻'
        descr={`Ждем появления схем на сервере... После появления надпись исчезнет`}
      /> */}
    </div>
  )

}