import React, { useState, useContext, useEffect } from "react";

import { API_URL } from "../../../../../../../config";
import AuthContext from "../../../../../../../context/AuthContext";

import "./ButtonAddWear.css";

export const Fundament = () => {
  const { authTokens } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    plotName: "",
    wear: "",
    area: "",
    photo: null,
  });

  // Определяем переменные
  const currentDate = new Date(); // Получаем текущую дату
  const year = currentDate.getFullYear(); // Получаем год
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Получаем месяц (месяцы начинаются с 0, поэтому добавляем 1) и добавляем 0 спереди
  const day = String(currentDate.getDate()).padStart(2, "0"); // Получаем день и добавляем 0 спереди
  const data_in_wear = `${year}-${month}-${day}`; // Форматируем в строку "YYYY-MM-DD"

  const idHouse = localStorage.getItem("ID_House"); // Получаем ID из localStorage

  const element = 0; // Элемент

  const foundationType = 1;

  // Функция обнуления значений
  const resetFormData = () => {
    setFormData({
      plotName: "",
      wear: "",
      area: "",
      photo: null,
    });
  };

  //Считывание текста
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  //Считывание фото
  const handlePhotoUpload = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      photo: file,
    }));
  };

  // Отправка данных
  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestData = {
      position: formData.plotName,
      data: data_in_wear,
      Element: element,
      volume: formData.area,
      degreeOfDamage: formData.wear,
      idHouse: idHouse,
    };

    try {
      const response = await fetch(`${API_URL}/CivilWear/Damage/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Ошибка при отправке данных: ${errorText}`);
      }

      const result = await response.json();
      console.log("Успешно отправлено:", result);
      alert("Износ добавлен !");
      resetFormData();
    } catch (error) {
      console.error("Ошибка при отправке:", error);
    }
  };

  let data = []; //Данные для таблицы

  switch (foundationType) {
    case 1:
      data = [
        ["Признаки износа", "Количественная оценка", "Физический износ, %"],
        ["Разрушение отделочного слоя цоколя, ослабление врубок", "-", "0-20"],
        [
          "Искривление горизонтальных линий цоколя, забирки >",
          "Повреждения на площади до 25%",
          "21-40",
        ],
        ["Поражение забирки гнилью", "Повреждения на площади до 50%", "41-60"],
        ["Искривление горизонтальной линии стен", "-", "61-80"],
      ];
      break;
    case 2:
      data = [
        ["Признаки износа", "Количественная оценка", "Физический износ, %"],
        [
          "Мелкие повреждения цокольной части",
          "Повреждения на площади до 5%",
          "0-20",
        ],
        [
          "Трещины, сколы, выпадение отдельных камней",
          "То же, до 25%",
          "21-40",
        ],
        ["Перекосы, выпучивание цоколя", "Ширина трещин до 5 мм", "41-60"],
        ["Искривление горизонтальных линий стен", "-", "61-80"],
      ];
      break;
    case 3:
      data = [
        ["Признаки износа", "Количественная оценка", "Физический износ, %"],
        ["Мелкие трещины в цоколе", "Ширина трещин до 1,5 мм", "0-20"],
        ["Трещины в швах между блоками", "То же, до 2 мм", "21-40"],
        [
          "Трещины, частичное разрушение блоков",
          "Глубина более 10 мм",
          "41-60",
        ],
        ["Массовые повреждения", "-", "61-80"],
      ];
      break;
    case 4:
      data = [
        ["Признаки износа", "Количественная оценка", "Физический износ, %"],
        ["Мелкие повреждения цоколя", "Повреждения на площади до 5%", "0-20"],
        ["Трещины, сколы", "То же, до 25%", "21-40"],
        ["Перекосы, выпучивание цоколя", "Ширина трещин до 5 мм", "41-60"],
        ["Разрушение цоколя", "-", "61-80"],
      ];
      break;
    case 5:
      data = [
        ["Признаки износа", "Количественная оценка", "Физический износ, %"],
        ["Трещины в цокольной части", "Ширина трещин до 1,5 мм", "0-20"],
        ["Искривление горизонтальных линий", "Неравномерная осадка", "21-40"],
        ["Сквозные трещины", "Ширина раскрытия трещин до 10 мм", "41-60"],
        ["Разрушение цоколя", "-", "61-80"],
      ];
      break;
    default:
      data = [["Нет данных"]];
      break;
  }

  return (
    <div className="container_Wear_ButtonAddWear">
      <form className="form_Wear_ButtonAddWear" onSubmit={handleSubmit}>
        <h3>Фундамент:</h3>
        <div className="form-group_Wear_ButtonAddWear">
          <label htmlFor="plotName" className="label_Wear_ButtonAddWear">
            Обозначение участка
          </label>
          <input
            className="input_Wear_ButtonAddWear"
            type="text"
            id="plotName"
            name="plotName"
            value={formData.plotName}
            onChange={handleChange}
          />
        </div>

        <div className="form-group_Wear_ButtonAddWear">
          <label htmlFor="wear" className="label_Wear_ButtonAddWear">
            Фактический физический износ участка
          </label>
          <input
            className="input_Wear_ButtonAddWear"
            type="text"
            id="wear"
            name="wear"
            value={formData.wear}
            onChange={handleChange}
          />
        </div>

        <div className="form-group_Wear_ButtonAddWear">
          <label htmlFor="area" className="label_Wear_ButtonAddWear">
            Площадь участка
          </label>
          <input
            className="input_Wear_ButtonAddWear"
            type="text"
            id="area"
            name="area"
            value={formData.area}
            onChange={handleChange}
          />
        </div>

        <div className="form-group_Wear_ButtonAddWear">
          <label htmlFor="photo" className="label_Wear_ButtonAddWear">
            Прикрепить фото
          </label>
          <input
            className="input_Wear_ButtonAddWear"
            type="file"
            id="photo"
            onChange={handlePhotoUpload}
          />
        </div>

        <button type="submit" className="submit-button_Wear_ButtonAddWear">
          Сохранить данные
        </button>
      </form>

      <div className="table-container_Wear_ButtonAddWear">
        <table className="table_Wear_ButtonAddWear">
          <thead>
            <tr>
              {data[0] &&
                data[0].map((header, index) => <th key={index}>{header}</th>)}
            </tr>
          </thead>
          <tbody>
            {data.slice(1).map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
