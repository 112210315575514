import { useEffect, useState, React, Children } from "react"
import { useContext, createContext } from "react";
import AuthContext from "../../../../../context/AuthContext";
import {
  getSmena,
  getWPS,
} from "../http/worker_http"

export let worker_context = createContext();

export let WorkerProvider = ({ children }) => {

  let { authTokens, logoutUser } = useContext(AuthContext);

  let [smena, setSmena] = useState([]);//Рабочая смена
  let [WPS, setWPS] = useState([]);//Все ВЗУ
  let [currentSmena, setCurrentSmena] = useState(123);//Текущая смена после создания

  let updateSmena = () => {
    getSmena(authTokens, setSmena)
  } 

  useEffect(() => {
    getWPS(authTokens, setWPS)
    updateSmena()
  }, [])

  return (
    <worker_context.Provider
      value={{smena,
        WPS,
        updateSmena,
        setWPS,
        setCurrentSmena,
        }} >
      {children}
    </worker_context.Provider >
  )
}