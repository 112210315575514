import React, { useState } from "react";

import { FactFullWear } from "./ButtonShowWear/FactFullWear";
import { TeorFullWear } from "./ButtonShowWear/TeorFullWear";
import { FactElementWear } from "./ButtonShowWear/FactElementWear";
import { TeorElementWear } from "./ButtonShowWear/TeorElementWear";

import "./ButtonAdd.css"

export const ButtonShowWear = () => {
  const [activeOption, setActiveOption] = useState(null);

  const renderOption = () => {
    switch (activeOption) {
      case "factFullWear":
        return <FactFullWear />;
      case "teorFullWear":
        return <TeorFullWear />;
      case "factElementWear":
        return <FactElementWear />;
      case "teorElementWear":
        return <TeorElementWear />;
      default:
        return null;
    }
  };

  return (
    <div className="ButtonShowWear_container">
      <div className="ButtonShowWear_buttons">
        <button className="ButtonShowWear_button" onClick={() => setActiveOption("factFullWear")}>Фактический полный износ</button>
        <button className="ButtonShowWear_button" onClick={() => setActiveOption("teorFullWear")}>Теоретический полный износ</button>
        <button className="ButtonShowWear_button" onClick={() => setActiveOption("factElementWear")}>Фактический износ элемента</button>
        <button className="ButtonShowWear_button" onClick={() => setActiveOption("teorElementWear")}>Теоретический износ элемента</button>
      </div>

      <div className="ButtonShowWear_content">
        {renderOption()}
      </div>
    </div>
  );
};
