import './RSODynamic_worker_workzone.scss'
import { RSODynamicButtonGroup } from '../../../../utils/RSODynamicButtonGroup/RSODynamicButtonGroup'
import { createContext, useContext } from 'react'
import { worker_workzone_context } from '../../hooks/useStats_worker_workzone'
import { worker_context } from '../../hooks/useStats_worker'
import { RSODynamic_dynamic_values } from './components/RSODynamic_dynamic_values/RSODynamic_dynamic_values'
import { RSODynamicChlorine } from './components/RSODynamicChlorine/RSODynamicChlorine'
import { RSODynamicCloseSmena } from './components/RSODynamicCloseSmena/RSODynamicCloseSmena'
import { RSODynamcOperInfo } from './components/RSODynamcOperInfo/RSODynamcOperInfo'
import { RSODynamicArchive } from './components/RSODynamicArchive/RSODynamicArchive'
import { RSODynamicEquipmentFailures } from './components/RSODynamicEquipmentFailures/RSODynamicEquipmentFailures'
import { WPSInfo } from './components/WPSInfo/WPSInfo'

export let RSODynamic_worker_workzone = () => {

  let {
    activeTab,
    setActiveTab
  } = useContext(worker_workzone_context)

  let {
    WPS
  } = useContext(worker_context)

  return <>
    <div className="large__container">
      <RSODynamicButtonGroup
        buttons={[
          { title: 'Информация о ВЗУ' },
          { title: 'Отказы оборудования' },
          { title: 'Оперативная информация' },
          { title: 'Почасовые показатели' },
          { title: 'Хлор' },
          { title: 'Закрытие смены' },
          { title: 'Архив' },
        ]}
        setter={setActiveTab}
        getter={activeTab} />

      {activeTab === "Информация о ВЗУ" &&
        <WPSInfo />}

      {activeTab === "Отказы оборудования" &&
        <RSODynamicEquipmentFailures />}

      {activeTab === "Оперативная информация" &&
        <RSODynamcOperInfo />}

      {activeTab === "Почасовые показатели" &&
        <RSODynamic_dynamic_values />}

      {activeTab === "Хлор" &&
        <RSODynamicChlorine />}

      {activeTab === "Закрытие смены" &&
        <RSODynamicCloseSmena />}

      {activeTab === "Архив" &&
        <RSODynamicArchive />}
    </div>
  </>
} 