import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../../../../../../../../context/AuthContext";
import { worker_workzone_context } from "../../../../../../hooks/useStats_worker_workzone";
import { worker_context } from "../../../../../../hooks/useStats_worker";
import { getFailruePumps, getFailrueWells, getWPSPumps } from "../../../../../../http/worker_http";
import { ModelTable } from "../../../../../../../../../../components/ModelTable/ModelTable";
import { dateConverter } from "../../../../../../../../../../utils/utils";
import { RSODynamicNotFound } from "../../../../../../../../utils/RSODynamicNotFound/RSODynamicNotFound";

export let RSODynamicEquipmentFailuresPaint = () => {

  let { authTokens, logoutUser } = useContext(AuthContext);

  let { currentWps } = useContext(worker_workzone_context);
  let { smena } = useContext(worker_context);

  let [failureWells, setFailureWells] = useState([]);
  let [failurePumps, setFailurePumps] = useState([]);
  let [pumps, setPumps] = useState([])

  useEffect(() => {//useEffect для подгрузки данных
    getFailrueWells(authTokens, currentWps.prefabricate_water_pipeline, setFailureWells);
    getFailruePumps(authTokens, currentWps.water_pumping_station_pump, setFailurePumps);
    getWPSPumps(authTokens, currentWps.water_pumping_station_pump, setPumps)
  }, [smena])

  return (
    <div className="container">
      {(failureWells.length !== 0 && currentWps) ?
        <ModelTable>
          <thead>
            <tr>
              <th colSpan={8}>{`Отказы оборудования ВЗУ ${currentWps?.name} на данный момент (СКВАЖИНЫ)`}</th>
            </tr>
            <tr>
              <th>Дата</th>
              <th>Время</th>
              <th>ФИО</th>
              <th>Номер смены</th>
              <th>Номер скважины</th>
              <th>Неисправное оборудование</th>
              <th>Вид отказа</th>
              <th>Проведенные работы</th>
            </tr>
          </thead>
          <tbody>
            {failureWells?.map(item =>
              <tr>
                <td>{dateConverter(item?.date_of_repair)}</td>
                <td>{item?.time.substring(0, 5)}</td>
                <td>{item?.fullname}</td>
                <td>{item?.shift_number}</td>
                <td>{item?.number_well}</td>
                <td>{item?.broken}</td>
                <td>{item?.type_of_repair}</td>
                <td>{item?.note}</td>
              </tr>
            )}
          </tbody>
        </ModelTable> :
        <RSODynamicNotFound
          title="Нет отказов скважин в данный момент"
          icon='😊'
          descr='Чтобы добавить отказавшее оборудование, перейдите на вкладку "Ввод отказов оборудования"'
        />
      }

      {(failurePumps.length !== 0 && currentWps) ?
        <div style={{ marginTop: "50px" }}>
          <ModelTable>
            <thead>
              <tr>
                <th colSpan={8}>{`Отказы оборудования ВЗУ ${currentWps?.name} на данный момент (НАСОСЫ)`}</th>
              </tr>
              <tr>
                <th>Дата</th>
                <th>Время</th>
                <th>ФИО</th>
                <th>Номер смены</th>
                <th>Номер насоса</th>
                <th>Неисправное оборудование</th>
                <th>Вид отказа</th>
                <th>Проведенные работы</th>
              </tr>
            </thead>
            <tbody>
              {failurePumps?.map(item =>
                <tr>
                  <td>{dateConverter(item?.date_of_repair)}</td>
                  <td>{item?.time.substring(0, 5)}</td>
                  <td>{item?.fullname}</td>
                  <td>{item?.shift_number}</td>
                  <td>{pumps.find(pump => pump?.id === item?.water_pumping_station_pump)?.number_of_line}</td>
                  <td>{item?.broken}</td>
                  <td>{item?.type_of_repair}</td>
                  <td>{item?.note}</td>
                </tr>
              )}
            </tbody>
          </ModelTable>
        </div> :
        <RSODynamicNotFound
          title="Нет отказов насосов в данный момент"
          icon='😊'
          descr='Чтобы добавить отказавшее оборудование, перейдите на вкладку "Ввод отказов оборудования"'
        />
      }
    </div>
  )

}