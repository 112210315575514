import React from "react";
import ExcelJS from "exceljs";
import { saveAs } from 'file-saver';
import "../../css/Wear.css";

const ExportExcel = ({
  foundationType,
  balconyType,
  roofType,
  wallType,
  roofingType,
  floorType,
  sewerageLength,
  chuteLength,
  centralHeatingLength,
  coldWaterSupplyLength,
  hotWaterSupplyLength,
  wiringLength,
}) => {
  const exportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();

    console.log(foundationType, balconyType, roofType, wallType, roofingType, floorType);
    
//Инженерные системы

    let sewerageLengthData = [];
    let chuteLengthData = [];
    let centralHeatingLengthData = [];
    let coldWaterSupplyLengthData = [];
    let hotWaterSupplyLengthData = [];
    let wiringLengthData = [];

    const checkAndDoAction = (variableName, variableValue) => {
      if (variableValue !== null && variableValue !== 0) {
        switch (variableName) {
          case "sewerageLength":
            sewerageLengthData.push(
              ["Канализация"],
              ["Признаки износа", "Физический износ, %",],
              [
                "Ослабление мест присоединения приборов; повреждение эмалированного покрытия моек, раковин, умывальников, ванн на площади до 10% их поверхности; трещины в трубопроводах из полимерных материалов",
                "0-20",
              ],
              [
                "Наличие течи в местах присоединения приборов до 10% всего количества; повреждение эмалированного покрытия моек, раковин, умывальников, ванн до 20% их поверхности; повреждение керамических умывальников и унитазов (сколы, трещины, выбоины) до 10% их количества; повреждения отдельных чугунных трубопроводов ; значительное повреждение трубопроводов из полимерных материалов",
                "21-40",
              ],
              [
                "Массовые течи в местах присоединения приборов; повреждение эмалированного покрытия моек, раковин, ванн, умывальников до 30% их поверхности; повреждение керамических умывальников и унитазов до 20% их количества; повреждение чугунных трубопроводов, масовые повреждение трубопроводов из полимерных материалов",
                "41-60",
              ],
              [
                "Неисправность системы; повсеместные повреждения приборов; следы ремонтов (хомуты, заделка и замена отдельных участков)",
                "61-80",
              ],
              [],
              ["Обозначение участка"," Фактический физический износ участка элементов, % Фi","длина участка,м"]
            );
            break;
          case "chuteLength":
            chuteLengthData.push(      
            ["Мусоропровод"],
            ["Признаки износа", "Физический износ, %",],
            ["Мелкие повреждения в стволе, застревание загрузочных клапанов", "0-20"],
            [
              "Неисправность загрузочных клапанов, неплотность в раструбных соединениях, отдельные пробоины в стволе мусоропровода, коррозия металлических частей",
              "21-40",
            ],
            [
              "Отсутствие или поломка металлических деталей загрузочных люков, большие пробоины и расшатывание соединений участков ствола, поломка бункера с шиберами, неисправности в стенках вентиляционной камеры мусоропровода",
              "41-60",
            ],
            [
              "Полное расшатывание ствола мусоропровода, отсутствие или поломка загрузочных устройств, разрушение вентиляционной камеры и неисправности в камере мусоросборника",
              "61-80",
            ],
            [],
            ["Обозначение участка"," Фактический физический износ участка элементов, % Фi","длина участка,м"]
        )
            break;
          case "centralHeatingLength":
            centralHeatingLengthData.push(
      ["Центральное отопление"],
      ["Признаки износа", "Физический износ, %",],
      [
        "Ослабление прокладок и набивки запорной арматуры, нарушения окраски отопительных приборов и стояков, нарушение теплоизоляции магистралей в отдельных местах",
        "0-20",
      ],
      [
        "Капельные течи в местах врезки запорной арматуры, приборов и в секциях отопительных приборов; отдельные хомуты на стояках и магистралях; значительные нарушения теплоизоляции магистралей; следы ремонта калориферов",
        "21-40",
      ],
      [
        "Капельные течи в отопительных приборах и местах их врезки; следы протечек в отопительных приборах, следы их восстановления, большое количество хомутов на стояках и магистралях, следы их ремонта отдельными местами и выборочной заменой; коррозия трубопроводов магистралей; неудовлетворительная работа калориферов",
        "41-60",
      ],
      [
        "Массовое повреждение трубопроводов (стояков и магистралей), сильное поражение ржавчиной, следы ремонта отдельными местами (хомуты, заварка), неудовлетворительная работа отопительных приборов и запорной арматуры, их закипание; значительное нарушение теплоизоляции трубопроводов",
        "61-80",
      ],
      [],
      ["Обозначение участка"," Фактический физический износ участка элементов, % Фi","длина участка,м"]
            )
            break;
          case "coldWaterSupplyLength":
            coldWaterSupplyLengthData.push(
      ["Холодное водоснабжение"],
      ["Признаки износа", "Физический износ, %",],
      [
        "Ослабление сальниковых набивок и прокладок кранов и запорной арматуры, в некоторых смывных бачках имеются утечки воды, повреждение окраски трубопроводов в отдельных местах",
        "0-20",
      ],
      [
        "Капельные течи в местах врезки кранов и запорной арматуры; отдельные повреждения трубопроводов (свищи, течи); поражение коррозией отдельных участков трубопроводов; утечки воды в 20% приборов и смывных бачков",
        "21-40",
      ],
      [
        "Расстройство арматуры и смывных бачков (до 40%); следы ремонта трубопроводов (хомуты, заварка, замена отдельных участков); значительная коррозия трубопроводов; повреждение до 10% смывных бачков (трещины, потеря крышек, рукояток)",
        "41-60",
      ],
      [
        "Полное расстройство системы, выход из строя запорной арматуры, большое количество хомутов, следы замены отдельными местами трубопроводов, большая коррозия элементов системы, повреждение до 30% смывных бачков",
        "61-80",
      ],
      [],
      ["Обозначение участка"," Фактический физический износ участка элементов, % Фi","длина участка,м"]
            )
            break;
          case "hotWaterSupplyLength":
            hotWaterSupplyLengthData.push(
                ["Горячее водоснабжение"],
                ["Признаки износа", "Физический износ, %",],
                [
                  "Ослабление сальниковых набивок и прокладок кранов и запорной арматуры, в некоторых смывных бачках имеются утечки воды, повреждение окраски трубопроводов в отдельных местах",
                  "0-20",
                ],
                [
                  "Капельные течи в местах врезки кранов и запорной арматуры; отдельные повреждения трубопроводов (свищи, течи); поражение коррозией отдельных участков трубопроводов; утечки воды в 20% приборов и смывных бачков",
                  "21-40",
                ],
                [
                  "Расстройство арматуры и смывных бачков (до 40%); следы ремонта трубопроводов (хомуты, заварка, замена отдельных участков); значительная коррозия трубопроводов; повреждение до 10% смывных бачков (трещины, потеря крышек, рукояток)",
                  "41-60",
                ],
                [
                  "Полное расстройство системы, выход из строя запорной арматуры, большое количество хомутов, следы замены отдельными местами трубопроводов, большая коррозия элементов системы, повреждение до 30% смывных бачков",
                  "61-80",
                ],
                [],
                ["Обозначение участка"," Фактический физический износ участка элементов, % Фi","длина участка,м"]
            )
            break;
          case "wiringLength":
            wiringLengthData.push(
        ["Электрооборудование"],
        ["Признаки износа", "Физический износ, %",],
        [
          "Неисправность, ослабление закреплений и отсутствие отдельных приборов (розеток, штепселей, патронов и т.д.); следы коррозии на поверхности металлических шкафов и частичное повреждение деревянных крышек",
          "0-20",
        ],
        [
          "Повреждение изоляции магистральных и внутриквартирных сетей в отдельных местах, потеря эластичности изоляции проводов, открытые проводки покрыты значительным слоем краски, отсутствие части приборов и крышек к ним, следы ремонта вводно-распределительных устройств (ВРУ)",
          "21-40",
        ],
        [
          "Полная потеря эластичности изоляции проводов, значительные повреждения магистральных и внутриквартирных сетей и приборов, следы ремонта системы с частичной заменой сетей и приборов отдельными местами, наличие временных прокладок, неисправность ВРУ",
          "41-60",
        ],
        [
          "Неисправность системы: проводки, щитков, приборов, ВРУ; отсутствие части приборов; оголение проводов; следы больших ремонтов (провесы проводов, повреждения шкафов, щитков, ВРУ)",
          "61-80",
        ],
        [],
        ["Обозначение участка"," Фактический физический износ участка элементов, % Фi","длина участка,м"]
            )
            break;
        }
      } 
    };

    const createSheet = (workbook, sheetName, data) => {
      const worksheet = workbook.addWorksheet(sheetName);

      // Установка данных и вычисление ширины столбцов и высоты строк
      data.forEach((row, rowIndex) => {
        const excelRow = worksheet.addRow(row);

        row.forEach((cell, colIndex) => {
          const excelCell = excelRow.getCell(colIndex + 1);

          // Подсчитываем максимальную ширину столбца
          const cellValue = cell ? cell.toString() : '';
          const column = worksheet.getColumn(colIndex + 1);
          const currentWidth = column.width || 10;
          const newWidth = Math.min(Math.max(currentWidth, cellValue.length + 2), 50); // Ограничение на ширину
          column.width = newWidth;

          // Устанавливаем границы ячеек
          excelCell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        });

        // Устанавливаем высоту строки в зависимости от количества строк в ячейке
        const maxLines = row.reduce((max, cell) => Math.max(max, (cell ? cell.toString().split('\n').length : 1)), 1);
        excelRow.height = maxLines * 30; // Примерная высота строки
      });

      return worksheet;
    };

    let foundationData = [];
    switch (foundationType) {
      case "1":
        foundationData.push(
          ["Фундамент"],
          ["Признаки износа", "Количественная оценка", "Физический износ, %",],
          ["Разрушение отделочного слоя цоколя, ослабление врубок", "-", "0-20"],
          ["Искривление горизонтальных линий цоколя, забирки > и повреждение отдельных досок забирки", "Повреждения на площади до 25%*", "21-40"],
          ["Поражение забирки гнилью, обрастание мхом нижней части цоколя, коробление и отставание досок Мелкие повреждения верх-", "Повреждения на площади до 50%*", "41-60"],
          ["Искривление горизонтальной линии стен, осадка отдельных участков здания. Поражение гнилью, жучком, частичные разрушения забирки и столбов", "-", "61-80"],
          [],
          [ "Обозначение участка", "Фактический физический износ участка элементов, % Фi", "Площадь участка, кв.м"]
        );
        break;
      case "2":
        foundationData.push(
          ["Фундамент"],
          ["Признаки износа", "Количественная оценка", "Физический износ, %", ],
          ["Мелкие повреждения цокольной части - трещины, местные выбоины", "Повреждения на площади до 5%", "0-20"],
          ["Трещины, сколы, выпадение отдельных камней в надземной части цоколя и фундаментных столбов", "То же, до 25%", "21-40"],
          ["Перекосы, выпучивание цоколя, трещины в цоколе, трещины, сколы и выпадение камней в надземной части столбов", "Ширина трещин до 5 мм. Выпучивание цоколя до 1/3 его толщины", "41-60"],
          ["Искривление горизонтальных линий стен, осадка отдельных участков, полное разрушение цоколя, нарушение монолитности кладки столбов", "-", "61-80"],
          [],
          ["Обозначение участка", "Фактический физический износ участка элементов, % Фi", "Площадь участка, кв.м"]
        );
        break;
      case "3":
        foundationData.push(
          ["Фундамент"],
          ["Признаки износа", "Количественная оценка", "Физический износ, %", ],
          ["Мелкие трещины в цоколе, местные нарушения штукатурного слоя цоколя и стен", "Ширина трещин до 1,5 мм", "0-20"],
          ["Трещины в швах между блоками, высолы и следы увлажнения стен подвала", "То же, до 2 мм", "21-40"],
          ["Трещины, частичное разрушение блоков, выщелачивание раствора из швов между блоками, следы увлажнения цоколя и стен подвала", "То же, более 2 мм, глубина более 10 мм", "41-60"],
          ["Массовые повреждения и разрушение блоков, прогрессирующие сквозные трещины на всю высоту здания, выпирание грунта в подвале", "-", "61-80"],
          [],
          ["Обозначение участка", "Фактический физический износ участка элементов, % Фi", "Площадь участка, кв.м"]
        );
        break;
      case "4":
        foundationData.push(
          ["Фундамент"],
          ["Признаки износа", "Количественная оценка", "Физический износ, %", ],
          ["Мелкие повреждения цокольной части - трещины, местные выбоины", "Повреждения на площади до 5%", "0-20"],
          ["Трещины, сколы, выпадение отдельных камней в надземной части цоколя и фундаментных столбов", "То же, до 25%", "21-40"],
          ["Перекосы, выпучивание цоколя, трещины в цоколе, трещины, сколы и выпадение камней в надземной части столбов", "Ширина трещин до 5 мм. Выпучивание цоколя до 1/3 его толщины", "41-60"],
          ["Искривление горизонтальных линий стен, осадка отдельных участков, полное разрушение цоколя, нарушение монолитности кладки столбов", "-", "61-80"],
          [],
          ["Обозначение участка", "Фактический физический износ участка элементов, % Фi", "Площадь участка, кв.м"]
        );
        break;
      case "5":
        foundationData.push(
          ["Фундамент"],
          ["Признаки износа", "Количественная оценка", "Физический износ, %", ],
          ["Трещины в цокольной части здания", "Ширина раскрытия трещин до 1,5 мм", "0-20"],
          ["Искривление горизонтальных линий цоколя без признаков увеличения осадочных деформаций", "Неравномерная осадка с прогибом стен до 0,01 от длины стены", "21-40"],
          ["Сквозные трещины в цоколе, распространение трещин на всю высоту здания. Искривление и значительная осадка отдельных участков стен. Развитие осадок не наблюдается", "Ширина раскрытия трещин до 10 мм. Неравномерная осадка с прогибом стен и более 0,01 от длины стены", "41-60"],
          ["Развитие сквозных трещин в стенах здания, разрушение цоколя, развитие деформаций фундаментов", "-", "61-80"],
          [],
          ["Обозначение участка", "Фактический физический износ участка элементов, % Фi", "Площадь участка, кв.м"]
        );
        break;
      default:
        foundationData.push(["Ошибка передачи фундамента"]);
        break;
    }

    let balconyData = [];
    switch (balconyType) {
      case "1":
        balconyData.push(
          ["Балкон"],
          ["Признаки износа", "Количественная оценка", "Физический износ, %", ],
          ["Мелкие повреждения металлических обделок и ограждений, усадочные трещины на стенках лоджий", "Повреждения на площади до 10%. Суммарная длина усадочных трещин на 1 м2 до 1 м", "0-20"],
          ["Повреждения пола и гидроизоляции, следы протечек на стене, трещины на нижней поверхности плиты и на стенках", "Повреждения на площади до 20%, уклон пола менее 1%. Ширина раскрытия трещин до 1 мм", "21-40"],
          ["Скалывание бетона стенок в местах опирания плит, трещины в стенах и плитах, прогиб плит", "Ширина раскрытия трещин до 2 мм. Прогиб плит до 1/100 пролета", "41-60"],
          ["Прогрессирующие прогибы плит, разрушение опорных участков стенок, деформации стенок, разрушение ограждений", "Прогиб плит более 1/100 пролета. Трещины более 2 мм. Выпучивание стенок более 1/150", "61-80"],
          [],
          ["Обозначение участка", "Фактический физический износ участка элементов, % Фi", "Площадь участка, кв.м"]
        );
        break;
      case "2":
        balconyData.push(
          ["Балкон"],
          ["Признаки износа", "Количественная оценка", "Физический износ, %", ],
          ["Мелкие повреждения металлических обделок и ограждений, усадочные трещины на стенках лоджий", "Повреждения на площади до 10%. Суммарная длина усадочных трещин на 1 м2 до 1 м", "0-20"],
          ["Повреждения пола и гидроизоляции, следы протечек на стене, трещины на нижней поверхности плиты и на стенках", "Повреждения на площади до 20%, уклон пола менее 1%. Ширина раскрытия трещин до 1 мм", "21-40"],
          ["Скалывание бетона стенок в местах опирания плит, трещины в стенах и плитах, прогиб плит", "Ширина раскрытия трещин до 2 мм. Прогиб плит до 1/100 пролета", "41-60"],
          ["Прогрессирующие прогибы плит, разрушение опорных участков стенок, деформации стенок, разрушение ограждений", "Прогиб плит более 1/100 пролета. Трещины более 2 мм. Выпучивание стенок более 1/150", "61-80"],
          [],
          ["Обозначение участка", "Фактический физический износ участка элементов, % Фi", "Площадь участка, кв.м"]
        );
        break;
      default:
        balconyData.push(["Ошибка передачи балкона"]);
    }

    let roofData = [];
    switch (roofType) {
      case "1":
        roofData.push(
          ["Крыша"],
          ["Признаки износа", "Количественная оценка", "Физический износ, %",],
          [
            "Ослабление креплений: болтов, хомутов, скоб; повреждение деталей слуховых окон",
            "-",
            "0-20",
          ],
          [
            "Поражение гнилью мауэрлата и концов стропильных ног, ослабление врубок и соединений",
            "Повреждения на площади до 20%",
            "21-40",
          ],
          [
            "Поражение гнилью древесины мауэрлата, стропил, обрешетки; наличие дополнительных временных креплений стропильных ног; увлажнение древесины",
            "То же, до 50%",
            "41-60",
          ],
          [
            "Прогибы стропильных ног, поражение гнилью и жучком древесины деталей крыши",
            "-",
            "61-80",
          ],
          [],
          ["Обозначение участка"," Фактический физический износ участка элементов, % Фi","Площадь участка, кв.м"]
        );

        break;
      case "2":
        roofData.push(
          ["Крыша"],
          ["Признаки износа", "Количественная оценка", "Физический износ, %",],
          [
            "Мелкие повреждения деревянных деталей, кирпичных столбиков",
            "-",
            "0-20",
          ],
          [
            "Трещины в кирпичных столбиках или опорных участках железобетонных панелей, мелкие пробоины в плитах покрытия, гниль в деревянных деталях",
            "Повреждения на площади до 20%",
            "21-40",
          ],
          [
            "Неглубокие трещины в железобетонных стропильных балках и плитах, протечки крыш",
            "Ширина раскрытия трещин до 2 мм",
            "41-60",
          ],
          [
            "Сквозные трещины в стропильных балках, плитах; прогибы плит покрытия; разрушение кирпичных столбиков и опорных участков железобетонных панелей стен; обнажение арматуры",
            "Ширина раскрытия трещин более 2 мм. Прогибы плит более 1/100 пролета. Повреждения на площади более 20%",
            "61-80",
          ],
          [],
          ["Обозначение участка"," Фактический физический износ участка элементов, % Фi","Площадь участка, кв.м"]
        );

        break;
      case "3":
        roofData.push(
          ["Крыша"],
          ["Признаки износа", "Количественная оценка", "Физический износ, %",],
          [
            "Мелкие выбоины на поверхности плит",
            "Повреждения на площади до 15%",
            "0-20",
          ],
          [
            "Трещины в панелях, пробоины, следы протечек. Оседание утеплителя и его высокая влажность",
            "Ширина трещин до 1 мм. Протечки на площади до 10%. Относительная влажность утеплителя более 20%",
            "21-40",
          ],
          [
            "Множественные трещины в панелях, протечки и промерзания, прогибы панелей",
            "Ширина трещин до 2 мм. Протечки и промерзания на площади до 25%. Прогиб панели до 1/80 пролета",
            "41-60",
          ],
          [
            "Местные разрушения панелей, деструкция утеплителя, протечки и промерзания",
            "-",
            "61-80",
          ],
          [],
          ["Обозначение участка"," Фактический физический износ участка элементов, % Фi","Площадь участка, кв.м"]
        );

        break;
      default:
        roofData.push("Ошибка передачи крыши");
    }

    let wallData = [];
    switch (wallType) {
      case "1":
        wallData.push(
          ["Стены"],
          ["Признаки износа", "Количественная оценка", "Физический износ, %",],
          [
            "Мелкие повреждение наружной обшивки щитов",
            "Повреждение на площади до 10%",
            "0-10",
          ],
          [
            "Поражение гнилью отливной доски, обшивки углов и стыков внутренних стен",
            "То же, до 30%",
            "11-20",
          ],
          [
            "Незначительный перекос стен, поражение гнилью нижней части щитов и обвязки, образование щелей в стыках щитов",
            "То же, до 25%",
            "21-30",
          ],
          [
            "Заметный перекос стен, образование щелей в вертикальных стыках между щитами, неравномерная осадка щитов, поражение древесины гнилью",
            "То же, до 30%",
            "31-40",
          ],
          [
            "Заметный перекос стен, выпучивание, отклонение от вертикали, поражение древесины гнилью, повышенная влажность в помещениях",
            "Повреждения на площади более 30%",
            "41-50",
          ],
          [
            "Перекос оконных и дверных проемов, деформация стен, поражение древесины гнилью, увлажнение древесины",
            "-",
            "51-60",
          ],
          [
            "Деформация стен, поражение древесины гнилью, сырость в помещениях, наличие временных креплений и подпорок",
            "-",
            "61-70",
          ],
          [],
          ["Обозначение участка"," Фактический физический износ участка элементов, % Фi","Площадь участка, кв.м"]
        );

        break;
      case "2":
        wallData.push(
          ["Стены"],
          ["Признаки износа", "Количественная оценка", "Физический износ, %",],
          ["Мелкие повреждение обшивки или штукатурки", "-", "0-10"],
          [
            "Продуваемость и следы промерзания стен, повреждение обшивки или отпадение штукатурки в угловых участках",
            "Повреждения на площади до 10%",
            "44136",
          ],
          [
            "Штукатурка выкрошилась местами, отдельные доски покороблены и повреждены, нижние - повреждены гнилью",
            "То же, до 20%",
            "21-30",
          ],
          [
            "Обшивка покоробилась, растрескалась и местами отстала, штукатурка отпала",
            "То же, до 40%",
            "31-40",
          ],
          [
            "Массовое отпадение штукатурки или гниль в древесине и отставание обшивки",
            "То же, до 50%",
            "41-50",
          ],
          [
            "Перекос стен, оконных и дверных коробок",
            "Повреждения на площади более 50%",
            "51-60",
          ],
          [
            "Выпучивание наружной обшивки и штукатурки, отставание досок",
            "-",
            "61-70",
          ],
          [
            "Значительное повреждение каркаса, поражение гнилью, полное разрушение обшивки",
            "-",
            "",
          ],
          [],
          ["Обозначение участка"," Фактический физический износ участка элементов, % Фi","Площадь участка, кв.м"]
        );

        break;
      case "3":
        wallData.push(
          ["Стены"],
          ["Признаки износа", "Количественная оценка", "Физический износ, %",],
          [
            "Незначительные повреждения наружной обшивки и конопатки",
            "-",
            "0-10",
          ],
          [
            "Трещины в наружной обшивке стен или штукатурке, нарушение конопатки, растрескивание древесины венцов",
            "Повреждения на площади до 10%",
            "",
          ],
          [
            "Искривление горизонтальных линий фасада, следы увлажнения и гнили на уровне нижнего оклада венца у карниза и под оконными проемами. Нарушение наружной обшивки или трещины в штукатурке",
            "Повреждения на площади до 20%",
            "21-30",
          ],
          [
            "Продуваемость и промерзание стен, глубокие трещины в венцах и частичное поражение гнилью",
            "-",
            "31-40",
          ],
          [
            "Выпучивание стен и прогибы, неравномерная осадка, перекос дверных и оконных косяков, поражение гнилью, осадка углов",
            "Выход из плоскости до ? толщины стены",
            "41-50",
          ],
          [
            "Деформация стен, повреждение венцов гнилью и трещинами",
            "Повреждения на площади до 40%",
            "51-60",
          ],
          [
            "Полное нарушение жесткости сруба, образование трещин, поражение гнилью",
            "-",
            "61-70",
          ],
          [],
          ["Обозначение участка"," Фактический физический износ участка элементов, % Фi","Площадь участка, кв.м"]
        );

        break;
      case "4":
        wallData.push(
          ["Стены"],
          ["Признаки износа", "Количественная оценка", "Физический износ, %",],
          [
            "Отдельные трещины и выбоины",
            "Повреждения на площади до 10%",
            "0-10",
          ],
          [
            "Трещины в швах кладки",
            "Ширина трещин до 2 мм на площади до 15%",
            "ноя.20",
          ],
          [
            "Выпучивание облицовки местами, выветривание раствора из швов, трещины в кирпичной кладке",
            "То же, до 20%",
            "21-30",
          ],
          [
            "Массовые выпучивания с отпадением штукатурки или выветриванием раствора из швов, выпадение отдельных кирпичей, частичное поражение гнилью венцов, увлажнение древесины",
            "Повреждения на площади до 50%",
            "31-40",
          ],
          [
            "Неравномерные осадки, перекос стояков проемов, частичное разрушение кирпичной кладки облицовки, поражение гнилью древесины окладных и местами вышерасположенных венцов",
            "-",
            "41-50",
          ],
          [
            "Выпадение кирпичей из кладки, неравномерные осадки, поражение древесины гнилью",
            "-",
            "51-60",
          ],
          ["Разрушение облицовки, поражение древесины гнилью", "-", "61-70"],
          [],
          ["Обозначение участка"," Фактический физический износ участка элементов, % Фi","Площадь участка, кв.м"]
        );

        break;
      case "5":
        wallData.push(
          ["Стены"],
          ["Признаки износа", "Количественная оценка", "Физический износ, %",],
          ["Отдельные трещины и выбоины", "Ширина трещины до 1 мм", "0-10"],
          [
            "Глубокие трещины и отпадение штукатурки местами, выветривание швов",
            "Ширина трещин до 2 мм, глубина до 1/3 толщины стены, разрушение швов на глубину до 1 см на площади до 10%",
            "ноя.20",
          ],
          [
            "Отслоение и отпадение штукатурки стен, карнизов и перемычек, выветривание швов, ослабление кирпичной кладки, выпадение отдельных кирпичей, трещины в карнизах и перемычках, увлажнение поверхности стен",
            "Глубина разрушения швов до 2 см на площади до 30%. Ширина трещины более 2 мм",
            "21-30",
          ],
          [
            "Массовое отпадение штукатурки, выветривание швов, ослабление кирпичной кладки стен, карниза, перемычек с выпадением отдельных кирпичей, высолы и следы увлажнения",
            "Глубина разрушения швов до 4 см на площади до 50%",
            "31-40",
          ],
          [
            "Сквозные трещины в перемычках и под оконными проемами, выпадение кирпичей, незначительное отклонение от вертикали и выпучивание стен",
            "Отклонение стены от вертикали в пределах помещения более 1/200 длины деформируемого участка",
            "41-50",
          ],
          [
            "Массовое прогрессирующие сквозные трещины, ослабление и частичное разрушение кладки, заметное искривление стен",
            "Выпучивание с прогибом более 1/200 длины деформируемого участка",
            "51-60",
          ],
          ["Разрушение кладки местами", "-", "61-70"],
          [],
          ["Обозначение участка"," Фактический физический износ участка элементов, % Фi","Площадь участка, кв.м"]
        );

        break;
      case "6":
        wallData.push(
          ["Стены"],
          ["Признаки износа", "Количественная оценка", "Физический износ, %",],
          [
            "Мелкие единичные трещины и местные выбоины в керамике",
            "Ширина трещин до 1 мм. Повреждения на площади до 10%",
            "0-10",
          ],
          [
            "Трещины на откосах проемов, отслоение облицовки и выпадение отдельных блоков или плиток на фасаде",
            "Трещины шириной более 1 мм",
            "ноя.20",
          ],
          [
            "Отслоение облицовки от кладки, трещины в швах, следы влаги в местах отсутствия облицовки",
            "Трещины в швах шириной до 2 мм. Выпадение плитки до 20% площади",
            "21-30",
          ],
          [
            "Выпадение облицовки, трещины в кладке, выветривание раствора из швов, высолы и следы увлажнения на поверхности кладки в местах отсутствия облицовки, трещины в отдельных перемычках",
            "Выпадение облицовки на площади более 20%. Трещины в кладке шириной более 2 мм",
            "31-40",
          ],
          [
            "Трещины в кирпичной кладке и в перемычках, выпадение отдельных кирпичей из карнизов, массовое отпадение облицовки, следы увлажнения стен",
            "Глубина трещин в кладке 0,5 толщины стены, трещины в перемычках шириной более 2 мм",
            "41-50",
          ],
          [
            "Полное отпадение облицовки, развивающиеся трещины в кладке и перемычках, выпадение кирпичей из кладки, заметное искривление стен, ослабление связей между отдельными участками стен",
            "Отклонение стены от вертикали в пределах помещения более 1/200 его высоты",
            "51-60",
          ],
          ["Массовое разрушение кладки", "-", "61-70"],
          [],
          ["Обозначение участка"," Фактический физический износ участка элементов, % Фi","Площадь участка, кв.м"]
        );

        break;
      case "7":
        wallData.push(
          ["Стены"],
          ["Признаки износа", "Количественная оценка", "Физический износ, %",],
          [
            "Отдельные трещины и выбоины",
            "Повреждения на площади до 5%",
            "0-10",
          ],
          [
            "Выветривание швов или трещины в штукатурке местами, коррозия металлических обделок выступающих частей",
            "То же, до 10%",
            "ноя.20",
          ],
          [
            "Выветривание швов отдельных камней, трещины в швах или отпадение штукатурки местами, сколы краев камней, глубокие трещины в карнизе",
            "Ширина трещин до 5 мм",
            "21-30",
          ],
          [
            "Глубокие трещины и выпадение камней карниза, массовое выветривание швов и камней кладки, отпадение штукатурки",
            "Глубина выветривания швов до 2 см. Площадь повреждений до 20%",
            "31-40",
          ],
          [
            "Сквозные трещины и выпадение камней в перемычках, карнизах и углах здания, незначительные отклонения от вертикали и выпучивание отдельных участков стен",
            "Отклонение от вертикали до 1/200 высоты помещений, выпучивание до 1/200 длины деформируемого участка",
            "41-50",
          ],
          [
            "Вертикальные трещины в простенках, разрушение и расслоение кадки стен местами, нарушение связи отдельных участков кладки",
            "-",
            "51-60",
          ],
          [
            "Массовое разрушение кладки, наличие временных креплений",
            "-",
            "61-70",
          ],
          [],
          ["Обозначение участка"," Фактический физический износ участка элементов, % Фi","Площадь участка, кв.м"]
        );

        break;
      case "8":
        wallData.push(
          ["Стены"],
          ["Признаки износа", "Количественная оценка", "Физический износ, %",],
          [
            "Нарушение покрытия выступающих частей фасада, отдельные мелкие выбоины, трещины",
            "На площади до 5%",
            "0-10",
          ],
          [
            "Выбоины местами в фактурном слое, ржавые потеки, загрязнение и выцветание наружной отделки",
            "На площадь до 30%",
            "ноя.20",
          ],
          [
            "Отслоение и выветривание раствора в стыках, следы протечек через стыки внутри здания, трещины",
            "Протечки в 5% помещений. Ширина трещин до 2 мм",
            "21-30",
          ],
          [
            "Глубоко раскрытые усадочные трещины, выветривание раствора в стыках, следы постоянных протечек, промерзание и продувание через стыки",
            "Ширина трещин до 3 мм. Повреждения на площади до 20%. Протечки и промерзания в 20% помещений",
            "31-40",
          ],
          [
            "Диагональные трещины по углам простенков, вертикальные трещины по перемычкам, в местах установки балконных плит и козырьков",
            "Ширина раскрытых трещин до 3 мм",
            "41-50",
          ],
          [
            "Вертикальные широко раскрытые трещины в стыках и перемычках, нарушение связи между отдельными участками стен",
            "Ширина трещин более 3 мм, длина трещин более 3 м",
            "51-60",
          ],
          [
            "Заметное искривление горизонтальных и вертикальных стен, массовое разрушение блоков и панелей",
            "Выпучивание стен более 1/200 длины деформированного участка, отклонение от вертикали более 1/100 высоты стены в пределах помещения",
            "61-70",
          ],
          [],
          ["Обозначение участка"," Фактический физический износ участка элементов, % Фi","Площадь участка, кв.м"]
        );

        break;
      case "9":
        wallData.push(
          ["Стены"],
          ["Признаки износа", "Количественная оценка", "Физический износ, %",],
          [
            "Незначительные повреждения отделки панелей, усадочные трещины, выбоины",
            "Повреждения на площади до 10 %. Ширина трещин до 0,3 мм",
            "0-10",
          ],
          [
            "Выбоины в фактурном слое, ржавые потеки",
            "Повреждения на площади до 15%",
            "ноя.20",
          ],
          [
            "Отслоение раствора в стыках, трещины на наружной поверхности, следы протечек в помещениях",
            "Ширина трещин до 1 мм. Протечки на площади до 10%",
            "21-30",
          ],
          [
            "Трещины, выбоины, отслоение защитного слоя бетона, местами протечки и промерзание в стыках",
            "Ширина трещин до 2 мм. Повреждения на площади до 20%",
            "31-40",
          ],
          [
            "Горизонтальные трещины в простенках и вертикальные в перемычках, выпучивание бетонных слоев, протечки и промерзание панелей",
            "Ширина трещин до 3 мм. Выпучивание до 1/200 расстояния между опорными участками панелей",
            "41-50",
          ],
          [
            "Трещины в простенках и перемычках, разрушение (деструкция) утеплителя, протечки и промерзание",
            "Ширина трещин более 3 мм",
            "51-60",
          ],
          [
            "Массовые трещины и деформации, разрушение и оседание утеплителя, протечки и промерзание панелей",
            "-",
            "61-70",
          ],
          [],
          ["Обозначение участка"," Фактический физический износ участка элементов, % Фi","Площадь участка, кв.м"]
        );

        break;
      case "10":
        wallData.push(
          ["Стены"],
          ["Признаки износа", "Количественная оценка", "Физический износ, %",],
          [
            "Повреждение обрамлений выступающих частей фасада, местами мелкие выбоины",
            "Повреждения на площади до 5%",
            "0-10",
          ],
          [
            "Трещины, выветривание раствора из стыков, мелкие повреждения облицовки или фактурного слоя, следы протечек через стыки внутри здания",
            "То же, до 10%",
            "ноя.20",
          ],
          [
            "Массовое отслоение, выветривание раствора из стыков, повреждение облицовки или фактурного слоя панелей, следы протечек внутри здания",
            "Повреждения на площади до 20%",
            "21-30",
          ],
          [
            "Промерзание стен, разрушение заделки стыков",
            "Промерзание в 5% помещений",
            "31-40",
          ],
          [
            "Следы протечек внутри помещения, высолы",
            "Повреждения в 10% помещений, наружные повреждения на площади до 30%",
            "41-50",
          ],
          [
            "Выпучивание или смещение панелей, разрушение узлов крепления панелей",
            "Прогиб панели до 1/200 ее длины",
            "51-60",
          ],
          [
            "Деформация стен, смещение панелей, трещины в панелях, разрушение узлов",
            "Прогиб панели более 1/200 ее длины",
            "61-70",
          ],
          [],
          ["Обозначение участка"," Фактический физический износ участка элементов, % Фi","Площадь участка, кв.м"]
        );

        break;

      default:
        wallData.push("Ошибка передачи стен");
    }

    let roofingData = [];
    switch (roofingType) {
      case "1":
        roofingData.push(
          ["Кровля"],
          ["Признаки износа", "Количественная оценка", "Физический износ, %",],
          [
            "Одиночные мелкие повреждения и пробоины в кровле и местах примыкания к вертикальным поверхностям, прогиб настенных желобов",
            "0-20",
            "1",
          ],
          [
            "Вздутие поверхности, трещины, разрывы (местами верхнего слоя кровли, требующие замены до 10% кровли); ржавление и значительные повреждения настенных желобов и ограждающей решетки; проникновение влаги в местах примыканий к вертикальным поверхностям; повреждение деталей водоприемного устройства (в плоских крышах)",
            "21-40",
            "2",
          ],
          [
            "Разрушение верхнего и местами нижних слоев покрытия; вздутия, требующие замены от 10 до 25% кровельного покрытия; ржавление и разрушение настенных желобов или водоприемных устройств, свесов и компенсаторов; ротечки кровли местами; массовые повреждения ограждающей решетки",
            "41-60",
            "3",
          ],
          [
            "Массовые протечки, отслоения покрытия от основания, отсутствие частей покрытия, ограждающая решетка разрушена",
            "61-80",
            "4",
          ],
          [],
          ["Обозначение участка"," Фактический физический износ участка элементов, % Фi","Площадь участка, кв.м"]
        );

        break;
      case "2":
        roofingData.push(
          ["Кровля"],
          ["Признаки износа", "Количественная оценка", "Физический износ, %",],
          [
            "Одиночные мелкие повреждения и пробоины в кровельном покрытии, водоотводящие устройства и покрытия из оцинкованной стали погнуты, верхний защитный слой и защитно-отделочное покрытие кровли отсутствует на площади до 10%",
            "0-20",
            "1",
          ],
          [
            "Вздутия мастичного покрытия и повреждения (трещины, отслаивания в местах сопряжения с вертикальными конструкциями), требующие замены до 10% кровли; ржавление и значительные повреждения настенных желобов и ограждений решетки; повреждения деталей водоприемных устройств (в плоских крышах)",
            "21-40",
            "2",
          ],
          [
            "Разрывы мастичного покрытия, вздутия покрытия, требующие замены от 10 до 20% площади кровли; разрушение кровельного покрытия в местах примыкания к вертикальным поверхностям; протечки местами; значительное повреждение ограждающей решетки",
            "41-60",
            "3",
          ],
          [
            "Повреждения и просадки основания кровли, трещины в стыках панелей, массовые протечки, разрушение устройств примыкания и ограждающей решетки",
            "61-80",
            "4",
          ],
          [],
          ["Обозначение участка"," Фактический физический износ участка элементов, % Фi","Площадь участка, кв.м"]
        );

        break;
      case "3":
        roofingData.push(
          ["Кровля"],
          ["Признаки износа", "Количественная оценка", "Физический износ, %",],
          [
            "Ослабление крепления отдельных листов к обрешетке, отдельные протечки",
            "0-20",
            "1",
          ],
          [
            "Неплотности фальцев, пробоины и нарушение примыканий к выступающим частям местами; просветы при осмотре со стороны чердака; повреждения настенных желобов",
            "21-40",
            "2",
          ],
          [
            "Ржавчина на поверхности кровли, свищи, пробоины; искривление и нарушение креплений ограждающей решетки; большое количество протечек",
            "41-60",
            "3",
          ],
          [
            "Массовые протечки, сильная ржавчина на поверхности кровли со стороны чердака, разрушение фальцев, большое количество заплат на кровле, разрушение ограждающей решетки",
            "61-80",
            "4",
          ],
          [],
          ["Обозначение участка"," Фактический физический износ участка элементов, % Фi","Площадь участка, кв.м"]
        );

        break;
      case "4":
        roofingData.push(
          ["Кровля"],
          ["Признаки износа", "Количественная оценка", "Физический износ, %",],
          [
            "Искривление местами металлических желобов; ослабление креплений отдельных асбоцементных листов к обрешетке",
            "0-20",
            "1",
          ],
          [
            "Протечки и просветы в отдельных местах, отставание и трещины коньковых плит; отрыв листов до 10% площади кровли",
            "21-40",
            "2",
          ],
          [
            "Отсутствие отдельных листов, отколы и трещины, протечки, ослабление креплений листов к обрешетке",
            "41-60",
            "3",
          ],
          [
            "Массовое разрушение кровли, отсутствие части настенных желобов и обделки свесов, большое количество заплат из рулонных материалов",
            "61-80",
            "4",
          ],
          [],
          ["Обозначение участка"," Фактический физический износ участка элементов, % Фi","Площадь участка, кв.м"]
        );

        break;
      case "5":
        roofingData.push(
          ["Кровля"],
          ["Признаки износа", "Количественная оценка", "Физический износ, %",],
          [
            "Единичные щели и неплотное примыкание черепиц, частичное нарушение промазки между черепицами",
            "0-20",
            "1",
          ],
          [
            "Повреждения отдельных черепиц (не более 1 черепицы на 1 м2); пробоины и ржавчина в подвесных желобах. Массовые разрушения промазки швов",
            "21-40",
            "2",
          ],
          [
            "Повреждение и раскол отдельных черепиц (2-3 черепицы на 1 м2), протечки, просветы, проникание воды и снега через щели",
            "41-60",
            "3",
          ],
          [
            "Массовые протечки кровли, отставание и повреждение большинства черепиц, большое количество заплат, отсутствие части обделок и подвесных желобов",
            "61-80",
            "4",
          ],
          [],
          ["Обозначение участка"," Фактический физический износ участка элементов, % Фi","Площадь участка, кв.м"]
        );

        break;
      case "6":
        roofingData.push(
          ["Кровля"],
          ["Признаки износа", "Количественная оценка", "Физический износ, %",],
          [
            "Загрязненность кровли, повреждение отдельных дранок до 5% площади кровли",
            "0-20",
            "1",
          ],
          [
            "Выпадение отдельных дранок до 10% площади кровли, ржавчина на металлических обрамлениях",
            "21-40",
            "2",
          ],
          [
            "Гниль или выпадение дранок на площади до 40% кровли, лишайники на поверхности кровли",
            "41-60",
            "3",
          ],
          ["Массовое поражение гнилью и выпадение дранок", "61-80", "4"],
          [],
          ["Обозначение участка"," Фактический физический износ участка элементов, % Фi","Площадь участка, кв.м"]
        );

        break;
      case "7":
        roofingData.push(
          ["Кровля"],
          ["Признаки износа", "Количественная оценка", "Физический износ, %",],
          ["Мелкие повреждения досок, загрязненность кровли", "0-20", "1"],
          [
            "Трещины в досках верхнего и нижнего ряда, наличие ржавчины в металлических желобах, протечки в отдельных местах",
            "21-40",
            "2",
          ],
          [
            "Поражение гнилью досок верхнего слоя, трещины в досках, массовые протечки кровли",
            "41-60",
            "3",
          ],
          [
            "Массовые поражения гнилью и жучком досок, отпадение досок верхнего и нижнего слоев, разрушение подвесных желобов",
            "61-80",
            "4",
          ],
          [],
          ["Обозначение участка"," Фактический физический износ участка элементов, % Фi","Площадь участка, кв.м"]
        );

        break;

      default:
        roofingData.push("Ошибка передачи кровли");
    }

    let floorData = [];
    switch (floorType) {
      case "1":
        floorData.push(
          ["Перекрытия"],
          ["Признаки износа", "Количественная оценка", "Физический износ, %",],
          [
            "Зазоры и щели между досками наката, прогибы балок и настилов",
            "Прогибы балок и настилов до 1/150 пролета",
            "0-40",
          ],
          [
            "Поражение верхних слоев древесины грибком, небольшие трещины, частичное скалывание в узлах соединений балок с настилом, прогиб балок и прогонов",
            "Поражение гилью на площади до 10%. Прогиб балок и прогонов до 1/100 пролета",
            "41-60",
          ],
          [
            "Сильное поражение древесины гнилью, появление продольных и поперечных трещин, расслоение древесины, полное или частичное скалывание в узлах сопряжений балок, прогиб балок и прогонов",
            "Прогиб балок и прогонов до 1/50 пролета",
            "61-80",
          ],
          [],
          ["Обозначение участка"," Фактический физический износ участка элементов, % Фi","Площадь участка, кв.м"]
        );
        break;
      case "2":
        floorData.push(
          ["Перекрытия"],
          ["Признаки износа", "Количественная оценка", "Физический износ, %",],
          ["Незначительные трещины перпендикулярно балкам", "-", "0-20"],
          [
            "Трещины в средней части сводов вдоль балок",
            "Ширина трещин до 1 мм",
            "21-40",
          ],
          [
            "Глубокие трещины в средней части сводов вдоль балок, расшатывание отдельных кирпичей, выщелачивание раствора в швах, выпадение отдельных кирпичей, коррозия балок",
            "То же, до 2 мм. Уменьшение сечения балок на 10%",
            "41-60",
          ],
          [
            "Ослабление кирпичной кладки, массовое выпадение кирпичей, наличие временных подпорок, коррозия и заметные прогибы балок",
            "Прогиб металлических балок до 1/150 пролета. Уменьшение сечения балок более 10%",
            "61-80",
          ],
          [],
          ["Обозначение участка"," Фактический физический износ участка элементов, % Фi","Площадь участка, кв.м"]
        );

        break;
      case "3":
        floorData.push(
          ["Перекрытия"],
          ["Признаки износа", "Количественная оценка", "Физический износ, %",],
          [
            "Мелкие отслоения и трещины в фактурном слое",
            "Повреждения на площади до 10%",
            "0-10",
          ],
          [
            "Отпадение фактурного слоя местами",
            "Повреждения на площади до 20%",
            "11-20",
          ],
          [
            "Усадочные трещины в нижних плитах",
            "Ширина трещин до 1 мм. Суммарная длина трещин на 1 м2 до 0,5 м",
            "21-30",
          ],
          [
            "Отдельные глубокие трещины в нижних плитах и в местах опирания плит, прогибы",
            "Ширина трещин до 2 мм. Прогибы до 1/120 пролета",
            "31-40",
          ],
          [
            "Продольные и поперечные глубокие трещины на нижних плитах, прогиб нижних плит, продавливание верхних плит под мебелью",
            "Ширина трещин до 3 мм. Суммарная длина трещин на 1 м2 до 1 м. Прогиб до 1/100 пролета",
            "41-50",
          ],
          [
            "Массовые сквозные продольные трещины на нижних плитах, отпадение защитного слоя нижних плит с обнажением арматуры, изломы и прогибы плит",
            "Прогиб до 1/50 пролета",
            "51-60",
          ],
          [
            "Прогибы, местами отпадение бетона нижних плит, отслоение бетона и обнажение ребер верхних плит",
            "Прогиб до 1/50 пролета",
            "61-80",
          ],
          [],
          ["Обозначение участка"," Фактический физический износ участка элементов, % Фi","Площадь участка, кв.м"]
        );

        break;
      case "4":
        floorData.push(
          ["Перекрытия"],
          ["Признаки износа", "Количественная оценка", "Физический износ, %",],
          ["Трещины в швах между плитами", "Ширина трещин до 2 мм", "0-10"],
          [
            "Незначительное смещение плит относительно друг друга по высоте",
            "Смещение плит до 1,5 см. Повреждения на площади до 10%",
            "11-20",
          ],
          [
            "Значительное смещение плит перекрытий по высоте",
            "Смещение плит до 3 см. Повреждения на площади до 20%",
            "21-30",
          ],
          [
            "Трещины в плитах, следы протечек или промерзаний",
            "Ширина трещин до 1 мм",
            "31-40",
          ],
          [
            "Поперечные трещины в плитах без оголения арматуры, прогиб",
            "Ширина трещин до 2 мм. Прогиб до 1/100 пролета",
            "41-50",
          ],
          [
            "Глубокие поперечные трещины с оголением арматуры, прогиб",
            "Ширина трещин более 2 мм. Прогиб до 1/80 пролета",
            "51-60",
          ],
          [
            "Множественные глубокие трещины в плитах, смещение",
            "Прогиб более 1/80 пролета",
            "61-80",
          ],
          [],
          ["Обозначение участка"," Фактический физический износ участка элементов, % Фi","Площадь участка, кв.м"]
        );

        break;
      case "5":
        floorData.push(
          ["Перекрытия"],
          ["Признаки износа", "Количественная оценка", "Физический износ, %",],
          [
            "Трещины в местах примыканий к стенам",
            "Ширина трещин до 0,5 мм",
            "0-10",
          ],
          [
            "Трещины в плитах (усадочные или вдоль рабочего пролета)",
            "Ширина трещин до 2 мм. Суммарная длина усадочных трещин на 1 м2 до 0,8 м",
            "11-20",
          ],
          [
            "Трещины в плитах поперек рабочего пролета или множественные усадочные",
            "Ширина раскрытия трещин до 2 мм. Суммарная длина усадочных трещин на 1 м2 до 1,5 м",
            "21-30",
          ],
          [
            "Трещины, прогибы, следы протечек или промерзаний в местах примыканий к наружным стенам",
            "Трещины более 2 мм. Прогибы до 1/150 пролета",
            "31-40",
          ],
          [
            "Развивающиеся трещины у опорных участков плит, прогибы",
            "Прогибы до 1/100 пролета",
            "41-50",
          ],
          [
            "Увеличение трещин и прогибов во времени",
            "Прогибы до 1/100 пролета. Трещины 3 мм",
            "51-80",
          ],
          [],
          ["Обозначение участка"," Фактический физический износ участка элементов, % Фi","Площадь участка, кв.м"]
        );

        break;
      case "6":
        floorData.push(
          ["Перекрытия"],
          ["Признаки износа", "Количественная оценка", "Физический износ, %",],
          [
            "Отдельные трещины в растянутой зоне, незначительное увлажнение местами, поверхностные отколы в растянутой зоне, прогибы",
            "Ширина трещин до 1 мм. Глубина отколов до 3 мм, не более трех на 1 м2",
            "0-40",
          ],
          [
            "Трещины различных направлений, следы увлажнения бетона атмосферными и агрессивными водами, отслоение защитного слоя бетона в растянутой зоне, оголение и коррозия арматуры, механические повреждения и глубокие сколы бетона на большой площади балки, прогиб",
            "Ширина трещин до 2 мм. Коррозия арматуры до 10% сечения. Прогиб до1/50 пролета",
            "41-60",
          ],
          [
            "Трещины по всей длине и высоте балки в середине пролета и в растянутой зоне, следы постоянного увлажнения бетона атмосферными и агрессивными водами, оголение и сильная коррозия арматуры, местами разрывы арматуры, крупные выбоины и сколы бетона в сжатой зоне",
            "Ширина трещин более 2 мм. Коррозия арматуры более 10% сечения. Прогиб более 1/150 пролета",
            "61-80",
          ],
          [],
          ["Обозначение участка"," Фактический физический износ участка элементов, % Фi","Площадь участка, кв.м"]
        );

        break;

      default:
        floorData.push("Ошибка передачи перекрытий");
    }


    // Создание листов и добавление данных
    createSheet(workbook, 'Фундамент', foundationData);
    createSheet(workbook, 'Балкон', balconyData);
    createSheet(workbook, 'Крыша', roofData);
    createSheet(workbook, 'Стены', wallData);
    createSheet(workbook, 'Кровля', roofingData);
    createSheet(workbook, 'Перекрытия', floorData);

    checkAndDoAction('sewerageLength', sewerageLength);
    checkAndDoAction('chuteLength', chuteLength);
    checkAndDoAction('centralHeatingLength', centralHeatingLength);
    checkAndDoAction('coldWaterSupplyLength', coldWaterSupplyLength);
    checkAndDoAction('hotWaterSupplyLength', hotWaterSupplyLength);
    checkAndDoAction('wiringLength', wiringLength);

    createSheet(workbook, 'Канализация', sewerageLengthData);
    createSheet(workbook, 'Мусоропровод', chuteLengthData);
    createSheet(workbook, 'Центральное отопление', centralHeatingLengthData);
    createSheet(workbook, 'Холодное водоснабжение', coldWaterSupplyLengthData);
    createSheet(workbook, 'Горячее водоснабжение', hotWaterSupplyLengthData);
    createSheet(workbook, 'Электрооборудование', wiringLengthData);




    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), `Расчет_износа_${new Date().toLocaleDateString()}.xlsx`);
  };

  return (
    <button className="btn_addwear_wear" onClick={exportToExcel}>
      Экспортировать
    </button>
  );
};

export default ExportExcel;
