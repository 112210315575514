import './RSODynamicButtonGroup.scss'

export let RSODynamicButtonGroup = ({ buttons, setter, getter, color, myStyle, setter1 }) => {
  return (
    <div className={`RSODynamicButtonGroup RSODynamicButtonGroup--${color}`} style={myStyle}>
      {buttons?.map(item =>
        <>
          {item &&
            <button
              key={item?.title}
              className={`RSODynamicButtonGroup-btn ${color ? `RSODynamicButtonGroup-btn--${color}` : ''} ${getter === item?.title ? 'selected' : 'unselected'}`}
              onClick={() => (setter(item?.title))}>
              <i className="fas fa-cut"></i>
              {item?.title}
            </button>
          }
        </>
      )}
    </div>
  );
}
