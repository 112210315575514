import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../../../../../context/AuthContext";
import { dispatcher_context } from "../../../hooks/useStats_dispatcher";
import { fetchAllDailyWaterForDateExcel, fetchWaterData } from "../../../http/dispatcherHttp";
import { ModelTable } from "../../../../../../../components/ModelTable/ModelTable";
import Jelly from "../../../../../../../components/Jelly";
import { useForm } from "react-hook-form";
import { RSODynamicButton } from "../../../../../utils/RSODynamicButton/RSODynamicButton";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { dateConverter, getNowDate } from "../../../../../../../utils/utils";
import './dop_style.scss'

export let RSODynamicDispDateReportWater = () => {

  const [dispatcherAllWaterMonthKeys] = useState([]);
  const [dispatcherAllWaterMonthKeys1] = useState([]);
  const [dispatcherAllWaterMonth, setDispatcherAllWaterMonth] = useState({});

  let { authTokens } = useContext(AuthContext)
  let { selectedDate } = useContext(dispatcher_context)

  const { handleSubmit } = useForm({ mode: 'onBlur' });

  let saveExcel = (e) => {
    e.preventDefault();
    fetchAllDailyWaterForDateExcel(authTokens, selectedDate)
  }

  async function fetchData() {
    try {
      const data = await fetchWaterData(selectedDate, authTokens.access);
      if (data) {
        setDispatcherAllWaterMonth(data);
        dispatcherAllWaterMonthKeys.length = 0;
        dispatcherAllWaterMonthKeys1.length = 0;
        for (let key in data) {
          if (key !== "all_water") {
            dispatcherAllWaterMonthKeys.push(key);
          }
        }
        for (let key1 in data.array_all_water) {
          dispatcherAllWaterMonthKeys1.push(key1);
        }
      }
    } catch (error) {
      console.error('Ошибка при загрузке данных:', error);
    }
  }

  useEffect(() => {
    fetchData();
  }, [selectedDate]);

  return (
    <>
      {(dispatcherAllWaterMonth.length !== 0 && dispatcherAllWaterMonthKeys.length !== 0 && dispatcherAllWaterMonthKeys1.length !== 0) ?
        <>
          <ModelTable style='min' myId="allWaterTable">
            <thead>
              <tr>
                <th colSpan={dispatcherAllWaterMonthKeys.length + 1}>Подано воды</th>
              </tr>
              <tr>
                <th>
                  Дата
                </th>
                <th>
                  Всего, сутки
                </th>
                {dispatcherAllWaterMonthKeys.map((item, index) => {
                  return (
                    <>
                      {item === "all_water" || item === "array_all_water" ? (
                        ""
                      ) : (
                        <th>{item.replaceAll("_", " ")}</th>
                      )}
                    </>
                  )
                })}
              </tr>
            </thead>
            <tbody>
              {dispatcherAllWaterMonthKeys1.map((item, index) => {
                return (
                  <>
                    <tr>
                      {item !== 'total' ? (
                        <td>{item.split('-')[2]}.{item.split('-')[1]}.{item.split('-')[0]}</td>
                      ) : (
                        <td>Всего</td>
                      )}

                      {dispatcherAllWaterMonthKeys.map((item1, index1) => {
                        return (
                          <>
                            <td>{dispatcherAllWaterMonth[item1][item]}</td>
                          </>
                        )
                      })}
                    </tr>
                  </>
                )
              })}
            </tbody>
          </ModelTable>

          <div style={{ display: "flex", alignItems: "center" }}>
            <ReactHTMLTableToExcel
              className="downloadBtn"
              table="allWaterTable"
              filename={`Вода за месяц ${dateConverter(dispatcherAllWaterMonthKeys1[0]).split('.')[1] + '.' + dateConverter(dispatcherAllWaterMonthKeys1[0]).split('.')[2]}`}

              sheet="Отказы оборудования"
              buttonText="Скачать отчет" />
            <h5 style={{ marginLeft: "20px", marginTop: "30px" }}>Перед отпрвкой на печать заполните недостающие данные в Excel</h5>
          </div>
        </> : <Jelly />}
    </>
  )
}