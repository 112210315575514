import React from 'react';
import '../../css/Button.css';

const ButtonData = ({formData, updateState}) => {

  const isFormDataValid = () => {

    // Проверка на заполнение всех полей
    if (!formData.text1 || !formData.text2 || !formData.text3 || !formData.text4 || !formData.text5 ||
        !formData.text23 || !formData.text24 || !formData.dropdown9 || !formData.text27 ||
        !formData.text28 || !formData.text29) {
      alert("Пожалуйста, заполните все необходимые поля");
      return false;
    }

    // Проверка на отрицательные значения
    if (formData.text2 < 0 || formData.text3 < 0 || formData.text4 < 0 || formData.text5 < 0 ||
        formData.text24 < 0 || formData.text27 < 0 || formData.text28 < 0) {
      alert("Поля не могут быть отрицательными");
      return false;
    }

    // Проверка на количество символов в текстовых полях
    if (formData.text2.length !== 4 || formData.text24.length !== 4) {
      alert("Поля 'Год ввода в эксплуатацию', 'Год ввода в эксплуатацию',должны содержать ровно 4 символа");
      return false;
    }

    if(!formData.text26){
      alert("Поле 'Год проведения реконструкции' должно быть заполнено, в случае отсутствия введите '0'")
      return false
    }

    // Все проверки прошли успешно
    return true;
  };

  const handleNextClick = () => {
    if (!isFormDataValid()) {
      return;
    }
    
    updateState(7);
  };

  return(
    <button className="btn-osn-wear" type="button" onClick={handleNextClick}> Далее </button>
  );
};

export default ButtonData;
