import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../../../../../../../../context/AuthContext";
import { worker_workzone_context } from "../../../../../../hooks/useStats_worker_workzone";
import { worker_context } from "../../../../../../hooks/useStats_worker";
import { getChlorine } from "../../../../../../http/worker_http";
import { ModelTable } from "../../../../../../../../../../components/ModelTable/ModelTable";
import { dateConverter } from "../../../../../../../../../../utils/utils";
import { RSODynamicNotFound } from "../../../../../../../../utils/RSODynamicNotFound/RSODynamicNotFound";

export let RSODynamicChlorinePaint = () => {

  let { authTokens, logoutUser } = useContext(AuthContext);

  let { currentWps } = useContext(worker_workzone_context);
  let { smena } = useContext(worker_context);

  let [chlorine, setChlorine] = useState([]);

  useEffect(() => {
    getChlorine(authTokens, smena?.[0]?.ordering_chlorine, setChlorine)
  }, [])

  return (
    <div className="large__container">
      {(currentWps.length !== 0 && chlorine.length !== 0) ?
        <ModelTable>
          <thead>
            <tr>
              <th>Дата</th>
              <th>Время</th>
              <th>ВЗУ</th>
              <th>Смена</th>
              <th>Доза хлора(макс)</th>
              <th>Расход реагента</th>
              <th>Примечание</th>
            </tr>
          </thead>
          <tbody>
            {chlorine?.map(item => 
              <tr>
                <td>{dateConverter(item.date)}</td>
                <td>{item?.time.substring(0, 5)}</td>
                <td>{currentWps?.name}</td>
                <td>{smena[0]?.shift_number}</td>
                <td>{item?.max_chlorine}</td>
                <td>{item?.reagent_consumption}</td>
                <td>{item?.note}</td>
              </tr>  
            )}
          </tbody>
        </ModelTable> : 
        <RSODynamicNotFound
          title="Еще не было передачи хлора за эту смену"
          descr='Перейдите на вкладку "Ввод хлора" и введите данные для отображения здесь'
        />
      }
    </div>
  )

}