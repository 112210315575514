import React, { useState, useContext, useEffect } from "react";

import { API_URL } from "../../../../../../../config";
import AuthContext from "../../../../../../../context/AuthContext";

import "./ButtonAddWear.css";

export const Krovla = () => {
  const { authTokens } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    plotName: "",
    wear: "",
    area: "",
    photo: null,
  });

  // Определяем переменные
  const currentDate = new Date(); // Получаем текущую дату
  const year = currentDate.getFullYear(); // Получаем год
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Получаем месяц (месяцы начинаются с 0, поэтому добавляем 1) и добавляем 0 спереди
  const day = String(currentDate.getDate()).padStart(2, "0"); // Получаем день и добавляем 0 спереди
  const data_in_wear = `${year}-${month}-${day}`; // Форматируем в строку "YYYY-MM-DD"

  const idHouse = localStorage.getItem("ID_House"); // Получаем ID из localStorage

  const element = 5; // Элемент

  const roofingType = 1;

  // Функция обнуления значений
  const resetFormData = () => {
    setFormData({
      plotName: "",
      wear: "",
      area: "",
      photo: null,
    });
  };

  //Считывание текста
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  //Считывание фото
  const handlePhotoUpload = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      photo: file,
    }));
  };

  // Отправка данных
  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestData = {
      position: formData.plotName,
      data: data_in_wear,
      Element: element,
      volume: formData.area,
      degreeOfDamage: formData.wear,
      idHouse: idHouse,
    };

    try {
      const response = await fetch(`${API_URL}/CivilWear/Damage/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Ошибка при отправке данных: ${errorText}`);
      }

      const result = await response.json();
      console.log("Успешно отправлено:", result);
      alert("Износ добавлен !");
      resetFormData();
    } catch (error) {
      console.error("Ошибка при отправке:", error);
    }
  };

  let data = []; //Данные для таблицы

  switch (roofingType) {
    case 1:
      data = [
        ["Признаки износа", "Физический износ, %", "Обозначение участка"],
        [
          "Одиночные мелкие повреждения и пробоины в кровле и местах примыкания к вертикальным поверхностям, прогиб настенных желобов",
          "0-20",
          "1",
        ],
        [
          "Вздутие поверхности, трещины, разрывы (местами верхнего слоя кровли, требующие замены до 10% кровли); ржавление и значительные повреждения настенных желобов и ограждающей решетки; проникновение влаги в местах примыканий к вертикальным поверхностям; повреждение деталей водоприемного устройства (в плоских крышах)",
          "21-40",
          "2",
        ],
        [
          "Разрушение верхнего и местами нижних слоев покрытия; вздутия, требующие замены от 10 до 25% кровельного покрытия; ржавление и разрушение настенных желобов или водоприемных устройств, свесов и компенсаторов; ротечки кровли местами; массовые повреждения ограждающей решетки",
          "41-60",
          "3",
        ],
        [
          "Массовые протечки, отслоения покрытия от основания, отсутствие частей покрытия, ограждающая решетка разрушена",
          "61-80",
          "4",
        ],
      ];

      break;
    case 2:
      data = [
        ["Признаки износа", "Физический износ, %", "Обозначение участка"],
        [
          "Одиночные мелкие повреждения и пробоины в кровельном покрытии, водоотводящие устройства и покрытия из оцинкованной стали погнуты, верхний защитный слой и защитно-отделочное покрытие кровли отсутствует на площади до 10%",
          "0-20",
          "1",
        ],
        [
          "Вздутия мастичного покрытия и повреждения (трещины, отслаивания в местах сопряжения с вертикальными конструкциями), требующие замены до 10% кровли; ржавление и значительные повреждения настенных желобов и ограждений решетки; повреждения деталей водоприемных устройств (в плоских крышах)",
          "21-40",
          "2",
        ],
        [
          "Разрывы мастичного покрытия, вздутия покрытия, требующие замены от 10 до 20% площади кровли; разрушение кровельного покрытия в местах примыкания к вертикальным поверхностям; протечки местами; значительное повреждение ограждающей решетки",
          "41-60",
          "3",
        ],
        [
          "Повреждения и просадки основания кровли, трещины в стыках панелей, массовые протечки, разрушение устройств примыкания и ограждающей решетки",
          "61-80",
          "4",
        ],
      ];

      break;
    case 3:
      data = [
        ["Признаки износа", "Физический износ, %", "Обозначение участка"],
        [
          "Ослабление крепления отдельных листов к обрешетке, отдельные протечки",
          "0-20",
          "1",
        ],
        [
          "Неплотности фальцев, пробоины и нарушение примыканий к выступающим частям местами; просветы при осмотре со стороны чердака; повреждения настенных желобов",
          "21-40",
          "2",
        ],
        [
          "Ржавчина на поверхности кровли, свищи, пробоины; искривление и нарушение креплений ограждающей решетки; большое количество протечек",
          "41-60",
          "3",
        ],
        [
          "Массовые протечки, сильная ржавчина на поверхности кровли со стороны чердака, разрушение фальцев, большое количество заплат на кровле, разрушение ограждающей решетки",
          "61-80",
          "4",
        ],
      ];

      break;
    case 4:
      data = [
        ["Признаки износа", "Физический износ, %", "Обозначение участка"],
        [
          "Искривление местами металлических желобов; ослабление креплений отдельных асбоцементных листов к обрешетке",
          "0-20",
          "1",
        ],
        [
          "Протечки и просветы в отдельных местах, отставание и трещины коньковых плит; отрыв листов до 10% площади кровли",
          "21-40",
          "2",
        ],
        [
          "Отсутствие отдельных листов, отколы и трещины, протечки, ослабление креплений листов к обрешетке",
          "41-60",
          "3",
        ],
        [
          "Массовое разрушение кровли, отсутствие части настенных желобов и обделки свесов, большое количество заплат из рулонных материалов",
          "61-80",
          "4",
        ],
      ];

      break;
    case 5:
      data = [
        ["Признаки износа", "Физический износ, %", "Обозначение участка"],
        [
          "Единичные щели и неплотное примыкание черепиц, частичное нарушение промазки между черепицами",
          "0-20",
          "1",
        ],
        [
          "Повреждения отдельных черепиц (не более 1 черепицы на 1 м2); пробоины и ржавчина в подвесных желобах. Массовые разрушения промазки швов",
          "21-40",
          "2",
        ],
        [
          "Повреждение и раскол отдельных черепиц (2-3 черепицы на 1 м2), протечки, просветы, проникание воды и снега через щели",
          "41-60",
          "3",
        ],
        [
          "Массовые протечки кровли, отставание и повреждение большинства черепиц, большое количество заплат, отсутствие части обделок и подвесных желобов",
          "61-80",
          "4",
        ],
      ];

      break;
    case 6:
      data = [
        ["Признаки износа", "Физический износ, %", "Обозначение участка"],
        [
          "Загрязненность кровли, повреждение отдельных дранок до 5% площади кровли",
          "0-20",
          "1",
        ],
        [
          "Выпадение отдельных дранок до 10% площади кровли, ржавчина на металлических обрамлениях",
          "21-40",
          "2",
        ],
        [
          "Гниль или выпадение дранок на площади до 40% кровли, лишайники на поверхности кровли",
          "41-60",
          "3",
        ],
        ["Массовое поражение гнилью и выпадение дранок", "61-80", "4"],
      ];

      break;
    case 7:
      data = [
        ["Признаки износа", "Физический износ, %", "Обозначение участка"],
        ["Мелкие повреждения досок, загрязненность кровли", "0-20", "1"],
        [
          "Трещины в досках верхнего и нижнего ряда, наличие ржавчины в металлических желобах, протечки в отдельных местах",
          "21-40",
          "2",
        ],
        [
          "Поражение гнилью досок верхнего слоя, трещины в досках, массовые протечки кровли",
          "41-60",
          "3",
        ],
        [
          "Массовые поражения гнилью и жучком досок, отпадение досок верхнего и нижнего слоев, разрушение подвесных желобов",
          "61-80",
          "4",
        ],
      ];

      break;

    default:
      console.log("Ошибка");
  }

  return (
    <div className="container_Wear_ButtonAddWear">
      <form className="form_Wear_ButtonAddWear" onSubmit={handleSubmit}>
        <h3>Кровля:</h3>
        <div className="form-group_Wear_ButtonAddWear">
          <label htmlFor="plotName" className="label_Wear_ButtonAddWear">
            Обозначение участка
          </label>
          <input
            className="input_Wear_ButtonAddWear"
            type="text"
            id="plotName"
            name="plotName"
            value={formData.plotName}
            onChange={handleChange}
          />
        </div>

        <div className="form-group_Wear_ButtonAddWear">
          <label htmlFor="wear" className="label_Wear_ButtonAddWear">
            Фактический физический износ участка
          </label>
          <input
            className="input_Wear_ButtonAddWear"
            type="text"
            id="wear"
            name="wear"
            value={formData.wear}
            onChange={handleChange}
          />
        </div>

        <div className="form-group_Wear_ButtonAddWear">
          <label htmlFor="area" className="label_Wear_ButtonAddWear">
            Площадь участка
          </label>
          <input
            className="input_Wear_ButtonAddWear"
            type="text"
            id="area"
            name="area"
            value={formData.area}
            onChange={handleChange}
          />
        </div>

        <div className="form-group_Wear_ButtonAddWear">
          <label htmlFor="photo" className="label_Wear_ButtonAddWear">
            Прикрепить фото
          </label>
          <input
            className="input_Wear_ButtonAddWear"
            type="file"
            id="photo"
            onChange={handlePhotoUpload}
          />
        </div>

        <button type="submit" className="submit-button_Wear_ButtonAddWear">
          Сохранить данные
        </button>
      </form>

      <div className="table-container_Wear_ButtonAddWear">
        <table className="table_Wear_ButtonAddWear">
          <thead>
            <tr>
              {data[0] &&
                data[0].map((header, index) => <th key={index}>{header}</th>)}
            </tr>
          </thead>
          <tbody>
            {data.slice(1).map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
