import { useContext, useEffect, useState } from 'react';
import './HydrologistBossTable.scss'
import AuthContext from '../../../../../../context/AuthContext';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { getMonthName, sumTime, sumWaterConsumption } from '../../../../utils/utils';
import { HydrologistBossContext } from '../../hooks/useHydrologistBoss';

export const HydrologistBossTable = ({ data }) => {

  // const { authTokens } = useContext(AuthContext);

  const [tableData, setTableData] = useState([]);

  let {
    memoizedWps,
    memoizedDate,
    memoizedWells
  } = useContext(HydrologistBossContext)

  useEffect(() => {
    let buffer = data;
    buffer = buffer?.map((item, index) => {
      let time_split = item.device_operating_time.split(' ');
      return {
        ...item, device_operating_time_days: time_split.length > 1 ? time_split[0] : '',
        device_operating_time_other: time_split.length > 1 ? time_split[1] : time_split[0],
      }
    })

    setTableData(buffer);
  }, [data]);
  

  return (
    <div>
      <form>
        <table className='ModelTable hydrologistBossTable'>
          <thead>
            <tr>
              <th colSpan={4}>{memoizedWps?.wps.find((wps) => wps.id === memoizedWps?.selectedWps)?.name}</th>
              <th colSpan={2}>Скважина №{memoizedWells.wells.find((well) => well.id === memoizedWells.selectedWell)?.number_well}</th>
            </tr>
            <tr>
              <th>Дата <br /> измерения</th>
              <th>Показания <br /> ПУ</th>
              <th>Время работы <br /> ПУ</th>
              <th>Расход воды <br /> м³/сут.</th>
              <th>Производительность <br /> м³/час</th>
              <th>Работник, <br /> осуществляющий <br /> учет</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, index) => (
              <tr key={index}>
                <td>{row.date}</td>
                <td>
                  {tableData[index].meter_readings}
                </td>
                <td>
                  {tableData[index].device_operating_time_days ? '24:00:00' : tableData[index].device_operating_time_other}
                </td>
                <td>
                  {tableData[index].water_consumption}
                </td>
                <td>
                  {tableData[index].reporting_capacity && tableData[index].reporting_capacity.toFixed(2)}
                </td>
                <td>
                  {tableData[index].hydrologist}
                </td>
              </tr>
            ))}
            <tr>
              <td></td>
              <td><b>Итого за месяц:</b></td>
              <td><b>{sumTime(tableData)}</b></td>
              <td></td>
              <td><b>{sumWaterConsumption(tableData)}</b></td>
            </tr>
          </tbody>
        </table>

        {/* Скрытая таблица для скачивания */}
        <table className='ModelTable hydrologistBossTable' style={{display: 'none'}} id='hydrologistBossTable'>
          <thead>
            <tr>
              <th colSpan={3}>{memoizedWps?.wps.find((wps) => wps.id === memoizedWps?.selectedWps)?.name}</th>
              <th colSpan={2}>Скважина №{memoizedWells.wells.find((well) => well.id === memoizedWells.selectedWell)?.number_well}</th>
            </tr>
            <tr>
              <th>Дата <br /> измерения</th>
              <th>Показания <br /> ПУ</th>
              <th>Время работы <br /> ПУ</th>
              <th>Расход воды <br /> м³/сут.</th>
              <th>Работник, <br /> осуществляющий <br /> учет</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, index) => (
              <tr key={index}>
                <td>{row.date}</td>
                <td>
                  {tableData[index].meter_readings}
                </td>
                <td>
                  {tableData[index].device_operating_time_days ? '24:00:00' : tableData[index].device_operating_time_other}
                </td>
                <td>
                  {tableData[index].water_consumption}
                </td>
                <td>
                  {tableData[index].hydrologist}
                </td>
              </tr>
            ))}
            <tr>
              <td></td>
              <td><b>Итого за месяц:</b></td>
              <td><b>{sumTime(tableData)}</b></td>
              <td><b>{sumWaterConsumption(tableData)}</b></td>
            </tr>
            <tr><td></td><td></td><td></td><td></td><td></td></tr>
            <tr>
              <td></td>
              <td><b>Проверил</b></td>
              <td>Гидролог</td>
              <td></td>
              <td>Д.В. Лавренин</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>(должность)</td>
              <td>(подпись)</td>
              <td>(И.О. Фамилия)</td>
            </tr>
          </tbody>
        </table>

        <ReactHTMLTableToExcel
          className="downloadBtn"
          table="hydrologistBossTable"
          filename={`Отчет скважины №${memoizedWells.wells.find((well) => well.id === memoizedWells.selectedWell)?.number_well} за ${getMonthName(memoizedDate.selectedDate)}`}

          sheet="Отказы оборудования"
          buttonText="Скачать отчет" />
      </form>
    </div>
  )

}