import { useContext, useState } from 'react';
import './RSODynamicChlorineInput.scss'
import AuthContext from '../../../../../../../../../../context/AuthContext';
import { useForm } from 'react-hook-form';
import { worker_context } from '../../../../../../hooks/useStats_worker';
import { RSODynamicInput } from '../../../../../../../../utils/RSODynamicInput/RSODynamicInput';
import { RSODynamicButton } from '../../../../../../../../utils/RSODynamicButton/RSODynamicButton';
import { ModalWindow } from '../../../../../../../../../../components/ModalWindow/ModalWindow';
import { postChlorine } from '../../../../../../http/worker_http';

export let RSODynamicChlorineInput = () => {

  let { authTokens, logoutUser } = useContext(AuthContext);
  let { handleSubmit, reset } = useForm({ mode: 'onBlur' });

  let [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  let {
    smena,
    updateSmena
  } = useContext(worker_context)

  let createPostChlorine = (e) => {
    e.preventDefault();
    handleOpenModal()
  }

  let [RSODynamicChlorineInput, setRSODynamicChlorineInput] = useState({});

  return (
    <>
      <form className='RSODynamicChlorineInput__form container' onSubmit={e => handleSubmit(createPostChlorine(e))}>
        <div className="RSODynamicChlorineInput__form-container">
          <RSODynamicInput
            type='number'
            title='Доза хлора (макс.)'
            name='max_chlorine'
            setter={setRSODynamicChlorineInput}
            getter={RSODynamicChlorineInput}
            option='max_chlorine'
            required={true}
            min={0}
            step={0.01}
          />
          <RSODynamicInput
            type='number'
            title='Расход реагента'
            name='reagent_consumption'
            setter={setRSODynamicChlorineInput}
            getter={RSODynamicChlorineInput}
            option='reagent_consumption'
            required={true}
            min={0}
            step={0.01}
          />
          <RSODynamicInput
            type='string'
            title='Примечание'
            name='note'
            setter={setRSODynamicChlorineInput}
            getter={RSODynamicChlorineInput}
            option='note'
            required={false}
          />
          <RSODynamicButton
            title='Сохранить'
            type='submit' />
        </div>
      </form>

      <ModalWindow
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title='Подтверждение хлора'
        hook={RSODynamicChlorineInput}
        isHook1Required={false}>
        <p><b>Вы передаете следующие параметры хлора:</b></p>
        <ul>
          <li>Доза хлора (макс.) - {`${RSODynamicChlorineInput.max_chlorine}`}</li>
          <li>Расход реагента - {`${RSODynamicChlorineInput.reagent_consumption}`}</li>
          {RSODynamicChlorineInput.note &&
            <li>Примечание - {`${RSODynamicChlorineInput.note}`}</li>
          }
        </ul>

        <form className='create__smena-btn' onSubmit={e => handleSubmit(postChlorine(e, authTokens, RSODynamicChlorineInput, smena[0].id, setRSODynamicChlorineInput, updateSmena))}>
          <RSODynamicButton title='Подтвердить' type='submit' />
        </form>
      </ModalWindow>
    </>
  )
}