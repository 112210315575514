import React from 'react';
import '../css/WearHeader.css'

const Header = () => {
  return (
  <div className='wearHeader'>
    Рассчёт износа 
  </div>
  );
};

export default Header;