import './RSODynamic_worker_create_smena.scss'
import { RSODynamicInput } from '../../../../utils/RSODynamicInput/RSODynamicInput'
import { RSODynamicSelect } from '../../../../utils/RSODynamicSelect/RSODynamicSelect'
import { RSODynamicButton } from '../../../../utils/RSODynamicButton/RSODynamicButton'
import { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { worker_context } from '../../hooks/useStats_worker'
import { ModalWindow } from '../../../../../../components/ModalWindow/ModalWindow'
import { postSmena } from '../../http/worker_http'
import { LoadingSpinner } from '../../../../../../components/LoadingSpinner/LoadingSpinner'
import AuthContext from '../../../../../../context/AuthContext'

export let RSODynamic_worker_create_smena = () => {
  let {
    WPS,
    setCurrentSmena,
    currentSmena
  } = useContext(worker_context)

  let { authTokens, logoutUser } = useContext(AuthContext);

  let [createSmena, setCreateSmena] = useState({ water_pumping_station: WPS?.[0]?.id, shift_number: 1, fullname: '' })//Создание смены
  let [isModalOpen, setIsModalOpen] = useState(false);
  let [createSmenaLoader, setCreateSmenaLoader] = useState(false)

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    setCreateSmena({ ...createSmena, water_pumping_station: WPS?.[0]?.id })//Добавляем id ВЗУ в стандартные знаачения кгода получим его в UseEffect
  }, [WPS])//Обновлление UseEffect пр ВЗУ

  const { handleSubmit, reset } = useForm({ mode: 'onBlur' });

  let createSmenaFunc = (e) => {
    e.preventDefault();
    handleOpenModal()
  }


  return (
    <div className="RSODynamic_worker_create_smena" >
      <h2 className="title">Создание смены</h2>
      <form
        className='RSODynamic_worker_create_smena__form'
        onSubmit={e => handleSubmit(createSmenaFunc(e))}>
        <RSODynamicSelect
          name='Выберите ВЗУ'
          list={WPS}
          value='id'
          parse='int'
          visible_value='name'
          setter={setCreateSmena}
          getter={createSmena}
          option='water_pumping_station' />
        <RSODynamicSelect
          name='Выберите смену'
          list={[{ number: 1 }, { number: 2 }]}
          value='number'
          parse='int'
          visible_value='number'
          setter={setCreateSmena}
          getter={createSmena}
          option='shift_number' />
        <RSODynamicInput
          type='text'
          title='Введите ФИО'
          name='ФИО'
          setter={setCreateSmena}
          getter={createSmena}
          option='fullname' />

        {createSmena.fullname ? (
          <RSODynamicButton
            title='Выбрать'
            type='submit' />
        ) : ("")}
      </form>

      <ModalWindow isOpen={isModalOpen} onClose={handleCloseModal} title='Подтверждение смены' hook={createSmena}>
        <p><b>Вы выбрали:</b></p>
        <ul>
          <li>1) ВЗУ - {WPS.find(item => item.id === createSmena.water_pumping_station)?.name}</li>
          <li>2) Смена - {createSmena.shift_number}</li>
          <li>3) ФИО - {createSmena.fullname}</li>
        </ul>
        {!createSmenaLoader ?
          <form className='create__smena-btn' onSubmit={e => handleSubmit(postSmena(e, authTokens, createSmena, setCurrentSmena, setCreateSmenaLoader))}>
            <RSODynamicButton title='подтвердить' type='submit' />
          </form> :
          <LoadingSpinner position={'center'} />
        }

      </ModalWindow>

    </div>
  )
} 