import React, { useEffect } from 'react';
import './HydrologistInput.scss';

export let HydrologistInput = ({ type, title, name, setter, getter, option, required, min, max, step }) => {

  useEffect(() => {
    // Проверяем, есть ли уже данные в getter и не пустая ли дата
    if (getter && getter !== "" && type === 'date') {
      document.getElementsByName(name)[0].valueAsDate = new Date(getter);
    }
  }, [getter, name, type]);

  const handleInputChange = (e) => {
    let value = e.target.value;

    // Проверка для type === 'number'
    if (type === 'number') {
      value = parseFloat(value);
      if (isNaN(value) || value === undefined) {
        handleInvalidValue();
        return;
      }
    }

    // Проверка для type === 'string'
    if (type === 'string') {
      if (value === undefined) {
        handleInvalidValue();
        return;
      }
    }

    // Проверка для пустой строки
    if (value === "") {
      handleInvalidValue();
      return;
    }

    // Обработка с option
    if (option) {
      setter({
        ...getter,
        [option]: value
      });
    } else {
      setter(value);
    }
  };

  const handleInvalidValue = () => {
    if (option) {
      delete getter[option];
    } else {
      setter("");
    }
  };


  return (
    <div className="RSODynamicInput">
      <label className="form-label">{title}</label>
      <input
        className='form-input'
        type={type}
        name={name}
        min={type === 'number' ? min : {}}
        max={type === 'number' ? max : {}}
        step={type === 'number' ? step : {}}
        onChange={e => handleInputChange(e)}
        required={required}
      />
    </div>
  );
};
