import { useContext, useEffect, useState } from 'react'
import './RSODynamicValuesValuesPaint.scss'
import { worker_workzone_context } from '../../../../../../hooks/useStats_worker_workzone'
import { worker_context } from '../../../../../../hooks/useStats_worker'
import AuthContext from '../../../../../../../../../../context/AuthContext'
import { getHourlyRates } from '../../../../../../http/worker_http'
import { ModelTable } from '../../../../../../../../../../components/ModelTable/ModelTable'
import { dateConverter } from '../../../../../../../../../../utils/utils'
import { RSODynamicNotFound } from '../../../../../../../../utils/RSODynamicNotFound/RSODynamicNotFound'

export let RSODynamicValuesValuesPaint = () => {

  let { authTokens, logoutUser } = useContext(AuthContext);

  let { currentWps } = useContext(worker_workzone_context);
  let { smena } = useContext(worker_context);

  let [hourlyRates, setHourlyRates] = useState([]);

  useEffect(() => {
    getHourlyRates(authTokens, smena[0].ordering_dynamic_settings, setHourlyRates, getHourlyRates)
  }, [])


  return (
    <div className="large__container">
      {(currentWps.length !== 0 && hourlyRates.length !== 0) ?
        <ModelTable>
          <thead>
            <tr>
              <th rowSpan='2'>Дата</th>
              <th rowSpan='2'>Время</th>
              <th rowSpan='2'>ВЗУ</th>
              <th rowSpan='2'>Номер смены</th>
              <th rowSpan='2'>Давление, кг/см²</th>
              <th rowSpan='2'>ДТ, кг/см²</th>
              <th rowSpan='2'>ДБ, кг/см²</th>
              <th colSpan='2'>Уровень воды в резервуаре, м</th>
              <th rowSpan='2'>Нагрузка, А</th>
              <th rowSpan='2'>Напряжение, В</th>
              <th rowSpan='2'>Насосы в работе</th>
              <th rowSpan='2'>Средний часовой расход, м3</th>
              <th rowSpan='2'>Наличие пломб на РЧВ, шт</th>
              <th rowSpan='2'>Включение резервного насоса</th>
            </tr>
            <tr>
              <th>№1</th>
              <th>№2</th>
            </tr>
          </thead>
          <tbody>
            {hourlyRates?.map(item =>
              <tr>
                <td>{dateConverter(item?.date)}</td>
                <td>{item?.time.substring(0, 5)}</td>
                <td>{currentWps?.name}</td>
                <td>{smena[0]?.shift_number}</td>
                <td>{item?.pressure}</td>
                <td>{item?.dt}</td>
                <td>{item?.db}</td>
                {item?.water_level_in_reseruar && item.water_level_in_reseruar.map(item1 =>
                  <>
                    <td>
                      {item1}
                    </td>
                    {item.water_level_in_reseruar.length === 1 && <td></td>}
                  </>
                )}
                <td>{item?.current}</td>
                <td>{item?.voltage}</td>
                <td>
                  {item?.pump_in_work && item.pump_in_work.map((pump, pumpIndex) =>
                    <>
                      {pump};&nbsp;
                      {(pumpIndex + 1) % 3 === 0 && <br />}
                    </>
                  )}
                </td>
                <td>{item?.max_consumption}</td>
                <td>{item?.presence_plomb}</td>
                <td>{item?.note}</td>
              </tr>
            )}
          </tbody>
        </ModelTable> : (
          <RSODynamicNotFound
            title="Еще не было передачи почасовых показателей за эту смену"
            descr='Перейдите на вкладку "Ввод почасовых показателей" и введите данные для отображения здесь'
          />)}
    </div>
  )

}