import React from "react";
import "../../css/Wear.css";

const ButtonInz = ({ onButtonClick }) => {
  function identifyNonZeroVariables() {
    const variables = {
      sewerageLength: localStorage.getItem("sewerageLength"),
      chuteLength: localStorage.getItem("chuteLength"),
      centralHeatingLength: localStorage.getItem("centralHeatingLength"),
      coldWaterSupplyLength: localStorage.getItem("coldWaterSupplyLength"),
      hotWaterSupplyLength: localStorage.getItem("hotWaterSupplyLength"),
      wiringLength: localStorage.getItem("wiringLength"),
    };

    const result = [];

    if (
      variables.sewerageLength !== null &&
      parseInt(variables.sewerageLength, 10) !== 0
    ) {
      result.push("Канализация");
    }

    if (
      variables.chuteLength !== null &&
      parseInt(variables.chuteLength, 10) !== 0
    ) {
      result.push("Мусоропровод");
    }

    if (
      variables.centralHeatingLength !== null &&
      parseInt(variables.centralHeatingLength, 10) !== 0
    ) {
      result.push("Центральное отопление");
    }

    if (
      variables.coldWaterSupplyLength !== null &&
      parseInt(variables.coldWaterSupplyLength, 10) !== 0
    ) {
      result.push("Холодное водоснабжение");
    }

    if (
      variables.hotWaterSupplyLength !== null &&
      parseInt(variables.hotWaterSupplyLength, 10) !== 0
    ) {
      result.push("Горячее водоснабжение");
    }

    if (
      variables.wiringLength !== null &&
      parseInt(variables.wiringLength, 10) !== 0
    ) {
      result.push("Электрооборудование");
    }

    return result;
  }

  const sewerage = () => {
    let tableData;

     tableData = [
      [
        "Признаки износа канализации",
        "Физический износ, %",
      ],
      [
        "Ослабление мест присоединения приборов; повреждение эмалированного покрытия моек, раковин, умывальников, ванн на площади до 10% их поверхности; трещины в трубопроводах из полимерных материалов",
        "0-20",
      ],
      [
        "Наличие течи в местах присоединения приборов до 10% всего количества; повреждение эмалированного покрытия моек, раковин, умывальников, ванн до 20% их поверхности; повреждение керамических умывальников и унитазов (сколы, трещины, выбоины) до 10% их количества; повреждения отдельных чугунных трубопроводов ; значительное повреждение трубопроводов из полимерных материалов",
        "21-40",
      ],
      [
        "Массовые течи в местах присоединения приборов; повреждение эмалированного покрытия моек, раковин, ванн, умывальников до 30% их поверхности; повреждение керамических умывальников и унитазов до 20% их количества; повреждение чугунных трубопроводов, масовые повреждение трубопроводов из полимерных материалов",
        "41-60",
      ],
      [
        "Неисправность системы; повсеместные повреждения приборов; следы ремонтов (хомуты, заделка и замена отдельных участков)",
        "61-80",
      ],
    ];
    

    onButtonClick("Канализация", tableData);
  };

  const chute = () => {
    let tableData;

    tableData = [

      ["Признаки износа мусоропровода", "Физический износ, %"],
      ["Мелкие повреждения в стволе, застревание загрузочных клапанов", "0-20"],
      [
        "Неисправность загрузочных клапанов, неплотность в раструбных соединениях, отдельные пробоины в стволе мусоропровода, коррозия металлических частей",
        "21-40",
      ],
      [
        "Отсутствие или поломка металлических деталей загрузочных люков, большие пробоины и расшатывание соединений участков ствола, поломка бункера с шиберами, неисправности в стенках вентиляционной камеры мусоропровода",
        "41-60",
      ],
      [
        "Полное расшатывание ствола мусоропровода, отсутствие или поломка загрузочных устройств, разрушение вентиляционной камеры и неисправности в камере мусоросборника",
        "61-80",
      ],
    ];

    onButtonClick("Мусоропровод", tableData);
  };

  const сentralHeating = () => {
    let tableData;

    tableData = [

      ["Признаки износа центрального отопления", "Физический износ, %"],
      [
        "Ослабление прокладок и набивки запорной арматуры, нарушения окраски отопительных приборов и стояков, нарушение теплоизоляции магистралей в отдельных местах",
        "0-20",
      ],
      [
        "Капельные течи в местах врезки запорной арматуры, приборов и в секциях отопительных приборов; отдельные хомуты на стояках и магистралях; значительные нарушения теплоизоляции магистралей; следы ремонта калориферов",
        "21-40",
      ],
      [
        "Капельные течи в отопительных приборах и местах их врезки; следы протечек в отопительных приборах, следы их восстановления, большое количество хомутов на стояках и магистралях, следы их ремонта отдельными местами и выборочной заменой; коррозия трубопроводов магистралей; неудовлетворительная работа калориферов",
        "41-60",
      ],
      [
        "Массовое повреждение трубопроводов (стояков и магистралей), сильное поражение ржавчиной, следы ремонта отдельными местами (хомуты, заварка), неудовлетворительная работа отопительных приборов и запорной арматуры, их закипание; значительное нарушение теплоизоляции трубопроводов",
        "61-80",
      ],
    ];

    onButtonClick("Центральное отопление", tableData);
  };

  const coldWaterSupply = () => {
    let tableData;

    tableData = [
      ["Признаки износа холодного водоснабжения", "Физический износ, %"],
      [
        "Ослабление сальниковых набивок и прокладок кранов и запорной арматуры, в некоторых смывных бачках имеются утечки воды, повреждение окраски трубопроводов в отдельных местах",
        "0-20",
      ],
      [
        "Капельные течи в местах врезки кранов и запорной арматуры; отдельные повреждения трубопроводов (свищи, течи); поражение коррозией отдельных участков трубопроводов; утечки воды в 20% приборов и смывных бачков",
        "21-40",
      ],
      [
        "Расстройство арматуры и смывных бачков (до 40%); следы ремонта трубопроводов (хомуты, заварка, замена отдельных участков); значительная коррозия трубопроводов; повреждение до 10% смывных бачков (трещины, потеря крышек, рукояток)",
        "41-60",
      ],
      [
        "Полное расстройство системы, выход из строя запорной арматуры, большое количество хомутов, следы замены отдельными местами трубопроводов, большая коррозия элементов системы, повреждение до 30% смывных бачков",
        "61-80",
      ],
    ];

    onButtonClick("Холодное водоснабжение", tableData);
  };

  const hotWaterSupply = () => {
    let tableData;

    tableData = [
      ["Признаки износа горячего водоснабжения", "Физический износ, %"],
      [
        "Ослабление сальниковых набивок и прокладок кранов и запорной арматуры, в некоторых смывных бачках имеются утечки воды, повреждение окраски трубопроводов в отдельных местах",
        "0-20",
      ],
      [
        "Капельные течи в местах врезки кранов и запорной арматуры; отдельные повреждения трубопроводов (свищи, течи); поражение коррозией отдельных участков трубопроводов; утечки воды в 20% приборов и смывных бачков",
        "21-40",
      ],
      [
        "Расстройство арматуры и смывных бачков (до 40%); следы ремонта трубопроводов (хомуты, заварка, замена отдельных участков); значительная коррозия трубопроводов; повреждение до 10% смывных бачков (трещины, потеря крышек, рукояток)",
        "41-60",
      ],
      [
        "Полное расстройство системы, выход из строя запорной арматуры, большое количество хомутов, следы замены отдельными местами трубопроводов, большая коррозия элементов системы, повреждение до 30% смывных бачков",
        "61-80",
      ],
    ];

    console.log(tableData)

    onButtonClick("Горячее водоснабжение", tableData);
  };

  const wiring = () => {
    let tableData;

      tableData = [
        ["Признаки износа Электрооборудованиеи", "Физический износ, %"],
        [
          "Неисправность, ослабление закреплений и отсутствие отдельных приборов (розеток, штепселей, патронов и т.д.); следы коррозии на поверхности металлических шкафов и частичное повреждение деревянных крышек",
          "0-20",
        ],
        [
          "Повреждение изоляции магистральных и внутриквартирных сетей в отдельных местах, потеря эластичности изоляции проводов, открытые проводки покрыты значительным слоем краски, отсутствие части приборов и крышек к ним, следы ремонта вводно-распределительных устройств (ВРУ)",
          "21-40",
        ],
        [
          "Полная потеря эластичности изоляции проводов, значительные повреждения магистральных и внутриквартирных сетей и приборов, следы ремонта системы с частичной заменой сетей и приборов отдельными местами, наличие временных прокладок, неисправность ВРУ",
          "41-60",
        ],
        [
          "Неисправность системы: проводки, щитков, приборов, ВРУ; отсутствие части приборов; оголение проводов; следы больших ремонтов (провесы проводов, повреждения шкафов, щитков, ВРУ)",
          "61-80",
        ],
      ];

    onButtonClick("Электрооборудование", tableData);
  };

  const handleClick = () => {
    const arraypage = identifyNonZeroVariables();
    let page = 0;
    console.log(arraypage);
    console.log(arraypage[0]);

    switch (arraypage[page]) {
      case "Канализация":
        sewerage();
        break;
      case "Мусоропровод":
        chute();
        break;
      case "Центральное отопление":
        сentralHeating();
        break;
      case "Холодное водоснабжение":
        coldWaterSupply();
        break;
      case "Горячее водоснабжение":
        hotWaterSupply();
        break;
      case "Электрооборудование":
        wiring();
        break;
      default:
        alert("Ошибка выбора элемента !");
    }
  };

  return <button className="btn_addwear_wear" onClick={handleClick}>Инженерные системы</button>;
};

export default ButtonInz;
