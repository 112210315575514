import React from "react";

import "../ResidentialHouse.css";

export const ResidentialInz = ({ formDataResidential, onDataChange }) => {

  //Считывание данных
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    onDataChange({ ...formDataResidential, [id]: value });
  };

  return (
    <div className="Residential_Inz_Main">
      <div className="Residential_Inz_Inputs">
        <div className="Residential_Inz_Input_Field_Group">
          <input
            className="Residential_Inz_Input_Field"
            type="number"
            id="hot_water_system"
            placeholder="Система горячего водоснабжения, всего, м"
            required
            value={formDataResidential.hot_water_system || ''}
            onChange={handleInputChange}
          />
          <input
            className="Residential_Inz_Input_Field"
            type="number"
            id="central_heating_system"
            placeholder="Система центрального отопления, всего, м"
            required
            value={formDataResidential.central_heating_system || ''}
            onChange={handleInputChange}
          />
          <input
            className="Residential_Inz_Input_Field"
            type="number"
            id="cold_water_system"
            placeholder="Система холодного водоснабжения, всего, м"
            required
            value={formDataResidential.cold_water_system || ''}
            onChange={handleInputChange}
          />
          <input
            className="Residential_Inz_Input_Field"
            type="number"
            id="sewage_system"
            placeholder="Система канализации, всего, м"
            required
            value={formDataResidential.sewage_system || ''}
            onChange={handleInputChange}
          />
          <input
            className="Residential_Inz_Input_Field"
            type="number"
            id="electrical_system"
            placeholder="Система электрооборудования, всего, м"
            required
            value={formDataResidential.electrical_system || ''}
            onChange={handleInputChange}
          />
          <input
            className="Residential_Inz_Input_Field"
            type="number"
            id="trash_chutes"
            placeholder="Мусоропроводы, всего, м"
            required
            value={formDataResidential.trash_chutes || ''}
            onChange={handleInputChange}
          />
        </div>
      </div>
    </div>
  );
};
