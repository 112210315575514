import React, { useState, useEffect, useContext } from "react";
import "./ButtonShowWear.css";
import { API_URL } from "../../../../../../../config";
import AuthContext from "../../../../../../../context/AuthContext";

export const FactElementWear = () => {
  const [tableData, setTableData] = useState(null); // Инициализация данных таблицы как null
  const { authTokens } = useContext(AuthContext); // Токен авторизации
  const id = 1; // ID дома

  // Функция для выполнения запросов
  const fetchData = async (elementType) => {
    const api = `${API_URL}/CivilWear/House/houseElementWear/${id}/${elementType}/`;

    try {
      const response = await fetch(api, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
      });

      if (!response.ok) {
        throw new Error("Ошибка при получении данных");
      }

      const data = await response.json();
      console.log(`Данные для элемента ${elementType}:`, data.rez); 
      return data.rez;
    } catch (error) {
      console.error("Ошибка !", error.message);
      return "Нет данных"; // Если произошла ошибка
    }
  };

  // Преобразование значений в проценты
  const toPercent = (value) => {
    return value === "Нет данных" ? value : value + "%";
  };

  // Функция для загрузки данных сразу при монтировании компонента
  useEffect(() => {
    const loadTableData = async () => {
      const results = {
        foundation: await fetchData(0),
        wall: await fetchData(1),
        floor: await fetchData(2),
        balcony: await fetchData(3),
        roof: await fetchData(4),
        roofing: await fetchData(5),
        sewerage: await fetchData(6),
        chute: await fetchData(7),
        centralHeating: await fetchData(8),
        coldWaterSupply: await fetchData(9),
        hotWaterSupply: await fetchData(10),
        wiring: await fetchData(11),
      };

      // Обновление таблицы
      setTableData([
        ["Фундамент", "Стены", "Балконы и т.д", "Крыша", "Кровля", "Перекрытия"],
        [
          toPercent(results.foundation),
          toPercent(results.wall),
          toPercent(results.balcony),
          toPercent(results.roof),
          toPercent(results.roofing),
          toPercent(results.floor),
        ],
        [
          "Канализация",
          "Мусоропровод",
          "Центральное отопление",
          "Холодное водоснабжение",
          "Горячее водоснабжение",
          "Электрооборудование",
        ],
        [
          toPercent(results.sewerage),
          toPercent(results.chute),
          toPercent(results.centralHeating),
          toPercent(results.coldWaterSupply),
          toPercent(results.hotWaterSupply),
          toPercent(results.wiring),
        ],
      ]);
    };

    // Загружаем данные при монтировании компонента
    loadTableData();
  }, [authTokens, id]); // Зависимости

  return (
    <div className="container_ButtonShowWear">
      {tableData ? (
        <table className="table_ButtonShowWear">
          <thead className="thead_ButtonShowWear">
            <tr className="tr_ButtonShowWear">
              {tableData[0].map((header, index) => (
                <th key={index} className="th_ButtonShowWear">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="tbody_ButtonShowWear">
            <tr className="tr_ButtonShowWear">
              {tableData[1].map((cell, index) => (
                <td key={index} className="td_ButtonShowWear">
                  {cell}
                </td>
              ))}
            </tr>
            <tr className="tr_ButtonShowWear">
              {tableData[2].map((header, index) => (
                <th key={index} className="th_ButtonShowWear">
                  {header}
                </th>
              ))}
            </tr>
            <tr className="tr_ButtonShowWear">
              {tableData[3].map((cell, index) => (
                <td key={index} className="td_ButtonShowWear">
                  {cell}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      ) : (
        <p>Загрузка данных...</p>
      )}
    </div>
  );
};
