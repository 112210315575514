//Компонента ввода основных данных мкд
import React from "react";
import { AddressSuggestions } from 'react-dadata';
import "../css/Data.css";


const Data = ({ formData, onDataChange }) => {

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    onDataChange({ ...formData, [id]: value });
  };

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    onDataChange({ ...formData, [id]: checked });
  };

  const handleAddressChange = (value) => {
    onDataChange({ ...formData, text1: value, text22: value });
  };
  

  return (
    <div className="main-content">
      <form>
        <div className="data_mane">
          <div className="data_input">
          <AddressSuggestions
              token="6dc7b29f2b8e6eb9bc537838a324af7caafc5eca"
              value={formData.text1}
              onChange={(suggestion) => handleAddressChange(suggestion.value)}
              inputProps={{
                className: "input-field-data-wear-AddressSuggestions",
                placeholder: "Адрес"
              }}
            />
            <input
              className="input-field-data-wear"
              type="number"
              id="text2"
              placeholder="Год ввода в эксплуатацию"
              required
              value={formData.text2}
              onChange={handleInputChange}
              
            />
            <input
              className="input-field-data-wear"
              type="number"
              id="text3"
              placeholder="Этажность"
              required
              value={formData.text3}
              onChange={handleInputChange}
            />
            <input
              className="input-field-data-wear"
              type="number"
              id="text4"
              placeholder="Количество подземных этажей"
              required
              value={formData.text4}
              onChange={handleInputChange}
            />
            <input
              className="input-field-data-wear"
              type="number"
              id="text5"
              placeholder="Площадь здания"
              required
              value={formData.text5}
              onChange={handleInputChange}
            />

            <input
              className="input-field-data-wear"
              type="text"
              id="text23"
              placeholder="Организация, осуществляющая управление МКД"
              required
              value={formData.text23}
              onChange={handleInputChange}
            />
            <input
              className="input-field-data-wear"
              type="number"
              id="text24"
              placeholder="Год постройки"
              required
              value={formData.text24}
              onChange={handleInputChange}
            />

            <select
              id="dropdown9"
              class="input-field-data-wear"
              required
              value={formData.dropdown9}
              onChange={handleInputChange}
            >
              <option value="" disabled selected>Стадия жизненного цикла</option>
              <option value="1">эксплуатация</option>
              <option value="2">модернизация</option>
              <option value="3">реконструкция</option>
              <option value="4">капитальный ремонт</option>
            </select>

            <input
              className="input-field-data-wear"
              type="number"
              id="text26"
              placeholder="Год проведения реконструкции"
              required
              value={formData.text26}
              onChange={handleInputChange}
            />
            <input
              className="input-field-data-wear"
              type="number"
              id="text27"
              placeholder="Количество квартир"
              required
              value={formData.text27}
              onChange={handleInputChange}
            />
            <input
              className="input-field-data-wear"
              type="number"
              id="text28"
              placeholder="Количество квартир в подъезде"
              required
              value={formData.text28}
              onChange={handleInputChange}
            />
            <input
              className="input-field-data-wear"
              type="number"
              id="text29"
              placeholder="Объем здания"
              required
              value={formData.text29}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="div-text-span-wear">
          <span className="text-span-wear">Оборудование и благоустройство</span>
        </div>

        <div className="group-checkbox">
          <label className="label-check-data-wear" htmlFor="checkbox1">
            Подвал
          </label>
          <input
            className="input-check-data-wear"
            type="checkbox"
            id="checkbox1"
            checked={formData.checkbox1}
            onChange={handleCheckboxChange}
          />

          <label className="label-check-data-wear" htmlFor="checkbox2">
            Пристройка
          </label>
          <input
            className="input-check-data-wear"
            type="checkbox"
            id="checkbox2"
            checked={formData.checkbox2}
            onChange={handleCheckboxChange}
          />

          <label className="label-check-data-wear" htmlFor="checkbox3">
            Мансарда
          </label>
          <input
            className="input-check-data-wear"
            type="checkbox"
            id="checkbox3"
            checked={formData.checkbox3}
            onChange={handleCheckboxChange}
          />

          <label className="label-check-data-wear" htmlFor="checkbox4">
            Канализация
          </label>
          <input
            className="input-check-data-wear"
            type="checkbox"
            id="checkbox4"
            checked={formData.checkbox4}
            onChange={handleCheckboxChange}
          />

          <label className="label-check-data-wear" htmlFor="checkbox5">
            Мусоропровод
          </label>
          <input
            className="input-check-data-wear"
            type="checkbox"
            id="checkbox5"
            checked={formData.checkbox5}
            onChange={handleCheckboxChange}
          />

          <label className="label-check-data-wear" htmlFor="checkbox6">
            Центральное отопление
          </label>
          <input
            className="input-check-data-wear"
            type="checkbox"
            id="checkbox6"
            checked={formData.checkbox6}
            onChange={handleCheckboxChange}
          />

          <label className="label-check-data-wear" htmlFor="checkbox7">
            Холодное водоснабжение
          </label>
          <input
            className="input-check-data-wear"
            type="checkbox"
            id="checkbox7"
            checked={formData.checkbox7}
            onChange={handleCheckboxChange}
          />

          <label className="label-check-data-wear" htmlFor="checkbox8">
            Горячее водоснабжение
          </label>
          <input
            className="input-check-data-wear"
            type="checkbox"
            id="checkbox8"
            checked={formData.checkbox8}
            onChange={handleCheckboxChange}
          />

          <label className="label-check-data-wear" htmlFor="checkbox9">
            Электропроводка
          </label>
          <input
            className="input-check-data-wear"
            type="checkbox"
            id="checkbox9"
            checked={formData.checkbox9}
            onChange={handleCheckboxChange}
          />

          <label className="label-check-data-wear" htmlFor="checkbox10">
            Лифт
          </label>
          <input
            className="input-check-data-wear"
            type="checkbox"
            id="checkbox10"
            checked={formData.checkbox10}
            onChange={handleCheckboxChange}
          />
        </div>
      </form>
    </div>
  );
};

export default Data;
