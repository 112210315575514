import Footer from "../components/Footer";
import Header from "../components/Header";
import { Link } from 'react-router-dom';
import React, { useContext, useEffect, useState, useRef } from 'react';
import v3 from '../images/iznos.mp4'


const WearPage = () => {


    const [IsV, setIsV] = useState(false);


    function handleButtonClick() {
        setIsV(!IsV);
    }


    return (
        <>
            <Header />
            <div className="ForWear">
                <div className="container">
                    <div className="ForWear__title">
                        Программа «МКД износ»
                    </div>
                    <div className="ForWear__inf">
                        Разработана Инжиниринговым центром «Технологии цифровой среды» Федерального государственного бюджетного образовательного учреждения высшего образования «Орловский государственный университет имени И.С. Тургенева».<br /><br />
                    </div>
                </div>
                <div className="ForWear__block1">
                    <div className="container">
                        <div className="ForWear__block1-describe1">
                            Метод итераций объединяет в себе экспертный метод и автоматизированную информационную среду, что в свою очередь позволяет оценить и визуализировать степень износа жилищного фонда любого региона, а также каждого жилого здания в отдельности с использованием ГИС ЖКХ.
                        </div>
                    </div>
                </div>
                <div className="ForWear__block2">
                    <div className="container">
                        <div className="ForWear__block2-describe2">
                            Периодичность проведения обязательной экспертной оценки специалистами технического состояния здания при использовании метода итераций – два раза в год в периоды сезонных обходов.
                        </div>
                    </div>
                </div>
                <div className="ForWear__block2">
                    <div className="container">
                        <div className="ForWear__block2-describe2">
                            ГИС ЖКХ в данном случае выступает как база данных для сбора, накопления, актуализации и хранения данных об износе конструктивных элементов. Тем самым достигается создание единой базы электронных образов объектов жилого фонда с актуальными характеристиками их технического состояния.
                        </div>
                    </div>
                </div>
                <div className="ForWear__block2">
                    <div className="container">
                        <div className="ForWear__block2-describe2">
                            Физический износ элементов строительной конструкции, систем инженерного оборудования рассчитывается автоматически.
                        </div>
                    </div>
                </div>
                <div className="ForWear__block2">
                    <div className="container">
                        <div className="ForWear__block2-describe2">
                            На основании расчетных данных физического износа и удельного веса элементов строительных конструкций, инженерных систем (как функция от типа жилого здания) рассчитывается физический износ жилого здания в целом.
                        </div>
                    </div>
                </div>
                <div className="ForWear__block2 ForWear__forMargBot">
                    <div className="container">
                        <div className="ForWear__block2-describe2">
                            Пользователи программного комплекса - поставщики информации ГИС ЖКХ - организации, осуществляющие управление многоквартирными домами.
                        </div>
                        <div className="ForWear__ForMargin">
                            <button className="ForVzy__btn ForAds__ForVBtn" onClick={handleButtonClick}>
                                Демо видео
                            </button>
                        </div>
                        {IsV === true ? (
                            <div className="ForAds__BlocV">
                                <video className="AdsPage__V2" width="1200" height="660" controls>
                                    <source src={v3} type="video/mp4" />
                                </video>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default WearPage;