import './RSODynamicCloseSmena.scss'
import { useContext, useEffect, useState } from "react"
import { worker_context } from "../../../../hooks/useStats_worker"
import { ModalWindow } from "../../../../../../../../components/ModalWindow/ModalWindow"
import AuthContext from "../../../../../../../../context/AuthContext";
import { ModelTable } from "../../../../../../../../components/ModelTable/ModelTable";
import { worker_workzone_context } from "../../../../hooks/useStats_worker_workzone";
import Jelly from "../../../../../../../../components/Jelly";
import { RSODynamicButton } from "../../../../../../utils/RSODynamicButton/RSODynamicButton";
import { useForm } from 'react-hook-form';
import { closeSmena } from '../../../../http/worker_http';
import { LoadingSpinner } from '../../../../../../../../components/LoadingSpinner/LoadingSpinner';

export let RSODynamicCloseSmena = () => {

  let { authTokens, logoutUser } = useContext(AuthContext);
  let { handleSubmit, reset } = useForm({ mode: 'onBlur' });

  let [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  let { smena, updateSmena } = useContext(worker_context)
  let { currentWps } = useContext(worker_workzone_context)

  let [shiftDuration, setShiftDuration] = useState('');
  let [createSmenaLoader, setCreateSmenaLoader] = useState(false)

  useEffect(() => {
    const timer = setInterval(() => {
      calculateShiftDuration(smena[0].date, smena[0].time);
    }, 1000);
    updateSmena()
    return () => clearInterval(timer); // Очистка таймера при размонтировании компонента
  }, []);

  let calculateShiftDuration = (date, time) => {
    const shiftStart = new Date(`${date} ${time}`).getTime(); // Время начала смены в миллисекундах
    const currentTime = new Date().getTime(); // Текущее время в миллисекундах

    const shiftDurationMs = currentTime - shiftStart; // Продолжительность смены в миллисекундах
    const hours = Math.floor(shiftDurationMs / (1000 * 60 * 60)); // Вычисляем часы
    const minutes = Math.floor((shiftDurationMs % (1000 * 60 * 60)) / (1000 * 60)); // Вычисляем минуты
    const seconds = Math.floor((shiftDurationMs % (1000 * 60)) / 1000); // Вычисляем секунды

    const result = `${hours}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`; // Форматируем результат

    setShiftDuration(result);
  }

  let submitCloseSmena = (e) => {
    e.preventDefault();
    handleOpenModal()
  }

  return (
    <div className="container">
      {(smena && shiftDuration) ?
        <>
          <ModelTable>
            <thead>
              <tr>
                <th>ВЗУ</th>
                <th>Номер смены</th>
                <th>ФИО</th>
                <th>Сеансов передачи динам. параметров</th>
                <th>Сеансов передачи хлора</th>
                <th>Время работы смены, ЧЧ:ММ:СС</th>
                <th>Передано воды</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{currentWps?.name}</td>
                <td>{smena[0]?.shift_number}</td>
                <td>{smena[0]?.fullname}</td>
                <td>{smena[0]?.ordering_dynamic_settings.length}</td>
                <td>{smena[0]?.ordering_chlorine.length}</td>
                <td>{shiftDuration}</td>
                <td>{smena[0]?.all_water}</td>
              </tr>
            </tbody>
          </ModelTable>

          <form className='RSODynamicCloseSmena__form' onSubmit={e => handleSubmit(submitCloseSmena(e))} >
            <RSODynamicButton
              title='Закрыть смену'
              type='submit' />
          </form>

          <ModalWindow
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            title='Подтверждение закрытия смены'
            hook={smena}
            isHook1Required={false}>
            <p><b>Вы точно хотите закрыть смену?</b></p>
            <p>Если передумали, нажмите на крестик и окно закроется</p>

            {!createSmenaLoader ?
              <form className='create__smena-btn' onSubmit={e => handleSubmit(closeSmena(e, authTokens, smena[0].id, smena[0].water_pumping_station, updateSmena, setCreateSmenaLoader))}>
                <RSODynamicButton title='Подтвердить' type='submit' />
              </form> :
              <LoadingSpinner position={'center'} />
            }
          </ModalWindow>
        </> : <Jelly />
      }
    </div>
  )
}