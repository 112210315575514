import { useContext, useEffect, useState } from "react";
import { RSODynamicInput } from "../../../../utils/RSODynamicInput/RSODynamicInput"
import { getNowDate } from "../../../../../../utils/utils";
import { dispatcher_context } from "../../hooks/useStats_dispatcher";
import { RSODynamicButtonGroup } from "../../../../utils/RSODynamicButtonGroup/RSODynamicButtonGroup"
import { RSODynamicDispDateReportHourly } from "./components/RSODynamicDispDateReportHourly";
import { RSODynamicDispDateReportWater } from "./components/RSODynamicDispDateReportWater";
import { RSODynamicDispDateReportGraphics } from "./components/RSODynamicDispDateReportGraphics";
import { RSODynamicDispDateReportGraphicsPeriod } from "./components/RSODynamicDispDateReportGraphicsPeriod";
import { getDispatcherPeriodReportGraphics } from "../../http/dispatcherHttp";
import AuthContext from "../../../../../../context/AuthContext";
import { RSODynamicSelect } from "../../../../utils/RSODynamicSelect/RSODynamicSelect";

export let RSODynamicDispDateReport = () => {

  let { dateReportTab,
    selectedDate,
    isSelectDateActive,
    selectedPeriodWPS,
    isSelectPeriodDateActive,
    selectedStartOfPeriodDate,
    selectedEndOfPeriodDate,
    setDateReportTab,
    setSelectedDate,
    setSelectedStartOfPeriodDate,
    setSelectedEndOfPeriodDate,
    setSelectedPeriodWPS, } = useContext(dispatcher_context);

  let { authTokens } = useContext(AuthContext);

  let [WPSListForPeriod, setWPSListForPeriod] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const data = await getDispatcherPeriodReportGraphics(authTokens, selectedStartOfPeriodDate, selectedEndOfPeriodDate, setWPSListForPeriod);
      setWPSListForPeriod(Object.keys(data));
      setSelectedPeriodWPS(Object.keys(data)[0])
    };

    fetchData();
  }, [authTokens, selectedStartOfPeriodDate, selectedEndOfPeriodDate]);

  return (
    <div className="large__container">
      {(isSelectDateActive && !isSelectPeriodDateActive) ? (
        <form className="container">
          <RSODynamicInput
            type='date'
            title='Выберите дату'
            name='selectDate'
            setter={setSelectedDate}
            selectedDate={selectedDate}
            value={selectedDate}
            required={true}
          />
        </form>
      ) : (
        <div style={{ display: "flex", flexWrap: "wrap" }} className="container">
          <div style={{ margin: "0 20px 0 20px" }}>
            <RSODynamicInput
              type='date'
              title='Выберите начало периода'
              name='selectDate'
              setter={setSelectedStartOfPeriodDate}
              required={true}
            />
          </div>
          <div>
            <RSODynamicInput
              type='date'
              title='Выберите конец периода'
              name='selectDate'
              setter={setSelectedEndOfPeriodDate}
              required={true}
            />
          </div>
          <div className="RSODynamicSelect" style={{marginLeft: "20px"}}>
            <label className="form-label">Выберите ВЗУ</label>
            <select className='form-input'
              onChange={e => setSelectedPeriodWPS(e.target.value)}
              value={selectedPeriodWPS}>
              {WPSListForPeriod?.map((item, index) =>
                <option key={item} value={item}>{`${item}`}</option>
              )}
            </select>
          </div>
        </div>
      )}

      <RSODynamicButtonGroup
        buttons={[
          { title: 'Почасовые показатели' },
          { title: 'Вода за месяц' },
          { title: 'График за дату' },
          { title: 'График за период' },
        ]}
        setter={setDateReportTab}
        getter={dateReportTab}
        color='green' />

      {dateReportTab === "Почасовые показатели" &&
        <RSODynamicDispDateReportHourly />}

      {dateReportTab === "Вода за месяц" &&
        <RSODynamicDispDateReportWater />}

      {dateReportTab === "График за дату" &&
        <RSODynamicDispDateReportGraphics />}

      {dateReportTab === "График за период" &&
        <RSODynamicDispDateReportGraphicsPeriod />}
    </div>
  )
}