import React, { useState } from "react";

import { ExportExcel } from "./ButtonExtraWear/ExportExcel";

import "./ButtonAdd.css"

export const ButtonExtraWear = () => {
  const [activeOption, setActiveOption] = useState(null);

  const renderOption = () => {
    switch (activeOption) {
      case "exportExcel":
        return <ExportExcel />;
      default:
        return null;
    }
  };

  return (
    <div className="ButtonExtraWear_container">
      <div className="ButtonExtraWear_buttons">
        <button className="ButtonExtraWear_button" onClick={() => setActiveOption("exportExcel")}>Экспорт в Excel</button>
      </div>

      <div className="ButtonExtraWear_content">
        {renderOption()}
      </div>
    </div>
  );
};
