import { useContext } from 'react'
import { worker_workzone_context } from '../../../../hooks/useStats_worker_workzone'
import './RSODynamicChlorine.scss'
import { RSODynamicButtonGroup } from '../../../../../../utils/RSODynamicButtonGroup/RSODynamicButtonGroup'
import { RSODynamicChlorineInput } from './components/RSODynamicChlorineInput/RSODynamicChlorineInput'
import { RSODynamicChlorinePaint } from './components/RSODynamicChlorinePaint/RSODynamicChlorinePaint'

export let RSODynamicChlorine = () => {
  let {
    chlorineTab,
    setChlorineTab
  } = useContext(worker_workzone_context)



  return (
    <>
      <RSODynamicButtonGroup
        buttons={[
          { title: 'Вывод хлора' },
          { title: 'Ввод хлора' },]}
        setter={setChlorineTab}
        getter={chlorineTab}
        color='green' />

      {chlorineTab === "Вывод хлора" && (
        <RSODynamicChlorinePaint />)}
      {chlorineTab === "Ввод хлора" && (
        <RSODynamicChlorineInput />)}
    </>
  )
}