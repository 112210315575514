import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import './RSODynamicArchiveHydrologist.scss'
import { getWPSPumps, getWells } from '../../../../../http/worker_http';
import { worker_workzone_context } from '../../../../../hooks/useStats_worker_workzone';
import AuthContext from '../../../../../../../../../context/AuthContext';
import { RSODynamicSelect } from '../../../../../../../utils/RSODynamicSelect/RSODynamicSelect';
import { API_URL } from '../../../../../../../../../config';
import { ModalWindow } from "../../../../../../../../../components/ModalWindow/ModalWindow";
import { LoadingSpinner } from '../../../../../../../../../components/LoadingSpinner/LoadingSpinner';
import { RSODynamicButton } from '../../../../../../../utils/RSODynamicButton/RSODynamicButton';

export let RSODynamicArchiveHydrologist = () => {

  let { authTokens, logoutUser } = useContext(AuthContext);
  let { currentWps } = useContext(worker_workzone_context)

  const [selectedFiles, setSelectedFiles] = useState(null);
  let [WPSWells, setWPSWells] = useState([]);
  const [selectedWell, setSelectedWell] = useState(null);
  let [isLoading, setIsLoading] = useState(false);
  let [isModalOpen, setIsModalOpen] = useState(false)
  let [fetchResponce, setFetchResponce] = useState(null)
  

  useEffect(() => {
    getWells(authTokens, currentWps.prefabricate_water_pipeline, setWPSWells);
  }, [])

  useEffect(() => {
    setSelectedWell(WPSWells?.[0]?.id)
  }, [WPSWells])


  const handleFileChange = (event) => {
    setSelectedFiles(event.target.files);
  };

  const handleUpload = async () => {
    if (!selectedFiles) return;

    const formData = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append('file', selectedFiles[i]);
    }

    try {
      setIsLoading(true)
      handleOpenModal()
      const response = await axios.post(`${API_URL}/hydrologist/data/set/time/${selectedWell}/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (response.status === 200) {
        setIsLoading(false)
        setFetchResponce(200)
      }
    } catch (error) {
      setFetchResponce('error')
      setIsLoading(false)
      console.error('Error uploading files:', error);
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {!isModalOpen ?
        <>
          <div className='container'>
            <RSODynamicSelect
              name='Выберите номер скважины'
              list={WPSWells}
              value='id'
              parse='int'
              visible_value='number_well'
              setter={setSelectedWell}
              getter={selectedWell}
              secondVisibleName='status'
            />
            <div className="file-upload-button">
              <input type="file" multiple onChange={handleFileChange} />
              <label className="upload-btn" onClick={handleUpload}>Загрузить</label>
            </div>
          </div>
        </> :
        <ModalWindow isOpen={isModalOpen} onClose={handleCloseModal} title='Отправка файла' hook={WPSWells}>
          {isLoading ? <LoadingSpinner position={'center'} /> :
            <>
              {fetchResponce === 200 ?
                <h5 style={{marginBottom: '20px'}}><b>Данные успешно отправлены🔥</b></h5> :
                <h5 style={{ marginBottom: '20px' }}><b>Произошла ошибка❌</b></h5>}
            </>
          }

        </ModalWindow>
      }
    </>
  );
}