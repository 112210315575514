import { useContext, useState } from "react";
import AuthContext from "../../../../../../context/AuthContext";
import { useForm } from "react-hook-form";
import { TControlContext } from "../../hooks/useTControl";
import { TControlButton } from "../../../../utils/TControlButton/TControlButton";
import { ModalWindow } from "../../../../../../components/ModalWindow/ModalWindow";
import { LoadingSpinner } from "../../../../../../components/LoadingSpinner/LoadingSpinner";
import { closeSmena } from "../../../../http/http";

export let CloseSmena = () => {
  let { authTokens, logoutUser } = useContext(AuthContext);
  let { handleSubmit, reset } = useForm({ mode: 'onBlur' });

  let {
    memoizedSmena,
  } = useContext(TControlContext)

  let [isModalOpen, setIsModalOpen] = useState(false);
  let [createSmenaLoader, setCreateSmenaLoader] = useState(false)

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  let submitCloseSmena = (e) => {
    e.preventDefault();
    handleOpenModal()
  }

  return (
    <div className="container">
      {memoizedSmena?.activeSmena?.[0]?.pk &&
        <form className='RSODynamicCloseSmena__form' onSubmit={e => handleSubmit(submitCloseSmena(e))} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <TControlButton
            title='Закрыть смену'
            type='submit' />
        </form>
      }
      <ModalWindow
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title='Подтверждение закрытия смены'
        hook={memoizedSmena.activeSmena?.[0]}
        isHook1Required={false}>
        <p><b>Вы точно хотите закрыть смену?</b></p>
        <p>Если передумали, нажмите на крестик и окно закроется</p>

        {!createSmenaLoader ?
          <form className='create__smena-btn' onSubmit={e => handleSubmit(closeSmena(e, authTokens, memoizedSmena?.activeSmena?.[0]?.pk, setCreateSmenaLoader))}>
            <TControlButton title='Подтвердить' type='submit' />
          </form> :
          <LoadingSpinner position={'center'} />
        }
      </ModalWindow>
    </div>
  )
}