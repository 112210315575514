import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../../../../../../../context/AuthContext";
import { worker_workzone_context } from "../../../../../hooks/useStats_worker_workzone";
import { useForm } from "react-hook-form";
import { dateConverter, getNowDate } from "../../../../../../../../../utils/utils";
import { RSODynamicInput } from "../../../../../../../utils/RSODynamicInput/RSODynamicInput";
import { getArchive } from "../../../../../http/worker_http";
import { ModelTable } from "../../../../../../../../../components/ModelTable/ModelTable";
import { worker_context } from "../../../../../hooks/useStats_worker";
import { RSODynamicNotFound } from "../../../../../../../utils/RSODynamicNotFound/RSODynamicNotFound";

export let RSODynamicArchiveMain = () => {

  let { authTokens, logoutUser } = useContext(AuthContext);
  let { handleSubmit } = useForm({ mode: 'onBlur' });

  let { currentWps, archiveTab, setArchiveTab } = useContext(worker_workzone_context);
  let { smena } = useContext(worker_context);

  let [hourlyRates, setHourlyRates] = useState([]);
  let [chlorine, setChlorine] = useState([]);
  let [repair, setRepair] = useState([]);

  let [selectedDate, setSelectedDate] = useState(getNowDate());


  useEffect(() => {
    getArchive(authTokens, currentWps.id, selectedDate, setHourlyRates, setChlorine, setRepair)
  }, [selectedDate])


  return (
    <div className="large__container">
      <form >
        <RSODynamicInput
          type='date'
          title='Выберите дату'
          name='selectDate'
          setter={setSelectedDate}
          getter={selectedDate}
          value={selectedDate}
          required={true}
        />
      </form>
      {(hourlyRates.length !== 0 && currentWps && smena) ?
        <ModelTable>
          <thead>
            <tr>
              <th colSpan={14}>{`Почасовые показатели на ВЗУ ${currentWps?.name} за ${dateConverter(selectedDate)}`}</th>
            </tr>
            <tr>
              <th rowSpan='2'>Дата</th>
              <th rowSpan='2'>Время</th>
              <th rowSpan='2'>ВЗУ</th>
              <th rowSpan='2'>Давление, кг/см²</th>
              <th rowSpan='2'>ДТ, кг/см²</th>
              <th rowSpan='2'>ДБ, кг/см²</th>
              <th colSpan='2'>Уровень воды в резервуаре, м</th>
              <th rowSpan='2'>Нагрузка, А</th>
              <th rowSpan='2'>Напряжение, В</th>
              <th rowSpan='2'>Насосы в работе</th>
              <th rowSpan='2'>Средний часовой расход, м3</th>
              <th rowSpan='2'>Наличие пломб на РЧВ, шт</th>
              <th rowSpan='2'>Включение резервного насоса</th>
            </tr>
            <tr>
              <th>№1</th>
              <th>№2</th>
            </tr>
          </thead>
          <tbody>
            {hourlyRates?.map(item => {
              return (
                <tr>
                  <td>{dateConverter(item?.date)}</td>
                  <td>{item?.time.substring(0, 5)}</td>
                  <td>{currentWps?.name}</td>
                  <td>{item?.pressure}</td>
                  <td>{item?.dt}</td>
                  <td>{item?.db}</td>
                  {item?.water_level_in_reseruar && item.water_level_in_reseruar.map(item1 =>
                    <>
                      <td>
                        {item1}
                      </td>
                      {item.water_level_in_reseruar.length === 1 && <td></td>}
                    </>
                  )}
                  <td>{item?.current}</td>
                  <td>{item?.voltage}</td>
                  <td>
                    {item?.pump_in_work && item.pump_in_work.map((pump, pumpIndex) =>
                      <>
                        {pump};&nbsp;
                        {(pumpIndex + 1) % 3 === 0 && <br />}
                      </>
                    )}
                  </td>
                  <td>{item?.max_consumption}</td>
                  <td>{item?.presence_plomb}</td>
                  <td>{item?.note}</td>
                </tr>)
            }
            )}
            <tr>
              <td colSpan={14}><b>Передано воды за сутки: {smena?.[0]?.all_water}</b></td>
            </tr>
          </tbody>
        </ModelTable> :
        <RSODynamicNotFound
          title={`Нет почасовых показателей за ${dateConverter(selectedDate)}`}
          descr='Выберите другую дату или, если вы выбрали сегодняшнее число, передайте почасовые показатели'
        />
      }

      {(chlorine.length !== 0 && currentWps) ?
        <div style={{ marginTop: '40px' }}>
          <ModelTable>
            <thead>
              <tr>
                <th colSpan={6}>{`Хлор на ВЗУ ${currentWps?.name} за ${dateConverter(selectedDate)}`}</th>
              </tr>
              <tr>
                <th>Дата</th>
                <th>Время</th>
                <th>ВЗУ</th>
                <th>Доза хлора(макс)</th>
                <th>Расход реагента</th>
                <th>Примечание</th>
              </tr>
            </thead>
            <tbody>
              {chlorine?.map(item =>
                <tr>
                  <td>{dateConverter(item.date)}</td>
                  <td>{item?.time.substring(0, 5)}</td>
                  <td>{currentWps?.name}</td>
                  <td>{item?.max_chlorine}</td>
                  <td>{item?.reagent_consumption}</td>
                  <td>{item?.note}</td>
                </tr>
              )}
            </tbody>
          </ModelTable>
        </div> :
        <RSODynamicNotFound
          title={`Нет хлора за ${dateConverter(selectedDate)}`}
          descr='Выберите другую дату или, если вы выбрали сегодняшнее число, передайте хлор'
        />
      }
    </div>
  )
}