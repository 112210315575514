import { useContext, useEffect, useState } from 'react'
import { HydrologistSelect } from '../../utils/HydrologistSelect/HydrologistSelect'
import { HydrologistInput } from '../../utils/HydrologistInput/HydrologistInput'
import './Hydrologist_worker.scss'
import { HydrologistContext } from './hooks/useHydrologist'
import { EditableTable } from './components/HydrologistWorkTable/HydrologistWorkTable'
import { getHydrologistDataMonthDate } from './http/HyderologistWorkerHttp'
import AuthContext from '../../../../context/AuthContext'
import { getDaysInMonth } from '../../utils/utils'

export const Hydrologist_worker = () => {

  const { authTokens } = useContext(AuthContext);

  let {
    memoizedHydrologists,
    memoizedWps,
    memoizedDate,
    memoizedWells
  } = useContext(HydrologistContext)

  const [tableData, setTableData] = useState([]);
  const [newTableData, setNewTableData] = useState([]);
  const [datesInMonth, setDatesInMonth] = useState([])

  useEffect(() => {
    if (memoizedDate?.selectedDate && memoizedWells?.selectedWell && memoizedHydrologists?.selectedHydrologist) {
      getHydrologistDataMonthDate(authTokens, memoizedDate?.selectedDate, memoizedWells?.selectedWell, setTableData, datesInMonth)
    }
  }, [memoizedDate?.selectedDate, memoizedWells?.selectedWell, datesInMonth])

  useEffect(() => {
    getDaysInMonth(memoizedDate?.selectedDate, setDatesInMonth)
  }, [memoizedDate?.selectedDate])

  return (
    <div className="hydrologist_worker">
      <div className="container">
        <form className='hydrologist_worker-form'>
          <HydrologistSelect
            name={"Выберите ВЗУ"}
            list={memoizedWps?.wps}
            value={'id'}
            visible_value={'name'}
            getter={memoizedWps?.selectedWps}
            setter={memoizedWps?.setSelectedWps}
            parse={'int'}
          />
          {memoizedWells?.selectedWell &&
            <>
              <HydrologistSelect
                name={"Выберите начальника ВЗУ"}
                list={memoizedHydrologists?.hydrologists}
                value={'id'}
                visible_value={'surname'}
                secondVisibleName={'name'}
                getter={memoizedHydrologists?.selectedHydrologist}
                setter={memoizedHydrologists?.setSelectedHydrologist}
                parse={'int'}
              />
              <HydrologistSelect
                name={"Выберите скважину"}
                list={memoizedWells?.wells}
                value={'id'}
                visible_value={'number_well'}
                secondVisibleName={'status'}
                getter={memoizedWells?.selectedWell}
                setter={memoizedWells?.setSelectedWell}
                parse={'int'}
              />
              <HydrologistInput
                type='date'
                title='Выберите дату'
                name='selectDate'
                setter={memoizedDate?.setSelectedDate}
                getter={memoizedDate?.selectedDate}
                value={memoizedDate?.selectedDate}
                required={true}
              />
            </>}
        </form>
        {memoizedWells?.selectedWell &&
          <EditableTable data={tableData} action={() => getHydrologistDataMonthDate(authTokens, memoizedDate?.selectedDate, memoizedWells?.selectedWell, setTableData, datesInMonth)} actions />
        }

      </div>
    </div>
  )
}