import { useContext } from "react"
import { dispatcher_context } from "../../hooks/useStats_dispatcher"
import { RSODynamicButtonGroup } from "../../../../utils/RSODynamicButtonGroup/RSODynamicButtonGroup"
import { RSODynamicDispDynamicDaily } from './components/RSODynamicDispDynamicDaily/RSODynamicDispDynamicDaily'
import { RSODynamicDispDynamicOper } from './components/RSODynamicDispDynamicOper/RSODynamicDispDynamicOper'
import { DynamicDispatchProvider } from "../../hooks/useStats_dispatcher_dynamic"

export let RSODynamicDispDynamic = () => {

  let { dynamicActiveTab, setDynamicActiveTab } = useContext(dispatcher_context)

  return (
    <>
      <DynamicDispatchProvider>
        <RSODynamicButtonGroup
          buttons={[
            { title: 'Дневной отчет' },
            { title: 'Оперативные данные' },
          ]}
          setter={setDynamicActiveTab}
          getter={dynamicActiveTab}
          color='green' />

        {dynamicActiveTab === "Дневной отчет" &&
          <RSODynamicDispDynamicDaily />
        }

        {dynamicActiveTab === "Оперативные данные" &&
          <RSODynamicDispDynamicOper />
        }
      </DynamicDispatchProvider>
    </>
  )
}