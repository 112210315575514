import { useState, useCallback } from "react";

function useHistory(initialState) {
  const [currentState, setCurrentState] = useState(initialState);
  const [history, setHistory] = useState([initialState]);
  const currentIndex = history.indexOf(currentState);

  const updateState = useCallback(
    (newState) => {
      const newHistory = history.slice(0, currentIndex + 1);
      newHistory.push(newState);
      setHistory(newHistory);
      setCurrentState(newState);
    },
    [currentIndex, history]
  );

  const goBack = useCallback(() => {
    if (currentIndex > 0) {
      const newState = history[currentIndex - 1];
      setCurrentState(newState);
    }
  }, [currentIndex, history]);

  return { currentState, updateState, goBack };
}

export default useHistory;
