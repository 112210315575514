import React, { useState } from 'react';
import { ButtonAddWear } from './ButtonAdd/ButtonAddWear';
import { ButtonShowWear } from './ButtonAdd/ButtonShowWear';
import { ButtonExtraWear } from './ButtonAdd/ButtonExtraWear';

import './AddWear.css'; 

export const AddWear = () => {
  const [activeSection, setActiveSection] = useState(null);

  const renderContent = () => {
    switch (activeSection) {
      case 'addWear':
        return <ButtonAddWear />;
      case 'showWear':
        return <ButtonShowWear />;
      case 'extraWear':
        return <ButtonExtraWear />;
      default:
        return null;
    }
  };

  return (
    <div className="AddWear_container">
      <div className="AddWear_buttons">
        <button className="AddWear_button" onClick={() => setActiveSection('addWear')}>Добавить износ</button>
        <button className="AddWear_button" onClick={() => setActiveSection('showWear')}>Просмотреть износ</button>
        <button className="AddWear_button" onClick={() => setActiveSection('extraWear')}>Дополнительно</button>
      </div>

      <div className="AddWear_content">
        {renderContent()}
      </div>
    </div>
  );
};
