import React from "react";
import "../../css/Button.css";
import { API_URL } from "../../../../config";
import { useContext} from "react";
import AuthContext from "../../../../context/AuthContext";

const ButtonAddHouse = ({ formData, updateState, onDataChange }) => {
  let { authTokens } = useContext(AuthContext)


  const isFormDataValid = () => {
    // Проверка на заполнение всех полей
    if (!formData.text16 || !formData.text17 || !formData.text18 || !formData.text19 || !formData.text20 ||
        !formData.text21) {
          alert("Пожалуйста, заполните все необходимые поля, в случае отсутствия каких-либо элементов введите '0'");
          return false;
    }

    // Проверка на отрицательные значения
    if (formData.text16 < 0 || formData.text17 < 0 || formData.text18 < 0 || formData.text19 < 0 ||
        formData.text20 < 0 || formData.text21 < 0) {
      alert("Значения не могут быть отрицательными");
      return false;
    }

    // Все проверки прошли успешно
    return true;
  };


  const AddWear = () => {

    const address = formData.text22;
    
    const api = `${API_URL}/Wear/House/houseByAddress/${address}/`;

    fetch(api, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          console.log("Данные успешно получены!");
          return response.json();
        } else {
          alert("Введен не верный адрес !");
        }
      })
      .then((data) => {

        if (data && data.length > 0) {

        var balconyType = data[0].balconyType;
        console.log(balconyType);

        var balconyVolume = data[0].balconyVolume;
        console.log(balconyVolume);
        var centralHeatingLength = data[0].centralHeatingLength;
        console.log(centralHeatingLength);

        var chuteLength = data[0].chuteLength;
        console.log(chuteLength);

        var coldWaterSupplyLength = data[0].coldWaterSupplyLength;
        console.log(coldWaterSupplyLength);
        var floorType = data[0].floorType;
        console.log(floorType);

        var floorVolume = data[0].floorVolume;
        console.log(floorVolume);

        var foundationType = data[0].foundationType;
        console.log(foundationType);

        var foundationVolume = data[0].foundationVolume;
        console.log(foundationVolume);

        var hotWaterSupplyLength = data[0].hotWaterSupplyLength;
        console.log(hotWaterSupplyLength);

        var id = data[0].id;
        console.log(id);

        var roofType = data[0].roofType;
        console.log(roofType);

        var roofVolume = data[0].roofVolume;
        console.log(roofVolume);

        var roofingType = data[0].roofingType;
        console.log(roofingType);

        var roofingVolume = data[0].roofingVolume;
        console.log(roofingVolume);

        var sewerage = data[0].sewerage;
        console.log(sewerage);

        var sewerageLength = data[0].sewerageLength;
        console.log(sewerageLength);

        var wallType = data[0].wallType;
        console.log(wallType);

        var wallVolume = data[0].wallVolume;
        console.log(wallVolume);

        var wiringLength = data[0].wiringLength;
        console.log(wiringLength);

        localStorage.setItem("balconyType", balconyType);
        localStorage.setItem("balconyVolume", balconyVolume);
        localStorage.setItem("floorType", floorType);
        localStorage.setItem("floorVolume", floorVolume);
        localStorage.setItem("foundationType", foundationType);
        localStorage.setItem("foundationVolume", foundationVolume);
        localStorage.setItem("id", id);
        localStorage.setItem("wallType", wallType);
        localStorage.setItem("wallVolume", wallVolume);
        localStorage.setItem("roofType", roofType);
        localStorage.setItem("roofVolume", roofVolume);
        localStorage.setItem("roofingType", roofingType);
        localStorage.setItem("roofingVolume", roofingVolume);
        localStorage.setItem("sewerageLength", sewerageLength);
        localStorage.setItem("chuteLength", chuteLength);
        localStorage.setItem("centralHeatingLength",centralHeatingLength);
        localStorage.setItem("coldWaterSupplyLength",coldWaterSupplyLength);
        localStorage.setItem("hotWaterSupplyLength",centralHeatingLength);
        localStorage.setItem("wiringLength", wiringLength);
        
        console.log(data)
        updateState(6);

      } else {
        alert("Неправильный ввод адрес");
      }
    })
      .catch((error) => {
        console.error("Ошибка при получении данных:", error.message);
      });
  };

  const handleAddHouseClick = () => {

    if (!isFormDataValid()) {
      return;
    }

    const api = `${API_URL}/Wear/House/`;
    const data = {

      yearOperation: formData.text2,
      address: formData.text1,  
      organizationMKD: formData.text23,
      yearConstruction: formData.text24,
      lifeStage: parseInt(formData.dropdown9),
      yearReconstruction: formData.text26,
      numbeApartments: parseInt(formData.text27),
      numbeApartmentsPodezd: parseInt(formData.text28),
      buildingVolume:parseInt(formData.text29),
      houseArea: parseInt(formData.text5),
      floorCount: parseInt(formData.text3),
      houseType: parseInt(formData.dropdown8),
      undergroundFloorCount: parseInt(formData.text4),
      foundationType: parseInt(formData.dropdown1),
      wallType: parseInt(formData.dropdown2),
      floorType: parseInt(formData.dropdown3),
      balconyType: parseInt(formData.dropdown4),
      roofType: parseInt(formData.dropdown5),
      roofingType: parseInt(formData.dropdown6),
      foundationVolume: parseInt(formData.text6),
      wallVolume: parseInt(formData.text8),

      floorVolume: parseInt(formData.text10),
      balconyVolume: parseInt(formData.text7),
      roofVolume: parseInt(formData.text11),
      roofingVolume: parseInt(formData.text11),
      sewerageLength: parseInt(formData.text19),
      chuteLength: parseInt(formData.text21),
      centralHeatingLength: parseInt(formData.text17),
      coldWaterSupplyLength: parseInt(formData.text18),
      hotWaterSupplyLength: parseInt(formData.text16),
      wiringLength: parseInt(formData.text20),
      basement: formData.checkbox1,
      outbuilding: formData.checkbox2,
      attic: formData.checkbox3,
      sewerage: formData.checkbox4,
      elevator: formData.checkbox10,
      balconyCount: parseInt(formData.text12),
      lodjiCount: parseInt(formData.text13),
      canopyCount: parseInt(formData.text14),
      orielCount: parseInt(formData.text15),
    };

    console.log(data);

    fetch(api, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          console.log("Данные успешно отправлены!");
          onDataChange();// Очистка полей
          updateState(0);
        } else {
          throw new Error("Ошибка при отправке данных, проверьте заполнение полей");
        }
      })
      .catch((error) => {
        console.error("Ошибка при отправке данных:", error.message);
        alert("Проверьте заполнение полей")

      });
  };

  const handleWearClick = () => {

    if (!isFormDataValid()) {
      return;
    }

    const api = `${API_URL}/Wear/House/`;
    const data = {

      yearOperation: formData.text2,
      address: formData.text1,  
      organizationMKD: formData.text23,
      yearConstruction: formData.text24,
      lifeStage: parseInt(formData.dropdown9),
      yearReconstruction: formData.text26,
      numbeApartments: parseInt(formData.text27),
      numbeApartmentsPodezd: parseInt(formData.text28),
      buildingVolume:parseInt(formData.text29),
      houseArea: parseInt(formData.text5),
      floorCount: parseInt(formData.text3),
      houseType: parseInt(formData.dropdown8),
      undergroundFloorCount: parseInt(formData.text4),
      foundationType: parseInt(formData.dropdown1),
      wallType: parseInt(formData.dropdown2),
      floorType: parseInt(formData.dropdown3),
      balconyType: parseInt(formData.dropdown4),
      roofType: parseInt(formData.dropdown5),
      roofingType: parseInt(formData.dropdown6),
      foundationVolume: parseInt(formData.text6),
      wallVolume: parseInt(formData.text8),

      floorVolume: parseInt(formData.text10),
      balconyVolume: parseInt(formData.text7),
      roofVolume: parseInt(formData.text11),
      roofingVolume: parseInt(formData.text11),
      sewerageLength: parseInt(formData.text19),
      chuteLength: parseInt(formData.text21),
      centralHeatingLength: parseInt(formData.text17),
      coldWaterSupplyLength: parseInt(formData.text18),
      hotWaterSupplyLength: parseInt(formData.text16),
      wiringLength: parseInt(formData.text20),
      basement: formData.checkbox1,
      outbuilding: formData.checkbox2,
      attic: formData.checkbox3,
      sewerage: formData.checkbox4,
      elevator: formData.checkbox10,
      balconyCount: parseInt(formData.text12),
      lodjiCount: parseInt(formData.text13),
      canopyCount: parseInt(formData.text14),
      orielCount: parseInt(formData.text15),
    };

    console.log(data);

    fetch(api, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          console.log("Данные успешно отправлены!");
          onDataChange(); // Очистка полей
          AddWear(); //Смена состояния на добавление поврежедений
        } else {
          throw new Error("Ошибка при отправке данных, проверьте заполнение полей");
        }
      })
      .catch((error) => {
        console.error("Ошибка при отправке данных:", error.message);
        alert("Проверьте заполнение полей")

      });
  };

  return (
      <>
      <button className= "btn-osn-wear" type="button" onClick={handleAddHouseClick}>Добавить здание</button>
      <button className= "btn-osn-wear" type="button" onClick={handleWearClick}>Рассчитать износ</button>
      </>


      
    
  );
};

export default ButtonAddHouse;
