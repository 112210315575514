import React, {useState} from "react"
import {Map, Marker} from "pigeon-maps"
import {DADATA_TOKEN} from "../config";
import {AddressSuggestions} from "react-dadata";

const MapP = () => { 
    return (
        <>

        </>

    );
};

export default MapP;
