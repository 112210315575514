import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../../../../../../context/AuthContext";
import { worker_workzone_context } from "../../../../hooks/useStats_worker_workzone";
import { useForm } from "react-hook-form";
import { dateConverter, getNowDate } from "../../../../../../../../utils/utils";
import { RSODynamicInput } from "../../../../../../utils/RSODynamicInput/RSODynamicInput";
import { RSODynamicButton } from "../../../../../../utils/RSODynamicButton/RSODynamicButton";
import { getArchive } from "../../../../http/worker_http";
import { ModelTable } from "../../../../../../../../components/ModelTable/ModelTable";
import { worker_context } from "../../../../hooks/useStats_worker";
import { RSODynamicNotFound } from "../../../../../../utils/RSODynamicNotFound/RSODynamicNotFound";
import { RSODynamicButtonGroup } from "../../../../../../utils/RSODynamicButtonGroup/RSODynamicButtonGroup";
import { RSODynamicArchiveMain } from "./components/RSODynamicArchiveMain";
import { RSODynamicArchiveHydrologist } from "./components/RSODynamicArchiveHydrologist";
import { RSODynamicArchiveHydrologistHands } from "./components/RSODynamicArchiveHydrologistHands";

export let RSODynamicArchive = () => {

  let { archiveTab, setArchiveTab } = useContext(worker_workzone_context);

  return (
    <>
      <RSODynamicButtonGroup
        buttons={[
          { title: 'Архив почасовых показаний' },
          { title: 'Архив гидрогеолога (Эксель)' },
          { title: 'Архив гидрогеолога (Ручной ввод)' },]}
        setter={setArchiveTab}
        getter={archiveTab}
        color='green' />

      {archiveTab === "Архив почасовых показаний" && (
        <RSODynamicArchiveMain />)}
      {archiveTab === "Архив гидрогеолога (Эксель)" && (
        <RSODynamicArchiveHydrologist />)}
      {archiveTab === "Архив гидрогеолога (Ручной ввод)" && (
        <RSODynamicArchiveHydrologistHands />)}
    </>
  )
}