import './HydrologistSelect.scss'
export let HydrologistSelect = ({ name, list, parse, value, visible_value, setter, getter, option, secondVisibleName }) => {
  let parseIt = (e) => {
    if (parse === 'int') {
      option ? (setter({ ...getter, [option]: parseInt(e.target.value) })) : (setter(parseInt(e.target.value)))
    }
    if (parse === "String") {
      option ? (setter({ ...getter, [option]: String(e.target.value) })) : (setter(String(e.target.value)))
    }
  }

  return (
    <div className="HydrologistSelect">
      <label className="form-label">{name}</label>
      <select className='form-input'
        onChange={e => parseIt(e)}>
        {list?.map((item, index) =>
          <option key={index} value={item[value]}>{`${item[visible_value]} ${secondVisibleName ? `[${item[secondVisibleName]}]` : ""}`}</option>
        )}
      </select>
    </div>
  )
}