import React from "react";
import "../css/Constr.css";

const Constr = ({ formData, onDataChange }) => {
  // Изменение состояния полей ввода
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    onDataChange({ ...formData, [id]: value });
  };

  return (
    <div className="main-content_constr">
      <div className="data_mane_constr">
        <div className="data_input_constr">
          <input
            className="input-field-constr-wear"
            type="number"
            id="text6"
            placeholder="Площадь стен фундамента"
            required
            value={formData.text6}
            onChange={handleInputChange}
          />
          <input
            className="input-field-constr-wear"
            type="number"
            id="text7"
            placeholder="Общая площадь балконов и т.д"
            required
            value={formData.text7}
            onChange={handleInputChange}
          />
          <input
            className="input-field-constr-wear"
            type="number"
            id="text8"
            placeholder="Площадь стен здания"
            required
            value={formData.text8}
            onChange={handleInputChange}
          />
          <input
            className="input-field-constr-wear"
            type="number"
            id="text9"
            placeholder="Общая площадь несущих конструкций"
            required
            value={formData.text9}
            onChange={handleInputChange}
          />
          <input
            className="input-field-constr-wear"
            type="number"
            id="text10"
            placeholder="Общая площадь перекрытия"
            required
            value={formData.text10}
            onChange={handleInputChange}
          />

          <input
            className="input-field-constr-wear"
            type="number"
            id="text11"
            placeholder="Общая площадь крыши"
            required
            value={formData.text11}
            onChange={handleInputChange}
          />
          <input
            className="input-field-constr-wear"
            type="number"
            id="text12"
            placeholder="Количество балконов"
            required
            value={formData.text12}
            onChange={handleInputChange}
          />
          <input
            className="input-field-constr-wear"
            type="number"
            id="text13"
            placeholder="Количество лоджий"
            required
            value={formData.text13}
            onChange={handleInputChange}
          />
          <input
            className="input-field-constr-wear"
            type="number"
            id="text14"
            placeholder="Количество козырьков"
            required
            value={formData.text14}
            onChange={handleInputChange}
          />
          <input
            className="input-field-constr-wear"
            type="number"
            id="text15"
            placeholder="Количество эркеров"
            required
            value={formData.text15}
            onChange={handleInputChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Constr;
