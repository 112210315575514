import React, { useContext, useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { NavLink } from 'react-router-dom';
import { API_URL } from '../config';
import Layout from '../components/Layout';
import AuthContext from '../context/AuthContext';
import { useForm } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import * as XLSX from 'xlsx';
import { usePopper } from 'react-popper';
import { AddressSuggestions } from 'react-dadata';
import { YMaps, Map, Placemark, ZoomControl, TypeSelector, SearchControl, GeolocationControl } from '@pbe/react-yandex-maps';
import { useAdblockDetector } from 'react-adblock-detector';
import {
  Chart,
  ChartTitle,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisTitle,
  ChartCategoryAxisItem,
} from "@progress/kendo-react-charts";
import "@progress/kendo-theme-material/dist/all.css";
import "hammerjs";
import { COLORS } from "../const";
import { Chart as MyChar } from 'primereact/chart';





const Ads = () => {

  useEffect(() => {
    fetchZayvkaForDAndD();
    fetchGetPerformer();
  }, []);

  const AdresRef = useRef(null);
  const textApplicationRef = useRef(null);
  const fullNameRef = useRef(null);
  const phoneNumberRef = useRef(null);
  const emailRef = useRef(null);
  const TypeAppRef = useRef(null);
  const setiRef = useRef(null);
  const ApplRef = useRef(null);
  const ObjRef = useRef(null);
  const PerfRef = useRef(null);
  const CategoryRef = useRef(null);
  const SaveButtonRef = useRef(null);


  const handleKeyPress = (e, ref) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      ref.current.focus();
    }
  };

  const [activeInput, setActiveInput] = useState(null);
  const referenceElement = useRef({});
  const popperElement = useRef({});

  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }


  const getPopperElement = (inputId) => {
    if (!popperElement.current[inputId]) {
      popperElement.current[inputId] = document.createElement('div');
    }
    return popperElement.current[inputId];
  };

  const { styles, attributes } = usePopper(referenceElement.current[activeInput], getPopperElement(activeInput), {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [-100, -140],
        },
      },
    ],
  });

  const handleFocus = (inputId) => {
    setActiveInput(inputId);
  };

  const handleBlur = (inputId) => {
    setActiveInput(null);
  };

  let navigate = useNavigate();
  useEffect(() => {
    getProfile();
  }, []);

  let { authTokens, logoutUser } = useContext(AuthContext);

  let [profile, setProfile] = useState('');

  let getProfile = async () => {
    let response = await fetch(API_URL + '/user/profile/', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });
    let data = await response.json();

    if (response.status === 200) {
      setProfile(data[0]);
      setJobTitle(data[0].job_title);
      if (!data[0].ADS_access) {
        navigate('/services/ads', { replace: true });
      }

    } else if (data["detail"] === "Учетные данные не были предоставлены.") {
      logoutUser();
    } else {
      localStorage.setItem('last_page', '/services/ads');
      navigate('/login', { replace: true });
    }
  };

  function getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  let [jobTitle, setJobTitle] = useState('');
  const [IsPriemZayvka, setIsPriemZayvka] = useState(false);
  const [IsIspolnenieZayvka, setIspolnenieZayvka] = useState(false);
  const [IsSostoyanieZayvka, setIsSostoyanieZayvka] = useState(false);
  const [IsAnalytic, setIsAnalytic] = useState(false);
  const [IsSave, setIsSave] = useState(false);
  const [IsCheckMap, setIsCheckMap] = useState(false);
  const [IsCheckSearch, setIsCheckSearch] = useState(false);
  const [IsCheckSearch1, setIsCheckSearch1] = useState(false);
  const [IsCheckChangeCoor, setIsCheckChangeCoor] = useState(false);
  const [IsCheckCoordinateMap, setIsCheckCoordinateMap] = useState(false);
  const [IsCheckGraph1, setIsCheckGraph1] = useState(false);
  const [IsCheckGraph2, setIsCheckGraph2] = useState(false);
  const [IsCheckGraph3, setIsCheckGraph3] = useState(false);
  const [IsCheckGraph7, setIsCheckGraph7] = useState(false);
  const [IsCheckGraph8, setIsCheckGraph8] = useState(false);
  const [IsCheckGraph4, setIsCheckGraph4] = useState(false);
  const [IsCheckGraph5, setIsCheckGraph5] = useState(false);
  const [IsCheckGraph6, setIsCheckGraph6] = useState(false);
  const [IsCheckGraph10, setIsCheckGraph10] = useState(false);
  const [IsCheckGraph20, setIsCheckGraph20] = useState(false);
  const [IsCheckGraph30, setIsCheckGraph30] = useState(false);
  const [IsCheckGraph200, setIsCheckGraph200] = useState(false);
  const [IsCheckGraph300, setIsCheckGraph300] = useState(false);
  const [IsPhotoChange, setIsPhotoChange] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImg, setSelectedImg] = useState(null);
  const [selectedEndImg, setSelectedEndImg] = useState(null);
  const [selectedStartImg, setSelectedStartImg] = useState(null);
  const [isAdblockCheckComplete, isAdblockerDetected] = useAdblockDetector();
  const [loading, setLoading] = useState(false);


  let [postData, setPostData] = useState({
    category: 'Аварийная',
    object: 'Течь сети',
    applicant: 'Гражданин',
    seti: 'Водоснабжение',
    application_type: 'Первичная',
    fullname: '',
    text_application: '',
    email: '',
  });
  let [number, setNumber] = useState({
    phone_number: '',
    home_phone_number: '',
  })
  let [postPerformers, setPostPerformers] = useState({
    type_of_activity: 'Канализация',
    firstname: 'Z',
  })
  let [postPerformers1, setPostPerformers1] = useState({
    type_of_activity: 'Канализация',
    firstname: 'Z',
  })
  let [performerZayva, setPerformerZayva] = useState({
    performer: '',
  })
  let [performerZayva1, setPerformerZayva1] = useState({
    performer: '',
  })
  let [postSaveData, setPostSaveData] = useState({
    damage_character: '',
    pipe_material: '',
    solution: '',
    comment: '',
    pipe_diametr: '',
    depth_top_of_pipe: '',
    damage_character1: '',
    pipe_material1: '',
    solution1: '',

  });
  let [NameSet, setNameSet] = useState({
    seti: '',
  });
  let [getDate, setGetDate] = useState({});
  let [getData, setGetData] = useState({});
  let [getZayvaByAdrDate, setZayvaByAdrDate] = useState([]);
  let [getDataByMonthInWork, setGetDataByMonthInWork] = useState({});
  let [getDataByMonthExectudet, setGetDataByMonthExecuted] = useState({});
  let [getDataByMonthAccepted, setGetDataByMonthAccepted] = useState({});
  let [getDataByMonthInWork1, setGetDataByMonthInWork1] = useState({});
  let [getDataByMonthExectudet1, setGetDataByMonthExecuted1] = useState({});
  let [getDataByMonthAccepted1, setGetDataByMonthAccepted1] = useState({});
  let [getDataByMonthPlan, setGetDataByMonthPlan] = useState({});
  let [getDataByMonthCons, setGetDataByMonthCons] = useState({});
  let [getDataByMonthAlarm, setGetDataByMonthAlarm] = useState({});
  let [getDataByMonthPaid, setGetDataByMonthPaid] = useState({});
  let [getDataByMonthWorkOnRequests, setGetDataByMonthWorkOnRequests] = useState({});
  let [getDataByMonthTechnicalShutdown, setGetDataByMonthTechnicalShutdown] = useState({});
  let [getDataByMonthWatersupply, setGetDataByMonthWatersupply] = useState({});
  let [getDataByMonthSewerage, setGetDataByMonthSewerage] = useState({});
  let [getDataByMonthImprovement, setGetDataByMonthImprovement] = useState({});
  const [getDataByYearAdr, setGetDataByYearAdr] = useState({ query: [] });
  const [getStatThreeMonth, setgetStatThreeMonth] = useState([]);
  let [getDataForMyGpaph, setGetDataForMyGpaph] = useState([]);
  let [getDataSearch, setGetDataSearch] = useState({});
  let [NameSearch, setNameSearch] = useState({
    value: 'По адресу',
  });
  let [WhatDataSearch, setWhatDataSearch] = useState(getCurrentDate());
  let [status, setStatus] = useState({
    applicant_status: 'В работе',
  });
  let [text, setText] = useState({});
  let [month1, setMonth] = useState({});
  let [date1, setDate1] = useState({});
  let [year1, setYear1] = useState({});
  let [CoordinateOnClick, setCoordinateOnClick] = useState({});
  let [ZayvkaById, setZayvkaById] = useState([]);
  let [ZayvkaBySearch, setZayvkaBySearch] = useState([]);
  let [ZayvkaBySearch1, setZayvkaBySearch1] = useState([]);
  let [Povtor, setIsPovtor] = useState(false);
  let [ZayvkaAllNonAct, setZayvkaAllNonAct] = useState([]);
  let [ZayvkaAllAct, setZayvkaAllAct] = useState([]);

  const [valueDaData, setValueDaData] = useState({});
  const [valueDaData1, setValueDaData1] = useState({});
  const [addressArr, setAddressArr] = useState([]);
  const [addressArr1, setAddressArr1] = useState([]);
  const [addressArr2, setAddressArr2] = useState([]);
  const [addressArr3, setAddressArr3] = useState([]);
  const [addressArr4, setAddressArr4] = useState([]);

  const [DataSearhMonth, setDataSearhMonth] = useState({ query: [] });

  const ChangePhotoBtn = () => {
    setIsPhotoChange(!IsPhotoChange);
  }


  const CheckChangeCoorMap = () => {
    setIsCheckChangeCoor(true);
  }
  const CheckOffChangeCoorMap = () => {
    setIsCheckChangeCoor(false);
  }

  const ClearCoorChange = () => {
    setCoordinateOnClick('');
  }

  const hideAll1 = () => {
    setIsCheckGraph1(false);
    setIsCheckGraph2(false);
    setIsCheckGraph3(false);
    setIsCheckGraph7(false);
    setIsCheckGraph10(false);
    setIsCheckMap(false);
  }

  const hideAll = () => {
    setIsPriemZayvka(false);
    setIspolnenieZayvka(false);
    setIsSostoyanieZayvka(false);
    setIsAnalytic(false);
  }

  const OpenGraph1 = () => {
    hideAll1();
    setIsCheckGraph1(!IsCheckGraph1);
  }

  const OpenGraph2 = () => {
    hideAll1();
    setIsCheckGraph2(!IsCheckGraph2);
  }

  const OpenGraph3 = () => {
    hideAll1();
    setIsCheckGraph3(!IsCheckGraph3);
  }

  const OpenGraph7 = () => {
    hideAll1();
    setIsCheckGraph7(!IsCheckGraph7);
  }

  const OpenGraph10 = () => {
    hideAll1();
    setIsCheckGraph10(!IsCheckGraph10);
  }

  const hideMap = () => {
    setIsSave(false);
  }

  const Mapp = () => {
    hideAll1();
    setIsCheckMap(!IsCheckMap);
  }

  const Search = () => {
    setIsCheckSearch(true);
  }

  const Ssearch = () => {
    setIsCheckSearch(false);
  }

  const Mappp = () => {
    setIsCheckMap(false);
    NameSearch.value = "По адресу";
  }
  const CheckSearchInIsp = () => {
    setIsCheckSearch1(true);
  }


  const activePriemZayvka = () => {
    hideAll();
    setIsPriemZayvka(!IsPriemZayvka);
  };

  const activeIspolnenieZayvka = () => {
    hideAll();
    setIspolnenieZayvka(!IsIspolnenieZayvka);
  };

  const activeSostyanieZayvka = () => {
    hideAll();
    setIsSostoyanieZayvka(!IsSostoyanieZayvka);
  };

  const activeAnalytic = () => {
    hideAll();
    setIsAnalytic(!IsAnalytic);
  };

  const saveButton = () => {
    setIsSave(true);
  }

  const saveButtonWhenUp = () => {
    setIsSave(false);
  }

  const MapForCoordinate = () => {
    setIsCheckCoordinateMap(true);
  }

  const HideMapForCoordinate = () => {
    setIsCheckCoordinateMap(false);
  }

  const postTextZayvka = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    setPostData({ ...postData, [name]: value, });
  }

  const postNumberPhone = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    setNumber({ ...number, [name]: value, });
  }

  const postAdressZayvka = (e, ref) => {
    setValueDaData(e);
    fetchSearchAdr(e.value);
    setTimeout(() => {
      ref.current.focus();
    }, 100);
    console.log(e);
  }

  const postTextZayvkaPerformer = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    setPerformerZayva({ ...performerZayva, [name]: value, });

  }

  const postTextZayvkaPerformer1 = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    setPerformerZayva1({ ...performerZayva1, [name]: value, });

  }

  const getZayvaByDate = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    setGetDate({ ...getDate, [name]: value, });

  }

  const postPerformer = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    setPostPerformers({ ...postPerformers, [name]: value, });
  }

  const postPerformer1 = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    setPostPerformers1({ ...postPerformers1, [name]: value, });
  }

  const delPerformer = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    setDeletePerformers({ ...DeletePerformers, [name]: value, });
    if (name === 'performer') {
      setPerfId(value, name);
    }

  }

  const setPerfId = (value, name) => {
    for (let z = 0; z < getPerformer.length; z++) {
      if (getPerformer[z].lastname === value) {
        setIdPerformers(getPerformer[z].pk);
        setNamePerformers({ ...NamePerformers, [name]: getPerformer[z].lastname });
      }
    }
  }

  const delPerformer1 = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    setDeletePerformers1({ ...DeletePerformers1, [name]: value, });
    if (name === 'performer') {
      setPerfId1(value, name);
    }

  }

  const setPerfId1 = (value, name) => {
    for (let z = 0; z < getPerformer.length; z++) {
      if (getPerformer[z].lastname === value) {
        setIdPerformers1(getPerformer[z].pk);
        setNamePerformers1({ ...NamePerformers1, [name]: getPerformer[z].lastname });
      }
    }
  }

  let [DeletePerformers, setDeletePerformers] = useState({
    type_of_activity: 'Канализация',
  })

  let [DeletePerformers1, setDeletePerformers1] = useState({
    type_of_activity: 'Водоснабжение',
  })

  let [IdPerformers, setIdPerformers] = useState([])
  let [IdPerformers1, setIdPerformers1] = useState([])
  let [NamePerformers, setNamePerformers] = useState({
    performer: '',
  })
  let [NamePerformers1, setNamePerformers1] = useState({
    performer: '',
  })

  const fetchDeletePerformer = async () => {
    let response = await fetch(API_URL + '/ADS/performer/' + IdPerformers + '/', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });
    fetchGetPerformer();
    let data = await response.json();

    if (response.status === 204) {
      alert('успех')
    }
    else {
      alert('ошибка')
    }
  }

  const fetchDeletePerformer1 = async () => {
    let response = await fetch(API_URL + '/ADS/performer/' + IdPerformers1 + '/', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });
    fetchGetPerformer();
    let data = await response.json();

    if (response.status === 204) {
      alert('успех')
    }
    else {
      alert('ошибка')
    }
  }


  const postDpName = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    setPostData({ ...postData, [name]: value, });

  }

  const NameOfSearch = (e) => {
    let value = e.target.value;
    setNameSearch({ ...NameSearch, value, });

  }

  const WhatSearch = (e) => {
    let value = e.target.value;
    setWhatDataSearch(value);

  }


  const postSaveZayvka = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    setPostSaveData({ ...postSaveData, [name]: value, });

  }

  const NameSetForStat = (e) => {
    setIsCheckGraph20(false);
    setIsCheckGraph30(false);
    setIsCheckGraph200(false);
    setIsCheckGraph300(false);
    let value = e.target.value;
    let name = e.target.name;
    setNameSet({ ...NameSet, [name]: value, });

  }

  const GetCountByMonth = (e) => {
    let month = e.target.value.substring(5, 7);
    setMonth({ ...month1, month });
    fetchZayvkaByMonthInWork(month);
    fetchZayvkaByMonthExecuted(month);
    fetchZayvkaByMonthAccepted(month);
  }

  const GetCountByMonth1 = (e) => {
    let value = e.target.value;
    let month = e.target.value.substring(5, 7);
    let year = e.target.value.substring(0, 4);
    setMonth({ ...month1, value });
    fetchZayvkaByMonthInWork1(month, year);
    fetchZayvkaByMonthExecuted1(month, year);
    fetchZayvkaByMonthAccepted1(month, year);
    setIsCheckGraph4(true);
  }

  const GetCountByMonth2 = (e) => {
    let value = e.target.value;
    let month = e.target.value.substring(5, 7);
    let year = e.target.value.substring(0, 4);
    setMonth({ ...month1, value });
    fetchCategoryByMonthPlan(month, year);
    fetchCategoryByMonthPaid(month, year);
    fetchCategoryByMonthAlarm(month, year);
    fetchCategoryByMonthCons(month, year);
    fetchCategoryByMonthWorkOnRequests(month, year);
    fetchCategoryByMonthTechnicalShutdown(month, year);
    setIsCheckGraph5(true);
  }

  const GetCountByMonth5 = (e) => {
    let value = e.target.value;
    let month = e.target.value.substring(5, 7);
    let year = e.target.value.substring(0, 4);
    setMonth({ ...month1, value });
    fetchSetiByMonthWatersupply(month, year);
    fetchSetiByMonthSewerage(month, year);
    fetchSetiByMonthImprovement(month, year);
    setIsCheckGraph8(true);
  }


  const GetCountByMonth3 = (e) => {
    let value = e.target.value;
    setDate1({ ...date1, value });

  }

  const GetCountByMonth10 = (e) => {
    let month = e.target.value.substring(5, 7);
    let year = e.target.value.substring(0, 4);
    setYear1({ ...year1, year });
    setIsCheckGraph20(true);
    setIsCheckGraph30(false);
    setIsCheckGraph200(false);
    setIsCheckGraph300(false);
    FetcAdresStats(month, year);
  }

  const GetCountByMonth100 = (e) => {
    let month = e.target.value.substring(5, 7);
    let year = e.target.value.substring(0, 4);
    setYear1({ ...year1, year });
    setIsCheckGraph20(false);
    setIsCheckGraph200(false);
    setIsCheckGraph300(false);
    setIsCheckGraph30(true);
    FetcAdresStats(month, year);
  }


  const GetCountByMonth20 = (e) => {
    let month = e.target.value.substring(5, 7);
    let year = e.target.value.substring(0, 4);
    setYear1({ ...year1, year });
    setIsCheckGraph200(true);
    setIsCheckGraph300(false);
    setIsCheckGraph20(false);
    setIsCheckGraph30(false);
    FetcAdresStatsSewage(month, year);
  }

  const GetCountByMonth200 = (e) => {
    let month = e.target.value.substring(5, 7);
    let year = e.target.value.substring(0, 4);
    setYear1({ ...year1, year });
    setIsCheckGraph200(false);
    setIsCheckGraph20(false);
    setIsCheckGraph30(false);
    setIsCheckGraph300(true);
    FetcAdresStatsSewage(month, year);
  }

  const GetStat3month = () => {
    FetcAdresStatsForThreeMonth();
    setIsCheckGraph20(false);
    setIsCheckGraph30(true);
  }

  const GetCountByMonth4 = (e) => {
    let data2 = e.target.value;
    fetchDataForGpaph(data2);
    setIsCheckGraph6(true);

  }

  let [dataKeys, setDataKeys] = useState([]);
  let [dataAlarm, setDataAlarm] = useState([]);
  let [dataCons, setDataCons] = useState([]);
  let [dataPaid, setDataPaid] = useState([]);
  let [dataPlan, setPlan] = useState([]);
  let [dataWorkOnRequests, setDataWorkOnRequests] = useState([]);
  let [dataTechnicalShutdown, setDataTechnicalShutdown] = useState([]);

  const series = [
    {
      status: "Принятые",
      data: [getDataByMonthAccepted1.count],
      color: COLORS.accepted,
    },
    {
      status: "Выполненные",
      data: [getDataByMonthExectudet1.count],
      color: COLORS.executed,
    },
    {
      status: "В работе",
      data: [getDataByMonthInWork1.count],
      color: COLORS.InWork,
    },
  ];

  const series1 = [
    {
      status: "Аварийная",
      data: [getDataByMonthAlarm.count],
      color: COLORS.InWork,
    },
    {
      status: "Плановая",
      data: [getDataByMonthPlan.count],
      color: COLORS.executed,
    },
    {
      status: "Платная",
      data: [getDataByMonthPaid.count],
      color: COLORS.accepted,
    },
    {
      status: "Консультация",
      data: [getDataByMonthCons.count],
      color: COLORS.Cons,
    },
    {
      status: "Техническое отключение",
      data: [getDataByMonthTechnicalShutdown.count],
      color: COLORS.green,
    },
    {
      status: "Работа по обращениям",
      data: [getDataByMonthWorkOnRequests.count],
      color: COLORS.blue,
    },
  ];




  const data = {
    labels: ['Водоснабжение', 'Канализация', 'Благоустройство'],
    datasets: [
      {
        data: [((getDataByMonthWatersupply.count / (getDataByMonthWatersupply.count + getDataByMonthSewerage.count + getDataByMonthImprovement.count)) * 100),
        ((getDataByMonthSewerage.count / (getDataByMonthWatersupply.count + getDataByMonthSewerage.count + getDataByMonthImprovement.count)) * 100),
        ((getDataByMonthImprovement.count / (getDataByMonthWatersupply.count + getDataByMonthSewerage.count + getDataByMonthImprovement.count)) * 100),
        ],
        backgroundColor: [
          COLORS.blue,
          COLORS.brown,
          COLORS.green
        ],
        hoverBackgroundColor: [
          COLORS.darkBlue,
          COLORS.darkBrown,
          COLORS.darkGreen
        ]
      }
    ]
  }

  // data.datasets[0].data = data.datasets[0].data.map(value => `${value.toFixed(2)}%`);
  // data.datasets[0].data = data.datasets[0].data.map(value => parseFloat(value));


  const options = {
    plugins: {
      legend: {
        labels: {
          usePointStyle: true
        }
      }
    }
  };

  const series2 = [
    {
      status: "Аварийная",
      data: dataAlarm,
      color: COLORS.InWork,
    },
    {
      status: "Плановая",
      data: dataPlan,
      color: COLORS.executed,
    },
    {
      status: "Платная",
      data: dataPaid,
      color: COLORS.accepted,
    },
    {
      status: "Консультация",
      data: dataCons,
      color: COLORS.Cons,
    },
    {
      status: "Техническое отключение",
      data: dataTechnicalShutdown,
      color: COLORS.green,
    },
    {
      status: "Работа по обращениям",
      data: dataWorkOnRequests,
      color: COLORS.blue,
    },
  ];

  const categories = [month1.value];

  const StatYear = [year1.year];

  // const Stat3Month = [getStatThreeMonth[0].month0, getStatThreeMonth[1].month1, getStatThreeMonth[2].month2];

  const categories1 = dataKeys;

  const seriesLabels = {
    visible: true,
    padding: 3,
    font: "normal 16px Arial, sans-serif",
    position: "center",
  };

  const seriesLabels1 = {
    visible: true,
    padding: 3,
    font: "normal 16px Arial, sans-serif",
    position: "top",
  };

  const categories5555 = ["Month 1", "Month 2"];
  const firstSeries = [10, 20]; // Данные для первой улицы
  const secondSeries = [15, 25]; // Данные для второй улицы


  const GetId = (e) => {
    let id = e.target.value;
    fetchZayvka(id);
    setGetData({ ...getData, id });
  }

  const defaultState = {
    center: [52.969411, 36.065562],
    zoom: 13,
    behaviors: ['default', 'scrollZoom'],

  };

  const defaultState1 = {
    center: [ZayvkaById.latitude, ZayvkaById.longtitude],
    zoom: 18,
    behaviors: ['default', 'scrollZoom'],

  };

  const GetSearch = (e) => {
    let value = e.target.value;
    setGetDataSearch({ ...getDataSearch, value, });
  }

  const GetStatus = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    setStatus({ [name]: value, });
  }

  const GetText = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    setText({ [name]: value, });
  }

  const NewMarker = (e) => {
    let coords = e.get('coords');
    setCoordinateOnClick(coords);
    alert("Координаты аварии изменены");
  }




  const fetchPostTextZayvka = async () => {
    console.log(valueDaData.value)
    if (valueDaData.value === undefined) {
      console.log("nen")
      alert("Ошибка: Не выбран адрес");
      return;
    }
    const formData = new FormData();
    if ((number.phone_number.startsWith("+79") || number.phone_number.startsWith("89") || number.phone_number.startsWith("79")) && number.phone_number.length > 9) {
      formData.append('phone_number', number.phone_number);
    }
    else {
      formData.append('home_phone_number', number.phone_number);
    }
    Object.entries(postData).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append('adress', valueDaData.value);
    formData.append('applicant_status', 'В работе');
    formData.append('active', true);
    formData.append('latitude', valueDaData.data.geo_lat);
    formData.append('longtitude', valueDaData.data.geo_lon);
    formData.append('dispatcher_name', itemFromLocalStorage);
    formData.append('performer', performerZayva.performer);


    let response = await fetch(API_URL + '/ADS/application/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
      body: formData,
    });

    if (response.status === 201) {
      alert('Заявка передана');
      setPostData({
        ...postData,
        text_application: "",
        fullname: "",
        email: "",
        category: 'Аварийная',
        object: 'Течь сети',
        applicant: 'Гражданин',
        seti: 'Водоснабжение',
        application_type: 'Первичная'
      });
      setNumber({
        phone_number: "",
        home_phone_number: "",
      })
      setValueDaData('');
      setZayvkaBySearch1('');
    }
    else {
      const errorMessage = await response.text();
      const jsonString = JSON.stringify(errorMessage);
      const newString = jsonString.substring(4);
      console.log(newString);
      if (newString.startsWith("phone")) {
        alert("Ошибка: " + "Неправильный формат мобильного номера или количество символов больше 15");
      }
      if (newString.startsWith("email")) {
        alert("Ошибка: " + "Введите правильный адрес электронной почты");
      }
    }

  }

  const fetchPostSaveZayvka = async () => {
    try {
      document.body.style.overflow = 'hidden';
      setLoading(true);
      const formData = new FormData();
      formData.append('active', false);
      formData.append('applicant_status', 'Исполнена');
      formData.append('damage_character', postSaveData.damage_character);
      formData.append('pipe_diametr', postSaveData.pipe_diametr);
      formData.append('pipe_material', postSaveData.pipe_material);
      formData.append('solution', postSaveData.solution);
      formData.append('comment', postSaveData.comment);
      formData.append('depth_top_of_pipe', postSaveData.depth_top_of_pipe);
      if (selectedEndImg && selectedStartImg !== null) {
        formData.append('photo_start', selectedStartImg);
        formData.append('photo_end', selectedEndImg);
      }
      formData.append('latitude', CoordinateOnClick[0]);
      formData.append('longtitude', CoordinateOnClick[1]);
      let response = await fetch(API_URL + '/ADS/application/' + ZayvkaById.id + '/', {
        method: 'PATCH',
        headers: {
          Authorization: 'Bearer ' + String(authTokens.access),
        },
        body: formData,
      });

      let data = await response.json();

      if (response.status === 200) {
        alert('Успешно');
        document.body.style.overflow = 'auto';
        setPostSaveData({
          damage_character: '',
          pipe_material: '',
          solution: '',
          comment: '',
          pipe_diametr: '',
          depth_top_of_pipe: '',
        })
        setSelectedEndImg(null);
        setSelectedStartImg(null);
      } else {

      }
    } catch (error) {
      console.error('Ошибка запроса:', error);
      alert('Произошла ошибка при выполнении запроса');
    } finally {
      setLoading(false);
    }
  }

  const fetchPostSaveZayvka1 = async () => {
    try {
      document.body.style.overflow = 'hidden';
      const formData = new FormData();
      formData.append('active', false);
      formData.append('applicant_status', 'Исполнена');
      formData.append('damage_character', postSaveData.damage_character1);
      formData.append('pipe_material', postSaveData.pipe_material1);
      formData.append('solution', postSaveData.solution1);
      formData.append('comment', postSaveData.comment);
      if (selectedEndImg && selectedStartImg !== null) {
        formData.append('photo_start', selectedStartImg);
        formData.append('photo_end', selectedEndImg);
      }
      formData.append('latitude', CoordinateOnClick[0]);
      formData.append('longtitude', CoordinateOnClick[1]);
      let response = await fetch(API_URL + '/ADS/application/' + ZayvkaById.id + '/', {
        method: 'PATCH',
        headers: {
          Authorization: 'Bearer ' + String(authTokens.access),
        },
        body: formData,
      });

      let data = await response.json();

      if (response.status === 200) {
        alert('Успешно');
        document.body.style.overflow = 'auto';
        setPostSaveData({
          damage_character1: '',
          pipe_material1: '',
          solution1: '',
          comment: '',
        })
        setSelectedEndImg(null);
        setSelectedStartImg(null);
      } else {

      }
    } catch (error) {
      console.error('Ошибка запроса:', error);
      alert('Произошла ошибка при выполнении запроса');
    } finally {
      setLoading(false);
    }

  }

  const fetchChangeCoor = async () => {
    let response = await fetch(API_URL + '/ADS/application/' + ZayvkaById.id + '/', {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
      body: JSON.stringify({
        latitude: CoordinateOnClick[0],
        longtitude: CoordinateOnClick[1],
      }),
    });

    let data = await response.json();

    if (response.status === 200) {
    } else {
      alert('Ошибка');
    }
  }

  const fetchTgBot = async () => {
    let response = await fetch(API_URL + '/TgBot/sendMessageFIO/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
      body: JSON.stringify({
        performer: performerZayva.performer,
        address: valueDaData.value,
        object: postData.object,
        phone_number: number.phone_number,
      }),
    });

    let data = await response.json();

    if (response.status === 200) {

    } else {

    }
  }



  const fetchZayvka = async (id) => {
    let response = await fetch(API_URL + '/ADS/application/' + id + '/', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });

    let data = await response.json();

    if (response.status === 200) {
      setZayvkaById(data);
      CoordinateOnClick[0] = data.latitude;
      CoordinateOnClick[1] = data.longtitude;
    }
    else {
      alert('Ошибка');
    }
  }

  const fetchSearchObj = async () => {
    let allZayvki = [];
    let nextPage = API_URL + '/ADS/application/applicant_status/' + WhatDataSearch + '/';
    while (nextPage) {
      let response = await fetch(nextPage, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
      });

      let data = await response.json();

      if (response.status === 200) {
        allZayvki = allZayvki.concat(data.results);
        nextPage = data.next;

      }
      else {
        alert('Ошибка');
        break;
      }
    }
    setZayvkaBySearch(allZayvki);
    setNameSearch({ value: 'По адресу' })
  }

  const fetchSearchDate = async () => {
    let allZayvki = [];
    let nextPage = API_URL + '/ADS/application/date/' + WhatDataSearch + '/';
    while (nextPage) {
      let response = await fetch(nextPage, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
      });

      let data = await response.json();

      if (response.status === 200) {
        allZayvki = allZayvki.concat(data.results);
        nextPage = data.next;

      }
      else {
        alert('Ошибка');
        break;
      }
    }
    setZayvkaBySearch(allZayvki);
    setNameSearch({ value: 'По адресу' })

  }

  const fetchSearchAdr = async (value) => {
    setIsPovtor(false);
    let allZayvki = [];
    let nextPage = API_URL + '/ADS/application/address/' + value + '/';
    while (nextPage) {
      let response = await fetch(nextPage, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
      });

      let data = await response.json();

      if (response.status === 200) {
        allZayvki = allZayvki.concat(data.results);
        nextPage = data.next;
      }
      else {
        alert('Ошибка');
        break;
      }
    }
    if (allZayvki.length !== 0) {
      for (var z = 0; z < allZayvki.length; z++) {
        if (allZayvki[z].seti !== "Теплоснабжение") {
          setIsPovtor(true);
          setPostData({
            ...postData,
            application_type: 'Повторная',
          })

        }
      }
    }

    setZayvkaBySearch1(allZayvki);
    setZayvkaBySearch(allZayvki);
    setNameSearch({ value: 'По адресу' })
  }

  const fetchZayvkaAll = async () => {
    let allZayvki = [];
    let nextPage = API_URL + '/ADS/application/not-active/';

    while (nextPage) {
      let response = await fetch(nextPage, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
      });

      let data = await response.json();

      if (response.status === 200) {
        allZayvki = allZayvki.concat(data.results);
        nextPage = data.next;

      }
      else {
        alert('Ошибка');
        break;
      }
    }
    setZayvkaAllNonAct(allZayvki);
  }

  const fetchZayvkaAll1 = async () => {
    let allZayvki = [];
    let nextPage = API_URL + '/ADS/application/active/';

    while (nextPage) {
      let response = await fetch(nextPage, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
      });

      let data = await response.json();

      if (response.status === 200) {
        allZayvki = allZayvki.concat(data.results);
        nextPage = data.next;

      }
      else {
        alert('Ошибка');
        break;
      }
    }
    setZayvkaAllAct(allZayvki);
  }

  const fetchZayvkaAllCoordinate = async () => {
    let allZayvki = [];
    let nextPage = API_URL + '/ADS/application/';
    while (nextPage) {
      let response = await fetch(nextPage, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
      });

      let data = await response.json();

      if (response.status === 200) {
        allZayvki = allZayvki.concat(data.results);
        nextPage = data.next;
      }
      else {
        alert('Ошибка');
        break;
      }
    }
    setAddressArr(allZayvki);
  }

  const fetchZayvkaAllCoordinate1 = async () => {
    let allZayvki = [];
    let nextPage = API_URL + '/ADS/application/';
    while (nextPage) {
      let response = await fetch(nextPage, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
      });

      let data = await response.json();

      if (response.status === 200) {
        allZayvki = allZayvki.concat(data.results);
        nextPage = data.next;
      }
      else {
        alert('Ошибка');
        break;
      }
    }
    setAddressArr1(allZayvki);
  }





  const fetchChangeStatus = async () => {
    let response = await fetch(API_URL + '/ADS/application/' + getData.id + '/', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
      body: JSON.stringify({
        applicant_status: status.applicant_status,
      }),
    });

    let data = await response.json();

    if (response.status === 200) {
      setZayvkaById(data);
    }
    else {
      alert('Ошибка');
    }
  }

  const fetchChangeStatusRab = async (id) => {
    let response = await fetch(API_URL + '/ADS/application/' + id + '/', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
      body: JSON.stringify({
        applicant_status: 'Отложить',
      }),
    });

    let data = await response.json();

    if (response.status === 200) {
      setZayvkaById(data);
    }
    else {
      alert('Ошибка');
    }
  }

  const fetchChangeStatusRab1 = async (id) => {
    let response = await fetch(API_URL + '/ADS/application/' + id + '/', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
      body: JSON.stringify({
        applicant_status: 'В работе',
      }),
    });

    let data = await response.json();

    if (response.status === 200) {
      setZayvkaById(data);
    }
    else {
      alert('Ошибка');
    }
  }

  const fetchChangeText = async () => {
    let response = await fetch(API_URL + '/ADS/application/' + getData.id + '/', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
      body: JSON.stringify({
        text_application: text.text_application,
      }),
    });

    let data = await response.json();

    if (response.status === 200) {
      setZayvkaById(data);
    }
    else {
      alert('Ошибка');
    }
  }

  const fetchChangePerformer = async () => {
    let response = await fetch(API_URL + '/ADS/application/' + modelId1 + '/', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
      body: JSON.stringify({
        performer: performerZayva1.performer,
        active: true,
        applicant_status: 'В работе'
      }),
    });

    let data = await response.json();

    if (response.status === 200) {
      alert('Успех');
    }
    else {
      alert('Ошибка');
    }
  }

  const fetchChangePhoto = async () => {
    const formData = new FormData();
    formData.append('photo_object', selectedImg);
    formData.append('applicant_status', 'В работе');
    formData.append('active', true);
    let response = await fetch(API_URL + '/ADS/application/' + modelId + '/', {
      method: 'PATCH',
      headers: {
        Authorization: 'Bearer ' + String(authTokens.access),
      },
      body: formData,
    });

    let data = await response.json();

    if (response.status === 200) {
      alert('Успешно');
    }
    else {
      alert('Ошибка');
    }
  }

  const fetchChangePhoto1 = async () => {
    const formData = new FormData();
    formData.append('photo_object', selectedImg);
    let response = await fetch(API_URL + '/ADS/application/' + modelId1 + '/', {
      method: 'PATCH',
      headers: {
        Authorization: 'Bearer ' + String(authTokens.access),
      },
      body: formData,
    });

    let data = await response.json();

    if (response.status === 200) {
      alert('Успешно');
    }
    else {
      alert('Ошибка');
    }
  }


  const fetchZayvkaForDAndD = async () => {
    let response = await fetch(API_URL + '/ADS/application/AllByPeriod/' + getCurrentMonth() + '/', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });

    let data = await response.json();

    if (response.status === 200) {
      setDataSearhMonth(data);
    }
    else {
      alert('Ошибка');
    }
  }

  const fetchZayvkaForArh = async (month) => {
    let response = await fetch(API_URL + '/ADS/application/AllByPeriod/' + month + '/', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });

    let data = await response.json();

    if (response.status === 200) {
      setDataSearhMonth(data);
    }
    else {
      alert('Ошибка');
    }
  }

  const fetchZayvkaByMonthInWork = async (month) => {
    let response = await fetch(API_URL + '/ADS/application/graph/status/inwork/' + month + '/', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorizat2ion: 'Bearer ' + String(authTokens.access),
      },
    });

    let data = await response.json();

    if (response.status === 200) {
      setGetDataByMonthInWork(data);
    }
    else {
      alert('Ошибка');
    }
  }


  const fetchZayvkaByMonthAccepted = async (month) => {
    let response = await fetch(API_URL + '/ADS/application/graph/status/accepted/' + month + '/', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });

    let data = await response.json();

    if (response.status === 200) {
      setGetDataByMonthAccepted(data);
    }
    else {
      alert('Ошибка');
    }
  }


  const fetchZayvkaByMonthExecuted = async (month) => {
    let response = await fetch(API_URL + '/ADS/application/graph/status/executed/' + month + '/', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });

    let data = await response.json();

    if (response.status === 200) {
      setGetDataByMonthExecuted(data);
    }
    else {
      alert('Ошибка');
    }
  }

  const fetchGetZayvaByDateAdr = async (nextPage) => {
    let response = await fetch(nextPage, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });

    if (response.status === 200) {
      const data = await response.json();
      return data;
    }
    else {
      alert('Ошибка');
    }
  }

  const fetchAllZayvaByDateAdr = async () => {
    let nextPage = API_URL + '/ADS/application/address/' + valueDaData1.value + '/' + getDate.date1 + '/' + getDate.date2 + '/';
    let allZayvaByAdrDate = [];

    try {
      while (nextPage) {
        const data = await fetchGetZayvaByDateAdr(nextPage);

        if (data.results.length === 0) {
          break;
        }

        allZayvaByAdrDate = allZayvaByAdrDate.concat(data.results);
        setZayvaByAdrDate(allZayvaByAdrDate);

        nextPage = data.next;
      }
    } catch (error) {
      alert(error.message);
    }
  };



  const fetchZayvkaByMonthInWork1 = async (month, year) => {
    let response = await fetch(API_URL + '/ADS/application/graph/status/inwork/' + month + '/' + `?year=${year}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });

    let data = await response.json();

    if (response.status === 200) {
      setGetDataByMonthInWork1(data);
    }
    else {
      alert('Ошибка');
    }
  }


  const fetchZayvkaByMonthAccepted1 = async (month, year) => {
    let response = await fetch(API_URL + '/ADS/application/graph/status/accepted/' + month + '/' + `?year=${year}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });

    let data = await response.json();

    if (response.status === 200) {
      setGetDataByMonthAccepted1(data);
    }
    else {
      alert('Ошибка');
    }
  }


  const fetchZayvkaByMonthExecuted1 = async (month, year) => {
    let response = await fetch(API_URL + '/ADS/application/graph/status/executed/' + month + '/' + `?year=${year}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });

    let data = await response.json();

    if (response.status === 200) {
      setGetDataByMonthExecuted1(data);
    }
    else {
      alert('Ошибка');
    }
  }

  const fetchCategoryByMonthPlan = async (month, year) => {
    let response = await fetch(API_URL + '/ADS/application/graph/category/planned/' + month + '/' + `?year=${year}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });

    let data = await response.json();

    if (response.status === 200) {
      setGetDataByMonthPlan(data);
    }
    else {
      alert('Ошибка');
    }
  }

  const fetchCategoryByMonthPaid = async (month, year) => {
    let response = await fetch(API_URL + '/ADS/application/graph/category/paid/' + month + '/' + `?year=${year}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });

    let data = await response.json();

    if (response.status === 200) {
      setGetDataByMonthPaid(data);
    }
    else {
      alert('Ошибка');
    }
  }

  const fetchCategoryByMonthCons = async (month, year) => {
    let response = await fetch(API_URL + '/ADS/application/graph/category/consultation/' + month + '/' + `?year=${year}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });

    let data = await response.json();

    if (response.status === 200) {
      setGetDataByMonthCons(data);
    }
    else {
      alert('Ошибка');
    }
  }

  const fetchCategoryByMonthWorkOnRequests = async (month, year) => {
    let response = await fetch(API_URL + '/ADS/application/graph/category/workOnRequests/' + month + '/' + `?year=${year}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });

    let data = await response.json();

    if (response.status === 200) {
      setGetDataByMonthWorkOnRequests(data);
    }
    else {
      alert('Ошибка');
    }
  }


  const fetchCategoryByMonthTechnicalShutdown = async (month, year) => {
    let response = await fetch(API_URL + '/ADS/application/graph/category/technicalShutdown/' + month + '/' + `?year=${year}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });

    let data = await response.json();

    if (response.status === 200) {
      setGetDataByMonthTechnicalShutdown(data);
    }
    else {
      alert('Ошибка');
    }
  }

  const fetchCategoryByMonthAlarm = async (month, year) => {
    let response = await fetch(API_URL + '/ADS/application/graph/category/alarm/' + month + '/' + `?year=${year}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });

    let data = await response.json();

    if (response.status === 200) {
      setGetDataByMonthAlarm(data);
    }
    else {
      alert('Ошибка');
    }
  }

  const fetchSetiByMonthWatersupply = async (month, year) => {
    let response = await fetch(API_URL + '/ADS/application/graph/seti/watersupply/' + month + '/' + `?year=${year}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });

    let data = await response.json();

    if (response.status === 200) {
      setGetDataByMonthWatersupply(data);
    }
    else {
      alert('Ошибка');
    }
  }

  const fetchSetiByMonthSewerage = async (month, year) => {
    let response = await fetch(API_URL + '/ADS/application/graph/seti/sewerage/' + month + '/' + `?year=${year}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });

    let data = await response.json();

    if (response.status === 200) {
      setGetDataByMonthSewerage(data);
    }
    else {
      alert('Ошибка');
    }
  }

  const fetchSetiByMonthImprovement = async (month, year) => {
    let response = await fetch(API_URL + '/ADS/application/graph/seti/improvement/' + month + '/' + `?year=${year}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });

    let data = await response.json();

    if (response.status === 200) {
      setGetDataByMonthImprovement(data);
    }
    else {
      alert('Ошибка');
    }
  }

  const fetchDataForProgr = async () => {
    let allZayvki = [];
    let nextPage = API_URL + '/ADS/application/not-active/';
    while (nextPage) {
      let response = await fetch(nextPage, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
      });

      let data = await response.json();

      if (response.status === 200) {
        allZayvki = allZayvki.concat(data.results);
        nextPage = data.next;
      }
      else {
        alert('Ошибка');
        break;
      }
    }
    setGetDataForMyGpaph(allZayvki);
  }


  const fetchDataForGpaph = async (date2) => {
    let response = await fetch(API_URL + '/ADS/graph/period/from/' + date1.value + '/to/' + date2, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });

    let data = await response.json();

    if (response.status === 200) {
      dataAlarm.length = 0;
      dataPlan.length = 0;
      dataPaid.length = 0;
      dataCons.length = 0;
      dataWorkOnRequests.length = 0;
      dataTechnicalShutdown.length = 0;
      dataKeys.length = 0;
      setGetDataForMyGpaph(data);
      for (var key in data.Аварийная) {
        dataAlarm.push(data.Аварийная[key]);
        dataKeys.push(key.substring(5, 10));
      }
      for (var key in data.Плановая) {
        dataPlan.push(data.Плановая[key]);
      }
      for (var key in data.Платная) {
        dataPaid.push(data.Платная[key]);
      }
      for (var key in data.Консультация) {
        dataCons.push(data.Консультация[key]);
      }
      for (var key in data['Работа по обращениям']) {
        dataWorkOnRequests.push(data['Работа по обращениям'][key]);
      }
      for (var key in data['Техническое отключение']) {
        dataTechnicalShutdown.push(data['Техническое отключение'][key]);
      }
    }
    else {
      alert('Ошибка');
    }
  }

  const FetcAdresStats = async (month, year) => {
    let response = await fetch(API_URL + '/ADS/application/Statistic/ByPeriod/' + year + '-01' + '/' + year + '-' + month + '/', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });

    let data = await response.json();

    if (response.status === 200) {
      setGetDataByYearAdr(data);
    } else {
      alert('Ошибка');
    }
  }

  const FetcAdresStatsSewage = async (month, year) => {
    let response = await fetch(API_URL + '/ADS/application/Statistic/Sewage/ByPeriod/' + year + '-01' + '/' + year + '-' + month + '/', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });

    let data = await response.json();

    if (response.status === 200) {
      setGetDataByYearAdr(data);
    } else {
      alert('Ошибка');
    }
  }

  const FetcAdresStatsForThreeMonth = async () => {
    let response = await fetch(API_URL + '/ADS/application/Statistic/ByLastThreeMonths/', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });

    let data = await response.json();

    if (response.status === 200) {
      setgetStatThreeMonth(data);
    } else {
      alert('Ошибка');
    }
  }

  const FetchPostPerformer = async () => {
    let response = await fetch(API_URL + '/ADS/performer/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
      body: JSON.stringify({
        firstname: postPerformers.firstname,
        lastname: postPerformers.lastname,
        type_of_activity: postPerformers.type_of_activity,
      }),
    });

    let data = await response.json();

    if (response.status === 201) {
      alert("Успех");
      fetchGetPerformer();
    } else {
      alert('Ошибка');
    }
  }

  const FetchPostPerformer1 = async () => {
    let response = await fetch(API_URL + '/ADS/performer/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
      body: JSON.stringify({
        firstname: postPerformers1.firstname,
        lastname: postPerformers1.lastname,
        type_of_activity: postPerformers1.type_of_activity,
      }),
    });

    let data = await response.json();

    if (response.status === 201) {
      alert("Успех");
      fetchGetPerformer();
    } else {
      alert('Ошибка');
    }
  }

  const fetchGetPerformer = async (month) => {
    let response = await fetch(API_URL + '/ADS/performer/', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });

    let data = await response.json();

    if (response.status === 200) {
      setGetPerformer(data);
      for (let z = 0; z < data.length; z++) {
        if (data[z].type_of_activity === "Канализация") {
          performerZayva.performer = data[z].lastname;
          setIdPerformers(data[z].pk);
          NamePerformers1.performer = data[z].lastname;
          break;
        }
      }
      for (let z = 0; z < data.length; z++) {
        if (data[z].type_of_activity === "Водоснабжение") {
          performerZayva1.performer = data[z].lastname;
          setIdPerformers1(data[z].pk);
          NamePerformers.performer = data[z].lastname;
          break;
        }
      }


    }
    else {
      alert('Ошибка');
    }
  }

  let [getPerformer, setGetPerformer] = useState([]);

  const handleFileChange = (event) => {
    setSelectedImage(event.target.files[0]);
  };

  const handleFile = (event) => {
    setSelectedImg(event.target.files[0]);
  };

  const StartPhotoFile = (event) => {
    setSelectedStartImg(event.target.files[0]);
  }

  const EndPhotoFile = (event) => {
    setSelectedEndImg(event.target.files[0]);
  }

  //console.log(itemFromLocalStorage1)


  const labelContent = (props) => {
    console.log("xxx")
    console.log(props.dataItem)
    // let formatedNumber = Number(props.dataItem.data).toLocaleString(undefined, {
    //   style: "percent",
    //   minimumFractionDigits: 2,
    // });
    // return `${props.dataItem.status} status: ${formatedNumber}`;
  };

  const itemFromLocalStorage = localStorage.getItem('FioOp');
  const itemFromLocalStorage1 = localStorage.getItem('FioRab');
  const [IsSmena, setIsSmena] = useState(true);
  const [IsFioOp, setIsFioOp] = useState(true);
  const [IsSmena1, setIsSmena1] = useState(true);
  const [IsFioRab, setIsFioRab] = useState(true);
  const [IsManModalBtn, setIsManModalBtn] = useState(true);
  const [IsAddPerf, setIsAddPerf] = useState(false);
  const [IsDelPerf, setIsDelPerf] = useState(false);
  const [IsChecModal, setIsChecModal] = useState(false);
  const [IsChecModal5, setIsChecModal5] = useState(false);
  const [IsChecModal1, setIsChecModal1] = useState(false);
  const [IsChecModal2, setIsChecModal2] = useState(false);
  const [IsChecModal3, setIsChecModal3] = useState(false);
  const [IsChecModal4, setIsChecModal4] = useState(false);
  const [IsChecModal111, setIsChecModal111] = useState(false);
  const [IsChecModal6, setIsChecModal6] = useState(false);
  const [IsChecNonActv, setIsChecNonAct] = useState(false);
  const [IsChecAct, setIsChecAct] = useState(false);
  const [IsWaitZayva, setIsWaitZayva] = useState(false);
  const [IsIspZayva, setIsIspZayva] = useState(false);
  const body = document.querySelector('body');
  let [modelId, setModelId] = useState();
  let [modelId1, setModelId1] = useState();

  const nonActv = () => {
    setIsChecNonAct(!IsChecNonActv);
    setIsChecAct(false);
    setIsWaitZayva(false);
    setIsIspZayva(false);
  }

  const Actv = () => {
    setIsChecNonAct(false);
    setIsWaitZayva(false);
    setIsIspZayva(false);
    setIsChecAct(!IsChecAct);
  }

  const WaitZayva = () => {
    setIsWaitZayva(!IsWaitZayva);
    setIsChecAct(false);
    setIsChecNonAct(false);
    setIsIspZayva(false);
  }

  const IspZayva = () => {
    setIsIspZayva(!IsIspZayva);
    setIsChecAct(false);
    setIsChecNonAct(false);
    setIsWaitZayva(false);
  }


  const ModalChange = () => {
    setIsChecModal(!IsChecModal);
  }
  const ModalChange5 = () => {
    setIsChecModal5(!IsChecModal5);
  }
  const ModalChange1 = () => {
    setIsChecModal1(!IsChecModal1);
  }
  const ModalChange6 = () => {
    setIsChecModal6(!IsChecModal6);
    setZayvkaBySearch([]);
    setZayvkaBySearch1([]);
  }
  const OpenModal2 = () => {
    setIsChecModal2(true);
    body.classList.add('no-scroll');
  }
  const CloseModal2 = () => {
    setIsChecModal2(false);
    body.classList.remove('no-scroll');
  }
  const OpenModal3 = () => {
    setIsChecModal3(true);
  }
  const CloseModal3 = () => {
    setIsChecModal3(false);
  }
  const OpenModal4 = () => {
    setIsChecModal4(true);
  }
  const CloseModal4 = () => {
    setIsChecModal4(false);
  }
  const OpenModal111 = () => {
    setIsChecModal111(true);
  }
  const CloseModal111 = () => {
    setIsChecModal111(false);
  }
  const AddBtnPerf = () => {
    setIsAddPerf(!IsAddPerf);
  }
  const HManBtn = () => {
    setIsManModalBtn(!IsManModalBtn);
  }
  const DelBtnPerf = () => {
    setIsDelPerf(!IsDelPerf);
    setDeletePerformers({ ...DeletePerformers, type_of_activity: "Канализация" })
  }
  const OpenSmena = () => {
    setIsSmena(false);
  }
  const OpenSmena1 = () => {
    setIsSmena1(false);
  }
  const ForFioOp = () => {
    setIsFioOp(false);
  }
  const ForFioRab = () => {
    setIsFioRab(false);
  }
  const HdeWhenClose = () => {
    setIsAddPerf(false);
    setIsDelPerf(false);
  }
  const OpenWhenClose = () => {
    setIsManModalBtn(true);
    setDeletePerformers({ ...DeletePerformers, type_of_activity: "Канализация" })
  }
  const ModalEdtPerf = () => {
    return (
      <>
        <div className="Ads__EdtPerf">
          <h1 className="Ads__EdtTtlePerd">Редактирование исполнителей</h1>
          <button className="Ads__CloseModalBtn" onClick={() => (ModalChange(), HdeWhenClose(), OpenWhenClose())}>X</button>
          <div className="Ads__ModalContainer">
            {IsManModalBtn === true ? (
              <>
                <button className="Ads__AddPerfBtn" onClick={() => (HManBtn(), AddBtnPerf())}>Добавить исполнителя</button>
                <button className="Ads__DelPerfBtn" onClick={() => (HManBtn(), DelBtnPerf())}>Удалить исполнителя</button>
              </>
            ) : (
              ''
            )}
          </div>
          {IsAddPerf === true ? (
            <>
              <div className="Ads__AddCont">
                <div className="Ads__AddCont1">
                  <label className="Ads__text-input Ads__block Ads__DopForLabel Ads__DopAdrStyle1">Введите фамилию:<input className="Ads__ForWdht Ads__sel Ads__block Ads__DopStyle" name='lastname' onChange={(e) => postPerformer(e)} /></label>
                  <label className="Ads__text-input Ads__block">Сфера работ:<select className="Ads__ForWdht Ads__sel Ads__block Ads__DopStyle" name='type_of_activity' onChange={(e) => postPerformer(e)}><option></option><option>Канализация</option><option>Водоснабжение</option><option>Благоустройство</option></select></label>
                </div>
                <button className="Ads__confrAddPerf" onClick={() => (HManBtn(), AddBtnPerf(), FetchPostPerformer())}>Добавить</button>
              </div>
            </>
          ) : (
            ''
          )}
          {IsDelPerf === true ? (
            <>
              <div className="Ads__AddCont">
                <div className="Ads__AddCont1">
                  <label className="Ads__text-input Ads__block Ads__DopForLabel Ads__DopAdrStyle1">Сфера работ:<select className="Ads__ForWdht Ads__sel Ads__block Ads__DopStyle" name='type_of_activity' onChange={(e) => delPerformer(e)}><option></option><option>Канализация</option><option>Водоснабжение</option><option>Благоустройство</option></select></label>
                  {DeletePerformers.type_of_activity === "Канализация" ? (
                    <label className="Ads__text-input Ads__block">Исполнитель:<select className="Ads__ForWdht Ads__sel Ads__block" name='performer' onChange={(e) => delPerformer(e)}>
                      <option></option>
                      {getPerformer.map((item, idx) => {
                        return (
                          <>
                            {item.type_of_activity === "Канализация" ? (
                              <>
                                <option>
                                  {item.lastname}
                                </option>
                              </>
                            ) : (
                              ''
                            )}
                          </>
                        )
                      })}
                    </select></label>
                  ) : (
                    ''
                  )}
                  {DeletePerformers.type_of_activity === "Водоснабжение" ? (
                    <label className="Ads__text-input Ads__block">Исполнитель:<select className="Ads__ForWdht Ads__sel Ads__block" name='performer' onChange={(e) => delPerformer(e)}>
                      <option></option>
                      {getPerformer.map((item, idx) => {
                        return (
                          <>
                            {item.type_of_activity === "Водоснабжение" ? (
                              <>
                                <option>
                                  {item.lastname}
                                </option>
                              </>
                            ) : (
                              ''
                            )}
                          </>
                        )
                      })}
                    </select></label>
                  ) : (
                    ''
                  )}
                  {DeletePerformers.type_of_activity === "Благоустройство" ? (
                    <label className="Ads__text-input Ads__block">Исполнитель:<select className="Ads__ForWdht Ads__sel Ads__block" name='performer' onChange={(e) => delPerformer(e)}>
                      <option></option>
                      {getPerformer.map((item, idx) => {
                        return (
                          <>
                            {item.type_of_activity === "Благоустройство" ? (
                              <>
                                <option>
                                  {item.lastname}
                                </option>
                              </>
                            ) : (
                              ''
                            )}
                          </>
                        )
                      })}
                    </select></label>
                  ) : (
                    ''
                  )}
                </div>
                <button className="Ads__confrAddPerf" onClick={() => (HManBtn(), DelBtnPerf(), fetchDeletePerformer())}>Удалить</button>
              </div>
            </>
          ) : (
            ''
          )}
        </div>
      </>
    )
  }

  const ModalEdtPerf1 = () => {
    return (
      <>
        <div className="Ads__EdtPerf">
          <h1 className="Ads__EdtTtlePerd">Редактирование исполнителей</h1>
          <button className="Ads__CloseModalBtn" onClick={() => (ModalChange5(), HdeWhenClose(), OpenWhenClose())}>X</button>
          <div className="Ads__ModalContainer">
            {IsManModalBtn === true ? (
              <>
                <button className="Ads__AddPerfBtn" onClick={() => (HManBtn(), AddBtnPerf())}>Добавить исполнителя</button>
                <button className="Ads__DelPerfBtn" onClick={() => (HManBtn(), DelBtnPerf())}>Удалить исполнителя</button>
              </>
            ) : (
              ''
            )}
          </div>
          {IsAddPerf === true ? (
            <>
              <div className="Ads__AddCont">
                <div className="Ads__AddCont1">
                  <label className="Ads__text-input Ads__block Ads__DopForLabel Ads__DopAdrStyle1">Введите фамилию:<input className="Ads__ForWdht Ads__sel Ads__block Ads__DopStyle" name='lastname' onChange={(e) => postPerformer1(e)} /></label>
                  <label className="Ads__text-input Ads__block">Сфера работ:<select className="Ads__ForWdht Ads__sel Ads__block Ads__DopStyle" name='type_of_activity' onChange={(e) => postPerformer1(e)}><option>Водоснабжение</option></select></label>
                </div>
                <button className="Ads__confrAddPerf" onClick={() => (HManBtn(), AddBtnPerf(), FetchPostPerformer1())}>Добавить</button>
              </div>
            </>
          ) : (
            ''
          )}
          {IsDelPerf === true ? (
            <>
              <div className="Ads__AddCont">
                <div className="Ads__AddCont1">
                  <label className="Ads__text-input Ads__block Ads__DopForLabel Ads__DopAdrStyle1">Сфера работ:<select className="Ads__ForWdht Ads__sel Ads__block Ads__DopStyle" name='type_of_activity' onChange={(e) => delPerformer1(e)}><option>Водоснабжение</option></select></label>
                  {DeletePerformers.type_of_activity === "Канализация" ? (
                    <label className="Ads__text-input Ads__block">Исполнитель:<select className="Ads__ForWdht Ads__sel Ads__block" name='performer' onChange={(e) => delPerformer1(e)}>
                      {getPerformer.map((item, idx) => {
                        return (
                          <>
                            {item.type_of_activity === "Водоснабжение" ? (
                              <option>
                                {item.lastname}
                              </option>
                            ) : (
                              ''
                            )}
                          </>
                        )
                      })}
                    </select></label>
                  ) : (
                    ''
                  )}
                </div>
                <button className="Ads__confrAddPerf" onClick={() => (HManBtn(), DelBtnPerf(), fetchDeletePerformer1())}>Удалить</button>
              </div>
            </>
          ) : (
            ''
          )}
        </div>
      </>
    )
  }

  function areDatesEqual(date1, date2) {
    return date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate();
  }

  function getCurrentMonth() {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    return `${year}-${month}`;
  }

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();


  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');

  const formattedDate = `${year}-${month}`;


  const [filteredData, setFilteredData] = useState([]);
  const [filteredData1, setFilteredData1] = useState([]);
  const [filteredData2, setFilteredData2] = useState([]);

  const [filters, setFilters] = useState({
    address: '',
    dateFrom: '',
    dateTo: '',
    status: '',
    seti: '',
  });

  const [filters1, setFilters1] = useState({

    address: '',
    dateFrom: '',
    dateTo: '',
    status: '',
    seti: '',
  });

  const [filters2, setFilters2] = useState({

    address: '',
    dateFrom: '',
    dateTo: '',
    status: '',
    seti: '',
  });

  const filterData = () => {

    const filteredByStatus = DataSearhMonth.query.filter((item) => {
      if (filters.status && filters.status === 'Исполнена') {
        return item.applicant_status !== 'Исполнена';
      }
      return true;
    });

    const filteredBySeti = filteredByStatus.filter((item) => {
      if (filters.seti) {
        return item.seti.includes(filters.seti);
      }
      return true;
    });

    const filteredByAddress = filteredBySeti.filter((item) => {
      return item.adress.includes(filters.address);
    });

    const filteredByDate = filteredByAddress.filter((item) => {
      const itemDate = new Date(item.created_date);
      const dateFrom = new Date(filters.dateFrom);
      const dateTo = new Date(filters.dateTo);

      return (!filters.dateFrom || areDatesEqual(itemDate, dateFrom) || itemDate > dateFrom) && (!filters.dateTo || areDatesEqual(itemDate, dateTo) || itemDate < dateTo);
    });

    const filteredBySetiType = filteredByDate.filter((item) => {
      return item.seti !== 'Теплоснабжение';
    });

    const sortedByDate = filteredBySetiType.sort((a, b) => new Date(b.created_date) - new Date(a.created_date));

    return sortedByDate;;
  };

  const filterData1 = () => {

    const filteredBySeti = DataSearhMonth.query.filter((item) => {
      if (filters1.seti) {
        return item.seti.includes(filters1.seti);
      }
      return true;
    });

    const filteredBySetiType = filteredBySeti.filter((item) => {
      return item.seti !== 'Теплоснабжение';
    });

    return filteredBySetiType;

  }

  const filterData2 = () => {

    const filteredByStatus = DataSearhMonth.query.filter((item) => {
      if (filters2.status) {
        return item.applicant_status.includes(filters2.status);
      }
      return true;
    });

    const filteredBySeti = filteredByStatus.filter((item) => {
      if (filters2.seti) {
        return item.seti.includes(filters2.seti);
      }
      return true;
    });

    const filteredByAddress = filteredBySeti.filter((item) => {
      return item.adress.includes(filters2.address);
    });

    const filteredByDate = filteredByAddress.filter((item) => {
      const itemDate = new Date(item.created_date);
      const dateFrom = new Date(filters2.dateFrom);
      const dateTo = new Date(filters2.dateTo);

      return (!filters2.dateFrom || areDatesEqual(itemDate, dateFrom) || itemDate > dateFrom) && (!filters2.dateTo || areDatesEqual(itemDate, dateTo) || itemDate < dateTo);
    });

    const filteredBySetiType = filteredByDate.filter((item) => {
      return item.seti !== 'Теплоснабжение';
    });

    return filteredBySetiType;
  }

  useEffect(() => {
    const filteredResult = filterData();
    setFilteredData(filteredResult);
  }, [filters, DataSearhMonth]);

  useEffect(() => {
    const filteredResult = filterData1();
    setFilteredData1(filteredResult);
  }, [filters1, DataSearhMonth]);

  useEffect(() => {
    const filteredResult = filterData2();
    setFilteredData2(filteredResult);
  }, [filters2, DataSearhMonth]);

  const handleAddressChange = (e) => {
    setFilters({ ...filters, address: e.value });
    setFilters2({ ...filters2, address: e.value });
  };

  const handleDateFromChange = (e) => {
    setFilters({ ...filters, dateFrom: e.target.value });
    setFilters2({ ...filters2, dateFrom: e.target.value });

  };

  const handleDateToChange = (e) => {
    setFilters({ ...filters, dateTo: e.target.value });
    setFilters2({ ...filters2, dateTo: e.target.value });

  };

  const handleAddressChange1 = (e) => {
    setFilters2({ ...filters2, address: e.value });
  };

  const handleDateFromChange1 = (e) => {
    setFilters2({ ...filters2, dateFrom: e.target.value });

  };

  const handleDateToChange1 = (e) => {
    setFilters2({ ...filters2, dateTo: e.target.value });

  };

  const handleStatusChange1 = (e) => {
    setFilters2({ ...filters2, status: e.target.value });

  };

  const handleSetiChange1 = (e) => {
    setFilters2({ ...filters2, seti: e.target.value });

  };

  const handleSetiChange2 = (e) => {
    setFilters1({ ...filters1, seti: e.target.value });
    setFile({ ...file, seti: e.target.value });

  };

  const handleSetiChange3 = (e) => {
    setFilters({ ...filters, seti: e.target.value });
    setFilters1({ ...filters1, seti: e.target.value });
    setFile({ ...file, seti: e.target.value });

  };


  const handleStatusChange = (e) => {
    setFilters({ ...filters, status: 'Исполнена' });

  };

  const handleMonthChange = (e) => {
    const month = e.target.value;
    fetchZayvkaForArh(month);
    setFile({ ...file, defaultMonth: e.target.value });
  };

  const handleMonthChange2 = (e) => {
    setFilters2({ ...filters2, month: parseInt((e.target.value).substr(5, 2), 10) });
  };


  const ClearFilters = () => {
    setFilters({
      ...filters,
      address: "",
      dateFrom: "",
      dateTo: "",
      status: "",
      seti: ""
    });
  }

  const ClearFilters1 = () => {
    setFilters2({
      ...filters2,
      month: currentMonth,
      year: currentYear,
      address: "",
      dateFrom: "",
      dateTo: "",
      status: "",
      seti: "",
    });
  }

  const [file, setFile] = useState({
    defaultMonth: formattedDate,
    seti: "Все заявки"
  })



  const ZayvaInNewWindow = (id) => {
    window.open(`/services/ads/detailed-info?id=${id}`, '_blank');

  }

  function exportToExcel(data1, data2, data3, data4, data5, data6, filename, nameSet) {
    const workbook = XLSX.utils.book_new();

    function addSheet(sheetName, data) {
      const ws = XLSX.utils.json_to_sheet(data);
      ws['!cols'] = [
        { width: 10 },
        { width: 15 },
        { width: 15 },
        { width: 20 },
        { width: 20 },
        { width: 30 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 15 },
        { width: 20 },
        { width: 20 },
        { width: 15 },
        { width: 20 },
        { width: 20 },
        { width: 15 },
        { width: 20 },
        { width: 20 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
      ];
      XLSX.utils.book_append_sheet(workbook, ws, sheetName);
    }

    addSheet('Поступившие', data1);
    addSheet('В работе', data2);
    if (nameSet === "Благоустройство") {
      addSheet('Выполненные', data5);
    } else if (nameSet === "Канализация") {
      addSheet('Выполненные', data6);
    } else {
      addSheet('Выполненные', data3);
    }
    addSheet('Отложенные', data4);

    XLSX.writeFile(workbook, `${filename} - ${nameSet}.xlsx`);
  }


  const data1 = filteredData1
    .filter(item => item.applicant_status === 'В работе')
    .map(item => {
      const utcDate = new Date(item.created_date);
      const moscowDate = new Date(utcDate.getTime() + 3 * 60 * 60 * 1000);
      return {
        '№ Заявки': item.id,
        'Дата': moscowDate.toISOString().substring(0, 10),
        'Время': moscowDate.toISOString().substring(11, 16),
        'ФИО оператора': item.dispatcher_name,
        'Исполнитель': item.performer,
        'Адрес': item.adress,
        'Текст заявки': item.text_application,
        'Тип заявителя': item.applicant,
        'Категория': item.category,
        'Сеть': item.seti,
        'Объект аварии': item.object,
        'Статус заявки': 'Принятно',
      };
    });

  const data2 = filteredData1
    .filter(item => item.applicant_status === 'В работе')
    .map(item => {
      const utcDate = new Date(item.created_date);
      const moscowDate = new Date(utcDate.getTime() + 3 * 60 * 60 * 1000);
      return {
        '№ Заявки': item.id,
        'Дата': moscowDate.toISOString().substring(0, 10),
        'Время': moscowDate.toISOString().substring(11, 16),
        'ФИО оператора': item.dispatcher_name,
        'Исполнитель': item.performer,
        'Адрес': item.adress,
        'Текст заявки': item.text_application,
        'Тип заявителя': item.applicant,
        'Категория': item.category,
        'Сеть': item.seti,
        'Объект аварии': item.object,
        'Статус заявки': item.applicant_status,
      };
    });

  const data3 = filteredData1
    .filter(item => item.applicant_status === 'Исполнена')
    .map(item => {
      const utcDate = new Date(item.created_date);
      const moscowDate = new Date(utcDate.getTime() + 3 * 60 * 60 * 1000);
      const utcDateNd = new Date(item.updated_date);
      const moscowDateNd = new Date(utcDateNd.getTime() + 3 * 60 * 60 * 1000);
      return {
        '№ Заявки': item.id,
        'Дата': moscowDate.toISOString().substring(0, 10),
        'Время': moscowDate.toISOString().substring(11, 16),
        'ФИО оператора': item.dispatcher_name,
        'Исполнитель': item.performer,
        'Адрес': item.adress,
        'Текст заявки': item.text_application,
        'Тип заявителя': item.applicant,
        'Категория': item.category,
        'Сеть': item.seti,
        'Объект аварии': item.object,
        'Статус заявки': item.applicant_status,
        'Характер повреждения': item.damage_character,
        'Глубина до верха трубы,см': item.depth_top_of_pipe,
        'Диаметр трубы': item.pipe_diametr,
        'Материал трубы': item.pipe_material,
        'Способ устранения': item.solution,
        'Комментарий': item.comment,
        'Дата  конца работ': moscowDateNd.toISOString().substring(0, 10),
        'Время конца работ': moscowDateNd.toISOString().substring(11, 16),
      };
    });

  const data4 = filteredData1
    .filter(item => item.applicant_status === 'Отложить')
    .map(item => {
      const utcDate = new Date(item.created_date);
      const moscowDate = new Date(utcDate.getTime() + 3 * 60 * 60 * 1000);
      return {
        '№ Заявки': item.id,
        'Дата': moscowDate.toISOString().substring(0, 10),
        'Время': moscowDate.toISOString().substring(11, 16),
        'ФИО оператора': item.dispatcher_name,
        'Исполнитель': item.performer,
        'Адрес': item.adress,
        'Текст заявки': item.text_application,
        'Тип заявителя': item.applicant,
        'Категория': item.category,
        'Сеть': item.seti,
        'Объект аварии': item.object,
        'Статус заявки': item.applicant_status,
      };
    });

  const data5 = filteredData1
    .filter(item => item.applicant_status === 'Исполнена')
    .map(item => {
      const utcDate = new Date(item.created_date);
      const moscowDate = new Date(utcDate.getTime() + 3 * 60 * 60 * 1000);
      const utcDateNd = new Date(item.updated_date);
      const moscowDateNd = new Date(utcDateNd.getTime() + 3 * 60 * 60 * 1000);
      return {
        '№ Заявки': item.id,
        'Дата': moscowDate.toISOString().substring(0, 10),
        'Время': moscowDate.toISOString().substring(11, 16),
        'ФИО оператора': item.dispatcher_name,
        'Исполнитель': item.performer,
        'Адрес': item.adress,
        'Текст заявки': item.text_application,
        'Тип заявителя': item.applicant,
        'Категория': item.category,
        'Сеть': item.seti,
        'Объект аварии': item.object,
        'Статус заявки': item.applicant_status,
        'Характер повреждения': item.damage_character,
        'Материал трубы': item.pipe_material,
        'Способ устранения': item.solution,
        'Комментарий': item.comment,
        'Дата  конца работ': moscowDateNd.toISOString().substring(0, 10),
        'Время конца работ': moscowDateNd.toISOString().substring(11, 16),
      };
    });

  const data6 = filteredData1
    .filter(item => item.applicant_status === 'Исполнена')
    .map(item => {
      const utcDate = new Date(item.created_date);
      const moscowDate = new Date(utcDate.getTime() + 3 * 60 * 60 * 1000);
      const utcDateNd = new Date(item.updated_date);
      const moscowDateNd = new Date(utcDateNd.getTime() + 3 * 60 * 60 * 1000);
      return {
        '№ Заявки': item.id,
        'Дата': moscowDate.toISOString().substring(0, 10),
        'Время': moscowDate.toISOString().substring(11, 16),
        'ФИО оператора': item.dispatcher_name,
        'Исполнитель': item.performer,
        'Адрес': item.adress,
        'Текст заявки': item.text_application,
        'Тип заявителя': item.applicant,
        'Категория': item.category,
        'Сеть': item.seti,
        'Объект аварии': item.object,
        'Статус заявки': item.applicant_status,
        'Характер повреждения': item.damage_character,
        'Способ устранения': item.solution,
        'Комментарий': item.comment,
        'Дата  конца работ': moscowDateNd.toISOString().substring(0, 10),
        'Время конца работ': moscowDateNd.toISOString().substring(11, 16),
      };
    });





  const ModalSearch = () => {
    return (
      <>
        <div className="Ads__SearchModalZayva">
          <h1 className="Ads__EdtTtlePerd">Поиск заявок</h1>
          <button className="Ads__CloseModalBtn" onClick={() => (ModalChange1())}>X</button>
          <div className="Ads__searchCont">
            <label className="Ads__text-input1 Ads__block Ads__DopForLabel1 Ads__DopAdrStyle">Адрес:<AddressSuggestions token="6dc7b29f2b8e6eb9bc537838a324af7caafc5eca" type='text' className="Ads__ForWdht Ads__sel Ads__block Ads__DopStyle" name='adr' value={filters.address} onChange={handleAddressChange} /></label>
            <label className="Ads__text-input Ads__block Ads__DopForLabel1">Дата(с):<input className="Ads__ForWdht Ads__sel Ads__block Ads__DopStyle" name='date1' type='date' value={filters.dateFrom} onChange={handleDateFromChange} /></label>
            <label className="Ads__text-input Ads__block Ads__DopForLabel1">Дата(до):<input className="Ads__ForWdht Ads__sel Ads__block Ads__DopStyle" name='date2' type='date' value={filters.dateTo} onChange={handleDateToChange} /></label>
            <label className="Ads__text-input Ads__block Ads__DopForLabel2">Сеть:<select className="Ads__SelSearch1ModalSearch" value={filters.seti} onChange={handleSetiChange3}><option></option><option>Водоснабжение</option><option>Канализация</option><option>Благоустройство</option></select></label>
          </div>
          <div className="Ads__btnSearchModalCont">
            <button className='Ads__execlBtn1' onClick={() => exportToExcel(data1, data2, data3, data4, data5, data6, file.defaultMonth, file.seti)}>Экспорт в Excel</button>
            <button className="Ads__btnSearchModal" onClick={() => ClearFilters()}>Показать все заявки</button>
            <button className="Ads__btnSearchModal1" onClick={() => handleStatusChange()}>Показать только неисполненные заявки</button>
          </div>
          <div className="Ads__tableByIdModal">
            <table className="Ads__tableById Ads__tableOleg">
              <thead>
                <tr>
                  <th>
                    №Заявки
                  </th>
                  <th>
                    Дата
                  </th>
                  <th>
                    Время
                  </th>
                  <th>
                    ФИО оператора
                  </th>
                  <th>
                    Адрес
                  </th>
                  <th>
                    Текст заявки
                  </th>
                  <th>
                    Тип заявителя
                  </th>
                  <th>
                    Категория
                  </th>
                  <th>
                    Объект аварии
                  </th>
                  <th>
                    Статус заявки
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((item, index) => {
                  let date = new Date(item.created_date);

                  let options = { timeZone: 'Europe/Moscow', hour: '2-digit', minute: '2-digit', year: 'numeric', month: '2-digit', day: '2-digit' };
                  let formattedDateTime = date.toLocaleString('ru-RU', options);

                  let [formattedDate, formattedTime] = formattedDateTime.split(', ');
                  return (
                    <>
                      <tr key={index} id={`row-${index}`}>
                        {item.applicant_status === 'Исполнена' ? (
                          <td><button className="Ads__BtnForDispIspZayvka" onClick={() => ZayvaInNewWindow(item.id)}>{item.id}</button></td>
                        ) : (
                          <td>
                            {item.id}
                          </td>
                        )}
                        <td>
                          {formattedDate}
                        </td>
                        <td>
                          {formattedTime}
                        </td>
                        <td>
                          {item.dispatcher_name}
                        </td>
                        <td>
                          {item.adress}
                        </td>
                        <td>
                          {item.text_application}
                        </td>
                        <td>
                          {item.applicant}
                        </td>
                        <td>
                          {item.category}
                        </td>
                        <td>
                          {item.object}
                        </td>
                        <td>
                          {item.applicant_status}
                        </td>
                      </tr>
                    </>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </>
    )
  }

  const ModalSearch1 = () => {
    return (
      <>
        <div className="Ads__SearchModalZayva1">
          <h1 className="Ads__EdtTtlePerd">Архив заявок</h1>
          <button className="Ads__CloseModalBtn" onClick={() => (ModalChange6(), ClearFilters1())}>X</button>
          <div className="Ads__ChangeMontModal">
            <label className="Ads__text-input Ads__block Ads__ForMargMonthChange">Выберите месяц:<input type="month" defaultValue={getCurrentMonth()} className="Ads__block Ads__sel Ads__DopStyle" name='dispatcher_name' onChange={(e) => handleMonthChange(e)} /></label>
            <button className='Ads__ClearFltres' onClick={ClearFilters1}>Очистить фильтры</button>
          </div>
          <div className="Ads__searchCont">
            <label className="Ads__text-input2 Ads__block Ads__DopForLabel2 Ads__DopAdrStyle">Адрес:<AddressSuggestions token="6dc7b29f2b8e6eb9bc537838a324af7caafc5eca" type='text' className="Ads__ForWdht1 Ads__sel Ads__block Ads__DopStyle" name='adr' value={filters2.address} onChange={handleAddressChange1} /></label>
            <label className="Ads__text-input Ads__block Ads__DopForLabel2">Дата(с):<input className="Ads__ForWdht1 Ads__sel Ads__block Ads__DopStyle" name='date1' type='date' value={filters2.dateFrom} onChange={handleDateFromChange1} /></label>
            <label className="Ads__text-input Ads__block Ads__DopForLabel2">Дата(до):<input className="Ads__ForWdht1 Ads__sel Ads__block Ads__DopStyle" name='date2' type='date' value={filters2.dateTo} onChange={handleDateToChange1} /></label>
            <label className="Ads__text-input Ads__block Ads__DopForLabel2">Статус заявки:<select className="Ads__SelSearch1ModalSearch" value={filters2.status} onChange={handleStatusChange1}><option></option><option>Принято</option><option>В работе</option><option>Исполнена</option><option>Отложить</option></select></label>
            <label className="Ads__text-input Ads__block Ads__DopForLabel2">Сеть:<select className="Ads__SelSearch1ModalSearch" value={filters2.seti} onChange={handleSetiChange1}><option></option><option>Водоснабжение</option><option>Канализация</option><option>Благоустройство</option></select></label>
          </div>
          <div className="Ads__tableByIdModal Ads__ForFlexTableModalSearch">
            <table className="Ads__tableById Ads__tableOleg">
              <thead>
                <tr>
                  <th>
                    №Заявки
                  </th>
                  <th>
                    Дата
                  </th>
                  <th>
                    Время
                  </th>
                  <th>
                    ФИО оператора
                  </th>
                  <th>
                    Адрес
                  </th>
                  <th>
                    Текст заявки
                  </th>
                  <th>
                    Тип заявителя
                  </th>
                  <th>
                    Категория
                  </th>
                  <th>
                    Объект аварии
                  </th>
                  <th>
                    Статус заявки
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredData2.map((item, index) => {
                  let date = new Date(item.created_date);

                  let options = { timeZone: 'Europe/Moscow', hour: '2-digit', minute: '2-digit', year: 'numeric', month: '2-digit', day: '2-digit' };
                  let formattedDateTime = date.toLocaleString('ru-RU', options);

                  let [formattedDate, formattedTime] = formattedDateTime.split(', ');
                  return (
                    <>
                      <tr key={index} id={`row-${index}`}>
                        {item.applicant_status === 'Исполнена' ? (
                          <td><button className="Ads__BtnForDispIspZayvka" onClick={() => ZayvaInNewWindow(item.id)}>{item.id}</button></td>
                        ) : (
                          <td>
                            {item.id}
                          </td>
                        )}
                        <td>
                          {formattedDate}
                        </td>
                        <td>
                          {formattedTime}
                        </td>
                        <td>
                          {item.dispatcher_name}
                        </td>
                        <td>
                          {item.adress}
                        </td>
                        <td>
                          {item.text_application}
                        </td>
                        <td>
                          {item.applicant}
                        </td>
                        <td>
                          {item.category}
                        </td>
                        <td>
                          {item.object}
                        </td>
                        <td>
                          {item.applicant_status}
                        </td>
                      </tr>
                    </>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </>
    )
  }

  const ModalEdtMap = () => {
    return (
      <>
        <div className="Ads__EdtModalZayvaMap">
          <h1 className="Ads__EdtTtlePerd">Заявка номер - {modelId1}</h1>
          <button className="Ads__CloseModalBtn" onClick={() => (CloseModal2())}>X</button>
          <div className="Ads__SmallModalCont">
            <label className="Ads__text-input Ads__ForSmallModalW Ads__block">Исполнитель:<select className="Ads__sel Ads__ForSmallModalWdth Ads__block" name='performer' onChange={(e) => postTextZayvkaPerformer1(e)}>
              {getPerformer.map((item, idx) => {
                return (
                  <>
                    {item.type_of_activity === "Водоснабжение" ? (
                      <option>
                        {item.lastname}
                      </option>
                    ) : (
                      ''
                    )}
                  </>
                )
              })}
            </select></label>
            <button className="Ads__confrmModalBtn" onClick={() => (fetchChangePerformer(), window.location.reload())}>Подтвердить</button>
          </div>


        </div>
      </>
    )
  }

  const ModalRab = () => {
    return (
      <>
        <div className="Ads__RabModal">
          <button className="Ads__CloseModalBtn" onClick={() => (CloseModal4())}>X</button>
          <div className="Ads__RabModalCont">
            <div className="Ads__MapForMob">
              <YMaps query={{ lang: 'ru_RU', apikey: 'b8d3fa06-b4a0-460a-a180-70fd22bfbdec' }}>
                <Map onClick={(e) => NewMarker(e)} height={300} width={1024} defaultState={defaultState1}>
                  <ZoomControl />
                  <TypeSelector />
                  <SearchControl />
                  <GeolocationControl options={{ float: 'left' }}
                    onSuccess={(e) => {
                      const coords = e.geoObjects.get(0).geometry.getCoordinates();
                      console.log('Координаты вашего местоположения:', coords);

                    }}
                    onError={(error) => {
                      console.error('Ошибка при получении местоположения:', error);
                    }} />
                  <Placemark
                    geometry={
                      [CoordinateOnClick[0], CoordinateOnClick[1]]
                    }
                  />
                </Map>
              </YMaps>
            </div>
            {ZayvkaById.applicant_status === "Отложить" ? (
              <div className="Ads__ReturnVJob">
                <button className="Ads__InJobStatusBtn1" onClick={() => (fetchChangeStatusRab1(ZayvkaById.id), CloseModal4())}>Вернуть в работу</button>
              </div>
            ) : (
              ''
            )}
            {ZayvkaById.applicant_status === "В работе" ? (
              <>
                <div className="Ads__text">
                  <div className="Ads__InJobStatus">
                    <label className="Ads__text-input Ads__block">Характер повреждения:<select className="Ads__sel1 Ads__block" name='damage_character' onChange={(e) => postSaveZayvka(e)}><option></option><option>Лопнула отбортовка</option><option>Свищ</option><option>Стык</option><option>Перелом</option><option>Трещина</option></select></label>
                    <label className="Ads__text-input Ads__block">Диаметр трубы:<input className="Ads__sel1 Ads__block" type="number" name='pipe_diametr' onChange={(e) => postSaveZayvka(e)}></input></label>
                    <label className="Ads__text-input Ads__block">Материал трубы:<select className="Ads__sel1 Ads__block" name='pipe_material' onChange={(e) => postSaveZayvka(e)}><option></option><option>Cталь</option><option>Чугун</option><option>Ж/бетон</option><option>Пэт</option></select></label>
                    <label className="Ads__text-input Ads__block">Глубина до верха трубы, см:<input className="Ads__sel1 Ads__block" type="number" name='depth_top_of_pipe' onChange={(e) => postSaveZayvka(e)}></input></label>
                    <label className="Ads__text-input Ads__block">Способ устранения:<select className="Ads__sel1 Ads__block" name='solution' onChange={(e) => postSaveZayvka(e)}><option></option><option>Вставка</option><option>Cв. муфта</option><option>Замена</option><option>Чеканка</option><option>Хомут</option><option>Латка</option><option>Заварили</option><option>Забили</option></select></label>
                    <label className="Ads__text-input Ads__block">Комментарий:<input className="Ads__sel1 Ads__block" name='comment' onChange={(e) => postSaveZayvka(e)}></input></label>
                    <label htmlFor="upload3" className="Ads__upload-button2">
                      Фото начала работ
                      <input
                        id="upload3"
                        className="Ads__file"
                        type="file"
                        accept="image/*"
                        onChange={StartPhotoFile}
                      />
                    </label>
                    <label htmlFor="upload4" className="Ads__upload-button3">
                      Фото результат
                      <input
                        id="upload4"
                        className="Ads__file"
                        type="file"
                        accept="image/*"
                        onChange={EndPhotoFile}
                      />
                    </label>
                    {selectedEndImg && selectedStartImg && (
                      <div className="Ads__selected-file">
                        Выбраны: {selectedEndImg.name}, {selectedStartImg.name}
                      </div>
                    )}
                  </div>
                </div>
                <div className="Ads__ForWaitBtn">
                  <button className="Ads__InJobStatusBtn" onClick={() => (fetchChangeStatusRab(ZayvkaById.id), CloseModal4())}>Отложить заявку</button>
                  <button className="Ads__InJobStatusBtn" onClick={() => (fetchPostSaveZayvka(), CloseModal4())}>Сохранить</button>
                </div>

              </>
            )
              : (
                ''
              )}
          </div>
        </div>
      </>
    )
  }

  const ModalRab1 = () => {
    return (
      <>
        <div className="Ads__RabModal">
          <button className="Ads__CloseModalBtn" onClick={() => (CloseModal3())}>X</button>
          <div className="Ads__RabModalCont">
            <div className="Ads__MapForMob">
              <YMaps>
                <Map onClick={(e) => NewMarker(e)} height={300} width={1024} defaultState={defaultState1}>
                  <ZoomControl />
                  <TypeSelector />
                  <SearchControl />
                  <Placemark
                    geometry={
                      [CoordinateOnClick[0], CoordinateOnClick[1]]
                    }
                  />
                </Map>
              </YMaps>
            </div>
            {ZayvkaById.applicant_status === "Отложить" ? (
              <div className="Ads__ReturnVJob">
                <button className="Ads__InJobStatusBtn1" onClick={() => (fetchChangeStatusRab1(ZayvkaById.id), CloseModal3())}>Вернуть в работу</button>
              </div>
            ) : (
              ''
            )}
            {ZayvkaById.applicant_status === "В работе" ? (
              <>
                <div className="Ads__text">
                  <div className="Ads__InJobStatus">
                    <label className="Ads__text-input Ads__block">Характер повреждения:<select className="Ads__sel1 Ads__block" name='damage_character1' onChange={(e) => postSaveZayvka(e)}><option></option><option>Лопнула крышка люка</option><option>Провал колодца</option><option>Люк под асфальтом</option></select></label>
                    <label className="Ads__text-input Ads__block">Материал трубы:<select className="Ads__sel1 Ads__block" name='pipe_material1' onChange={(e) => postSaveZayvka(e)}><option></option><option>Чугун</option><option>Бетон</option><option>Пластик</option></select></label>
                    <label className="Ads__text-input Ads__block">Способ устранения:<input className="Ads__sel1 Ads__block" name='solution1' onChange={(e) => postSaveZayvka(e)}></input></label>
                    <label className="Ads__text-input Ads__block">Комментарий:<input className="Ads__sel1 Ads__block" name='comment' onChange={(e) => postSaveZayvka(e)}></input></label>
                    <label htmlFor="upload3" className="Ads__upload-button2">
                      Фото начала работ
                      <input
                        id="upload3"
                        className="Ads__file"
                        type="file"
                        accept="image/*"
                        onChange={StartPhotoFile}
                      />
                    </label>
                    <label htmlFor="upload4" className="Ads__upload-button3">
                      Фото результат
                      <input
                        id="upload4"
                        className="Ads__file"
                        type="file"
                        accept="image/*"
                        onChange={EndPhotoFile}
                      />
                    </label>
                    {selectedEndImg && selectedStartImg && (
                      <div className="Ads__selected-file">
                        Выбраны: {selectedEndImg.name}, {selectedStartImg.name}
                      </div>
                    )}
                  </div>
                </div>
                <div className="Ads__ForWaitBtn">
                  <button className="Ads__InJobStatusBtn" onClick={() => (fetchChangeStatusRab(ZayvkaById.id), CloseModal3())}>Отложить заявку</button>
                  <button className="Ads__InJobStatusBtn" onClick={() => (fetchPostSaveZayvka1(), CloseModal3())}>Сохранить</button>
                </div>

              </>
            )
              : (
                ''
              )}
          </div>
        </div>
      </>
    )
  }

  const ModalRab2 = () => {
    return (
      <>
        <div className="Ads__RabModal">
          <button className="Ads__CloseModalBtn" onClick={() => (CloseModal111())}>X</button>
          <div className="Ads__RabModalCont">
            <div className="Ads__MapForMob">
              <YMaps>
                <Map onClick={(e) => NewMarker(e)} height={300} width={1024} defaultState={defaultState1}>
                  <ZoomControl />
                  <TypeSelector />
                  <SearchControl />
                  <Placemark
                    geometry={
                      [CoordinateOnClick[0], CoordinateOnClick[1]]
                    }
                  />
                </Map>
              </YMaps>
            </div>
            {ZayvkaById.applicant_status === "Отложить" ? (
              <div className="Ads__ReturnVJob">
                <button className="Ads__InJobStatusBtn1" onClick={() => (fetchChangeStatusRab1(ZayvkaById.id), CloseModal111())}>Вернуть в работу</button>
              </div>
            ) : (
              ''
            )}
            {ZayvkaById.applicant_status === "В работе" ? (
              <>
                <div className="Ads__text">
                  <div className="Ads__InJobStatus">
                    <label className="Ads__text-input Ads__block">Характер повреждения:<select className="Ads__sel1 Ads__block" name='damage_character1' onChange={(e) => postSaveZayvka(e)}><option></option><option>Засор</option><option>Смещение стыка</option></select></label>
                    <label className="Ads__text-input Ads__block">Способ устранения:<select className="Ads__sel1 Ads__block" name='solution1' onChange={(e) => postSaveZayvka(e)}><option></option><option>Засор устранён</option><option>На пропуск</option></select></label>
                    <label className="Ads__text-input Ads__block">Комментарий:<input className="Ads__sel1 Ads__block" name='comment' onChange={(e) => postSaveZayvka(e)}></input></label>
                    <label htmlFor="upload3" className="Ads__upload-button2">
                      Фото начала работ
                      <input
                        id="upload3"
                        className="Ads__file"
                        type="file"
                        accept="image/*"
                        onChange={StartPhotoFile}
                      />
                    </label>
                    <label htmlFor="upload4" className="Ads__upload-button3">
                      Фото результат
                      <input
                        id="upload4"
                        className="Ads__file"
                        type="file"
                        accept="image/*"
                        onChange={EndPhotoFile}
                      />
                    </label>
                    {selectedEndImg && selectedStartImg && (
                      <div className="Ads__selected-file">
                        Выбраны: {selectedEndImg.name}, {selectedStartImg.name}
                      </div>
                    )}
                  </div>
                </div>
                <div className="Ads__ForWaitBtn">
                  <button className="Ads__InJobStatusBtn" onClick={() => (fetchChangeStatusRab(ZayvkaById.id), CloseModal111())}>Отложить заявку</button>
                  <button className="Ads__InJobStatusBtn" onClick={() => (fetchPostSaveZayvka1(), CloseModal111())}>Сохранить</button>
                </div>

              </>
            )
              : (
                ''
              )}
          </div>
        </div>
      </>
    )
  }


  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });

  const openModal = (index) => {
    const row = document.querySelector(`#row-${index}`);
    const rect = row.getBoundingClientRect();
    console.log(row);
    console.log(rect);
    setModalPosition({
      top: rect.top,
      left: rect.right,
    });
  };

  const SmallModal = () => {
    return (
      <div
        style={{
          position: 'absolute',
          top: (modalPosition.top - 180) + 'px',
          left: 20 + '%',
          backgroundColor: '#fff',
          zIndex: 9999,
          border: '1px solid #ccc',
          borderRadius: 10 + 'px',
          width: 500 + 'px',
          height: 150 + 'px',
        }}
      >
        <div className="Ads__ContForTtle">
          <h1 className="Ads__SmallTtle">Номер заявки - {modelId}</h1>
        </div>
        <button className="Ads__CloseModalBtn" onClick={() => (CloseModal3())}>X</button>
        <div className="Ads__SmallModalCont">
          <label htmlFor="upload1" className="Ads__uploadPhotobnt">
            Фрагмент карты
            <input
              id="upload1"
              className="Ads__file"
              type="file"
              accept="image/*"
              onChange={handleFile}
            />
          </label>
          {selectedImg && (
            <div className="Ads__selected-fileModal">
              Выбрано: {selectedImg.name}
            </div>
          )}
          <button className="Ads__confrmModalBtn" onClick={() => (fetchChangePhoto(), window.location.reload())}>Потвердить</button>
        </div>
      </div>
    )
  }







  return (
    <>
      <Header />
      <div className="Ads">
        <div className="Ads__button">
          {jobTitle === "Диспетчер" ? (
            <>
              {itemFromLocalStorage !== null ? (
                <>
                  <button className="Ads__bimba" onClick={activePriemZayvka}> Прием заявки </button>
                  <button className="Ads__ChangeBtnPhoto Ads__ForMargDBtn" onClick={() => (ModalChange1(), saveButtonWhenUp(), fetchZayvkaForDAndD())}>Поиск заявок</button>
                  <button className="Ads__ChangeBtnPhoto Ads__ForMargDBtn" onClick={() => (ModalChange6(), fetchZayvkaForDAndD())}>Архив</button>
                  <button className='Ads__ChangeBtnPhoto' onClick={OpenGraph10}>Гистограмма по адресам</button>
                  {/* <button className="Ads__bimba" onClick={() => (activeIspolnenieZayvka(), fetchZayvkaAll())}>Исполнение заявок</button> */}
                </>
              ) : (
                <>
                  {IsSmena === true ? (
                    <>
                      <div className="Ads__center-container">
                        <button className="Ads__createSmena" onClick={OpenSmena}>Создать смену</button>
                      </div>
                    </>
                  ) : (
                    <>
                      {IsFioOp === true ? (
                        <>
                          <div className="Ads__center-container1">
                            <label className="Ads__text-input Ads__block">Введите ФИО:<input type="text" className="Ads__block Ads__sel" name='dispatcher_name' onChange={(e) => postDpName(e)} /></label>
                          </div>
                          <div className="Ads__center-container2">
                            <button className="Ads__createSmena1" onClick={() => (ForFioOp(), localStorage.setItem("FioOp", postData.dispatcher_name))}>Подтвердить</button>
                          </div>
                        </>
                      ) : (
                        ''
                      )}

                    </>
                  )}
                </>
              )}

            </>
          )
            : (
              <>

              </>
            )}
          {jobTitle === "Технический администратор" ? (
            <>
              <div>
                <div className="Ads__ForFlex">
                  <div className='Ads__BtnForGraph'>
                    <button className='Ads__ThisBtnForGraph' onClick={OpenGraph10}>Гистограмма по адресам</button>
                    <button className='Ads__ThisBtnForGraph' onClick={OpenGraph1}>Гистограмма по статусам</button>
                    <button className='Ads__ThisBtnForGraph' onClick={OpenGraph3}>График по категориям</button>
                    <button className='Ads__ThisBtnForGraph' onClick={OpenGraph2}>Гистограмма по категориям</button>
                    <button className='Ads__ThisBtnForGraph' onClick={OpenGraph7}>Круговая диаграмма по сетям</button>
                    <button className='Ads__ThisBtnForGraph' onClick={Mapp}>Показать на карте</button>
                  </div>
                </div>
                {IsCheckGraph1 === true ? (
                  <div className='Ads__Graph'>
                    <div className='Ads__GraphInput'>
                      <label className='Ads__text-input Ads__block'>Выберите месяц:<input className="Ads__sel Ads__block" type="month" onChange={(e) => GetCountByMonth1(e)} /></label>
                    </div>
                    {IsCheckGraph4 === true ? (
                      <Chart>
                        <ChartTitle text="Статус заявок" />
                        <ChartLegend visible={true} />
                        <ChartCategoryAxis>
                          <ChartCategoryAxisItem categories={categories}>
                            <ChartCategoryAxisTitle text="Месяц" />
                          </ChartCategoryAxisItem>
                        </ChartCategoryAxis>
                        <ChartSeries>
                          {series.map((item, idx) => (
                            <ChartSeriesItem
                              key={idx}
                              type="column"
                              gap={3}
                              spacing={2.5}
                              labels={seriesLabels}
                              data={item.data}
                              name={item.status}
                              color={item.color}
                            />
                          ))}
                        </ChartSeries>
                      </Chart>
                    ) : (
                      ''
                    )}

                  </div>
                ) : (
                  ''
                )
                }
                {IsCheckGraph2 === true ? (
                  <div className='Ads__Graph'>
                    <div className='Ads__GraphInput'>
                      <label className='Ads__text-input Ads__block'>Выберите месяц:<input className="Ads__sel Ads__block" type="month" onChange={(e) => GetCountByMonth2(e)} /></label>
                    </div>
                    {IsCheckGraph5 === true ? (
                      <Chart>
                        <ChartTitle text="Распределение по категориям" />
                        <ChartLegend visible={true} />
                        <ChartCategoryAxis>
                          <ChartCategoryAxisItem categories={categories}>
                            <ChartCategoryAxisTitle text="Месяц" />
                          </ChartCategoryAxisItem>
                        </ChartCategoryAxis>
                        <ChartSeries>
                          {series1.map((item, idx) => (
                            <ChartSeriesItem
                              key={idx}
                              type="column"
                              gap={3}
                              spacing={2.5}
                              labels={seriesLabels}
                              data={item.data}
                              name={item.status}
                              color={item.color}
                              style={{ width: "10%", height: "80%" }}
                            />
                          ))}
                        </ChartSeries>
                      </Chart>
                    ) : (
                      ''
                    )}

                  </div>
                ) : (
                  ''
                )
                }
                {IsCheckGraph10 === true ? (
                  <div className='Ads__Graph'>
                    <div className='Ads__GraphInput1'>
                      <label className="Ads__text-input Ads__block">Сеть:<select className="Ads__sel1 Ads__block" name='seti' onChange={(e) => NameSetForStat(e)}><option></option><option>Водоснабжение</option><option>Канализация</option></select></label>
                      {NameSet.seti === "Водоснабжение" ? (
                        <div className='Ads__TopMargStat'>
                          <label className='Ads__text-input Ads__block'>Укажите год и месяц (Абсолютный показатель):<input className="Ads__sel Ads__block" type="month" onChange={(e) => GetCountByMonth10(e)} /></label>
                          <label className='Ads__text-input Ads__block Ads__ForMargGraphStat'>Укажите год и месяц (Относительный показатель):<input className="Ads__sel Ads__block" type="month" onChange={(e) => GetCountByMonth100(e)} /></label>
                        </div>
                      ) : (
                        ''
                      )}
                      {NameSet.seti === "Канализация" ? (
                        <div className='Ads__TopMargStat'>
                          <label className='Ads__text-input Ads__block'>Укажите год и месяц (Абсолютный показатель):<input className="Ads__sel Ads__block" type="month" onChange={(e) => GetCountByMonth20(e)} /></label>
                          <label className='Ads__text-input Ads__block Ads__ForMargGraphStat'>Укажите год и месяц (Относительный показатель):<input className="Ads__sel Ads__block" type="month" onChange={(e) => GetCountByMonth200(e)} /></label>
                        </div>
                      ) : (
                        ''
                      )}

                      {/* <button className="Ads__ForMargGraphStat Ads__ThisBtnForGraph" onClick={() => (GetStat3month())}>Топ 10</button> */}
                    </div>
                    {IsCheckGraph20 === true ? (
                      <Chart style={{ width: "100%", height: "900px" }}>
                        <ChartTitle text="Вода" />
                        <ChartLegend
                          visible={true}
                          position="right"
                        />
                        <ChartCategoryAxis>
                          <ChartCategoryAxisItem categories={StatYear}>
                            <ChartCategoryAxisTitle text="Год" />
                          </ChartCategoryAxisItem>
                        </ChartCategoryAxis>
                        <ChartSeries>
                          {getDataByYearAdr.query.map((item, idx) => {
                            return (
                              <ChartSeriesItem
                                key={idx}
                                type="column"
                                gap={10}
                                spacing={2.5}
                                labels={seriesLabels1}
                                data={[item.count]}
                                name={item.street + ' - ' + [item.count]}
                                color={getRandomColor()}
                                style={{ width: "10%", height: "80%" }}
                              />
                            );
                          })}
                        </ChartSeries>

                      </Chart>
                    ) : (
                      ''
                    )}
                    {IsCheckGraph30 === true ? (
                      <>
                        <Chart style={{ width: "100%", height: "900px" }}>
                          <ChartTitle text="Вода" />
                          <ChartLegend
                            visible={true}
                            position="right"
                          />
                          <ChartCategoryAxis>
                            <ChartCategoryAxisItem categories={StatYear}>
                              <ChartCategoryAxisTitle text="Год" />
                            </ChartCategoryAxisItem>
                          </ChartCategoryAxis>
                          <ChartSeries>
                            {getDataByYearAdr.query
                              .sort((a, b) => b.relative_count - a.relative_count)
                              .slice(0, 10)
                              .map((item, idx) => {
                                const roundedCount = Number(item.relative_count.toFixed(3));
                                return (
                                  <ChartSeriesItem
                                    key={idx}
                                    type="column"
                                    gap={10}
                                    spacing={2.5}
                                    labels={seriesLabels1}
                                    data={[roundedCount]}
                                    name={item.street + ' - ' + [roundedCount]}
                                    color={getRandomColor()}
                                    style={{ width: "10%", height: "80%" }}
                                  />
                                );
                              })}
                          </ChartSeries>

                        </Chart>
                        {/* <Chart>
                          <ChartTitle text="Относительный показатель аварий на улицах" font="bold 20px Arial" />
                          <ChartLegend visible={true} />
                          <ChartCategoryAxis>
                            <ChartCategoryAxisItem
                              categories={[getStatThreeMonth[0].month0]}
                              labels={{
                                font: "bold 14px Arial",
                                color: "#000",
                              }}
                            >
                              <ChartCategoryAxisTitle
                                text="Месяц"
                                font="bold 16px Arial"
                                color="#000"
                              />
                            </ChartCategoryAxisItem>
                          </ChartCategoryAxis>
                          <ChartSeries>
                            {getStatThreeMonth[0].query0.map((item, idx) => {
                              const roundedCount = Number(item.relative_count.toFixed(3));
                              return (
                                <ChartSeriesItem
                                  key={`query0-${idx}`}
                                  type="column"
                                  gap={20}
                                  spacing={0.25}
                                  labels={seriesLabels1}
                                  data={[roundedCount]}
                                  name={item.street + ' - ' + [roundedCount]}
                                  color={getRandomColor()}
                                  style={{ width: "10%", height: "80%" }}
                                />
                              );
                            })}
                          </ChartSeries>
                        </Chart>
                        <Chart>
                          <ChartLegend visible={true} />
                          <ChartCategoryAxis>
                            <ChartCategoryAxisItem
                              categories={[getStatThreeMonth[1].month1]}
                              labels={{
                                font: "bold 14px Arial",
                                color: "#000",
                              }}
                            >
                              <ChartCategoryAxisTitle
                                text="Месяц"
                                font="bold 16px Arial"
                                color="#000"
                              />
                            </ChartCategoryAxisItem>
                          </ChartCategoryAxis>
                          <ChartSeries>
                            {getStatThreeMonth[1].query1.map((item, idx) => {
                              const roundedCount = Number(item.relative_count.toFixed(3));
                              return (
                                <ChartSeriesItem
                                  key={`query1-${idx}`}
                                  type="column"
                                  gap={20}
                                  spacing={0.25}
                                  labels={seriesLabels1}
                                  data={[roundedCount]}
                                  name={item.street + ' - ' + [roundedCount]}
                                  color={getRandomColor()}
                                  style={{ width: "10%", height: "80%" }}
                                />
                              );
                            })}
                          </ChartSeries>
                        </Chart>
                        <Chart>
                          <ChartLegend visible={true} />
                          <ChartCategoryAxis>
                            <ChartCategoryAxisItem
                              categories={[getStatThreeMonth[2].month2]}
                              labels={{
                                font: "bold 14px Arial",
                                color: "#000",
                              }}
                            >
                              <ChartCategoryAxisTitle
                                text="Месяц"
                                font="bold 16px Arial"
                                color="#000"
                              />
                            </ChartCategoryAxisItem>
                          </ChartCategoryAxis>
                          <ChartSeries>
                            {getStatThreeMonth[2].query2.map((item, idx) => {
                              const roundedCount = Number(item.relative_count.toFixed(3));
                              return (
                                <ChartSeriesItem
                                  key={`query1-${idx}`}
                                  type="column"
                                  gap={20}
                                  spacing={0.25}
                                  labels={seriesLabels1}
                                  data={[roundedCount]}
                                  name={item.street + ' - ' + [roundedCount]}
                                  color={getRandomColor()}
                                  style={{ width: "10%", height: "80%" }}
                                />
                              );
                            })}
                          </ChartSeries>
                        </Chart> */}
                      </>
                    ) : (
                      ''
                    )}
                    {IsCheckGraph200 === true ? (
                      <Chart style={{ width: "100%", height: "900px" }}>
                        <ChartTitle text="Канализация" />
                        <ChartLegend
                          visible={true}
                          position="right"
                        />
                        <ChartCategoryAxis>
                          <ChartCategoryAxisItem categories={StatYear}>
                            <ChartCategoryAxisTitle text="Год" />
                          </ChartCategoryAxisItem>
                        </ChartCategoryAxis>
                        <ChartSeries>
                          {getDataByYearAdr.query.map((item, idx) => {
                            return (
                              <ChartSeriesItem
                                key={idx}
                                type="column"
                                gap={10}
                                spacing={2.5}
                                labels={seriesLabels1}
                                data={[item.count]}
                                name={item.street + ' - ' + [item.count]}
                                color={getRandomColor()}
                                style={{ width: "10%", height: "80%" }}
                              />
                            );
                          })}
                        </ChartSeries>

                      </Chart>
                    ) : (
                      ''
                    )}
                    {IsCheckGraph300 === true ? (
                      <Chart style={{ width: "100%", height: "900px" }}>
                        <ChartTitle text="Канализация" />
                        <ChartLegend
                          visible={true}
                          position="right"
                        />
                        <ChartCategoryAxis>
                          <ChartCategoryAxisItem categories={StatYear}>
                            <ChartCategoryAxisTitle text="Год" />
                          </ChartCategoryAxisItem>
                        </ChartCategoryAxis>
                        <ChartSeries>
                          {getDataByYearAdr.query
                            .sort((a, b) => b.relative_count - a.relative_count)
                            .slice(0, 15)
                            .map((item, idx) => {
                              const roundedCount = Number(item.relative_count.toFixed(3));
                              return (
                                <ChartSeriesItem
                                  key={idx}
                                  type="column"
                                  gap={10}
                                  spacing={2.5}
                                  labels={seriesLabels1}
                                  data={[roundedCount]}
                                  name={item.street + ' - ' + [roundedCount]}
                                  color={getRandomColor()}
                                  style={{ width: "10%", height: "80%" }}
                                />
                              );
                            })}
                        </ChartSeries>
                      </Chart>
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  ''
                )
                }
                {IsCheckGraph3 === true ? (
                  <div className='Ads__Graph'>
                    <div className='Ads__GraphInput'>
                      <label className='Ads__text-input Ads__block'>Выберите период с:<input className="Ads__sel Ads__block" type="date" onChange={(e) => GetCountByMonth3(e)} /></label>
                      <label className='Ads__text-input Ads__block Ads__ForMargGraph'>Выберите период до:<input className="Ads__sel Ads__block" type="date" onChange={(e) => GetCountByMonth4(e)} /></label>
                    </div>
                    {IsCheckGraph6 === true ? (
                      <Chart>
                        <ChartTitle text="Распределение по категориям" />
                        <ChartLegend visible={true} />
                        <ChartCategoryAxis>
                          <ChartCategoryAxisItem categories={categories1}>
                            <ChartCategoryAxisTitle text="Дата" />
                          </ChartCategoryAxisItem>
                        </ChartCategoryAxis>
                        <ChartSeries>
                          {series2.map((item, idx) => (
                            <ChartSeriesItem
                              key={idx}
                              type="line"
                              gap={2}
                              spacing={0.25}
                              labels={seriesLabels}
                              data={item.data}
                              name={item.status}
                              color={item.color}
                            />
                          ))}
                        </ChartSeries>
                      </Chart>
                    ) : (
                      ''
                    )}

                  </div>
                ) : (
                  ''
                )
                }
                {IsCheckGraph7 === true ? (
                  <div className='Ads__Graph'>
                    <div className='Ads__GraphInput'>
                      <label className='Ads__text-input Ads__block'>Выберите месяц:<input className="Ads__sel Ads__block" type="month" onChange={(e) => GetCountByMonth5(e)} /></label>
                    </div>
                    {IsCheckGraph8 === true ? (
                      <>
                        <MyChar type="pie" data={data} options={options} className='Ads__Crug' />
                      </>
                    ) : (
                      ''
                    )}

                  </div>
                ) : (
                  ''
                )
                }
                {IsCheckMap === true ? (
                  <>
                    {DataSearhMonth.length !== 0 ? (
                      <div className="Ads__Mappp">
                        <YMaps>
                          <Map height={600} width={-1} defaultState={defaultState}>
                            <ZoomControl />
                            <TypeSelector />
                            {filteredData2.map((item, index) => {
                              let date = new Date(item.created_date);

                              let options = { timeZone: 'Europe/Moscow', hour: '2-digit', minute: '2-digit', year: 'numeric', month: '2-digit', day: '2-digit' };
                              let formattedDateTime = date.toLocaleString('ru-RU', options);

                              let [formattedDate, formattedTime] = formattedDateTime.split(', ');
                              return (
                                <>
                                  {item.applicant_status === "В работе" ? (
                                    <>
                                      <Placemark

                                        geometry={
                                          [item.latitude, item.longtitude]
                                        }
                                        properties={{
                                          hintContent: item.applicant_status,
                                          balloonContent: item.adress + ',' + item.object + ", Дата приёма: " + formattedDate + ", Время приёма: " + formattedTime
                                        }}

                                        options={{
                                          preset: 'islands#redStretchyIcon',
                                          draggable: true,
                                        }}
                                        modules={
                                          ['geoObject.addon.balloon', 'geoObject.addon.hint']
                                        }
                                      />
                                    </>
                                  ) : (
                                    ''
                                  )}
                                  {item.applicant_status === "Отложить" ? (
                                    <>
                                      <Placemark

                                        geometry={
                                          [item.latitude, item.longtitude]
                                        }
                                        properties={{
                                          hintContent: item.applicant_status,
                                          balloonContent: item.adress + ',' + item.object + ", Дата приёма: " + formattedDate + ", Время приёма: " + formattedTime
                                        }}

                                        options={{
                                          preset: 'islands#yellowStretchyIcon',
                                          draggable: true,
                                        }}
                                        modules={
                                          ['geoObject.addon.balloon', 'geoObject.addon.hint']
                                        }
                                      />
                                    </>
                                  ) : (
                                    ''
                                  )}
                                  {item.applicant_status === "Исполнена" ? (
                                    <>
                                      <Placemark

                                        geometry={
                                          [item.latitude, item.longtitude]
                                        }
                                        properties={{
                                          hintContent: item.applicant_status,
                                          balloonContent: item.adress + ',' + item.object + ", Дата исполнения: " + formattedDate + ", Время исполнения: " + formattedTime + ", Координаты аварии: " + item.latitude.substring(0, 7) + " (широта), " + item.longtitude.substring(0, 7) + " (долгота)"
                                        }}

                                        options={{
                                          preset: 'islands#greenStretchyIcon',
                                          draggable: true,
                                        }}
                                        modules={
                                          ['geoObject.addon.balloon', 'geoObject.addon.hint']
                                        }
                                      />
                                    </>
                                  ) : (
                                    ''
                                  )}
                                  {item.applicant_status === "Принято" ? (
                                    <>
                                      <Placemark

                                        geometry={
                                          [item.latitude, item.longtitude]
                                        }
                                        properties={{
                                          hintContent: item.applicant_status,
                                          balloonContent: item.adress + ',' + item.object + ", Дата приёма: " + formattedDate + ", Время приёма: " + formattedTime
                                        }}

                                        options={{
                                          preset: 'islands#blueStretchyIcon',
                                          draggable: true,
                                        }}
                                        modules={
                                          ['geoObject.addon.balloon', 'geoObject.addon.hint']
                                        }
                                      />
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </>

                              )
                            })}
                          </Map>
                        </YMaps>
                        {IsCheckCoordinateMap === true ? (
                          <>
                          </>
                        ) :
                          (
                            <>
                              <div className="Ads__Mappp-legend">
                                <div className="Ads__Mappp-legend-mark Ads__Mappp-legend-mark-red">
                                  Красный - статус: "В работе"
                                </div>
                                <div className="Ads__Mappp-legend-mark Ads__Mappp-legend-mark-yelow">
                                  Желтый - статус: "Отложить"
                                </div>
                                <div className="Ads__Mappp-legend-mark Ads__Mappp-legend-mark-blue">
                                  Синий - статус: "Принято"
                                </div>
                                <div className="Ads__Mappp-legend-mark Ads__Mappp-legend-mark-green">
                                  Зеленый - статус: "Исполнена"
                                </div>
                              </div>

                              <button className="Ads__btnSearchModal Ads__VAllMap" onClick={() => ClearFilters1()}>Показать все заявки</button>

                              <label className="Ads__text-input Ads__block Ads__selectSearch1">Критерий поиска:<select className="Ads__sel Ads__block Ads__selectSearch2" onChange={(e) => NameOfSearch(e)}><option>По адресу</option><option>По периоду</option><option>По сетям</option></select></label>
                              {NameSearch.value === "По адресу" ? (
                                <label className="Ads__Mappp-search-dadata">Адрес:<AddressSuggestions token="6dc7b29f2b8e6eb9bc537838a324af7caafc5eca" type='text' className="Ads__ForWdht Ads__sel Ads__block Ads__DopStyle" name='adr' value={filters2.address} onChange={handleAddressChange1} /></label>
                              )
                                : (
                                  ''
                                )}
                              {NameSearch.value === "По периоду" ? (
                                <div className="Ads__MapContPer">
                                  <label className="Ads__text-input Ads__block Ads__DopForLabel1">Дата(с):<input className="Ads__ForWdht Ads__sel Ads__block Ads__DopStyle" name='date1' type='date' value={filters2.dateFrom} onChange={handleDateFromChange1} /></label>
                                  <label className="Ads__text-input Ads__block Ads__DopForLabel1">Дата(до):<input className="Ads__ForWdht Ads__sel Ads__block Ads__DopStyle" name='date2' type='date' value={filters2.dateTo} onChange={handleDateToChange1} /></label>
                                </div>
                              )
                                : (
                                  ''
                                )}
                              {NameSearch.value === "По сетям" ? (
                                <div className="Ads__MapContPer">
                                  <label className="Ads__text-input Ads__block Ads__DopForLabel1">Сеть:<select className="Ads__SelSearch1ModalSearch" value={filters2.seti} onChange={handleSetiChange1}><option></option><option>Водоснабжение</option><option>Канализация</option><option>Благоустройство</option></select></label>
                                </div>
                              )
                                : (
                                  ''
                                )}
                              <button className='Ads__map-button' onClick={() => (ClearFilters1(), Mappp())}>Скрыть карту</button>
                            </>
                          )
                        }
                      </div>
                    ) : (
                      ''
                    )}
                  </>
                )
                  : (
                    ''
                  )}
                <h1 className='Ads__title'>Параметры заявки</h1>
                <div className="Ads__ForFlexRucFltrs">
                  <label className="Ads__text-input Ads__block Ads__ForMargMonthChange1">Выберите месяц:<input type="month" defaultValue={getCurrentMonth()} className="Ads__block Ads__sel" name='dispatcher_name' onChange={(e) => handleMonthChange(e)} /></label>
                  <label className="Ads__text-input Ads__block Ads__DopForLabel3">Сеть:<select className="Ads__SelSearch1ModalSearch" value={filters1.seti} onChange={handleSetiChange2}><option></option><option>Водоснабжение</option><option>Канализация</option><option>Благоустройство</option></select></label>
                </div>
                <button className='Ads__execlBtn' onClick={() => exportToExcel(data1, data2, data3, data4, data5, data6, file.defaultMonth, file.seti)}>Экспорт в Excel</button>
                <table className="Ads__tableById1 Ads__tableOleg">
                  <tbody>
                    <tr>
                      <th>Поступившее</th>
                      <h1 className='Ads__title1'>Поступившее:</h1>
                      <div className='Ads__tableById3'>
                        <table className="Ads__tableById2 Ads__tableOleg">
                          <thead>
                            <tr>
                              <th>
                                №Заявки
                              </th>
                              <th>
                                Дата
                              </th>
                              <th>
                                Время
                              </th>
                              <th>
                                ФИО оператора
                              </th>
                              <th>
                                Адрес
                              </th>
                              <th>
                                Текст заявки
                              </th>
                              <th>
                                Тип заявителя
                              </th>
                              <th>
                                Категория
                              </th>
                              <th>
                                Объект аварии
                              </th>
                              <th>
                                Статус заявки
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredData1.map((item, index) => {
                              let date = new Date(item.created_date);

                              let options = { timeZone: 'Europe/Moscow', hour: '2-digit', minute: '2-digit', year: 'numeric', month: '2-digit', day: '2-digit' };
                              let formattedDateTime = date.toLocaleString('ru-RU', options);

                              let [formattedDate, formattedTime] = formattedDateTime.split(', ');
                              return (
                                <>
                                  {item.applicant_status === "Принято" ? (
                                    <>
                                      <tr>
                                        <td>
                                          {item.id}
                                        </td>
                                        <td>
                                          {formattedDate}
                                        </td>
                                        <td>
                                          {formattedTime}
                                        </td>
                                        <td>
                                          {item.dispatcher_name}
                                        </td>
                                        <td>
                                          {item.adress}
                                        </td>
                                        <td>
                                          {item.text_application}
                                        </td>
                                        <td>
                                          {item.applicant}
                                        </td>
                                        <td>
                                          {item.category}
                                        </td>
                                        <td>
                                          {item.object}
                                        </td>
                                        <td>
                                          {item.applicant_status}
                                        </td>
                                      </tr>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </tr>
                    <tr>
                      <th>В работе</th>
                      <h1 className='Ads__title1'>В работе:</h1>
                      <div className='Ads__tableById3'>
                        <td>
                          <table className="Ads__tableById2 Ads__tableOleg">
                            <thead>
                              <tr>
                                <th>
                                  №Заявки
                                </th>
                                <th>
                                  Дата
                                </th>
                                <th>
                                  Время
                                </th>
                                <th>
                                  ФИО оператора
                                </th>
                                <th>
                                  Исполнитель
                                </th>
                                <th>
                                  Адрес
                                </th>
                                <th>
                                  Текст заявки
                                </th>
                                <th>
                                  Тип заявителя
                                </th>
                                <th>
                                  Категория
                                </th>
                                <th>
                                  Объект аварии
                                </th>
                                <th>
                                  Статус заявки
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {filteredData1.map((item, index) => {
                                let date = new Date(item.created_date);

                                let options = { timeZone: 'Europe/Moscow', hour: '2-digit', minute: '2-digit', year: 'numeric', month: '2-digit', day: '2-digit' };
                                let formattedDateTime = date.toLocaleString('ru-RU', options);

                                let [formattedDate, formattedTime] = formattedDateTime.split(', ');
                                return (
                                  <>
                                    {item.applicant_status === "В работе" ? (
                                      <>
                                        <tr>
                                          <td>
                                            {item.id}
                                          </td>
                                          <td>
                                            {formattedDate}
                                          </td>
                                          <td>
                                            {formattedTime}
                                          </td>
                                          <td>
                                            {item.dispatcher_name}
                                          </td>
                                          <td>
                                            {item.performer}
                                          </td>
                                          <td>
                                            {item.adress}
                                          </td>
                                          <td>
                                            {item.text_application}
                                          </td>
                                          <td>
                                            {item.applicant}
                                          </td>
                                          <td>
                                            {item.category}
                                          </td>
                                          <td>
                                            {item.object}
                                          </td>
                                          <td>
                                            {item.applicant_status}
                                          </td>
                                        </tr>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                )
                              })}
                            </tbody>
                          </table>
                        </td>
                      </div>
                    </tr>
                    <tr>
                      <th>Выполнено</th>
                      <h1 className='Ads__title1'>Выполненные:</h1>
                      <div className='Ads__tableById3 Ads__ForPlanCont2'>
                        <table className="Ads__tableById2 Ads__tableOleg">
                          <thead>
                            <tr>
                              <th>
                                №Заявки
                              </th>
                              <th>
                                Дата
                              </th>
                              <th>
                                Время
                              </th>
                              <th>
                                ФИО оператора
                              </th>
                              <th>
                                Исполнитель
                              </th>
                              <th>
                                Адрес
                              </th>
                              <th>
                                Текст заявки
                              </th>
                              <th>
                                Тип заявителя
                              </th>
                              <th>
                                Категория
                              </th>
                              <th>
                                Объект аварии
                              </th>
                              <th>
                                Статус заявки
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredData1.map((item, index) => {
                              let date = new Date(item.created_date);

                              let options = { timeZone: 'Europe/Moscow', hour: '2-digit', minute: '2-digit', year: 'numeric', month: '2-digit', day: '2-digit' };
                              let formattedDateTime = date.toLocaleString('ru-RU', options);

                              let [formattedDate, formattedTime] = formattedDateTime.split(', ');
                              return (
                                <>
                                  {item.applicant_status === "Исполнена" ? (
                                    <>
                                      <tr>
                                        <td><button className="Ads__BtnForDispIspZayvka" onClick={() => ZayvaInNewWindow(item.id)}>{item.id}</button></td>
                                        <td>
                                          {formattedDate}
                                        </td>
                                        <td>
                                          {formattedTime}
                                        </td>
                                        <td>
                                          {item.dispatcher_name}
                                        </td>
                                        <td>
                                          {item.performer}
                                        </td>
                                        <td>
                                          {item.adress}
                                        </td>
                                        <td>
                                          {item.text_application}
                                        </td>
                                        <td>
                                          {item.applicant}
                                        </td>
                                        <td>
                                          {item.category}
                                        </td>
                                        <td>
                                          {item.object}
                                        </td>
                                        <td>
                                          {item.applicant_status}
                                        </td>
                                      </tr>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </tr>
                    <tr>
                      <th>Отложенные</th>
                      <h1 className='Ads__title1'>Отложенные:</h1>
                      <div className='Ads__tableById3'>
                        <td>
                          <table className="Ads__tableById2 Ads__tableOleg">
                            <thead>
                              <tr>
                                <th>
                                  №Заявки
                                </th>
                                <th>
                                  Дата
                                </th>
                                <th>
                                  Время
                                </th>
                                <th>
                                  ФИО оператора
                                </th>
                                <th>
                                  Адрес
                                </th>
                                <th>
                                  Текст заявки
                                </th>
                                <th>
                                  Тип заявителя
                                </th>
                                <th>
                                  Категория
                                </th>
                                <th>
                                  Объект аварии
                                </th>
                                <th>
                                  Статус заявки
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {filteredData1.map((item, index) => {
                                let date = new Date(item.created_date);

                                let options = { timeZone: 'Europe/Moscow', hour: '2-digit', minute: '2-digit', year: 'numeric', month: '2-digit', day: '2-digit' };
                                let formattedDateTime = date.toLocaleString('ru-RU', options);

                                let [formattedDate, formattedTime] = formattedDateTime.split(', ');
                                return (
                                  <>
                                    {item.applicant_status === "Отложить" ? (
                                      <>
                                        <tr>
                                          <td>
                                            {item.id}
                                          </td>
                                          <td>
                                            {formattedDate}
                                          </td>
                                          <td>
                                            {formattedTime}
                                          </td>
                                          <td>
                                            {item.dispatcher_name}
                                          </td>
                                          <td>
                                            {item.adress}
                                          </td>
                                          <td>
                                            {item.text_application}
                                          </td>
                                          <td>
                                            {item.applicant}
                                          </td>
                                          <td>
                                            {item.category}
                                          </td>
                                          <td>
                                            {item.object}
                                          </td>
                                          <td>
                                            {item.applicant_status}
                                          </td>
                                        </tr>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                )
                              })}
                            </tbody>
                          </table>
                        </td>
                      </div>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            ''
          )}
          {jobTitle === "Оператор БД" ? (
            <>
              <div className="Ads__contanerProgr">
                <button className="Ads__NonActvZ" onClick={() => (nonActv(), fetchZayvkaAll())}>Принятые заявки</button>
                <button className="Ads__ActvZ" onClick={() => (Actv(), fetchZayvkaAll1())}>Активные заявки</button>
                <button className="Ads__ActvZ" onClick={() => (WaitZayva(), fetchZayvkaAll1())}>Отложенные заявки</button>
                <button className="Ads__ActvZ" onClick={() => (IspZayva(), fetchZayvkaAll1())}>Исполненные заявки</button>
                <button className="Ads__ActvZ1 Ads__ActZ1EdtPerf" onClick={() => (ModalChange5())}>Редактирование исполнителей</button>
              </div>
            </>
          ) : (
            ''
          )}
        </div>
        {jobTitle === "Работник" ? (
          <>
            {itemFromLocalStorage1 !== null ? (
              <>
                <div className="Ads__ContanerRabBtn">
                  <button className="Ads__bimba" onClick={() => (activeIspolnenieZayvka(), fetchZayvkaAll1())}>Исполнение заявок</button>
                  <button className="Ads__bimba1" onClick={() => (alert("Смена закрыта"), localStorage.removeItem("FioRab"), window.location.reload())}>Закрыть смену</button>
                </div>
              </>
            ) : (
              <>
                {IsSmena1 === true ? (
                  <div className="Ads__center-container">
                    <button className="Ads__createSmena" onClick={OpenSmena1}>Создать смену</button>
                  </div>
                ) : (
                  <>
                    {IsFioRab === true ? (
                      <>
                        <div className="Ads__center-contRab">
                          <label className="Ads__text-input Ads__block Ads__DopForLabel Ads__DopAdrStyle1">Сфера работ:<select className="Ads__ForWdht Ads__sel Ads__block Ads__DopStyle" name='type_of_activity' onChange={(e) => postPerformer1(e)}><option>Канализация</option><option>Водоснабжение</option><option>Благоустройство</option></select></label>
                          {postPerformers1.type_of_activity === "Водоснабжение" ? (
                            <label className="Ads__text-input Ads__block">Исполнитель:<select className="Ads__ForWdht Ads__sel Ads__block" name='performer' onChange={(e) => delPerformer1(e)}>
                              <option></option>
                              {getPerformer.map((item, idx) => {
                                return (
                                  <>
                                    {item.type_of_activity === "Водоснабжение" ? (
                                      <option>
                                        {item.lastname}
                                      </option>
                                    ) : (
                                      ''
                                    )}
                                  </>
                                )
                              })}
                            </select></label>
                          ) : (
                            ''
                          )}
                          {postPerformers1.type_of_activity === "Канализация" ? (
                            <label className="Ads__text-input Ads__block">Исполнитель:<select className="Ads__ForWdht Ads__sel Ads__block" name='performer' onChange={(e) => delPerformer1(e)}>
                              <option></option>
                              {getPerformer.map((item, idx) => {
                                return (
                                  <>
                                    {item.type_of_activity === "Канализация" ? (
                                      <option>
                                        {item.lastname}
                                      </option>
                                    ) : (
                                      ''
                                    )}
                                  </>
                                )
                              })}
                            </select></label>
                          ) : (
                            ''
                          )
                          }
                          {postPerformers1.type_of_activity === "Благоустройство" ? (
                            <label className="Ads__text-input Ads__block">Исполнитель:<select className="Ads__ForWdht Ads__sel Ads__block" name='performer' onChange={(e) => delPerformer1(e)}>
                              <option></option>
                              {getPerformer.map((item, idx) => {
                                return (
                                  <>
                                    {item.type_of_activity === "Благоустройство" ? (
                                      <option>
                                        {item.lastname}
                                      </option>
                                    ) : (
                                      ''
                                    )}
                                  </>
                                )
                              })}
                            </select></label>
                          ) : (
                            ''
                          )
                          }
                        </div>
                        {postPerformers.type_of_activity === "Водоснабжение" ? (
                          <div id="myElement" className="Ads__center-container2">
                            <button className="Ads__createSmena1" onClick={() => (ForFioRab(), localStorage.setItem("FioRab", NamePerformers1.performer))}>Подтвердить</button>
                          </div>
                        ) : (
                          ''
                        )}
                        {postPerformers.type_of_activity === "Канализация" ? (
                          <div id="myElement" className="Ads__center-container2">
                            <button className="Ads__createSmena1" onClick={() => (ForFioRab(), localStorage.setItem("FioRab", NamePerformers1.performer))}>Подтвердить</button>
                          </div>
                        ) : (
                          ''
                        )}
                        {postPerformers.type_of_activity === "Благоустройство" ? (
                          <div id="myElement" className="Ads__center-container2">
                            <button className="Ads__createSmena1" onClick={() => (ForFioRab(), localStorage.setItem("FioRab", NamePerformers1.performer))}>Подтвердить</button>
                          </div>
                        ) : (
                          ''
                        )}
                      </>
                    ) : (
                      ''
                    )}
                  </>
                )}
              </>
            )}
          </>
        ) : (
          ''
        )}
        {IsChecModal5 === true ? (
          <>
            {ModalEdtPerf1()}
          </>
        ) : (
          ''
        )}
        {IsChecNonActv === true ? (
          <div className="Ads__ForCenterTable">
            <table className="Ads__tableById2 Ads__tableOleg">
              <thead>
                <tr>
                  <th>
                    №Заявки
                  </th>
                  <th>
                    Дата
                  </th>
                  <th>
                    Время
                  </th>
                  <th>
                    ФИО оператора
                  </th>
                  <th>
                    Адрес
                  </th>
                  <th>
                    Текст заявки
                  </th>
                  <th>
                    Исполнитель
                  </th>
                </tr>
              </thead>
              <tbody>
                {ZayvkaAllNonAct.map((item, index) => {
                  return (
                    <>
                      {item.applicant_status === 'Принято' && item.seti === 'Водоснабжение' ? (
                        <>
                          <tr key={index} id={`row-${index}`}>
                            <td>
                              {item.id}
                            </td>
                            <td>
                              {(item.created_date.substring(0, 10)).split('-')[2]}.{(item.created_date.substring(0, 10)).split('-')[1]}.{(item.created_date.substring(0, 10)).split('-')[0]}
                            </td>
                            <td>
                              {(item.created_date.substring(11, 16))}
                            </td>
                            <td>
                              {item.dispatcher_name}
                            </td>
                            <td>
                              {item.adress}
                            </td>
                            <td>
                              {item.text_application}
                            </td>
                            <td>
                              <button className='Ads__EdtFragBtn1' onClick={() => (OpenModal2(), setModelId1(item.id))}>Назначить исполнителя</button>
                            </td>
                          </tr>
                        </>
                      ) : (
                        ''
                      )}
                    </>
                  )
                })}
              </tbody>
            </table>
            {IsChecModal2 === true ? (
              <>
                <div className="Ads__ModalBg">
                  {ModalEdtMap()}
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        ) : (
          ''
        )}
        {IsChecAct === true ? (
          <div className="Ads__ForCenterTable">
            <table className="Ads__tableById2 Ads__tableOleg">
              <thead>
                <tr>
                  <th>
                    №Заявки
                  </th>
                  <th>
                    Дата
                  </th>
                  <th>
                    Время
                  </th>
                  <th>
                    ФИО оператора
                  </th>
                  <th>
                    Адрес
                  </th>
                  <th>
                    Текст заявки
                  </th>
                  <th>
                    Статус заявки
                  </th>
                  <th>
                    Исполнитель
                  </th>
                </tr>
              </thead>
              <tbody>
                {ZayvkaAllAct.map((item, index) => {
                  return (
                    <>
                      {item.applicant_status === 'В работе' && item.seti === 'Водоснабжение' ? (
                        <tr key={index} id={`row-${index}`}>
                          <td>
                            {item.id}
                          </td>
                          <td>
                            {(item.created_date.substring(0, 10)).split('-')[2]}.{(item.created_date.substring(0, 10)).split('-')[1]}.{(item.created_date.substring(0, 10)).split('-')[0]}
                          </td>
                          <td>
                            {(item.created_date.substring(11, 16))}
                          </td>
                          <td>
                            {item.dispatcher_name}
                          </td>
                          <td>
                            {item.adress}
                          </td>
                          <td>
                            {item.text_application}
                          </td>
                          <td>
                            {item.applicant_status}
                          </td>
                          <td>
                            {item.performer}
                          </td>
                        </tr>
                      ) : (
                        ''
                      )}

                    </>
                  )
                })}
              </tbody>
            </table>
          </div>
        ) : (
          ''
        )}
        {IsWaitZayva === true ? (
          <div className="Ads__ForCenterTable">
            <table className="Ads__tableById2 Ads__tableOleg">
              <thead>
                <tr>
                  <th>
                    №Заявки
                  </th>
                  <th>
                    Дата
                  </th>
                  <th>
                    Время
                  </th>
                  <th>
                    ФИО оператора
                  </th>
                  <th>
                    Адрес
                  </th>
                  <th>
                    Текст заявки
                  </th>
                  <th>
                    Статус заявки
                  </th>
                  <th>
                    Исполнитель
                  </th>
                </tr>
              </thead>
              <tbody>
                {ZayvkaAllAct.map((item, index) => {
                  return (
                    <>
                      {item.applicant_status === 'Отложить' && item.seti === 'Водоснабжение' ? (
                        <tr key={index} id={`row-${index}`}>
                          <td>
                            {item.id}
                          </td>
                          <td>
                            {(item.created_date.substring(0, 10)).split('-')[2]}.{(item.created_date.substring(0, 10)).split('-')[1]}.{(item.created_date.substring(0, 10)).split('-')[0]}
                          </td>
                          <td>
                            {(item.created_date.substring(11, 16))}
                          </td>
                          <td>
                            {item.dispatcher_name}
                          </td>
                          <td>
                            {item.adress}
                          </td>
                          <td>
                            {item.text_application}
                          </td>
                          <td>
                            {item.applicant_status}
                          </td>
                          <td>
                            {item.performer}
                          </td>
                        </tr>
                      ) : (
                        ''
                      )}

                    </>
                  )
                })}
              </tbody>
            </table>
          </div>
        ) : (
          ''
        )}
        {IsIspZayva === true ? (
          <>
            <div className="Ads__DateSearchProgr">
              <label className="Ads__text-input Ads__block Ads__DopForLabel1">Дата(с):<input className="Ads__ForWdht Ads__sel Ads__block Ads__DopStyle" name='date1' type='date' onChange={(e) => getZayvaByDate(e)} /></label>
              <label className="Ads__text-input Ads__block Ads__DopForLabel1">Дата(до):<input className="Ads__ForWdht Ads__sel Ads__block Ads__DopStyle" name='date2' type='date' onChange={(e) => getZayvaByDate(e)} /></label>
            </div>
            <div className="Ads__CentrContBtnSProgr">
              <button className="Ads__btnSearchModal" onClick={() => fetchDataForProgr()}>Найти</button>
            </div>
            {getDataForMyGpaph.length !== 0 ? (
              <div className="Ads__ForCenterTable">
                <table className="Ads__tableById2 Ads__tableOleg">
                  <thead>
                    <tr>
                      <th>
                        №Заявки
                      </th>
                      <th>
                        Дата
                      </th>
                      <th>
                        Время
                      </th>
                      <th>
                        ФИО оператора
                      </th>
                      <th>
                        Адрес
                      </th>
                      <th>
                        Текст заявки
                      </th>
                      <th>
                        Статус заявки
                      </th>
                      <th>
                        Исполнитель
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {getDataForMyGpaph.map((item, index) => {
                      return (
                        <>
                          {item.applicant_status === 'Исполнена' && item.seti === 'Водоснабжение' && item.created_date >= getDate.date1 && item.created_date <= getDate.date2 ? (
                            <tr key={index} id={`row-${index}`}>
                              <td>
                                {item.id}
                              </td>
                              <td>
                                {(item.created_date.substring(0, 10)).split('-')[2]}.{(item.created_date.substring(0, 10)).split('-')[1]}.{(item.created_date.substring(0, 10)).split('-')[0]}
                              </td>
                              <td>
                                {(item.created_date.substring(11, 16))}
                              </td>
                              <td>
                                {item.dispatcher_name}
                              </td>
                              <td>
                                {item.adress}
                              </td>
                              <td>
                                {item.text_application}
                              </td>
                              <td>
                                {item.applicant_status}
                              </td>
                              <td>
                                {item.performer}
                              </td>
                            </tr>
                          ) : (
                            ''
                          )}

                        </>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              ''
            )}
          </>
        ) : (
          ''
        )}
        {IsCheckGraph10 === true && jobTitle === "Диспетчер" ? (
          <div className='Ads__Graph'>
            <div className='Ads__GraphInput1'>
              <label className="Ads__text-input Ads__block">Сеть:<select className="Ads__sel1 Ads__block" name='seti' onChange={(e) => NameSetForStat(e)}><option></option><option>Водоснабжение</option><option>Канализация</option></select></label>
              {NameSet.seti === "Водоснабжение" ? (
                <div className='Ads__TopMargStat'>
                  <label className='Ads__text-input Ads__block'>Укажите год и месяц (Абсолютный показатель):<input className="Ads__sel Ads__block" type="month" onChange={(e) => GetCountByMonth10(e)} /></label>
                  <label className='Ads__text-input Ads__block Ads__ForMargGraphStat1'>Укажите год и месяц (Относительный показатель):<input className="Ads__sel Ads__block" type="month" onChange={(e) => GetCountByMonth100(e)} /></label>
                </div>
              ) : (
                ''
              )}
              {NameSet.seti === "Канализация" ? (
                <div className='Ads__TopMargStat'>
                  <label className='Ads__text-input Ads__block'>Укажите год и месяц (Абсолютный показатель):<input className="Ads__sel Ads__block" type="month" onChange={(e) => GetCountByMonth20(e)} /></label>
                  <label className='Ads__text-input Ads__block Ads__ForMargGraphStat1'>Укажите год и месяц (Относительный показатель):<input className="Ads__sel Ads__block" type="month" onChange={(e) => GetCountByMonth200(e)} /></label>
                </div>
              ) : (
                ''
              )}
            </div>
            {IsCheckGraph20 === true ? (
              <Chart style={{ width: "100%", height: "900px" }}>
                <ChartLegend
                  visible={true}
                  position="right"
                />
                <ChartCategoryAxis>
                  <ChartCategoryAxisItem categories={StatYear}>
                    <ChartCategoryAxisTitle text="Год" />
                  </ChartCategoryAxisItem>
                </ChartCategoryAxis>
                <ChartSeries>
                  {getDataByYearAdr.query.map((item, idx) => {
                    return (
                      <ChartSeriesItem
                        key={idx}
                        type="column"
                        gap={10}
                        spacing={2.5}
                        labels={seriesLabels1}
                        data={[item.count]}
                        name={item.street + ' - ' + [item.count]}
                        color={getRandomColor()}
                        style={{ width: "10%", height: "80%" }}
                      />
                    );
                  })}
                </ChartSeries>
              </Chart>
            ) : (
              ''
            )}
            {IsCheckGraph30 === true ? (
              <>
                <Chart style={{ width: "100%", height: "900px" }}>
                  <ChartLegend
                    visible={true}
                    position="right"
                  />
                  <ChartCategoryAxis>
                    <ChartCategoryAxisItem categories={StatYear}>
                      <ChartCategoryAxisTitle text="Год" />
                    </ChartCategoryAxisItem>
                  </ChartCategoryAxis>
                  <ChartSeries>
                    {getDataByYearAdr.query
                      .sort((a, b) => b.relative_count - a.relative_count)
                      .slice(0, 10)
                      .map((item, idx) => {
                        const roundedCount = Number(item.relative_count.toFixed(3));
                        return (
                          <ChartSeriesItem
                            key={idx}
                            type="column"
                            gap={10}
                            spacing={2.5}
                            labels={seriesLabels1}
                            data={[roundedCount]}
                            name={item.street + ' - ' + [roundedCount]}
                            color={getRandomColor()}
                            style={{ width: "10%", height: "80%" }}
                          />
                        );
                      })}
                  </ChartSeries>

                </Chart>
                {/* <Chart>
                  <ChartTitle text="Относительный показатель аварий на улицах" font="bold 20px Arial" />
                  <ChartLegend visible={true} />
                  <ChartCategoryAxis>
                    <ChartCategoryAxisItem
                      categories={[getStatThreeMonth[0].month0]}
                      labels={{
                        font: "bold 14px Arial",
                        color: "#000",
                      }}
                    >
                      <ChartCategoryAxisTitle
                        text="Месяц"
                        font="bold 16px Arial"
                        color="#000"
                      />
                    </ChartCategoryAxisItem>
                  </ChartCategoryAxis>
                  <ChartSeries>
                    {getStatThreeMonth[0].query0.map((item, idx) => {
                      const roundedCount = Number(item.relative_count.toFixed(3));
                      return (
                        <ChartSeriesItem
                          key={`query0-${idx}`}
                          type="column"
                          gap={20}
                          spacing={0.25}
                          labels={seriesLabels1}
                          data={[roundedCount]}
                          name={item.street + ' - ' + [roundedCount]}
                          color={getRandomColor()}
                          style={{ width: "10%", height: "80%" }}
                        />
                      );
                    })}
                  </ChartSeries>
                </Chart>
                <Chart>
                  <ChartLegend visible={true} />
                  <ChartCategoryAxis>
                    <ChartCategoryAxisItem
                      categories={[getStatThreeMonth[1].month1]}
                      labels={{
                        font: "bold 14px Arial",
                        color: "#000",
                      }}
                    >
                      <ChartCategoryAxisTitle
                        text="Месяц"
                        font="bold 16px Arial"
                        color="#000"
                      />
                    </ChartCategoryAxisItem>
                  </ChartCategoryAxis>
                  <ChartSeries>
                    {getStatThreeMonth[1].query1.map((item, idx) => {
                      const roundedCount = Number(item.relative_count.toFixed(3));
                      return (
                        <ChartSeriesItem
                          key={`query1-${idx}`}
                          type="column"
                          gap={20}
                          spacing={0.25}
                          labels={seriesLabels1}
                          data={[roundedCount]}
                          name={item.street + ' - ' + [roundedCount]}
                          color={getRandomColor()}
                          style={{ width: "10%", height: "80%" }}
                        />
                      );
                    })}
                  </ChartSeries>
                </Chart>
                <Chart>
                  <ChartLegend visible={true} />
                  <ChartCategoryAxis>
                    <ChartCategoryAxisItem
                      categories={[getStatThreeMonth[2].month2]}
                      labels={{
                        font: "bold 14px Arial",
                        color: "#000",
                      }}
                    >
                      <ChartCategoryAxisTitle
                        text="Месяц"
                        font="bold 16px Arial"
                        color="#000"
                      />
                    </ChartCategoryAxisItem>
                  </ChartCategoryAxis>
                  <ChartSeries>
                    {getStatThreeMonth[2].query2.map((item, idx) => {
                      const roundedCount = Number(item.relative_count.toFixed(3));
                      return (
                        <ChartSeriesItem
                          key={`query1-${idx}`}
                          type="column"
                          gap={20}
                          spacing={0.25}
                          labels={seriesLabels1}
                          data={[roundedCount]}
                          name={item.street + ' - ' + [roundedCount]}
                          color={getRandomColor()}
                          style={{ width: "10%", height: "80%" }}
                        />
                      );
                    })}
                  </ChartSeries>
                </Chart> */}
              </>
            ) : (
              ''
            )}
            {IsCheckGraph200 === true ? (
              <Chart style={{ width: "100%", height: "900px" }}>
                <ChartTitle text="Канализация" />
                <ChartLegend
                  visible={true}
                  position="right"
                />
                <ChartCategoryAxis>
                  <ChartCategoryAxisItem categories={StatYear}>
                    <ChartCategoryAxisTitle text="Год" />
                  </ChartCategoryAxisItem>
                </ChartCategoryAxis>
                <ChartSeries>
                  {getDataByYearAdr.query.map((item, idx) => {
                    return (
                      <ChartSeriesItem
                        key={idx}
                        type="column"
                        gap={10}
                        spacing={2.5}
                        labels={seriesLabels1}
                        data={[item.count]}
                        name={item.street + ' - ' + [item.count]}
                        color={getRandomColor()}
                        style={{ width: "10%", height: "80%" }}
                      />
                    );
                  })}
                </ChartSeries>

              </Chart>
            ) : (
              ''
            )}
            {IsCheckGraph300 === true ? (
              <Chart style={{ width: "100%", height: "900px" }}>
                <ChartTitle text="Канализация" />
                <ChartLegend
                  visible={true}
                  position="right"
                />
                <ChartCategoryAxis>
                  <ChartCategoryAxisItem categories={StatYear}>
                    <ChartCategoryAxisTitle text="Год" />
                  </ChartCategoryAxisItem>
                </ChartCategoryAxis>
                <ChartSeries>
                  {getDataByYearAdr.query
                    .sort((a, b) => b.relative_count - a.relative_count)
                    .slice(0, 15)
                    .map((item, idx) => {
                      const roundedCount = Number(item.relative_count.toFixed(3));
                      return (
                        <ChartSeriesItem
                          key={idx}
                          type="column"
                          gap={10}
                          spacing={2.5}
                          labels={seriesLabels1}
                          data={[roundedCount]}
                          name={item.street + ' - ' + [roundedCount]}
                          color={getRandomColor()}
                          style={{ width: "10%", height: "80%" }}
                        />
                      );
                    })}
                </ChartSeries>
              </Chart>
            ) : (
              ''
            )}
          </div>
        ) : (
          ''
        )
        }
        {IsPriemZayvka === true ? (
          <>
            <div className="Ads__PrCont">
              <div className="Ads__text">
                <label className="Ads__text-input1 Ads__block">Адрес:<AddressSuggestions token="6dc7b29f2b8e6eb9bc537838a324af7caafc5eca" type='text' id='address' className="Ads__dadata" name='adress' ref={AdresRef} value={valueDaData.value} onChange={(e) => postAdressZayvka(e, TypeAppRef)} /></label>
                <label className="Ads__text-input Ads__block">Тип обращения:<select className="Ads__ForWdht Ads__sel Ads__block" name='application_type' value={postData.application_type} ref={TypeAppRef} onKeyDown={(e) => handleKeyPress(e, ApplRef)} onChange={(e) => postTextZayvka(e)}>
                  {ZayvkaBySearch1.length !== 0 && Povtor === true ? (
                    <>
                      {/* {postData.application_type = 'Повторная'} */}
                      <option>Повторная</option><option>Претензия</option>
                    </>
                  ) : (
                    <>
                      {/* {postData.application_type = 'Первичная'} */}
                      <option>Первичная</option><option>Претензия</option>
                    </>
                  )}
                </select></label>
                <label className="Ads__text-input Ads__block">Тип заявителя:<select className="Ads__ForWdht Ads__sel Ads__block" name='applicant' value={postData.applicant} ref={ApplRef} onKeyDown={(e) => handleKeyPress(e, fullNameRef)} onChange={(e) => postTextZayvka(e)}><option>Гражданин</option><option>Госорганы</option><option>Организация</option></select></label>
                <label className="Ads__text-input Ads__block">ФИО заявителя:<input className="Ads__sel Ads__block" type="text" name='fullname' value={postData.fullname} onChange={(e) => postTextZayvka(e)} ref={fullNameRef} onKeyDown={(e) => handleKeyPress(e, phoneNumberRef)} /></label>
                <label className="Ads__text-input Ads__block">Телефон:<input className="Ads__sel Ads__block" type="text" name='phone_number' ref={phoneNumberRef} onKeyDown={(e) => handleKeyPress(e, emailRef)} value={number.phone_number} onChange={(e) => postNumberPhone(e)} /></label>
                <label className="Ads__text-input Ads__block">Email:<input className="Ads__sel Ads__block" type="text" name='email' ref={emailRef} onKeyDown={(e) => handleKeyPress(e, textApplicationRef)} value={postData.email} onChange={(e) => postTextZayvka(e)} /></label>
                <label className="Ads__text-input Ads__block">Текст заявки:<input type="text" className="Ads__block Ads__textZ" name='text_application' ref={textApplicationRef} onKeyDown={(e) => handleKeyPress(e, setiRef)} value={postData.text_application} onChange={(e) => postTextZayvka(e)} /></label>
                <label className="Ads__text-input Ads__block">Сети:<select className="Ads__ForWdht Ads__sel Ads__block" value={postData.seti} name='seti' ref={setiRef} onKeyDown={(e) => handleKeyPress(e, ObjRef)} onChange={(e) => postTextZayvka(e)}><option>Водоснабжение</option><option>Канализация</option><option>Благоустройство</option></select></label>
                {postData.seti === "Водоснабжение" ? (
                  <label className="Ads__text-input Ads__block">Объект аварии:<select className="Ads__sel Ads__block" name='object' value={postData.object} ref={ObjRef} onKeyDown={(e) => handleKeyPress(e, PerfRef)} onChange={(e) => postTextZayvka(e)}><option>Течь сети</option><option>Колодец</option><option>Течь пожарного гидранта</option><option>Колонка</option></select></label>
                ) : (
                  ''
                )}
                {postData.seti === "Канализация" ? (
                  <label className="Ads__text-input Ads__block">Объект аварии:<select className="Ads__sel Ads__block" name='object' value={postData.object} ref={ObjRef} onKeyDown={(e) => handleKeyPress(e, PerfRef)} onChange={(e) => postTextZayvka(e)}><option>Течь сети</option><option>Колодец</option><option>Засор канализации</option><option>Иное</option></select></label>
                ) : (
                  ''
                )}
                {postData.seti === "Благоустройство" ? (
                  <label className="Ads__text-input Ads__block">Объект аварии:<select className="Ads__sel Ads__block" name='object' ref={ObjRef} onKeyDown={(e) => handleKeyPress(e, PerfRef)} onChange={(e) => postTextZayvka(e)}><option></option><option>Колодец</option></select></label>
                ) : (
                  ''
                )}
                {postData.seti === "Водоснабжение" ? (
                  <>
                    <label className="Ads__text-input Ads__block">Исполнитель:<select className="Ads__ForWdht Ads__sel Ads__block" name='performer' ref={PerfRef} onKeyDown={(e) => handleKeyPress(e, CategoryRef)} onChange={(e) => postTextZayvkaPerformer(e)}>
                      <option></option>
                      {getPerformer.map((item, idx) => {
                        return (
                          <>
                            {item.type_of_activity === "Водоснабжение" ? (
                              <>
                                <option>
                                  {item.lastname}
                                </option>
                              </>
                            ) : (
                              ''
                            )}
                          </>
                        )
                      })}
                    </select></label>
                  </>
                ) : (
                  ''
                )}
                {postData.seti === "Канализация" ? (
                  <label className="Ads__text-input Ads__block">Исполнитель:<select className="Ads__ForWdht Ads__sel Ads__block" name='performer' ref={PerfRef} onKeyDown={(e) => handleKeyPress(e, CategoryRef)} onChange={(e) => postTextZayvkaPerformer(e)}>
                    <option></option>
                    {getPerformer.map((item, idx) => {
                      return (
                        <>
                          {item.type_of_activity === "Канализация" ? (
                            <>
                              <option>
                                {item.lastname}
                              </option>
                            </>
                          ) : (
                            ''
                          )}
                        </>
                      )
                    })}
                  </select></label>
                ) : (
                  ''
                )}
                {postData.seti === "Благоустройство" ? (
                  <label className="Ads__text-input Ads__block">Исполнитель:<select className="Ads__ForWdht Ads__sel Ads__block" name='performer' ref={PerfRef} onKeyDown={(e) => handleKeyPress(e, CategoryRef)} onChange={(e) => postTextZayvkaPerformer(e)}>
                    <option></option>
                    {getPerformer.map((item, idx) => {
                      return (
                        <>
                          {item.type_of_activity === "Благоустройство" ? (
                            <>
                              <option>
                                {item.lastname}
                              </option>
                            </>
                          ) : (
                            ''
                          )}
                        </>
                      )
                    })}
                  </select></label>
                ) : (
                  ''
                )}
                <label className="Ads__text-input Ads__block">Категория:<select className="Ads__ForWdht Ads__sel Ads__block" name='category' value={postData.category} ref={CategoryRef} onKeyDown={(e) => handleKeyPress(e, SaveButtonRef)} onChange={(e) => postTextZayvka(e)}><option>Аварийная</option><option>Ремонт</option><option>Плановая</option><option>Платная</option><option>Консультация</option><option>Работа по обращениям</option><option>Техническое отключение</option></select></label>
              </div>
            </div>
            <div className="Ads__PrCont">
              <div className="Ads__ChangePhoto">
                <button className="Ads__ChangeBtnPhoto" onClick={() => (ModalChange(), saveButtonWhenUp())}>Скорректировать список исполнителей</button>
                <button className="Ads__save" ref={SaveButtonRef} onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    fetchPostTextZayvka();
                    fetchTgBot();
                    AdresRef.current.focus();
                  }
                }} onClick={() => (fetchPostTextZayvka(), fetchTgBot(), HideMapForCoordinate())}>Cохранить</button>
                <button className='Ads__ChangeBtnPhoto1' onClick={Mapp}>Показать на карте</button>
                <button className="Ads__ChangeBtnPhoto1" onClick={() => (alert("Смена закрыта"), localStorage.removeItem("FioOp"), window.location.reload())}>Закрыть смену</button>
                {IsCheckMap === true ? (
                  <>
                    {DataSearhMonth.length !== 0 ? (
                      <div className="Ads__Mappp">
                        <YMaps>
                          <Map height={600} width={-1} defaultState={defaultState}>
                            <ZoomControl />
                            <TypeSelector />
                            {filteredData2.map((item, index) => {
                              let date = new Date(item.created_date);

                              let options = { timeZone: 'Europe/Moscow', hour: '2-digit', minute: '2-digit', year: 'numeric', month: '2-digit', day: '2-digit' };
                              let formattedDateTime = date.toLocaleString('ru-RU', options);

                              let [formattedDate, formattedTime] = formattedDateTime.split(', ');
                              return (
                                <>
                                  {item.applicant_status === "В работе" ? (
                                    <>
                                      <Placemark

                                        geometry={
                                          [item.latitude, item.longtitude]
                                        }
                                        properties={{
                                          hintContent: item.applicant_status,
                                          balloonContent: item.adress + ',' + item.object + ", Дата приёма: " + formattedDate + ", Время приёма: " + formattedTime
                                        }}

                                        options={{
                                          preset: 'islands#redStretchyIcon',
                                          draggable: true,
                                        }}
                                        modules={
                                          ['geoObject.addon.balloon', 'geoObject.addon.hint']
                                        }
                                      />
                                    </>
                                  ) : (
                                    ''
                                  )}
                                  {item.applicant_status === "Отложить" ? (
                                    <>
                                      <Placemark

                                        geometry={
                                          [item.latitude, item.longtitude]
                                        }
                                        properties={{
                                          hintContent: item.applicant_status,
                                          balloonContent: item.adress + ',' + item.object + ", Дата приёма: " + formattedDate + ", Время приёма: " + formattedTime
                                        }}

                                        options={{
                                          preset: 'islands#yellowStretchyIcon',
                                          draggable: true,
                                        }}
                                        modules={
                                          ['geoObject.addon.balloon', 'geoObject.addon.hint']
                                        }
                                      />
                                    </>
                                  ) : (
                                    ''
                                  )}
                                  {item.applicant_status === "Исполнена" ? (
                                    <>
                                      <Placemark

                                        geometry={
                                          [item.latitude, item.longtitude]
                                        }
                                        properties={{
                                          hintContent: item.applicant_status,
                                          balloonContent: item.adress + ',' + item.object + ", Дата исполнения: " + formattedDate + ", Время исполнения: " + formattedTime + ", Координаты аварии: " + item.latitude.substring(0, 7) + " (широта), " + item.longtitude.substring(0, 7) + " (долгота)"
                                        }}

                                        options={{
                                          preset: 'islands#greenStretchyIcon',
                                          draggable: true,
                                        }}
                                        modules={
                                          ['geoObject.addon.balloon', 'geoObject.addon.hint']
                                        }
                                      />
                                    </>
                                  ) : (
                                    ''
                                  )}
                                  {item.applicant_status === "Принято" ? (
                                    <>
                                      <Placemark

                                        geometry={
                                          [item.latitude, item.longtitude]
                                        }
                                        properties={{
                                          hintContent: item.applicant_status,
                                          balloonContent: item.adress + ',' + item.object + ", Дата приёма: " + formattedDate + ", Время приёма: " + formattedTime
                                        }}

                                        options={{
                                          preset: 'islands#blueStretchyIcon',
                                          draggable: true,
                                        }}
                                        modules={
                                          ['geoObject.addon.balloon', 'geoObject.addon.hint']
                                        }
                                      />
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </>

                              )
                            })}
                          </Map>
                        </YMaps>
                        {IsCheckCoordinateMap === true ? (
                          <>
                          </>
                        ) :
                          (
                            <>
                              <div className="Ads__Mappp-legend">
                                <div className="Ads__Mappp-legend-mark Ads__Mappp-legend-mark-red">
                                  Красный - статус: "В работе"
                                </div>
                                <div className="Ads__Mappp-legend-mark Ads__Mappp-legend-mark-yelow">
                                  Желтый - статус: "Отложить"
                                </div>
                                <div className="Ads__Mappp-legend-mark Ads__Mappp-legend-mark-blue">
                                  Синий - статус: "Принято"
                                </div>
                                <div className="Ads__Mappp-legend-mark Ads__Mappp-legend-mark-green">
                                  Зеленый - статус: "Исполнена"
                                </div>
                              </div>

                              <button className="Ads__btnSearchModal Ads__VAllMap" onClick={() => ClearFilters1()}>Показать все заявки</button>

                              <label className="Ads__text-input Ads__block Ads__selectSearch1">Критерий поиска:<select className="Ads__sel Ads__block Ads__selectSearch2" onChange={(e) => NameOfSearch(e)}><option>По адресу</option><option>По месяцу и периоду</option><option>По сетям</option></select></label>
                              {NameSearch.value === "По адресу" ? (
                                <label className="Ads__Mappp-search-dadata Ads__ForMargMapDsp1">Адрес:<AddressSuggestions token="6dc7b29f2b8e6eb9bc537838a324af7caafc5eca" type='text' className="Ads__ForWdht Ads__sel Ads__block Ads__DopStyle" name='adr' value={filters2.address} onChange={handleAddressChange1} /></label>
                              )
                                : (
                                  ''
                                )}
                              {NameSearch.value === "По месяцу и периоду" ? (
                                <div className="Ads__MapContPer">
                                  <label className="Ads__text-input Ads__block Ads__ForMargMapDsp1">Выберите месяц:<input type="month" defaultValue={getCurrentMonth()} className="Ads__block Ads__sel" onChange={(e) => handleMonthChange(e)} /></label>
                                  <div className="Ads__ForMargDvForDspMap">
                                    <label className="Ads__text-input Ads__block Ads__ForMargMapDsp">Дата с:<input type="date" className="Ads__block Ads__sel" onChange={(e) => handleDateFromChange1(e)} /></label>
                                    <label className="Ads__text-input Ads__block Ads__ForMargMapDsp">Дата до:<input type="date" className="Ads__block Ads__sel" onChange={(e) => handleDateToChange1(e)} /></label>
                                  </div>
                                </div>
                              )
                                : (
                                  ''
                                )}
                              {NameSearch.value === "По сетям" ? (
                                <div className="Ads__MapContPer">
                                  <label className="Ads__text-input Ads__block Ads__DopForLabel1 Ads__ForMargMapDsp1">Сеть:<select className="Ads__SelSearch1ModalSearch" value={filters2.seti} onChange={handleSetiChange1}><option></option><option>Водоснабжение</option><option>Канализация</option><option>Благоустройство</option></select></label>
                                </div>
                              )
                                : (
                                  ''
                                )}
                              <button className='Ads__map-button' onClick={() => (ClearFilters1(), Mappp())}>Скрыть карту</button>
                            </>
                          )
                        }
                      </div>
                    ) : (
                      ''
                    )}
                  </>
                )
                  : (
                    ''
                  )}
                {IsChecModal === true ? (
                  <>
                    <div className="Ads__ModalBg">
                      {ModalEdtPerf()}
                    </div>
                  </>
                ) : (
                  ''
                )}
                {IsChecModal1 === true ? (
                  <>
                    <div className="Ads__ModalBg">
                      {ModalSearch()}
                    </div>
                  </>
                ) : (
                  ''
                )}
                {IsChecModal6 === true ? (
                  <>
                    <div className="Ads__ModalBg">
                      {ModalSearch1()}
                    </div>
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
            {IsCheckCoordinateMap === true ? (
              <div className="Ads__Mapppp">
                <YMaps>
                  <Map onClick={(e) => NewMarker(e)} height={600} width={-1} defaultState={defaultState}>
                    <ZoomControl />
                    <TypeSelector />
                    <SearchControl />
                    <Placemark
                      geometry={
                        [CoordinateOnClick[0], CoordinateOnClick[1]]
                      }
                    />
                  </Map>
                </YMaps>
              </div>
            ) : (
              '')
            }
            {ZayvkaBySearch1.length !== 0 && Povtor === true ? (
              <div className="Ads__CentralContForDTable">
                <h1>Ранее принятые заявки по этому адресу:</h1>
                <table className="Ads__tableById2 Ads__tableOleg">
                  <thead>
                    <tr>
                      <th>
                        №Заявки
                      </th>
                      <th>
                        Дата
                      </th>
                      <th>
                        Время
                      </th>
                      <th>
                        Адрес
                      </th>
                      <th>
                        Текст заявки
                      </th>
                      <th>
                        Статус заявки
                      </th>
                      <th>
                        Исполнитель
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {ZayvkaBySearch1.map((item, index) => {
                      return item.seti !== "Теплоснабжение" ? (
                        <>
                          <tr key={index} id={`row-${index}`}>
                            <td>
                              {item.id}
                            </td>
                            <td>
                              {(item.created_date.substring(0, 10)).split('-')[2]}.{(item.created_date.substring(0, 10)).split('-')[1]}.{(item.created_date.substring(0, 10)).split('-')[0]}
                            </td>
                            <td>
                              {(item.created_date.substring(11, 16))}
                            </td>
                            <td>
                              {item.adress}
                            </td>
                            <td>
                              {item.text_application}
                            </td>
                            <td>
                              {item.applicant_status}
                            </td>
                            <td>
                              {item.performer}
                            </td>
                          </tr>
                        </>
                      ) : null;
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              ''
            )}
            {/* <button className="Ads__save" onClick={() => (saveButton(), fetchPostTextZayvka(), HideMapForCoordinate())}>Cохранить</button> */}
            {IsSave === true ? (
              <>
                <button className='Ads__map' onClick={() => (fetchZayvkaAllCoordinate(), Mapp())}>Показать на карте</button>

                {IsCheckSearch === true ? (
                  <div className="Ads__Mappp">
                    <YMaps>
                      <Map height={600} width={-1} defaultState={defaultState}>
                        <ZoomControl />
                        <TypeSelector />
                        {ZayvkaBySearch.map((item, index) => {
                          return (
                            <>
                              {item.applicant_status === "В работе" ? (
                                <>
                                  <Placemark

                                    geometry={
                                      [item.latitude, item.longtitude]
                                    }
                                    properties={{
                                      hintContent: item.applicant_status,
                                      balloonContent: item.adress + ',' + item.object
                                    }}

                                    options={{
                                      preset: 'islands#redStretchyIcon',
                                      draggable: true,
                                    }}
                                    modules={
                                      ['geoObject.addon.balloon', 'geoObject.addon.hint']
                                    }
                                  />
                                </>
                              ) : (
                                ''
                              )}
                              {item.applicant_status === "Отложить" ? (
                                <>
                                  <Placemark

                                    geometry={
                                      [item.latitude, item.longtitude]
                                    }
                                    properties={{
                                      hintContent: item.applicant_status,
                                      balloonContent: item.adress + ',' + item.object
                                    }}

                                    options={{
                                      preset: 'islands#yellowStretchyIcon',
                                      draggable: true,
                                    }}
                                    modules={
                                      ['geoObject.addon.balloon', 'geoObject.addon.hint']
                                    }
                                  />
                                </>
                              ) : (
                                ''
                              )}
                              {item.applicant_status === "Исполнена" ? (
                                <>
                                  <Placemark

                                    geometry={
                                      [item.latitude, item.longtitude]
                                    }
                                    properties={{
                                      hintContent: item.applicant_status,
                                      balloonContent: item.adress + ',' + item.object
                                    }}

                                    options={{
                                      preset: 'islands#greenStretchyIcon',
                                      draggable: true,
                                    }}
                                    modules={
                                      ['geoObject.addon.balloon', 'geoObject.addon.hint']
                                    }
                                  />
                                </>
                              ) : (
                                ''
                              )}
                              {item.applicant_status === "Принято" ? (
                                <>
                                  <Placemark

                                    geometry={
                                      [item.latitude, item.longtitude]
                                    }
                                    properties={{
                                      hintContent: item.applicant_status,
                                      balloonContent: item.adress + ',' + item.object
                                    }}

                                    options={{
                                      preset: 'islands#blueStretchyIcon',
                                      draggable: true,
                                    }}
                                    modules={
                                      ['geoObject.addon.balloon', 'geoObject.addon.hint']
                                    }
                                  />
                                </>
                              ) : (
                                ''
                              )}
                            </>

                          )
                        })}
                      </Map>
                    </YMaps>
                    {IsCheckCoordinateMap === true ? (
                      <>
                        <div className="Ads__Mappp-legend1">
                          <div className="Ads__Mappp-legend-mark Ads__Mappp-legend-mark-red">
                            Красный - статус: "В работе"
                          </div>
                          <div className="Ads__Mappp-legend-mark Ads__Mappp-legend-mark-yelow">
                            Желтый - статус: "Отложить"
                          </div>
                          <div className="Ads__Mappp-legend-mark Ads__Mappp-legend-mark-blue">
                            Синий - статус: "Принято"
                          </div>
                          <div className="Ads__Mappp-legend-mark Ads__Mappp-legend-mark-green">
                            Зеленый - статус: "Исполнена"
                          </div>
                        </div>

                        <label className="Ads__text-input Ads__block Ads__selectSearch3">Критерий поиска:<select className="Ads__sel Ads__block Ads__selectSearch2" onChange={(e) => NameOfSearch(e)}><option>По дате</option><option>По адресу</option><option>По объекту аварии</option></select></label>
                        {NameSearch.value === "По адресу" ? (
                          <div>
                            <span className="Ads__Mappp-search-dadata1"><AddressSuggestions token="6dc7b29f2b8e6eb9bc537838a324af7caafc5eca" type='text' id='address' value={valueDaData} onChange={setValueDaData} /></span>
                            <button className="Ads__Mappp-btnSearch1" onClick={() => (Search(), Mappp(), fetchSearchAdr())}>Поиск</button>
                          </div>
                        )
                          : (
                            ''
                          )}
                        {NameSearch.value === "По объекту аварии" ? (
                          <div>
                            <select className="Ads__Mappp-search1" onChange={(e) => WhatSearch(e)}><option>Течь сети</option><option>Течь колодца</option><option>Течь пожарного гидранта</option><option>Течь колонки</option><option>Засор канализации</option><option>Иное</option></select>
                            <button className="Ads__Mappp-btnSearch1" onClick={() => (Search(), Mappp(), fetchSearchObj())}>Поиск</button>
                          </div>
                        )
                          : (
                            ''
                          )}
                        {NameSearch.value === "По дате" ? (
                          <div>
                            <input className="Ads__Mappp-search-data1" type="date" defaultValue={getCurrentDate()} onChange={(e) => WhatSearch(e)}></input>
                            <button className="Ads__Mappp-btnSearch1" onClick={() => (Search(), Mappp(), fetchSearchDate())}>Поиск</button>
                          </div>
                        )
                          : (
                            ''
                          )}
                        <button className='Ads__map-button1' onClick={() => (Mapp(), Ssearch())}>Вернуться к полной карте</button>
                      </>
                    ) :
                      (
                        <>
                          <div className="Ads__Mappp-legend">
                            <div className="Ads__Mappp-legend-mark Ads__Mappp-legend-mark-red">
                              Красный - статус: "В работе"
                            </div>
                            <div className="Ads__Mappp-legend-mark Ads__Mappp-legend-mark-yelow">
                              Желтый - статус: "Отложить"
                            </div>
                            <div className="Ads__Mappp-legend-mark Ads__Mappp-legend-mark-blue">
                              Синий - статус: "Принято"
                            </div>
                            <div className="Ads__Mappp-legend-mark Ads__Mappp-legend-mark-green">
                              Зеленый - статус: "Исполнена"
                            </div>
                          </div>

                          <label className="Ads__text-input Ads__block Ads__selectSearch1">Критерий поиска:<select className="Ads__sel Ads__block Ads__selectSearch2" onChange={(e) => NameOfSearch(e)}><option>По адресу</option><option>По дате</option><option>По объекту аварии</option></select></label>
                          {NameSearch.value === "По адресу" ? (
                            <div>
                              <span className="Ads__Mappp-search-dadata"><AddressSuggestions token="6dc7b29f2b8e6eb9bc537838a324af7caafc5eca" type='text' id='address' value={valueDaData} onChange={setValueDaData} /></span>
                              <button className="Ads__Mappp-btnSearch" onClick={() => (Search(), Mappp(), fetchSearchAdr())}>Поиск</button>
                            </div>
                          )
                            : (
                              ''
                            )}
                          {NameSearch.value === "По объекту аварии" ? (
                            <div>
                              <select className="Ads__Mappp-search" onChange={(e) => WhatSearch(e)}><option>Течь сети</option><option>Течь колодца</option><option>Течь пожарного гидранта</option><option>Течь колонки</option><option>Засор канализации</option><option>Иное</option></select>
                              <button className="Ads__Mappp-btnSearch" onClick={() => (Search(), Mappp(), fetchSearchObj())}>Поиск</button>
                            </div>
                          )
                            : (
                              ''
                            )}
                          {NameSearch.value === "По дате" ? (
                            <div>
                              <input className="Ads__Mappp-search-data" type="date" defaultValue={getCurrentDate()} onChange={(e) => WhatSearch(e)}></input>
                              <button className="Ads__Mappp-btnSearch" onClick={() => (Search(), Mappp(), fetchSearchDate())}>Поиск</button>
                            </div>
                          )
                            : (
                              ''
                            )}
                          <button className='Ads__map-button' onClick={() => (Mapp(), Ssearch())}>Вернуться к полной карте</button>
                        </>
                      )
                    }
                  </div>
                ) :
                  (
                    ''
                  )}

              </>
            )
              : (
                ''
              )}
          </>
        )
          : (
            ''
          )}
        {IsIspolnenieZayvka ? (
          <>
            <div className="Ads__ContarRabTable">
              <table className="Ads__tableById2 Ads__tableOleg">
                <thead>
                  <tr>
                    <th>
                      №Заявки
                    </th>
                    <th>
                      Дата
                    </th>
                    <th>
                      Время
                    </th>
                    <th>
                      ФИО оператора
                    </th>
                    {/* <th>
                      Исполнитель
                    </th> */}
                    <th>
                      Адрес
                    </th>
                    <th>
                      Фио заявителя
                    </th>
                    <th>
                      Номер заявителя
                    </th>
                    <th>
                      Текст заявки
                    </th>
                    <th>
                      Категория заявки
                    </th>
                    <th>
                      Заявка подробно
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {ZayvkaAllAct.map((item, index) => {
                    return (
                      <>
                        {item.performer === itemFromLocalStorage1 ? (
                          <tr key={index} id={`row-${index}`}>
                            <td>
                              {item.id}
                            </td>
                            <td>
                              {(item.created_date.substring(0, 10)).split('-')[2]}.{(item.created_date.substring(0, 10)).split('-')[1]}.{(item.created_date.substring(0, 10)).split('-')[0]}
                            </td>
                            <td>
                              {(item.created_date.substring(11, 16))}
                            </td>
                            <td>
                              {item.dispatcher_name}
                            </td>
                            {/* <td>
                              {item.performer}
                            </td> */}
                            <td>
                              {item.adress}
                            </td>
                            <td>
                              {item.fullname}
                            </td>
                            {item.phone_number !== null ? (
                              <td>
                                {item.phone_number}
                              </td>
                            ) : (
                              <td>
                                {item.home_phone_number}
                              </td>
                            )}
                            <td>
                              {item.text_application}
                            </td>
                            <td>
                              {item.category}
                            </td>
                            <td>
                              {item.seti === "Благоустройство" ? (
                                <button className='Ads__EdtFragBtn' onClick={() => (OpenModal3(), fetchZayvka(item.id))}>+</button>
                              ) : (
                                ''
                              )}
                              {item.seti === "Водоснабжение" ? (
                                <button className='Ads__EdtFragBtn' onClick={() => (OpenModal4(), fetchZayvka(item.id))}>+</button>
                              ) : (
                                ''
                              )}
                              {item.seti === "Канализация" ? (
                                <button className='Ads__EdtFragBtn' onClick={() => (OpenModal111(), fetchZayvka(item.id))}>+</button>
                              ) : (
                                ''
                              )}

                            </td>
                          </tr>
                        ) : (
                          ''
                        )}

                      </>
                    )
                  })}
                </tbody>
              </table>
            </div>
            {loading && <div className="Ads__loading-overlay">
              <div className="Ads__loading-wheel"></div>
            </div>}
            {IsChecModal4 === true ? (
              <>
                <div className="Ads__ModalBg">
                  {ModalRab()}
                </div>
              </>
            ) : (
              ''
            )}
            {IsChecModal3 === true ? (
              <>
                <div className="Ads__ModalBg">
                  {ModalRab1()}
                </div>
              </>
            ) : (
              ''
            )}
            {IsChecModal111 === true ? (
              <>
                <div className="Ads__ModalBg">
                  {ModalRab2()}
                </div>
              </>
            ) : (
              ''
            )}
            {ZayvkaById.length !== 0 ? (
              <>

                {ZayvkaById.applicant_status === "Уточнить на месте" ? (
                  <>
                    <div className="Ads__PhotoCont">
                      <img className="Ads__photoObj" src={ZayvkaById.photo_object} alt="нет фотки" />
                    </div>
                    <div className="Ads__text">
                      <label className="Ads__text-input Ads__block">Окончательный статус заявки:<select className="Ads__sel Ads__block" name='applicant_status' onChange={(e) => GetStatus(e)}><option>В работе</option><option>Отложить</option></select></label>
                    </div>
                    <button className="Ads__text-input__getZ" onClick={() => (fetchChangeStatus())}>Установить статус</button>
                  </>
                )
                  : (
                    ''
                  )}
              </>
            ) : (
              ""
            )}
            {IsCheckChangeCoor === true ? (
              <>
                <div className="Ads__Mapppp">
                  <YMaps>
                    <Map onClick={(e) => NewMarker(e)} height={600} width={-1} defaultState={defaultState1}>
                      <ZoomControl />
                      <TypeSelector />
                      <SearchControl />
                      <Placemark
                        geometry={
                          [ZayvkaById.latitude, ZayvkaById.longtitude]
                        }
                        properties={{
                          hintContent: "Старая метка",
                          balloonContent: "Старая метка"
                        }}
                        modules={
                          ['geoObject.addon.balloon', 'geoObject.addon.hint']
                        }
                      />
                      <Placemark
                        geometry={
                          [CoordinateOnClick[0], CoordinateOnClick[1]]
                        }
                        properties={{
                          hintContent: "Новая метка",
                          balloonContent: "Новая метка"
                        }}
                        modules={
                          ['geoObject.addon.balloon', 'geoObject.addon.hint']
                        }
                      />
                    </Map>
                    <div className='Ads__ForBtnInMapChange'>
                      <button className='Ads__BtnOnChangeMap' onClick={() => (fetchChangeCoor(), CheckOffChangeCoorMap())}>Изменить</button>
                      <button className='Ads__BtnOnChangeMap' onClick={() => (CheckOffChangeCoorMap(), ClearCoorChange())}>Отменить изменение</button>
                    </div>
                  </YMaps>
                </div>
              </>
            ) : (
              ''
            )}
          </>
        )
          : (
            ''
          )}
        {IsAnalytic === true ? (
          <>
          </>
        )
          : (
            ''
          )}
      </div>
      <Footer />
    </>
  )
}


export default Ads
