export const COLORS = {
    accepted: "#6366F1",
    executed: "#059669",
    InWork: "#B91C1C",
    Cons: "#ffff00",
    accepted1: '#3E40A1',
    executed1: '#017D59',
    InWork1: '#8B0C0C',
    Cons1: '#CCCC00',
    blue: '#0074D9',
    darkBlue: '#001f3f',
    brown: '#8B4513',
    darkBrown: '#5A2F0C',
    green: '#2ECC40',
    darkGreen: '#196F3D'
  };