import React, { useState, useContext, useEffect } from "react";

import { API_URL } from "../../../../../../../config";
import AuthContext from "../../../../../../../context/AuthContext";

import "./ButtonAddWear.css";

export const Perecritia = () => {
  const { authTokens } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    plotName: "",
    wear: "",
    area: "",
    photo: null,
  });

  // Определяем переменные
  const currentDate = new Date(); // Получаем текущую дату
  const year = currentDate.getFullYear(); // Получаем год
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Получаем месяц (месяцы начинаются с 0, поэтому добавляем 1) и добавляем 0 спереди
  const day = String(currentDate.getDate()).padStart(2, "0"); // Получаем день и добавляем 0 спереди
  const data_in_wear = `${year}-${month}-${day}`; // Форматируем в строку "YYYY-MM-DD"
  
  const idHouse = localStorage.getItem("ID_House"); // Получаем ID из localStorage

  const element = 2; // Элемент

  const floorType = 1;

  // Функция обнуления значений
  const resetFormData = () => {
    setFormData({
      plotName: "",
      wear: "",
      area: "",
      photo: null,
    });
  };

  //Считывание текста
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  //Считывание фото
  const handlePhotoUpload = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      photo: file,
    }));
  };

  // Отправка данных
  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestData = {
      position: formData.plotName,
      data: data_in_wear,
      Element: element,
      volume: formData.area,
      degreeOfDamage: formData.wear,
      idHouse: idHouse,
    };

    try {
      const response = await fetch(`${API_URL}/CivilWear/Damage/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Ошибка при отправке данных: ${errorText}`);
      }

      const result = await response.json();
      console.log("Успешно отправлено:", result);
      alert("Износ добавлен !");
      resetFormData();
    } catch (error) {
      console.error("Ошибка при отправке:", error);
    }
  };

  let data = []; //Данные для таблицы

  switch (floorType) {
    case 1:
      data = [
        ["Признаки износа", "Количественная оценка", "Физический износ, %"],
        [
          "Зазоры и щели между досками наката, прогибы балок и настилов",
          "Прогибы балок и настилов до 1/150 пролета",
          "0-40",
        ],
        [
          "Поражение верхних слоев древесины грибком, небольшие трещины, частичное скалывание в узлах соединений балок с настилом, прогиб балок и прогонов",
          "Поражение гилью на площади до 10%. Прогиб балок и прогонов до 1/100 пролета",
          "41-60",
        ],
        [
          "Сильное поражение древесины гнилью, появление продольных и поперечных трещин, расслоение древесины, полное или частичное скалывание в узлах сопряжений балок, прогиб балок и прогонов",
          "Прогиб балок и прогонов до 1/50 пролета",
          "61-80",
        ],
      ];

      break;
    case 2:
      data = [
        ["Признаки износа", "Количественная оценка", "Физический износ, %"],
        ["Незначительные трещины перпендикулярно балкам", "-", "0-20"],
        [
          "Трещины в средней части сводов вдоль балок",
          "Ширина трещин до 1 мм",
          "21-40",
        ],
        [
          "Глубокие трещины в средней части сводов вдоль балок, расшатывание отдельных кирпичей, выщелачивание раствора в швах, выпадение отдельных кирпичей, коррозия балок",
          "То же, до 2 мм. Уменьшение сечения балок на 10%",
          "41-60",
        ],
        [
          "Ослабление кирпичной кладки, массовое выпадение кирпичей, наличие временных подпорок, коррозия и заметные прогибы балок",
          "Прогиб металлических балок до 1/150 пролета. Уменьшение сечения балок более 10%",
          "61-80",
        ],
      ];

      break;
    case 3:
      data = [
        ["Признаки износа", "Количественная оценка", "Физический износ, %"],
        [
          "Мелкие отслоения и трещины в фактурном слое",
          "Повреждения на площади до 10%",
          "0-10",
        ],
        [
          "Отпадение фактурного слоя местами",
          "Повреждения на площади до 20%",
          "11-20",
        ],
        [
          "Усадочные трещины в нижних плитах",
          "Ширина трещин до 1 мм. Суммарная длина трещин на 1 м2 до 0,5 м",
          "21-30",
        ],
        [
          "Отдельные глубокие трещины в нижних плитах и в местах опирания плит, прогибы",
          "Ширина трещин до 2 мм. Прогибы до 1/120 пролета",
          "31-40",
        ],
        [
          "Продольные и поперечные глубокие трещины на нижних плитах, прогиб нижних плит, продавливание верхних плит под мебелью",
          "Ширина трещин до 3 мм. Суммарная длина трещин на 1 м2 до 1 м. Прогиб до 1/100 пролета",
          "41-50",
        ],
        [
          "Массовые сквозные продольные трещины на нижних плитах, отпадение защитного слоя нижних плит с обнажением арматуры, изломы и прогибы плит",
          "Прогиб до 1/50 пролета",
          "51-60",
        ],
        [
          "Прогибы, местами отпадение бетона нижних плит, отслоение бетона и обнажение ребер верхних плит",
          "Прогиб до 1/50 пролета",
          "61-80",
        ],
      ];

      break;
    case 4:
      data = [
        ["Признаки износа", "Количественная оценка", "Физический износ, %"],
        ["Трещины в швах между плитами", "Ширина трещин до 2 мм", "0-10"],
        [
          "Незначительное смещение плит относительно друг друга по высоте",
          "Смещение плит до 1,5 см. Повреждения на площади до 10%",
          "11-20",
        ],
        [
          "Значительное смещение плит перекрытий по высоте",
          "Смещение плит до 3 см. Повреждения на площади до 20%",
          "21-30",
        ],
        [
          "Трещины в плитах, следы протечек или промерзаний",
          "Ширина трещин до 1 мм",
          "31-40",
        ],
        [
          "Поперечные трещины в плитах без оголения арматуры, прогиб",
          "Ширина трещин до 2 мм. Прогиб до 1/100 пролета",
          "41-50",
        ],
        [
          "Глубокие поперечные трещины с оголением арматуры, прогиб",
          "Ширина трещин более 2 мм. Прогиб до 1/80 пролета",
          "51-60",
        ],
        [
          "Множественные глубокие трещины в плитах, смещение",
          "Прогиб более 1/80 пролета",
          "61-80",
        ],
      ];

      break;
    case 5:
      data = [
        ["Признаки износа", "Количественная оценка", "Физический износ, %"],
        [
          "Трещины в местах примыканий к стенам",
          "Ширина трещин до 0,5 мм",
          "0-10",
        ],
        [
          "Трещины в плитах (усадочные или вдоль рабочего пролета)",
          "Ширина трещин до 2 мм. Суммарная длина усадочных трещин на 1 м2 до 0,8 м",
          "11-20",
        ],
        [
          "Трещины в плитах поперек рабочего пролета или множественные усадочные",
          "Ширина раскрытия трещин до 2 мм. Суммарная длина усадочных трещин на 1 м2 до 1,5 м",
          "21-30",
        ],
        [
          "Трещины, прогибы, следы протечек или промерзаний в местах примыканий к наружным стенам",
          "Трещины более 2 мм. Прогибы до 1/150 пролета",
          "31-40",
        ],
        [
          "Развивающиеся трещины у опорных участков плит, прогибы",
          "Прогибы до 1/100 пролета",
          "41-50",
        ],
        [
          "Увеличение трещин и прогибов во времени",
          "Прогибы до 1/100 пролета. Трещины 3 мм",
          "51-80",
        ],
      ];

      break;
    case 6:
      data = [
        ["Признаки износа", "Количественная оценка", "Физический износ, %"],
        [
          "Отдельные трещины в растянутой зоне, незначительное увлажнение местами, поверхностные отколы в растянутой зоне, прогибы",
          "Ширина трещин до 1 мм. Глубина отколов до 3 мм, не более трех на 1 м2",
          "0-40",
        ],
        [
          "Трещины различных направлений, следы увлажнения бетона атмосферными и агрессивными водами, отслоение защитного слоя бетона в растянутой зоне, оголение и коррозия арматуры, механические повреждения и глубокие сколы бетона на большой площади балки, прогиб",
          "Ширина трещин до 2 мм. Коррозия арматуры до 10% сечения. Прогиб до1/50 пролета",
          "41-60",
        ],
        [
          "Трещины по всей длине и высоте балки в середине пролета и в растянутой зоне, следы постоянного увлажнения бетона атмосферными и агрессивными водами, оголение и сильная коррозия арматуры, местами разрывы арматуры, крупные выбоины и сколы бетона в сжатой зоне",
          "Ширина трещин более 2 мм. Коррозия арматуры более 10% сечения. Прогиб более 1/150 пролета",
          "61-80",
        ],
      ];

      break;

    default:
      console.log("Ошибка !");
  }

  return (
    <div className="container_Wear_ButtonAddWear">
      <form className="form_Wear_ButtonAddWear" onSubmit={handleSubmit}>
        <h3>Перекрытия:</h3>
        <div className="form-group_Wear_ButtonAddWear">
          <label htmlFor="plotName" className="label_Wear_ButtonAddWear">
            Обозначение участка
          </label>
          <input
            className="input_Wear_ButtonAddWear"
            type="text"
            id="plotName"
            name="plotName"
            value={formData.plotName}
            onChange={handleChange}
          />
        </div>

        <div className="form-group_Wear_ButtonAddWear">
          <label htmlFor="wear" className="label_Wear_ButtonAddWear">
            Фактический физический износ участка
          </label>
          <input
            className="input_Wear_ButtonAddWear"
            type="text"
            id="wear"
            name="wear"
            value={formData.wear}
            onChange={handleChange}
          />
        </div>

        <div className="form-group_Wear_ButtonAddWear">
          <label htmlFor="area" className="label_Wear_ButtonAddWear">
            Площадь участка
          </label>
          <input
            className="input_Wear_ButtonAddWear"
            type="text"
            id="area"
            name="area"
            value={formData.area}
            onChange={handleChange}
          />
        </div>

        <div className="form-group_Wear_ButtonAddWear">
          <label htmlFor="photo" className="label_Wear_ButtonAddWear">
            Прикрепить фото
          </label>
          <input
            className="input_Wear_ButtonAddWear"
            type="file"
            id="photo"
            onChange={handlePhotoUpload}
          />
        </div>

        <button type="submit" className="submit-button_Wear_ButtonAddWear">
          Сохранить данные
        </button>
      </form>

      <div className="table-container_Wear_ButtonAddWear">
        <table className="table_Wear_ButtonAddWear">
          <thead>
            <tr>
              {data[0] &&
                data[0].map((header, index) => <th key={index}>{header}</th>)}
            </tr>
          </thead>
          <tbody>
            {data.slice(1).map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
